import { DatePicker } from "@pankod/refine-antd";
import { BaseRecord, useUpdate } from "@pankod/refine-core";
import dayjs from 'dayjs';
import React, { useEffect, useRef, useState } from "react";
import { BsCalendar2Week } from 'react-icons/bs';

import { USER_ID } from "authProvider";
import { useMyTask } from "../Context/MyTask.context";
import "./PopUpCarlendar.scss";

interface PopUpCarlendarProps {
  resourceId?: number;
  dueOn?: string;
  placeholder?:string
}
export const PopUpCarlendar: React.FC<PopUpCarlendarProps> = ({resourceId, dueOn, placeholder}) => {
const [date, setDate] = useState<BaseRecord>({
  type: null,
  value: null
})
const values = useMyTask()
const datePickerRef = useRef<any>(null)
const setComments = values?.setComments
const userId = localStorage.getItem(USER_ID)


const {mutate} = useUpdate()

const handleDateChange = (val: any) => {
if(val) {
  datePickerRef.current?.blur()
  const getDate = (dateTime: Date) => {
    
    if(date.value){
      dateTime.setDate(dateTime.getDate() + 1)
    }

    const isoDate = dateTime.toISOString()
    return isoDate.split('T')[0]
  
  }
  const dateNoTime = getDate(val.$d)
  setDate({
    type: 'add',
    value: dateNoTime
  })
} else {
  setDate({
    type: 'remove',
    value: val
  })
}
  
}

useEffect(() => {
  
  if(resourceId && date.value && (dueOn !== date.value) && date.type === 'add') {
    const dateValues = {
      dueAt: date.value,
      dueOn: date.value,
      type: 'addduedate'
    }

    mutate({
      resource: "resourcesdetails/upsert",
      id: resourceId,
      values: dateValues,
      successNotification: {
        message: "Thành công !",
        type: "success" 
      }
    }, {
      onSuccess(data, variables, context) {
        const newComment = {
          resourcesid: Math.random(),
          resources: {
              createdAt: (new Date()).toISOString(),
              createdby: userId,
              name: `{{${userId}}} changed the due date to ${date.value}`,
              resourcetype: "log"
          }
        } 

          setTimeout(() => {
            values?.setResourceDetailsChange({
              dueOn: date.value
            })
            setComments && setComments(prev => [...prev, newComment])
          },0)
        
      },
    })
  }

  if(resourceId && date.type === 'remove') {

    mutate({
      resource: "resourcesdetails/upsert",
      id: resourceId,
      values: {
        dueAt:null,
        dueOn:null,
      type: 'removeduedate'

      },
      successNotification: {
        message: "Xóa Thành công !",
        type: "success" 
      }
    }, {
      onSuccess(data, variables, context) {
        const newComment = {
          resourcesid: Math.random(),
          resources: {
              createdAt: (new Date()).toISOString(),
              createdby: userId,
              name: `{{${userId}}} removed the due date.`,
              resourcetype: "log"
          }
        } 
          setTimeout(() => {
            values?.setResourceDetailsChange({
              dueOn: null
            })
            setComments && setComments(prev => [...prev, newComment])
          },0)
        
      },
    })

  }

},[date])




useEffect(() => {
  dueOn && setDate({value:dueOn})
 if(!dueOn) {
  setDate({value:null})
 }
}, [dueOn])

const dateFormat = 'DD - MM - YYYY'
  return (     
    <>
      <DatePicker 
        placeholder={placeholder ? placeholder : ''}
        format={dateFormat}
        placement="bottomRight" 
        className="datepicker"
        onChange={handleDateChange}
        value={date.value ? dayjs(date.value) : null}
        suffixIcon={<BsCalendar2Week />}
        ref={datePickerRef}
        
        />
        
    </>
  );
};
