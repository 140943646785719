import axios from "axios";
import { Button, DataGrid, Toolbar } from "devextreme-react";
import {
  Column,
  Editing,
  FilterRow,
  Form,
  HeaderFilter,
  Item as ItemToolbar,
  Popup,
  ToolbarItem,
} from "devextreme-react/data-grid";
import { Item } from "devextreme-react/form";
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { toast } from "react-toastify";
import { CauHinhPopupInsert } from "./cauhinhPopupInsert";
import { CauHinhPopupUpdate } from "./cauhinhPopupUpdate";
import "./danhsachform.css";
interface Props {
  setFormId: Dispatch<SetStateAction<number>>;
}

export const DanhSachFormHOC = React.memo(({ setFormId }: Props) => {
  const dataGrid = useRef<any | undefined>();
  const edittingKey = useRef(null);
  const titleKey = useRef(null);
  const [listForm, setListForm] = useState([]);
  const [randomNumber, setRandomNumber] = useState(0);
  const [openPopUpUpdate, setOpenPopupUpdate] = useState(false);
  const [openPopUpInsert, setOpenPopupInsert] = useState(false);
  const [dataRow, setDataRow] = useState({});

  useEffect(() => {
    const sendRequest = async () => {
      const results = await axios.get(`/resources/get-tat-ca-danh-sach-form`);

      setListForm(results.data);
    };
    sendRequest();
  }, [randomNumber]);


  const onRowClick = () => {};

  const onRowDblClick = () => {
    titleKey.current = edittingKey?.current;
    const rowIndex = dataGrid.current.instance.getRowIndexByKey(
      edittingKey.current
    );
    dataGrid.current.instance.editRow(rowIndex);
  };

  const onRowRemoving = () => {};

  const onInitNewRow = () => {
    dataGrid.current.instance.option("editing.popup.title", "Thêm mới form");
  };
  const onEditingStart = (e: any) => {
    dataGrid.current.instance.option(
      "editing.popup.title",
      "Chỉnh sửa bản ghi"
    );
  };

  const onFocusedRowChanged = (e: any) => {
    const clickedRowKey = e?.row?.data?.id;

    edittingKey.current = clickedRowKey;
    setFormId(clickedRowKey);
  };

  const linkRender = (e: any) => {
    const copyLink = (e: any, key: any, data: any) => {
      navigator.clipboard.writeText(`${window.location.origin}/form/show/${key}`);
      toast.success("Copy thành công!", {
        autoClose: 3000,
        style: {
          fontSize: "14px",
          position: "fixed",
          top: "0px",
          right: "0px",
        },
      });
    };

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          color: "blue",
          backgroundColor: "transparent",
        }}
      >
        <button
          onClick={() => copyLink(e, e.key, e.data.chinhanh_id)}
          style={{
            backgroundColor: "inherit",
            border: "none",
            textDecoration: "underline",
            color: "blue",
          }}
        >
          Copy
        </button>
      </div>
    );
  };

  const addRow = () => {
    setOpenPopupInsert(true)
  };
  const closePopup = () => {
    dataGrid.current.instance.cancelEditData();
  };

  const editRow = () => {
    titleKey.current = edittingKey?.current;
    const rowIndex = dataGrid.current.instance.getRowIndexByKey(
      edittingKey.current
    );
    const currentFocus = dataGrid.current.instance.option("focusedRowKey");
    const data = dataGrid.current.instance.getDataByKeys([currentFocus])
      .resolveArgs[0][0];
    if (rowIndex !== -1) {
      setDataRow(data);
      setOpenPopupUpdate(true);
    }
  };

  const saveItem = (e: any) => {
    dataGrid.current.instance.saveEditData();
  };

  const saveClose = (e: any) => {

    return (
      <div style={{ display: "flex" }}>
        <div>
          <Button icon="save" type="default" text="Lưu" onClick={saveItem} />
        </div>
        <div style={{ marginLeft: "5px" }}>
          <Button
            icon="close"
            type="danger"
            text="Hủy bỏ"
            onClick={closePopup}
          />
        </div>
      </div>
    );
  };


  return (
    <div style={{}} id="danhsachform">
      <h1 style={{ marginLeft: "10px" }}>DANH SÁCH FORM</h1>
      <div style={{ height: "100%" }}>
        <React.Fragment>
          <DataGrid
            dataSource={listForm}
            showBorders={true}
            hoverStateEnabled={true}
            allowColumnReordering={true}
            rowAlternationEnabled={true}
            repaintChangesOnly={true}
            height="90%"
            width="95%"
            ref={dataGrid}
            remoteOperations={true}
            focusedRowEnabled={true}
            keyExpr="id"
            style={{ fontSize: "14px" }}
            allowColumnResizing={true}
            onRowClick={onRowClick}
            onRowDblClick={onRowDblClick}
            onRowRemoving={onRowRemoving}
            onInitNewRow={onInitNewRow}
            onEditingStart={onEditingStart}
            onFocusedRowChanged={onFocusedRowChanged}
          >
            <FilterRow visible={true} />
            <HeaderFilter visible={true} />
            <Editing mode="popup">
              <Popup
                showTitle={true}
                width={"500px"}
                height={"300px"}
                onHidden={closePopup}
              >
                <ToolbarItem
                  text="Lưu"
                  location="after"
                  toolbar="bottom"
                  render={saveClose}
                ></ToolbarItem>
              </Popup>
              <Form>
                <Item itemType="group" colCount={1} colSpan={2}>
                  <Item dataField="name"></Item>
                </Item>
              </Form>
            </Editing>
            <Column
              dataField="id"
              caption="ID Form"
              dataType="string"
              width={90}
            />
            <Column
              dataField="name"
              caption="Tên Form"
              dataType="string"
              width={250}
            />
            <Column
              dataField="createdAt"
              caption="Link"
              dataType="string"
              width={70}
              cellRender={linkRender}
              allowResizing={false}
            />
          </DataGrid>
          <Toolbar>
            <ItemToolbar location={"before"}>
              <Button
                style={{ marginLeft: "5px" }}
                icon="add"
                type="default"
                onClick={addRow}
                text="Thêm Mới"
              />
            </ItemToolbar>
            <ItemToolbar location={"before"}>
              <Button
                style={{ marginLeft: "5px" }}
                icon="edit"
                type="default"
                onClick={editRow}
                text="Sửa"
              />
            </ItemToolbar>
          </Toolbar>
        </React.Fragment>
        <CauHinhPopupInsert
          open={openPopUpInsert}
          setOpen={setOpenPopupInsert}
          dataGrid={dataGrid}
          setRandomNumber={setRandomNumber}
        />
        <CauHinhPopupUpdate
          open={openPopUpUpdate}
          setOpen={setOpenPopupUpdate}
          dataRow={dataRow}
          dataGrid={dataGrid}
          setRandomNumber={setRandomNumber}
        />
      </div>
    </div>
  );
});
