import create from 'zustand'

interface InboxFilterState {
    isFilterByFromPerson: boolean
    setIsFilterByFromPerson: (arg: boolean) => void
    isOpenPopupToSelectFilter: boolean
    setIsOpenPopupToSelectFilter: (arg: boolean) => void
    selectedUserId: number | null
    setSelectedUserId: (arg: number | null) => void
}
const useFilterState = create<InboxFilterState>((set) => ({
    isFilterByFromPerson: false,
    selectedUserId: null,
    setIsFilterByFromPerson: (arg: boolean) => set((state) => {
        state.isFilterByFromPerson = arg
        return {...state}
    }),
    isOpenPopupToSelectFilter: false,
    setIsOpenPopupToSelectFilter: (arg: boolean) => set((state) => {
        state.isOpenPopupToSelectFilter = arg
        return {...state}
    }),
    setSelectedUserId: (arg: number | null) => set((state) => {
        state.selectedUserId = arg
        return {...state}
    }),
    
}))

export default useFilterState