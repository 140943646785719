import { keyBy } from 'lodash'
import create from 'zustand'

export type User = {
    name: string,
    id: number
}
export interface GlobalDataType {
    users: {
        [field : string]: User
    },
    addUsers:  (users: User[]) => void,
    readableProjects: any[],
    addReadableProjects: (projects: any[]) => void
    isScrollVisible: boolean
    updateScrollVisible:  (arg: boolean) => void,
}

export const useGlobalDataState = create<GlobalDataType>((set) => ({
    users: {},
    isScrollVisible: false,
    addUsers: (users: User[]) => set((state) => {
        state.users = keyBy(users, "id")
        return { ...state }
    }),
    readableProjects: [],
    addReadableProjects: (projects: any[]) => set((state) => {
        state.readableProjects = projects
        return { ...state }
    }),
    updateScrollVisible: (arg: boolean) => set((state) => {
        state.isScrollVisible = arg
        return { ...state }
    }),
 
}))