import { message, Tooltip } from "@pankod/refine-antd";
import {
  BaseRecord,
  useNavigation,
  useRouterContext,
} from "@pankod/refine-core";
import MarkComplete from "components/Mytask/mtSession/SectionRow/MarkComplete/MarkComplete";
import React, { useEffect, useState } from "react";
import { BiArrowToRight } from "react-icons/bi";
import { IoMdClose } from "react-icons/io";
import { useMyTask } from "../../Context/MyTask.context";
import { headerItems } from "./Data";
import "./DetailsHeader.scss";
import ProjectMentiontask from "pages/Teams/Inputmension/ProjectMentiontask";
import axios from "axios";
import { TOKEN_KEY_LOGIN } from "authProvider";
import { useDetectProposePage, useSelectTask } from "pages/ProposeTasks/hooks";
import ProposeMarkComplete from "pages/ProposeTasks/components/MarkComplete";
import { useProposeTaskDetail } from "pages/ProposeTasks/state/details";
import { extractParametersWithRegex, extractValuesFromURL } from "pages/ProposeTasks/services";
import { RECENTLYTASK } from "components/Mytask/Constant";
import MarkCompletedRecently from "pages/Recently-completed-task/components/MarkCompletedRecent";
import { useRecentlyState } from "pages/Recently-completed-task/state";
import { useRecentlyTaskOld } from "pages/Recently-completed-task/state/recently-task-old";
import { useTaskRecentSelect } from "pages/Recently-completed-task/hooks";

interface DetailsHeaderProps {
  type?: string;
  disPlayStatus?: any
}
export const DetailsHeader: React.FC<DetailsHeaderProps> = ({ type,disPlayStatus }) => {
  const values = useMyTask();
  const { isProposeTaskPage } = useDetectProposePage();
  const { task: proposeDetailTask } = useProposeTaskDetail();

  const sectionId = proposeDetailTask?.sectionId as number;
  const proposeTaskId = proposeDetailTask?.taskId as number;
  const task = useSelectTask(sectionId, proposeTaskId);


  //Variable Recently

  const selectedTaskId:any = values?.selectedTaskId
  const {task: taskRecently}:any = useRecentlyTaskOld()
  const taskRecentSelect = useTaskRecentSelect(taskRecently?.taskId ?? selectedTaskId)
  
  //End Variable Recently

  const handleClose = () => {
    values?.setShowMyTaskDetails(false);
    values?.setCurrentTaskDetailId(null);
    values?.setCurrentTaskDetailTitle(undefined);
    values?.setSubTaskDetailsMode(false);
    values?.setTaskDetailsQueue([]);

    if (isProposeTaskPage) return;

    if (idUserTaskList === values?.currentProjectId) {
      window.history.replaceState(
        {},
        "task/show",
        `/mytask/show/${idUserTaskList}`
      );
      values?.setPathName(`/mytask/show/${idUserTaskList}`);
    } else {
      window.history.replaceState(
        {},
        "task/show",
        `/projects/show/${values?.currentProjectId}`
      );
      values?.setPathName(`/projects/show/${values?.currentProjectId}`);
    }
  };

  const { push, goBack, replace } = useNavigation();
  const { useParams, useLocation } = useRouterContext();
  const params = useParams() as BaseRecord;
  const locations = useLocation() as BaseRecord;
  const idUserTaskList = JSON.parse(
    localStorage.getItem(TOKEN_KEY_LOGIN) || ""
  );
  const [nameTitleTask, setNameTaskTitle] = useState("");

  const resourceId =
    typeof values?.currentTaskDetailId === "number"
      ? values?.currentTaskDetailId
      : undefined;

  const completedStatus = values?.detailsHeaderData
    ? values?.detailsHeaderData[0]?.completed
    : undefined;

  const taskId = values?.currentTaskDetailId;

  const handleIconCLick = (item: any) => {
    if (item.type === "link") {
      handleCopyLink();
    } else if (item.type === "expand") {
      goFullScreenTask();
    }
  };

  useEffect(() => {
    let url = `resources/${taskId}`;
    if (!values?.currentTaskDetailTitle && taskId) {
      axios.get(url).then((res) => {
        if (res.data) {
          setNameTaskTitle(res?.data?.name);
        }
      });
    }
  }, [taskId, values?.currentTaskDetailTitle]);

  const handleCopyLink = () => {
    navigator.clipboard.writeText(
      `${window.location.origin}/task/show/${taskId}?projectid=${values?.currentProjectId}&fullscreen=true`
    );
    message.success("Copied link!");
  };

  const goFullScreenTask = () => {
    let { search } = locations;
    const regex = /[?&]projectid=(\d+)/;
    const match = regex.exec(search);
    const projectId2 = match && match[1];
    const url = window.location.href;

    if (url.includes("Inbox")) {
      push(
        `/task/show/${taskId}?projectid=${values?.currentProjectId}&resource=Inbox&fullscreen=true`
      );
    } else {
      if (idUserTaskList === values?.currentProjectId) {
        if(disPlayStatus == RECENTLYTASK) {
          push(
            `/task/show/${taskId}?projectid=${values?.currentProjectId}&resource=mytask&screen=recently&fullscreen=true`
          );
        }else {
          push(
            `/task/show/${taskId}?projectid=${values?.currentProjectId}&resource=mytask&fullscreen=true`
          );
        }
      } else {
        if(disPlayStatus == RECENTLYTASK) {
          if (url.includes("projects")) {
            push(
              `/task/show/${taskId}?projectid=${values?.currentProjectId}&resource=projects&screen=recently&fullscreen=true`
            );
          } else {
            push(
              `/task/show/${taskId}?projectid=${values?.currentProjectId}&resource=${params?.resource}&screen=recently&fullscreen=true`
            );
          }
        }else {
          if (url.includes("projects")) {
            push(
              `/task/show/${taskId}?projectid=${values?.currentProjectId}&resource=projects&fullscreen=true`
            );
          } else {
            push(
              `/task/show/${taskId}?projectid=${values?.currentProjectId}&resource=${params?.resource}&fullscreen=true`
            );
          }
        }
      }
    }
  };

  const goHome = () => {
    const url = window.location.href;
    let conditionMet = false;
    if (url.includes("mytask")) {
      if (url.includes("recently")) { 
        replace(`/task/show/${taskId}?projectid=${idUserTaskList}&screen=recently`);
      }else {
        replace(`/task/show/${taskId}?projectid=${idUserTaskList}`);
      }
    } else if (url.includes("Inbox")) {
      replace(`/Inbox/show/${taskId}?projectid=${values?.currentProjectId}`);
    } else if (url.includes("projects")) {
      replace(`/task/show/${taskId}?projectid=${values?.currentProjectId}`);
    } else if (url.includes("propose")) {
      const values = extractParametersWithRegex(url);
      replace(
        `/propose-task/show/${values?.projectId}?taskId=${values?.taskId}&sectionId=${values?.sectionId}`
      );
    }else {
      if (values?.projectAndSection && values?.projectAndSection.length > 0) {
        const getCount = async () => {
          const res = await axios.post(`resources/getProjectFromTask`, {
            mangdulieu: JSON.stringify(values?.projectAndSection),
          });

          if (res.data) { 
            conditionMet = true;
            replace(`/task/show/${taskId}?projectid=${res.data}`);
          } else {
            replace(`/task/show/${taskId}?projectid=${idUserTaskList}`);
          }
        };

        getCount();
      } else {
        replace(`/task/show/${taskId}?projectid=${idUserTaskList}`);
      }
    }
  };

  const handleEscPress = (e: KeyboardEvent) => {
    if (e.keyCode === 27) {
      push(`/mytask/show/${values?.currentProjectId}`);
      return;
    }
    return;
  };

  useEffect(() => {
    type === "singledetails" &&
      document.addEventListener("keydown", handleEscPress);
    return () => document.removeEventListener("keydown", handleEscPress);
  }, [type, values?.currentProjectId]);

  const isShowExpandIcon = (item: any) => {
    const result =
      type === "singledetails" && item.type === "expand"
        ? { display: "none" }
        : { display: "" };
    return result;
  };

  return (
    <div className="dt-header-container ">
      <div className="dt-header-main ">
        <div
          className={
            "main-complete-btn " + (values?.changeTitle ? "slide" : "")
          }
        >
          {!isProposeTaskPage && disPlayStatus == RECENTLYTASK &&  type !== 'singledetails' ?  <MarkCompletedRecently  type="button" task={taskRecentSelect} /> : (
            <MarkComplete
              type="button"
              resourceId={resourceId}
              completedStatus={completedStatus}
            />
            )
           
          }

          {isProposeTaskPage && proposeDetailTask && task && (
            <ProposeMarkComplete type="button" task={task} />
          )}
        </div>

        <div className={"main-title " + (values?.changeTitle ? "slide2" : "")}>
          {!isProposeTaskPage && disPlayStatus == RECENTLYTASK &&  type !== 'singledetails' ? <MarkCompletedRecently task={taskRecentSelect} /> : (
            <MarkComplete
              resourceId={resourceId}
              completedStatus={completedStatus}
            />
          )

        }

          {isProposeTaskPage && proposeDetailTask && task && (
            <ProposeMarkComplete task={task} />
          )}

          <h3>
            {values?.currentTaskDetailTitle
              ? values?.currentTaskDetailTitle
              : nameTitleTask}
          </h3>
        </div>
      </div>

      <div className="dt-header-item">
        {typeof taskId === "number" && <ProjectMentiontask taskId={taskId} />}

        {headerItems.map((item, index) => {
          const Icon = item.icon;
          return (
            <Tooltip
              key={index}
              title={item.desc ? item.desc : ""}
              placement="bottom"
            >
              <Icon
                style={isShowExpandIcon(item)}
                className="item-icon"
                onClick={() => handleIconCLick(item)}
              />
            </Tooltip>
          );
        })}
        {type === "singledetails" ? (
          <IoMdClose className="item-icon" onClick={goHome} />
        ) : (
          values?.hideArrowToRightScreenDetail === "" && (
            <BiArrowToRight className="item-icon" onClick={handleClose} />
          )
        )}
      </div>
    </div>
  );
};
