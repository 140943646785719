import { useList } from '@pankod/refine-core'
import { useEffect, useState } from 'react'
import { TeamContext } from 'context/contextapi'
import { Topintoprender } from './Topintoprender'
import { PinContext } from 'context/ContextPin'
import './Topintop.scss'
import axios from 'axios'
interface PropsFileTopintop {
    currentTaskDetailId: any
}
export const Topintop: React.FC<PropsFileTopintop> = ({ currentTaskDetailId }) => {
    const setdataResultPin = PinContext()
    const dataResultPin = PinContext()
    useEffect(() => {
        if (currentTaskDetailId) {
            axios.get(`pincomment/datapintotop/${currentTaskDetailId}`).then((res: any) => {
                if (res) {
                    setdataResultPin?.setdataResultPin(res?.data)
                }
            })
        }
    }, [currentTaskDetailId])

    return (
        <div className="pintotop_container">
            {dataResultPin &&
                dataResultPin?.dataResultPin?.map((item: any, index: any) => {
                    return (
                        <div key={item.id}>
                            <Topintoprender
                                content={item.name}
                                gid={item.gid}
                                hasFile={item?.hasFile}
                                cmtId={item.commentId}
                                userId={item?.createdby}
                                likes={item?.likes}
                                cmtCreatedAt={item?.createdAt}
                                cmtUpdatedAt={item?.updatedAt}
                            />
                        </div>
                    )
                })}
        </div>
    )
}
