/* eslint-disable react-hooks/exhaustive-deps */
import { useList, useRouterContext } from "@pankod/refine-core";
import { TeamContext } from "context/contextapi";
import React, { memo, useEffect } from 'react';
import './ListProject.css';
import ProjectItem from "./ProjectItem";

interface PropProject {
  dataNewProject?:any;
  setDataNewProject?:any


}
function ListProjectContext(){
  const dataNewProjectValue=TeamContext()
  const setDataNewProjectValue = TeamContext()
  return <ListProject
  setDataNewProject={setDataNewProjectValue?.setDataNewProject} 
  dataNewProject ={dataNewProjectValue?.dataNewProject}
  />
}

const ListProject: React.FC<PropProject>=memo(({dataNewProject,setDataNewProject})=>{
  const {useParams} = useRouterContext()
  const getParam:any = useParams()
  let {id} = getParam
  // http://localhost:7000/resources?join=resourcesDetails&join=resourceRelation&filter=resourceRelation.resourcesparentid||$eq||70
  const dataNewP = useList({
    resource: "resources/list-project",
    config: {
      filters: [
        {
          field: "resourceRelation.resourcesparentid", // dieu kien where.
          operator: "eq",
          value: id, // id trong where
        },
        {
          field: "resourcesmember.member_id", // dieu kien where.
          operator: "eq",
          value: 1008, // id trong where
        },
      ],
      pagination: {current: 1, pageSize:100}
    },
    metaData: {
      config: {
        fields: ["id","name"],
        joins: [
          {
            field: "resourcesDetails",
            select: ["id","isPublic"],
          },
          {
            field: "resourceRelation",
            select: ["id","resourcesparentid"],
          },
          {
            field: "resourcesmember",
            select: ["memberId"],
          },
        
        ],
        
      },
    },
  }).data;


  
  useEffect(() => {
   setDataNewProject(dataNewP?.data);
  }, [dataNewP]);

  return <div className="container-project">
    {
      dataNewProject?.map((item:any)=>{
        return (
          <ProjectItem projectItem ={item} key={item.id}/>
        )
      })
    }
  </div>
})
export default ListProjectContext

// /resources/list-project?filter=resourceRelation.resourcesparentid||$eq||2504&filter=resourcesmember.member_id||$eq||1007&filter=resourcesmember.is_read||$eq||true&fields=id,name&join=resourcesDetails%7C%7Cid%2CisPublic&join=resourceRelation%7C%7Cid%2Cresourcesparentid&join=resourcesmember%7C%7CmemberId&limit=100&page=1&offset=0