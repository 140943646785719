import { useList } from "@pankod/refine-core";
import React, { createContext, useContext, useState, useEffect } from "react";

type Props = {
  children: React.ReactNode;
};
type Context = {
  dataTeams: any;
  favorites: any;
  start: any;
  setdataTeam: any;
  setfavorites: any;
  setstart: any;
  dataNewProject: any;
  setDataNewProject: any;
  dataUpdateProject: any;
  setDataUpdateProject: any;
  intValueUpdateProject: any;
  setIntValueUpdateProject: any;
  setdatadeletecheckbox: any;
  datadeletecheckbox: any;
  setdataPrevmember: any;
  dataPrevmember: any;
  navigation: any;
  setNavigation: any;
  dataListproject: any;
  setdataListproject: any;
  // dataScreenActivity: any;
  // setDataScreenActivity: any;
  // dataScreenArchive: any;
  // setDataScreenArchive: any;
  setidUsermytask: any;
  idUsermytask: any;


  isUserTaskList: any;
  setIsUserTaskList: any;
  datamemberuser: any;
  setdatamemberuser: any;
  input: any;
  setInput: any;
  show: any;
  setShow: any
  dataArchiveAll:any
  setDataArchiveAll:any;
  ischeckFavoritest:any;
  setischeckFavoritest:any;
  dataUpP:any, setDataUpP:any
  keyFilter:any, setKeyFilter:any
  type:any, setType:any
  disable:boolean, setDisable:any
  isInboxLoading:any, setIsInboxLoading:any
  dataPintotop:any,
  setdataPintotop:any,
  color:any, setColor:any
  checkStarred:any 
  setCheckStarred:any
  resultStar:any, setResultStar:any





};
const XContextTeam = createContext<Context | null>(null);

function XContextProvider({ children }: Props) {


  const [dataTeams, setdataTeam] = useState([]);
  const [favorites, setfavorites] = useState([]);
  const [start, setstart] = useState([]);
  const [dataNewProject, setDataNewProject] = useState([])
  const [dataUpdateProject, setDataUpdateProject] = useState(null)
  const [datadeletecheckbox, setdatadeletecheckbox] = useState([])
  const [dataPrevmember, setdataPrevmember] = useState([])
  const [intValueUpdateProject, setIntValueUpdateProject] = useState("")
  const [navigation, setNavigation] = useState('')
  const [dataListproject, setdataListproject] = useState()
  // const [dataScreenActivity, setDataScreenActivity] = useState([])
  // const [dataScreenArchive, setDataScreenArchive] = useState([])
  const [datamemberuser, setdatamemberuser] = useState([])
  const [isUserTaskList, setIsUserTaskList] = useState('')
  const [input, setInput] = useState("")
  const [show, setShow] = useState("")
  const [idUsermytask, setidUsermytask] = useState([])
  const [dataArchiveAll, setDataArchiveAll] = useState("")
  const [ischeckFavoritest,setischeckFavoritest] = useState([])
  const [dataUpP, setDataUpP] = useState([])
  const [keyFilter, setKeyFilter] = useState('Mention')
  const [type, setType] = useState('Mention')
  const [disable, setDisable] = useState(true);
  const [isInboxLoading, setIsInboxLoading] = useState(true)
  const [dataPintotop, setdataPintotop] = useState([])
  const [color, setColor] = useState("")
  const [checkStarred, setCheckStarred] = useState(true)
  const [resultStar, setResultStar] = useState({})








  return (
    <XContextTeam.Provider
      value={{
        dataTeams,
        setdataTeam,
        favorites,
        setfavorites,
        start,
        setstart,
        dataNewProject,
        setDataNewProject,
        dataUpdateProject,
        setDataUpdateProject,
        intValueUpdateProject,
        setIntValueUpdateProject,
        datadeletecheckbox,
        setdatadeletecheckbox,
        dataPrevmember,
        setdataPrevmember,
        navigation,
        setNavigation,
        dataListproject,
        setdataListproject,
        // dataScreenActivity, setDataScreenActivity,
        // dataScreenArchive, setDataScreenArchive,
        isUserTaskList,
        setIsUserTaskList,
        datamemberuser,
        setdatamemberuser, input, setInput,
        show, setShow,
        idUsermytask,
        setidUsermytask,
        dataArchiveAll, setDataArchiveAll,
        ischeckFavoritest,setischeckFavoritest,
        dataUpP, setDataUpP,
        keyFilter, setKeyFilter,
        type, setType,
        disable, setDisable,
        isInboxLoading, setIsInboxLoading,
        dataPintotop,setdataPintotop,
        color, setColor,
        checkStarred, setCheckStarred,
        resultStar, setResultStar




      }}
    >
      {children}
    </XContextTeam.Provider>
  );
}
const TeamContext = () => useContext(XContextTeam);
export { TeamContext, XContextProvider };
