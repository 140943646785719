import React from "react"
import { useProjectRecentlyState } from "pages/Recently-completed-task/state/project-list"
import { Select } from "@pankod/refine-antd"
import { addProjectRecentlyAsync } from "pages/Recently-completed-task/service"

interface ProjectSelectDetailPropIF {
    task?:any
    setOpenSearch?:any,
    onBlur?:any
    updateValueTask?:any
}

const ProjectSelectDetailRecently:React.FC<ProjectSelectDetailPropIF> = ({task,setOpenSearch,onBlur,updateValueTask}) => {
    const {projects}:any = useProjectRecentlyState()
    const onSelect = (projectId:any) => {
        addProjectRecentlyAsync(task?.taskId,projectId,task,updateValueTask)
        setOpenSearch(false)
    }

    return (
        <>
            <Select
                className="project-input-container"
                showSearch
                open
                placeholder="Add task to projects..."
                optionFilterProp="children"
                onBlur={onBlur}
                onSelect={onSelect}
                autoFocus={true}
                filterOption={(input:any, option:any) =>
                    (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                }
                options={projects}
            />
        </>
    )
}

export default ProjectSelectDetailRecently