import axios from "axios";
import ApiConstants from "constanst";
import useMyTaskStore from "pages/myTask/state/store";
import React, { memo, useEffect, useState } from "react";
import { MentionData } from "@draft-js-plugins/mention";
import { BaseRecord, useList, useNavigation, useRouterContext, useUpdate } from "@pankod/refine-core";
import { useAppContext } from "App.context/App.context";
import { AddSection } from "./AddSection/AddSection";
import { Button } from "./Button/Button";
import { ALLTASK, INCOMPLETE, RECENTLYTASK } from "./Constant";
import { useMyTask } from "./Context/MyTask.context";
import Loading from "./Loading/Loading";
import LockPermission from "./LockPermission";
import { MyTaskDetailsHOC } from "./mtDetails/MyTaskDetails";
import { MyTaskHeader } from "./mtHeader/MyTaskHeader";
import { MyTaskSectionHOC } from "./mtSession/MyTaskSection";
import "./mytask.scss";
import { MyTaskSortFeature } from "./MyTaskSortFeature/MyTaskSortFeature";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import useDragDropStore from "pages/myTask/state/DragDropStore";
import { addToList, removeFromList, reorder } from "./utils";
import { NewInsertTaskSocketTeamContext } from "socket/newinserttaskcontext";
import { TOKEN_KEY_LOGIN } from "authProvider";
import { replace } from "lodash";
import { DISPLAYSTATUS } from "pages/CompletedTasks/CompletedTaskPage";
import RecentlyTask from "pages/Recently-completed-task/components/RecentlyTask";
interface MyTaskHOCProps {
  showSidebar?: any
  IsMyTaskLoading?: any
  SetIsMyTaskLoading?: any
  setCurrentProjectId?: any
  showMyTaskDetails?: any
  setPathName?: any
  params?: any
  location?: any
  replace?: any
  setUsersData?: any
  setMentionsData?: any
  setProjectList?: any
  setProjectData?: any
  setTaskDisplayStatus?: any
  mtContentRef?: any
  setCurrentTaskDetailTitle?: any
  currentTaskDetailId?:any
  setProjectAndSection?:any
}
export const MyTaskHOC: React.FC<MyTaskHOCProps> = ({}) => {
  const values = useMyTask();
  const AppContext = useAppContext();
  const showSidebar = AppContext?.showSideBar;
  const IsMyTaskLoading = AppContext?.isMyTaskLoading;
  const SetIsMyTaskLoading = AppContext?.setIsMyTaskLoading;
  const setCurrentProjectId = values?.setCurrentProjectId;
  const setPathName = values?.setPathName;
  const showMyTaskDetails = values?.showMyTaskDetails;
  const mtContentRef = values?.mtContentRef;
  const setMentionsData = AppContext?.setMentionsData;
  const setUsersData = AppContext?.setUsersData;
  const setProjectList = AppContext?.setProjectList;
  const setProjectData = AppContext?.setProjectData;
  const setTaskDisplayStatus = AppContext?.setTaskDisplayStatus;
  const { useLocation, useParams } = useRouterContext();
  const params = useParams();
  const location = useLocation();
  const setCurrentTaskDetailTitle =  values?.setCurrentTaskDetailTitle;
  const setProjectAndSection = values?.setProjectAndSection
  const { push, goBack , replace} = useNavigation();

  return <MyTask 
  showSidebar={showSidebar}
  IsMyTaskLoading={IsMyTaskLoading}
  SetIsMyTaskLoading={SetIsMyTaskLoading}
  setCurrentProjectId={setCurrentProjectId}
  showMyTaskDetails={showMyTaskDetails}
  setPathName={setPathName}
  params={params}
  location={location}
  replace={replace}
  mtContentRef={mtContentRef}
  setUsersData={setUsersData}
  setMentionsData={setMentionsData}
  setProjectList={setProjectList}
  setProjectData={setProjectData}
  setTaskDisplayStatus={setTaskDisplayStatus}
  setCurrentTaskDetailTitle={setCurrentTaskDetailTitle}
  setProjectAndSection={setProjectAndSection}
  />
}

export const MyTask:React.FC<MyTaskHOCProps> = memo(({
  showSidebar,
  IsMyTaskLoading,
  SetIsMyTaskLoading,
  setCurrentProjectId,
  showMyTaskDetails,
  setPathName,
  params,
  location,
  replace,
  mtContentRef,
  setUsersData,
  setMentionsData,
  setProjectList,
  setProjectData,
  setTaskDisplayStatus,
  setCurrentTaskDetailTitle,
  setProjectAndSection
})=>{
// export const MyTask: React.FC = () => {
  // const values = useMyTask();
  const AppContext = useAppContext();
  const disPlayStatus = AppContext?.taskDisplayStatus;
  // console.log('MyTask', disPlayStatus);
  
  // const newInsertTaskSocketTeamContext = NewInsertTaskSocketTeamContext();
  // const showSidebar = AppContext?.showSideBar;
  // const IsMyTaskLoading = AppContext?.isMyTaskLoading;
  // const SetIsMyTaskLoading = AppContext?.setIsMyTaskLoading;
  const [sections, setSections] = useState<BaseRecord[]>([]);
  const [getRequest, setGetRequest] = useState(false);
  const [projectId, setProjectId] = useState<any>(undefined);
  // const { useLocation, useParams } = useRouterContext();
  // const params = useParams();
  // const location = useLocation();
  const { id, resource } = params as BaseRecord;
  const [isLock, setIsLock] = useState(false);
  // const { push, goBack , replace} = useNavigation();
  const idUserTaskList =JSON.parse(localStorage.getItem(TOKEN_KEY_LOGIN) || "") ;
  let {search, pathname} = location
  const regex = /[?&]projectid=(\d+)/;
  const match = regex.exec(search);
  const projectId2 = match && match[1];
  const value = pathname.split("show/")[1];
  // console.log('projectId2',projectId2); // Output: 2709

  const { mutate } = useUpdate();


      useEffect(()=>{
        if(pathname === '/mytask' || pathname === '/mytask/'){
          replace(`/mytask/show/${idUserTaskList}`)
        }
      },[])

     

  useEffect(() => {
    // console.log('vaoday');
    
    if (params && id) {
      if(location.pathname.includes('mytask')){
        const idprojectV2 = Number.parseInt(value, 10)
        // console.log('vao day my task',idprojectV2);
        
        idprojectV2 && setProjectId(idprojectV2);
        idprojectV2 && setCurrentProjectId(idprojectV2);
        // id && setProjectId(+id);
        // id && setCurrentProjectId(+id);
      }else if(location.pathname.includes('projects')){
        
        const idprojectV2 = Number.parseInt(value, 10)
        // console.log('vao day project', idprojectV2);
        idprojectV2 && setProjectId(idprojectV2);
        idprojectV2 && setCurrentProjectId(idprojectV2);
        // id && setProjectId(+id);
        // id && setCurrentProjectId(+id);
        // id && setProjectId(+id);
        // id && setCurrentProjectId(+id);
      }
      else{
        const idprojectV2 =projectId2&&  Number.parseInt(projectId2, 10)
        // console.log('vao day project 2',idprojectV2);
        idprojectV2 && setProjectId(idprojectV2);
        idprojectV2 && setCurrentProjectId(idprojectV2);
      }
    }
  // }, [location.pathname]);
  }, [id]);
  // }, [params]);

  // Check permission truoc khi goi api
  useEffect(() => {
    const getPermission = async () => {
      // console.log('vao day', projectId);
      
      try {
        await axios.post("resourcesmembers/checkreadpermission", {
          resourceId: projectId,
        });
        setIsLock(false);
        setGetRequest(true);
        // SetIsMyTaskLoading && SetIsMyTaskLoading(true);
      } catch (error) {
        setIsLock(true);
      }
    };
    projectId > 0 && getPermission();
    
  }, [projectId]);

  useEffect(() => {
    
    setPathName(location.pathname);
  }, [location.pathname]);
 
  




  const { appendSection, data, updateTaskState, sectionIds, reorderSectionIds, updateSectionOrder } = useMyTaskStore();


  useEffect(()=>{
    const getComment = async () => {
      
      try {
        if(projectId ) {
          SetIsMyTaskLoading(true);
          const res = await axios.get(`resourcesrelation/${projectId === idUserTaskList ? 'mytask': 'project'}?filter=resourcesparentid%7C%7C%24eq%7C%7C${projectId}&filter=resources.deletedAt%7C%7C%24isnull&filter=resources.id%7C%7C%24notnull&fields=resourcesid%2Corder&join=resources%7C%7Cname%2Cid%2CcreatedAt&limit=1500&page=1&offset=0&sort=order%2CDESC&sort=resources.createdAt%2CDESC`)
          const dataSection = res.data.data;   
          
          dataSection && dataSection.length > 0 && setSections(dataSection)
          appendSection(dataSection);
          SetIsMyTaskLoading(false);
        }

      }
      catch(err) {
        SetIsMyTaskLoading(false);
      }
    };
    getComment();

  },[projectId,disPlayStatus,idUserTaskList])


  const usersRes = useList({
    resource: "resources/allusers",
    queryOptions: { cacheTime: 0 },

    config: {
      filters: [
        {
          field: "resourcetype",
          operator: "eq",
          value: "user",
        },
      ],
      pagination: { current: 1, pageSize: 1500 },
    },
    metaData: {
      config: {
        fields: ["name", "id"],
        joins: [{field: 'resourceRelation2',select: ["resourcesid", "id"]}]
      },
    },
  }).data as MentionData | undefined;

  const projectsRes = useList({
    resource: "resourcesmembers/projectlist",
    queryOptions: { cacheTime: 0 },

    config: {
      filters: [
        {
          field: "memberId",
          operator: "eq",
          value: 1,
        },
        {
          field: "isRead",
          operator: "eq",
          value: true,
        },
        {
          field: "resources.resourcetype",
          operator: "eq",
          value: "project",
        },
      ],
      pagination: { current: 1, pageSize: 1500 },
    },
    metaData: {
      config: {
        fields: ["resourcesId"],
        joins: [{ field: "resources", select: ["name", "resourcetype"] }],
      },
    },
  }).data as MentionData | undefined;

  const hashMapData = (MentionDatas: BaseRecord[]) => {
    const dataObj = MentionDatas.reduce(
      (total: BaseRecord, data: BaseRecord) => {
        if (data.id) {
          total[data?.resources?.id] = data.resources;
        }
        return total;
      },
      {}
    );
    return dataObj;
  };

  const hashMapUser = (MentionDatas: BaseRecord[]) => {
    const dataObj = MentionDatas.reduce(
      (total: BaseRecord, data: BaseRecord) => {
        if (data.id) {
          total[data?.id] = data;
        }
        return total;
      },
      {}
    );
    return dataObj;
  };

  useEffect(() => {
    if (usersRes) {
      const MentionData = usersRes.data.map((item: any) => {
        return {
          ...item,
          avatar: `${ApiConstants.BASE_URL}/${ApiConstants.AVATAR}/${item.id}`,
        };
      });

      setMentionsData(MentionData);
    }
    usersRes && setUsersData(hashMapUser(usersRes.data));
  }, [usersRes]);

  useEffect(() => {
    if (projectsRes) {
      const ProjectOption = projectsRes.data.map((project: BaseRecord) => {
        return {
          label: project?.resources?.name,
          value: project?.resources?.id,
        };
      });

      setProjectList(ProjectOption);
      setProjectData(hashMapData(projectsRes.data));
    }
  }, [projectsRes]);

  useEffect(() => {
    sections.length > 0 && setGetRequest(false);
  }, [sections]);

  useEffect(() => {
    //minh
    const url = window.location.href;
    
    if (resource === "mytask") {
      setTaskDisplayStatus(disPlayStatus);
      
    }else if(projectId === idUserTaskList){
      if(url.includes('recently')) {
        setTaskDisplayStatus(RECENTLYTASK)
      }else {
      setTaskDisplayStatus(INCOMPLETE);
      }
    }else{
      if(url.includes('recently')) {
        setTaskDisplayStatus(RECENTLYTASK)
      }else {
        setTaskDisplayStatus(disPlayStatus);
      }
   
    }
    setCurrentTaskDetailTitle(undefined)
  }, [resource,projectId]);


  //Drag drop
  const {toggleSectionDrop, toggleTaskDrop, isDisableSectionDrop} = useDragDropStore()


  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }

    //Nếu vị trí không thay đổi thì khoong gọi api
    if(result.source.droppableId === result.destination.droppableId && result.source.index === result.destination.index) {
      toggleSectionDrop(false) 
      toggleTaskDrop(false)
      return
    }

    if(result.destination.droppableId !== 'sectionDroppable') {      
      let sourceList;

    if (!data[result.source.droppableId]?.tasks) {
      sourceList = [];
    } else {
      sourceList = data[result.source.droppableId]?.tasks;
    }

    const lastChangedElement = data[result.destination.droppableId]?.tasks.at(
      result.destination.index
    );
    const [removedElement, newSourceList] = removeFromList(
      sourceList,
      result.source.index
    );

    updateTaskState(result.source.droppableId, newSourceList as BaseRecord[]);

    let destinationList;

    if (!data[result.destination.droppableId]?.tasks) {
      destinationList = [];
    } else {
      destinationList = data[result.destination.droppableId]?.tasks;
    }

    const newDestinationList = addToList(
      destinationList,
      result.destination.index,
      removedElement
    );

    // Sau khi thả thì gọi api để cập nhật lại mảng
    updateTaskState(
      result.destination.droppableId,
      newDestinationList as BaseRecord[]
    );
    const dragSectionId = result.source.droppableId;
    const dropSectionId = result.destination.droppableId;

    result &&
      reOrderTaskPosition(
        +dragSectionId,
        +dropSectionId,
        removedElement,
        lastChangedElement
      );

    } else {
      const startElement = data[sectionIds?.at(result.source.index) as keyof Object] as BaseRecord
      const toElement = data[sectionIds?.at(result.destination.index) as keyof Object] as BaseRecord
      const startOrder = startElement?.order
      const toOrder = toElement?.order
      
      const reorderedSectionIds = reorder(sectionIds, result.source.index, result.destination.index)
      reorderSectionIds(reorderedSectionIds as string[]) 
      reOrderSectionPosition(startOrder, toOrder, projectId)  
    }

    toggleSectionDrop(false) 
    toggleTaskDrop(false)
    
    
  };

  const reOrderSectionPosition = async (startOrder: number, toOrder: number, dragSectionId: number) => {
    await axios
    .post(`resourcesrelation/reorder`, {
      start: startOrder,
      end: toOrder,
      dragSectionId,

    }).then(res => {
      const updatedSection = res.data
      updateSectionOrder(updatedSection)
    })
  }

  const reOrderTaskPosition = async (
    dragSectionId: number,
    dropSectionId: number,
    removedElement: any,
    lastChangedElement: any
  ) => {

    const dragItemOrder = removedElement?.order; // order của item mình kéo
    const lastChangedElementOrder = lastChangedElement?.order; // Order của item sát item mình đổi chỗ - sát phía trên
    // => Lấy được 2 order này để lấy tất cả các task trong phạm vi kéo thả ở BE

    await axios
      .post(`resourcesrelation/reorder`, {
        start: dragItemOrder,
        end: lastChangedElementOrder,
        dragSectionId,
        type:
          dragSectionId === dropSectionId
            ? undefined
            : { dragSectionId, dropSectionId, removedElement },
      })
      .then((res) => {
        const updatedData = res.data;
        const updatedTask = data[dropSectionId]?.tasks.map((task: any) => {
          // Lặp qua mảng tasks hiện tại, so sánh với các item trả về, nếu có rsId giống nhau, thì cập nhật lại order
          //Còn nếu không giống nhau thì để nguyên
          let newTask = task;

          updatedData.forEach((_: any) => {
            if (_?.resourcesid === task?.resources.id) {
              // So sánh id để cập nhật lại order
              newTask = { ...newTask, order: _.order };
            }
          });

          return newTask;
        });

        updateTaskState(dropSectionId, updatedTask); // Sau khi cập nhật xong thì xét vào lại store để cập nhật dữ liệu
      });
  
      location.pathname.includes('projects') && await mutate(
        {
          resource: "resources/project-task-detail-drag-drop",
          id: dropSectionId,
          values: {
            newSectionId: +dropSectionId,
            taskId: removedElement.resourcesid,
            oldSectionId: dragSectionId,
          },
        },
        {
          onSuccess(data, variables, context) {
            setProjectAndSection((prev:any) => {
              const oldData = [...prev];
              const newData = oldData.map((item) => {
                if (item.sectionId === dragSectionId) {
                  return { ...item, sectionId: +dropSectionId };
                } else {
                  return item;
                }
              });
  
              return newData;
            });
          },
        }
      );
  };


  const onDragStart = (result: any) => {
   if(result.source.droppableId === 'sectionDroppable') {
    toggleTaskDrop(true)
   } else {
    toggleSectionDrop(true)
   }
  }


  return (
    <>
      {!isLock && (
        <div className="mytask-container">
          <div
            className="mt-header-container"
            style={{ left: showSidebar ? "0px" : "200px" }}
          >
            <div className="mytask-header">
              {disPlayStatus !== RECENTLYTASK ? <Button /> : <div className="mt-btn-container"></div> } 
              <MyTaskSortFeature
                myTaskSetGetReq={setGetRequest}
                resource={resource}
                id={projectId}
              />
            </div>
            <MyTaskHeader />
          </div>

          {disPlayStatus !== RECENTLYTASK ? <div
            ref={mtContentRef}
            className={
              "mytask-content-container " +
              (showMyTaskDetails ? "hasDetail" : "")
            }
            style={{ left: showSidebar ? "0px" : "200px" }}
          >
              <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>

                <Droppable droppableId="sectionDroppable" isDropDisabled={isDisableSectionDrop} >
                  {(provided, snapshot) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>

                      {sectionIds.length > 0 &&
                        !IsMyTaskLoading &&
                        sectionIds.map((sectionId, index) => {

                          const section = data[sectionId]
                          
                          return (
                            <MyTaskSectionHOC
                              sectionId={section.resourcesid}
                              key={section.resourcesid}
                              resources={section.resources}
                              index={index}
                              setSections={setSections}
                              projectId={projectId}
                              myTaskSetGetReq={setGetRequest}
                              idUserTaskList={idUserTaskList}
                              displayStatus={disPlayStatus}
                            />
                          );
                        })}

                        
                        {provided.placeholder}

                    </div>
                  )}
                </Droppable>

              </DragDropContext>

            {IsMyTaskLoading && (
              <div className="mytask-loading">
                <Loading />
              </div>
            )}

            {(!IsMyTaskLoading && disPlayStatus !== RECENTLYTASK) && (
              <AddSection setSections={setSections} projectId={projectId} />
            )}
          </div> :
            <div
            ref={mtContentRef}
            className={
              "mytask-content-container " 
            }
            style={{ left: showSidebar ? "0px" : "200px" }}
            >
            <RecentlyTask/>
          </div>
         }
        </div>
      )}

      {isLock && <LockPermission />}
      <MyTaskDetailsHOC />
    </>
  );
});
