

import { Select } from "@pankod/refine-antd";
import { useCreate } from "@pankod/refine-core";
import { useAppContext } from "App.context/App.context";
import { useMyTask } from "../Context/MyTask.context";
import "./ProjectsSelect.scss";

interface ProjectsSelectProps {
  onChange?: (val: string) => void
  onSearch?: (val: string) => void
  onBlur:() => void
  taskId?: number
  setOpenSearch: React.Dispatch<React.SetStateAction<boolean>>
}


export const ProjectsSelect: React.FC<ProjectsSelectProps> = ({onChange,setOpenSearch, onSearch, onBlur, taskId}) => {
  const AppContext = useAppContext()
  const values = useMyTask()

  const Projects = AppContext?.projectList
  const {mutate} = useCreate()

  const onSelect = (projectId: string) => {
    mutate({
      resource: 'resources/addtoproject',
      values: {
        projectId, 
        taskId: taskId || values?.currentTaskDetailId
      },
      successNotification: {
        message: 'Thành công !',
        type: 'success'
      }
    }, {
      onSuccess(data, variables, context) {
        setOpenSearch(false)
        values?.setProjectAndSection(prev => [...prev, { resourcesId: projectId, sectionId: data.data.sectionId}])
        values?.setResourceTaskChange({projectId, sectionId: data.data.sectionId, type: 'add'})
      },
    })

    
  }

 
  return (
   <>
   <Select
      className="project-input-container"
      showSearch
      open
      placeholder="Add task to projects..."
      optionFilterProp="children"
      onChange={onChange}
      onSelect={onSelect}
      onSearch={onSearch}
      onBlur={onBlur}
      autoFocus={true}
      filterOption={(input, option) =>
        (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
      }
      options={Projects}
    />
   </>
  );
};
