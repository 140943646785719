import axios from 'axios'
import { useMyTask } from 'components/Mytask/Context/MyTask.context'
import { useEffect, useState } from 'react'
import QueueItem from './QueueItem/QueueItem'
import './SubTaskQueue.scss'

interface SubTaskQueueProps {
    type?: string,
}
export const SubTaskQueue: React.FC<SubTaskQueueProps> = () => {
 
    const values = useMyTask()
    const TaskQueues = values?.taskDetailsQueue
    const currentTaskDetailId = values?.currentTaskDetailId
    const [name, setName] = useState<any[]>([])
    

    useEffect(() => {
        TaskQueues && TaskQueues.length <= 1 && 
        values?.subTaskDetailsMode && 
        values?.setSubTaskDetailsMode(false)
        let array:number[] = []
        if(TaskQueues && currentTaskDetailId && TaskQueues.length >0 ){
            TaskQueues?.map((item:any, index:any)=>{
                if(item?.taskId && item?.taskId === currentTaskDetailId){

                    array.push(item?.taskId)
                }

            })
        }
        
        // Kiểm tra nếu mảng taskQueue bé hơn hoặc bằng 1 tức là đã về task chính, kp là sub task
        //Và Nếu chế độ subtask detail đang true thì set chế độ sub task = false

        const getComment = async () => {
            // setDataScreenArchive([]);
            if(array.length >0 ){
                
                const res = await axios.get(`resources?join=resourceRelation&filter=id||in||${array}&filter=resourcetype||eq||subtask&join=resourceRelation.resourcesParent&filter=resourceRelation.resourcesParent.resourcetype||$in||task,subtask`)
                // const res = await axios.get(`resources?join=resourceRelation&filter=id||in||${array}&filter=resourcetype||eq||subtask&join=resourceRelation.resourcesParent`)
                const data = await res.data;
                if(data.length >0){
                    setName(data)
                }
            }else{
                const res:any = currentTaskDetailId && await axios.get(`resources?join=resourceRelation&filter=id||in||${currentTaskDetailId}&filter=resourcetype||eq||subtask&join=resourceRelation.resourcesParent&filter=resourceRelation.resourcesParent.resourcetype||$in||task,subtask`)
                // const res = await axios.get(`resources?join=resourceRelation&filter=id||in||${currentTaskDetailId}&filter=resourcetype||eq||subtask&join=resourceRelation.resourcesParent`)
                const data = await res.data;
                if(data?.length >0){
                    setName(data)
                }
            }
            
            
          };
          getComment();
        
    },[TaskQueues, currentTaskDetailId])
    return (
        <div className="subtask-queue-warpper" >

            {TaskQueues && TaskQueues.length >0 ? 
                 TaskQueues?.map((item, index) => {
                if(TaskQueues.length === index ) {
                    return
                }
                if(TaskQueues.length > 2 && TaskQueues.length === index +1) {
                    return
                }
                return (
                    <QueueItem key={item.taskId} name={name[index]?.resourceRelation?.resourcesParent?.name} idParent={name[index]?.resourceRelation?.resourcesParent?.id} taskId={name[index]?.resourceRelation?.resourcesParent?.id} index={name[index]?.resourceRelation?.resourcesParent?.id}/>
                    // <QueueItem key={item.taskId} name={item.name} taskId={item.taskId} index={index}/>
                )

            }) 
            
            :
            <QueueItem key={name[0]?.resourceRelation?.resourcesParent?.id} name={name[0]?.resourceRelation?.resourcesParent?.name} idParent={name[0]?.resourceRelation?.resourcesParent?.id} taskId={name[0]?.resourceRelation?.resourcesParent?.name.id} index={name[0]?.resourceRelation?.resourcesParent?.id}/>
                    // <QueueItem key={item.taskId} name={item.name} taskId={item.taskId} index={index}/>
            }
            
            {/* {TaskQueues?.map((item, index) => {
                if(TaskQueues.length === index ) {
                    return
                }
                if(TaskQueues.length > 2 && TaskQueues.length === index +1) {
                    return
                }
                return (
                    <QueueItem key={item.taskId} name={name[index]?.resourceRelation?.resourcesParent?.name} idParent={name[index]?.resourceRelation?.resourcesParent?.id} taskId={item.taskId} index={index}/>
                    // <QueueItem key={item.taskId} name={item.name} taskId={item.taskId} index={index}/>
                )

            })} */}
          
        </div>
    )
}