import { MentionData } from "@draft-js-plugins/mention";
import { AntdLayout, Divider } from "@pankod/refine-antd";
import { useList, useNavigation, useRouterContext } from "@pankod/refine-core";
import { useAppContext } from "App.context/App.context";
import { Avatar } from "components/avatar/avatar";
import { TeamContext } from "context/contextapi";
import ListProjectContext from "pages/projects/ListProject";
import React, { memo, useEffect, useRef, useState } from "react";
import { useClickAway } from "react-use";
import Inputmension from "./Inputmension/Inputmension";
import "./Teamsproject.css";

interface IsProjectcontex {
  dataTeams?: any;
  favorites?: any;
  setfavorites?: any;
  start?: any;
  datadeletecheckbox?: any;
  setdatadeletecheckbox?: any;
  setdataPrevmember?: any;
  dataPrevmember?: any;
}

function ProjectContext() {
  const valuesdataTeams = TeamContext();
  const valuessetfavorites = TeamContext();
  const valuesfavorites = TeamContext();
  const valuesstart = TeamContext();
  const valuesdatadeletecheckbox = TeamContext();
  const valuessetdatadeletecheckbox = TeamContext();
  const valuesdataPrevmember = TeamContext();
  const valuessetdataPrevmember = TeamContext();

  const AppContext = useAppContext();
  const usersRes = useList({
    resource: "resources",
    config: {
      filters: [
        {
          field: "resourcetype",
          operator: "eq",
          value: "user",
        },
      ],
      pagination: { current: 1, pageSize: 1500 },
    },
    metaData: {
      config: {
        fields: ["name", "id"],
      },
    },
  }).data as MentionData | undefined;

  useEffect(() => {
    usersRes && AppContext?.setMentionsData(usersRes.data);
  }, [usersRes]);

  return (
    <PostListTproject
      dataTeams={valuesdataTeams?.dataTeams}
      favorites={valuesfavorites?.favorites}
      setfavorites={valuessetfavorites?.setfavorites}
      start={valuesstart?.start}
      datadeletecheckbox={valuesdatadeletecheckbox?.datadeletecheckbox}
      setdatadeletecheckbox={valuessetdatadeletecheckbox?.setdatadeletecheckbox}
      dataPrevmember={valuesdataPrevmember?.dataPrevmember}
      setdataPrevmember={valuessetdataPrevmember?.setdataPrevmember}
    />
  );
}

const PostListTproject: React.FC<IsProjectcontex> = memo(
  ({
    setdatadeletecheckbox,
    datadeletecheckbox,
    setdataPrevmember,
  }) => {
    const { push } = useNavigation();
    const clickRef: any = useRef("");
    const [modal, setmodal] = useState(false);

    const getParam = useRouterContext();
    const getParamId: any = getParam.useParams();
    let { id } = getParamId;

    const handleNewProject = () => {
      push(`/projects/create/${id}`);
    };

    //Call Api
    const members = useList({
      resource: "resourcesmembers",
      config: {
        filters: [
          {
            field: "resourcesId",
            operator: "eq",
            value: id,
          },
        ],
        pagination: { current: 1, pageSize: 400 },
        sort: [{ order: "desc", field: "createdAt" }],
      
      },

      metaData: {
        config: {
          fields: ["resourcesId", "isCreate", "isRead", "isUpdate", "isDelete","createdAt"],

          joins: [{ field: "resourcesmemberid", select: ["name"] }],
        },
      },
    }).data;
    useEffect(() => {
      members?.data && setdatadeletecheckbox(members.data);
    }, [members]);
    
    useClickAway(clickRef, () => {
      setmodal(false);
    });
 
    

    return (
      <AntdLayout.Header
        style={{
          padding: "0px 24px",
          height: "40px",
          backgroundColor: "#f0f2f5",
          lineHeight: "none",
          fontFamily: " Arial, Helvetica, sans-serif",
        }}
      >
        <div className="member">
          <div className="member_list">
            <div className="member_list_section">
              <div className="member_list_title">
                <h5 className="member_title">Members</h5>
              </div>
              <div></div>
            </div>
            <div style={{ width: "100%", height: "130px", overflow: "hidden" }} ref={clickRef}>
              <div className="teamMemberSection">
                <div className="themeMemberCard " onClick={() => setmodal(true)} >
                  <div className="themeMemberCard_addMember">
                    <svg
                      className="Icon UserPlusIcon"
                      viewBox="0 0 32 32"
                      aria-hidden="true"
                      focusable="false"
                    >
                      <path d="M31,26h-3v-3c0-0.6-0.4-1-1-1s-1,0.4-1,1v3h-3c-0.6,0-1,0.4-1,1s0.4,1,1,1h3v3c0,0.6,0.4,1,1,1s1-0.4,1-1v-3h3c0.6,0,1-0.4,1-1S31.6,26,31,26z M16,18c4.4,0,8-3.6,8-8s-3.6-8-8-8s-8,3.6-8,8S11.6,18,16,18z M16,4c3.3,0,6,2.7,6,6s-2.7,6-6,6s-6-2.7-6-6S12.7,4,16,4z M21.2,20H8.8C5,20,2,23,2,26.8V31c0,0.6,0.4,1,1,1s1-0.4,1-1v-4.2C4,24.2,6.2,22,8.8,22h12.4c0.6,0,1-0.4,1-1S21.8,20,21.2,20z"></path>
                    </svg>
                  </div>
                  <div className="teamOverviewMemberSection"   >
                    <Inputmension />
                  </div>
                </div>
              
                {datadeletecheckbox.map((item: any, index: any) => {
                 
                  
                  return (
                    <div
                      className="themeMemberCard"
                      key={item.id}
                      title={item?.resourcesmemberid?.name}
                    >
                      <div className="themeMemberCard_addMember">
                        <Avatar
                          userId={item?.resourcesmemberid?.id}
                          isTooltip={false}
                        />
                      </div>
                      <div className="teamOverviewMemberSection" key={index}>
                        <h6 className="teamOverviewMemberSection_addmember teamOverviewMemberSection_name">
                          {item?.resourcesmemberid?.name}
                        </h6>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="member">
          <div className="member_list projects-zone">
            <Divider
              style={{
                margin: 0,
                borderTop: "1px solid rgb(131 129 129 / 24%)",
              }}
            />
            <div className="member_list_section">
              <div className="member_list_title">
                <h5 className="member_title">Projects</h5>
              </div>
              <div></div>
              <div>
                <div>
                  <svg
                    className="Icon HubViewMenu-listIcon ListViewIcon"
                    viewBox="0 0 32 32"
                    aria-hidden="true"
                    focusable="false"
                  >
                    <path d="M6.5,14H3.5C1.6,14,0,12.4,0,10.5V7.5C0,5.6,1.6,4,3.5,4h3.1C8.4,4,10,5.6,10,7.5v3.1C10,12.4,8.4,14,6.5,14z M3.5,6  C2.7,6,2,6.7,2,7.5v3.1C2,11.3,2.7,12,3.5,12h3.1C7.3,12,8,11.3,8,10.5V7.5C8,6.7,7.3,6,6.5,6H3.5z M6.5,28H3.5  C1.6,28,0,26.4,0,24.5v-3.1C0,19.6,1.6,18,3.5,18h3.1c1.9,0,3.5,1.6,3.5,3.5v3.1C10,26.4,8.4,28,6.5,28z M3.5,20  C2.7,20,2,20.7,2,21.5v3.1C2,25.3,2.7,26,3.5,26h3.1C7.3,26,8,25.3,8,24.5v-3.1C8,20.7,7.3,20,6.5,20H3.5z M32,9L32,9  c0-0.6-0.4-1-1-1H14c-0.6,0-1,0.4-1,1v0c0,0.6,0.4,1,1,1h17C31.6,10,32,9.6,32,9z M32,23L32,23c0-0.6-0.4-1-1-1H14c-0.6,0-1,0.4-1,1  v0c0,0.6,0.4,1,1,1h17C31.6,24,32,23.6,32,23z"></path>
                  </svg>
                </div>
              </div>
            </div>
            <div className="teamProjectSection_hubList">
              <div className="sortableList_itemContainer_colum sortableList_itemContainer">
                <div className="hubList_nonDraggableItemWrapper">
                  <div className="IconSquircleRow teamProjectSection_itemRow">
                    <div className="ItemRow ItemRow_enabed RowStructure">
                      <div
                        className="ItemRowTwoColumStructure_left"
                        onClick={handleNewProject}
                      >
                        <div className="RowStructure_leftGutter"></div>
                        <div className="RowStructure_leftChildren">
                          <div className="IconQquircleRow_leftElements">
                            <div className="IconSquircleRow_box">
                              <svg
                                className="Icon PlusIcon"
                                viewBox="0 0 32 32"
                                aria-hidden="true"
                                focusable="false"
                              >
                                <path d="M26,14h-8V6c0-1.1-0.9-2-2-2l0,0c-1.1,0-2,0.9-2,2v8H6c-1.1,0-2,0.9-2,2l0,0c0,1.1,0.9,2,2,2h8v8c0,1.1,0.9,2,2,2l0,0c1.1,0,2-0.9,2-2v-8h8c1.1,0,2-0.9,2-2l0,0C28,14.9,27.1,14,26,14z"></path>
                              </svg>
                            </div>
                            <p>New Project</p>
                          </div>
                        </div>
                      </div>
                      <ListProjectContext />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AntdLayout.Header>
    );
  }
);

export default ProjectContext;
