import { AntdLayout, Tooltip } from "@pankod/refine-antd";
import {
  BaseRecord,
  useList,
  useRouterContext,
  useCreate,
  useDelete
} from "@pankod/refine-core";
import { TeamContext } from "context/contextapi";
import { TOKEN_KEY_LOGIN, DEFAULT_WORKSPACE, USER_ID } from "authProvider";

import React, { useEffect, useState } from "react";
import { AiFillStar } from "react-icons/ai";
import { FaEdit } from "react-icons/fa";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./HeaderProject.css";
interface CategoryMutationResult {
  resourceId: number;
  suDung: boolean;
}
interface Ipropspath {
  path?: any;
  dataListproject?: any;
  search?: any
}
export const HeaderProject: React.FC<Ipropspath> = ({ path, search }) => {
  const [isdatatext, setisdatatext] = useState<BaseRecord[]>([]);
  const dataTeams = TeamContext();
  const idUsermytask = TeamContext();
  const getParam = useRouterContext();
  const getParamId: any = getParam.useParams();
  let { id } = getParamId;

  
  
  const { mutate } = useCreate<CategoryMutationResult>();
  const idUserTaskList = localStorage.getItem(TOKEN_KEY_LOGIN);
  const Idfavorites = localStorage.getItem(USER_ID);
  const { useLocation } = useRouterContext();
  const { pathname }: any = useLocation();
  const [datafavorites, setdatafavorites] = useState<any>([]);
  
  const regex = /[?&]projectid=(\d+)/;
  const regexPath = '/Inbox';
  const match = regex.exec(search);
  const projectId2 = match && match[1];
  // console.log('id', getParamId);
  // console.log('search', search);
  // console.log('pathname', pathname);
  // console.log('projectId2', projectId2);
  // console.log('projectId3', idUserTaskList);
  const dataTeam = useList({
    queryOptions: {
      enabled: pathname === `/Inbox` ? false : true && pathname === `/Admin` ? false : true,
      cacheTime: 0,
    },
    resource: "resources",
    config: {
      filters: [
        {
          field: "id", // dieu kien where.
          operator: "eq",
          // value: id, // id trong where
          value: idUserTaskList === projectId2? idUserTaskList: projectId2 ? projectId2: id, // id trong where
        },
      ],
    },
    metaData: {
      config: {
        fields: ["name", "id"],
        joins: [
          {
            field: "resourceRelation",
            select: ["resourcesParent"],
          },
          {
            field: "resourceRelation.resourcesParent",
            select: ["name"],
          },
        ],
      },
    },
  }).data;

  useEffect(() => {
    dataTeam?.data && setisdatatext(dataTeam.data);
  }, [dataTeam]);

  //Set title page
  useEffect(() => {
    let titlePage = "";
    if (path === `/mytask/show/${idUserTaskList}`) {
      titlePage = `My Tasks -`;
    } else if (path === `/Inbox`) {
      titlePage = `Inbox -`;
    } else {
      titlePage = `${isdatatext[0]?.name} -`;
    }
    document.title = `${titlePage} Family Hospital`;
  }, [path, isdatatext]);

  function renderdataName() {
    return isdatatext.map((item: any) => {
      return (
        <p key={item.id} style={{ fontWeight: 600,marginTop:2 }}>
        {item?.name}
        </p>
      );
    });
  }

  const CreateFavorites = (id:any) => {
    mutate({
      resource: "favorties",
      values: {
        resourceid: id,
        suDung: true,
      },
      successNotification: {
        message: "Thành Công !",
        type: "success",
      },
    });
  };



  const ischeckFavorites = useList({
    resource: "favorties",
    config: {
      filters: [
        {
          field: "userid" , // dieu kien where.
          operator: "eq",
          value: Idfavorites, // id trong where
        },
  
      ],
      sort: [{ order: "desc", field: "createdAt" }],
      pagination: { current: 1, pageSize: 1500 },
    },
    metaData: {
      config: {
        fields: ["resourcesid", "suDung", "createdAt","userid"],
        joins: [
          {
            field: "resources",
            select: ["name"],
          },
        ],
      },
    },
  }).data;
  useEffect(()=>{
    setdatafavorites(ischeckFavorites?.data)
  },[ischeckFavorites])

  const data = ischeckFavorites?.data?.find((item: any) => item.resources.id == id)

  return (
    <AntdLayout.Header
      style={{
        padding: "0px 24px",
        height: "40px",
        backgroundColor: "#f0f2f5",
        lineHeight: "none",
        fontFamily: " Arial, Helvetica, sans-serif",
      }}
    >
      {path === `/mytask/show/${id}` ||
      path === `/mytask/show/${idUserTaskList}` ||
      path === `/user/show/${id}` ||
       projectId2 === idUserTaskList && !path.includes(regexPath)
      ? (
        <div className="header_project">
          <div className="header_container_project">
            <div className="header_project_layout">
              <div style={{ display: "flex" }}>
                {path === `/mytask/show/${idUserTaskList}` || projectId2 === idUserTaskList ? (
                  <span style={{ fontWeight: 600 }}>My Tasks</span>
                ) : (
                  <span
                    style={{ fontWeight: 600, textTransform: "capitalize" }}
                  >
                    {renderdataName()}
                  </span>
                )}
              </div>
              <div>
                <nav className="Topbar_header_nav">
                  <ul className="Topbar_header_list">
                    <li
                      className="topbar_header_li"
                      style={{ fontSize: "12px" }}
                    >
                      {isdatatext[0]?.resourceRelation?.resourcesParent?.name}
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      ) : path === `/Inbox` || path.includes(regexPath)? (
        <div className="header_project">
          <div className="header_container_project">
            <div className="header_project_layout">
              <div style={{ display: "flex" }}>
                <h1 style={{ fontWeight: "600" }}>Inbox</h1>
                <div className="large_font text_center top_20"></div>
              </div>
              <div>
                <nav className="Topbar_header_nav">
                  <ul className="Topbar_header_list">
                    <li className="topbar_header_li"></li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      ) : path === `/Admin` ? (
        <div className="header_project">
          <div className="header_container_project">
            <div className="header_project_layout">
              <div style={{ display: "flex" }}>
                <h1 style={{ fontWeight: "600" }}>SideBar Admin</h1>
                <div className="large_font text_center top_20"></div>
              </div>
              <div>
                <nav className="Topbar_header_nav">
                  <ul className="Topbar_header_list">
                    <li className="topbar_header_li"></li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      ) 
      : path === `/cauhinhdanhmucform` ? (
        <div className="header_project">
          <div className="header_container_project">
            <div className="header_project_layout">
              <div style={{ display: "flex" }}>
                <h1 style={{ fontWeight: "600" }}>CẤU HÌNH DANH MỤC FORM</h1>
                <div className="large_font text_center top_20"></div>
              </div>
              <div>
                <nav className="Topbar_header_nav">
                  <ul className="Topbar_header_list">
                    <li className="topbar_header_li"></li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      )
      : (
        <div className="header_project">
          <div className="header_container_project">
            <div className="header_project_layout">
              <div style={{ display: "flex" }} className="header-project-title">
              
                <div className="tooltip">
                <Tooltip title={renderdataName} placement="bottom" style={{color:"#fff"}}>
                     {renderdataName()}
                </Tooltip>
                </div>
                <div className="large_font">
                  {path === `/team/show/${id}` ||
                  path === `/mytask/show/${idUserTaskList}` ||
                  path === `/mytask/show/${id}` ||
                  path === `/projects/show/${id}` ? (
                    id == data?.resources?.id ? (
                      <AiFillStar
                        className="text_center"
                        onClick={() => CreateFavorites(id)}
                      >
                        <div className="red-bg"></div>
                      </AiFillStar>
                    ) : (
                      <AiFillStar
                        className="text_centernone"
                        onClick={() => CreateFavorites(id)}
                      >
                        <div className="red-bg"></div>
                      </AiFillStar>
                    )
                  ) : null}
                </div>
              </div>
              <div>
                <nav className="Topbar_header_nav">
                  <ul className="Topbar_header_list">
                    <li className="topbar_header_li"></li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      )}
    </AntdLayout.Header>
  );
};
