import { Comment } from "../../../../components/Mytask/mtDetails/mtDetails-Content/Comments/Comment/Comment";
import { BaseRecord } from "@pankod/refine-core";
import { useList } from "@pankod/refine-core";
import { memo, useEffect, useState } from "react";
interface Propspintotoprender{
    content: string;
    gid?: number;
    file?: BaseRecord[];
    fileId?: number;
    cmtId: number;
    setComments?: React.Dispatch<React.SetStateAction<BaseRecord[]>>;
    userId: number;
    likes: BaseRecord[];
    cmtCreatedAt?: string;
    cmtUpdatedAt?: string;
    lastCmtRef?: React.RefObject<HTMLDivElement>;
    pinTop?:boolean;
    hasFile?:any; 
}
export const Topintoprender: React.FC<Propspintotoprender> = ({content,gid,file,fileId,cmtId,userId,likes,cmtCreatedAt,cmtUpdatedAt,pinTop,hasFile}) => {
    const [files, setFiles] = useState<BaseRecord[]>([])
       const arrayfile = useList({
        resource: "resourcesrelation",
        queryOptions: {
          enabled: !!hasFile,
          onSuccess(data) {
            
            const newFiles = data.data.map(file => {
                return  {fileName: file.resources.name, fileId: file.resourcesid}
            })
            setFiles(newFiles)
          }
        },
  
        config: {
          filters: [
            {
              field: "resourcesparentid",
              operator: "eq",
              value: cmtId,
            },
           
          ],
        },
        metaData: {
          config: {
            fields: ["resourcesid"],
            joins: [{ field: "resources", select: ["name"] }],
          },
        },
      }).data;

   return (
       <>
            <Comment  
                    pinTop={pinTop}               
                    content={content}
                    gid={gid}
                    file={files}
                    cmtId={cmtId}
                    userId={userId}
                    likes={likes}
                    cmtCreatedAt={cmtCreatedAt}
                    cmtUpdatedAt={cmtUpdatedAt}
                />
       </>
   )
}