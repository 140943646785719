import React, { createContext, useState, useContext, useRef } from "react";
interface Props {
  children: React.ReactNode;
}



interface MyTaskFooterContextType {
  uploadedPercent: number
  setUploadedPercent: React.Dispatch<React.SetStateAction<number>>;
}

const MyTaskFooterConText = createContext<MyTaskFooterContextType | null>(null);

function MyTaskFooterProvider({ children }: Props) {
  const [uploadedPercent, setUploadedPercent] = useState(0);
  const values = {
    setUploadedPercent,
    uploadedPercent,
  }

  
  return (
    <MyTaskFooterConText.Provider value={values}>
      {children}
    </MyTaskFooterConText.Provider>
  );
}

const useMyTaskFooter = () => useContext(MyTaskFooterConText);

export { MyTaskFooterProvider, useMyTaskFooter };
