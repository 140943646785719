import React, { FC } from 'react'
import styled from 'styled-components'
import {FaUsersCog} from 'react-icons/fa'
const Button = styled.div `
  display: flex;
  background-image: linear-gradient(to right, #ff4b1f 0%, #ff9068  51%, #ff4b1f  100%);
  padding: 10px;
  text-align: center;
  font-weight: 600;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;            
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
  cursor: pointer;
  line-height: 1;
  &:hover {
    transform: scale(1.05);
  background-position: right center; 
  color: #fff;
  text-decoration: none;
}
`
const MemberButton:FC<{name: string; hideIcon?: boolean, handler: any}> = ({handler,hideIcon, name}) => {
  return (
    <Button onClick={handler}>
        {!hideIcon && <FaUsersCog fontSize={15} style={{marginRight: '5px'}} />}
        {name}</Button>
  )
}

export default MemberButton