import axios from 'axios'
import { toast } from 'react-toastify'
export const fetchDataTaskName = async (
    filesData: any,
    setFormData: any,
    setFiles: any,
    Files: any,
    setSucCess: any,
    setResultDataResponse: any,
    setLoading: any
) => {
    await axios
        .post(`resources/insert-form-de-xuat${Files.length > 1 ? '?multiplefile=1' : ''}`, filesData)
        .then((res: any) => {
            if (res?.status) {
                setResultDataResponse(res?.data)
                setFormData({
                    tentask: '',
                    reason: '',
                    completionDate: '',
                    Files: [],
                    idMention: null
                })
                setFiles([])
                setSucCess(true)
                setTimeout(() => {
                    setLoading(false)
                }, 3000)
            }
        })
}
