import { MentionData } from "@draft-js-plugins/mention";
import { Tooltip } from "@pankod/refine-antd";
import { Avatar } from "components/avatar/avatar";
import React from "react";
import { FaTimes } from "react-icons/fa";

interface AssigneeProps {
    assignee: MentionData , 
    handleRemoveAssign: () => void
}

const Assignee: React.FC<AssigneeProps> = ({assignee, handleRemoveAssign}: AssigneeProps) => {
  return (
    <>
      <Tooltip placement="bottom" title={assignee.name}>
        <div className="assignee">
          <Avatar userId={assignee.id} />
          <p className="">{assignee.name}</p>
          <FaTimes className="remove-assign" onClick={handleRemoveAssign} />
        </div>
      </Tooltip>
    </>
  );
};

export default Assignee;
