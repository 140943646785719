import { MentionData } from "@draft-js-plugins/mention";
import { BaseRecord, useList } from "@pankod/refine-core";
import { useAppContext } from "App.context/App.context";
import { MyTaskDetailsHOC } from "components/Mytask/mtDetails/MyTaskDetails";
import { useEffect } from "react";
import ActivityContext from "../mt_activity/Activity";
import "./inboxscreen.css";
import { useGlobalDataAsync } from "pages/ProposeTasks/hooks";
interface isProp {
  dataScreenActivity: any;
  setDataScreenActivity: any;
  dataScreenArchive: any;
  setDataScreenArchive: any;
  dataStarred: any;
  setDataStarred: any;
}

const InboxScreen: React.FC<isProp> = ({
  dataScreenActivity,
  setDataScreenActivity,
  dataScreenArchive,
  setDataScreenArchive,
  dataStarred,
  setDataStarred,
}) => {
  const AppContext = useAppContext();
  const usersRes = useList({
    resource: "resources/allusers",
    queryOptions: { cacheTime: 0 },

    config: {
      filters: [
        {
          field: "resourcetype",
          operator: "eq",
          value: "user",
        },
      ],
      pagination: { current: 1, pageSize: 1500 },
    },
    metaData: {
      config: {
        fields: ["name", "id"],
        joins: [{ field: "resourceRelation2", select: ["resourcesid", "id"] }],
      },
    },
  }).data as MentionData | undefined;

  const hashMapUser = (MentionDatas: BaseRecord[]) => {
    const dataObj = MentionDatas.reduce(
      (total: BaseRecord, data: BaseRecord) => {
        if (data.id) {
          total[data?.id] = data;
        }
        return total;
      },
      {}
    );
    return dataObj;
  };

  useEffect(() => {
    usersRes && AppContext?.setMentionsData(usersRes.data);
    usersRes && AppContext?.setUsersData(hashMapUser(usersRes.data));
  }, [usersRes]);

  const projectsRes = useList({
    resource: "resourcesmembers/projectlist",
    queryOptions: { cacheTime: 0 },

    config: {
      filters: [
        {
          field: "memberId",
          operator: "eq",
          value: 1,
        },
        {
          field: "isRead",
          operator: "eq",
          value: true,
        },
        {
          field: "resources.resourcetype",
          operator: "eq",
          value: "project",
        },
      ],
      pagination: { current: 1, pageSize: 1500 },
    },
    metaData: {
      config: {
        fields: ["resourcesId"],
        joins: [{ field: "resources", select: ["name", "resourcetype"] }],
      },
    },
  }).data as MentionData | undefined;

  const hashMapData = (MentionDatas: BaseRecord[]) => {
    const dataObj = MentionDatas.reduce(
      (total: BaseRecord, data: BaseRecord) => {
        if (data.id) {
          total[data?.resources?.id] = data.resources;
        }
        return total;
      },
      {}
    );
    return dataObj;
  };
  useEffect(() => {
    if (projectsRes) {
      const ProjectOption = projectsRes.data.map((project: BaseRecord) => {
        return {
          label: project?.resources?.name,
          value: project?.resources?.id,
        };
      });

      AppContext?.setProjectList(ProjectOption);
      AppContext?.setProjectData(hashMapData(projectsRes.data));
    }
  }, [projectsRes]);

  useGlobalDataAsync();

  return (
    <div className="mt-inbox">
      <ActivityContext
        dataScreenActivity={dataScreenActivity}
        setDataScreenActivity={setDataScreenActivity}
        dataScreenArchive={dataScreenArchive}
        setDataScreenArchive={setDataScreenArchive}
        dataStarred={dataStarred}
        setDataStarred={setDataStarred}
      />
      {dataScreenActivity?.length > 0 && <MyTaskDetailsHOC type="inbox" />}
    </div>
  );
};
export default InboxScreen;
