import './Search.css'
import { useState, useEffect, useRef, useCallback } from 'react'
import React from 'react'
import Highlighter from 'react-highlight-words'
import _ from 'lodash'
import { useNavigation } from '@pankod/refine-core'
import axios from 'axios'
import { TeamContext } from 'context/contextapi'
import { BiCheckCircle, BiChevronRight } from 'react-icons/bi'
import { useClickAway } from 'react-use'
import 'react-toastify/dist/ReactToastify.css'
import { debounce } from 'lodash'
import Loading from 'components/Mytask/Loading/Loading'
import Loadingsearch from './LoadingSearch/Loadingsearch'
import { Avatar } from '../../components/avatar/avatar'
import { IoIosArrowDropdownCircle, IoIosArrowDropdown } from 'react-icons/io'
export const Search: React.FC = () => {
    const [searchInput, setsearchInput] = useState('')
    const [limit, setlimit] = useState(5)
    const [pageproject, setpageproject] = useState(1)
    const [page, setpage] = useState(1)
    const [pagetask, setpagetask] = useState(1)
    const [pageuser, setpageuser] = useState(1)
    const [pageteam, setpageteam] = useState(1)
    const [opentype, setopentype] = useState()
    const [opentypetask, setopentypetask] = useState()
    const [opentypepeople, setopentypepeople] = useState()
    const [opentypeteam, setopentypeteam] = useState()
    const [dataresultProject, setdataresultProject] = useState<any>([])
    const [dataresultTask, setdatadataresultTask] = useState<any>([])
    const [dataresultTeam, setdataresultTeam] = useState<any>([])
    const [dataresultUser, setdataresultUser] = useState<any>([])
    const [modal, setmodal] = useState(false)
    const [expandproject, setExpandproject] = useState(false)
    const [expandtask, setExpandtask] = useState(false)
    const [expanUser, setExpandUser] = useState(false)
    const [expandTeam, setExpandTeam] = useState(false)
    const [checkloadingProject, setcheckloadingProject] = useState(false)
    const [checkloadingtask, setcheckloadingtask] = useState(false)
    const [checkloadinguser, setcheckloadinguser] = useState(false)
    const [checkloadingteam, setcheckloadingteam] = useState(false)
    const [loaddingcallapi, setloaddingcallapi] = useState(false)
    const clickRef = useRef<HTMLDivElement>(null)
    const { show } = useNavigation()
    const setidUsermytask = TeamContext()
    const idHref = useRef()
    function fetchDropdownOptions(searchInput: any, loaddingcallapi: any) {
        setloaddingcallapi(!loaddingcallapi)
        if (searchInput.length > 0) {
            let url = `resources/searchAsanaAll2`
            let data = {
                name: searchInput,
                limit: limit,
                page: page
            }
            axios.post(url, data).then((res) => {
                if (res.data) {
                    setdataresultProject(res.data.resultProject.data)
                    setdatadataresultTask(res.data.resultTask.data)
                    setdataresultTeam(res.data.resultTeam.data)
                    setdataresultUser(res.data.resultUser.data)
                }
                setloaddingcallapi(loaddingcallapi)
            })
        }
    }

    function fetchDropdownOptionsproject(searchInput: any, pageproject: number, checkloadingProject: any) {
        setcheckloadingProject(!checkloadingProject)
        setTimeout(() => {
            if (searchInput.length > 0) {
                let urlproject = `resources/searchAsanaProject2`
                let dataproject = {
                    name: searchInput,
                    limit: limit,
                    page: pageproject
                }
                axios.post(urlproject, dataproject).then((res) => {
                    if (res.data) {
                        setdataresultProject((prev: any) => [...res.data.resultProject.data, ...prev])
                    }
                    setcheckloadingProject(checkloadingProject)
                })
            }
        }, 1000)
    }

    function fetchDropdownOptionstask(searchInput: any, pagetask: number, checkloadingtask: any) {
        setcheckloadingtask(!checkloadingtask)
        setTimeout(() => {
            if (searchInput.length > 0) {
                let urltask = `resources/searchAsanaTask2`
                let datatask = {
                    name: searchInput,
                    limit: limit,
                    page: pagetask
                }
                axios.post(urltask, datatask).then((res) => {
                    if (res.data) {
                        setdatadataresultTask((prev: any) => [...res.data.resultTask.data, ...prev])
                    }
                    setcheckloadingtask(checkloadingtask)
                })
            }
        }, 1000)
    }

    function fetchDropdownOptionsuser(searchInput: any, pageuser: number, checkloadinguser: any) {
        setcheckloadinguser(!checkloadinguser)
        setTimeout(() => {
            if (searchInput.length > 0) {
                let urluser = `resources/searchAsanaUser2`
                let datauser = {
                    name: searchInput,
                    limit: limit,
                    page: pageuser
                }
                axios.post(urluser, datauser).then((res) => {
                    if (res.data) {
                        setdataresultUser((prev: any) => [...res.data.resultUser.data, ...prev])
                    }
                    setcheckloadinguser(checkloadinguser)
                })
            }
        }, 1000)
    }
    function fetchDropdownOptionsteam(searchInput: any, pageteam: number, checkloadingteam: any) {
        setcheckloadingteam(!checkloadingteam)
        setTimeout(() => {
            if (searchInput.length > 0) {
                let urlteam = `resources/searchAsanaTeam2`
                let datateam = {
                    name: searchInput,
                    limit: limit,
                    page: pageteam
                }
                axios.post(urlteam, datateam).then((res) => {
                    if (res.data) {
                        setdataresultTeam((prev: any) => [...res.data.resultTeam.data, ...prev])
                    }
                    setcheckloadingteam(checkloadingteam)
                })
            }
        }, 1000)
    }
    const debounceDropDown = useCallback(
        debounce((nextValue) => fetchDropdownOptions(nextValue, loaddingcallapi), 1000),
        []
    )
    const handleonChange = (e: any) => {
        debounceDropDown(e.target.value)
        setsearchInput(e.target.value)
        setdataresultProject([])
        setdatadataresultTask([])
        setdataresultTeam([])
        setdataresultUser([])
        setpageproject(1)
        setpagetask(1)
        setpageuser(1)
        setpageteam(1)

        // phan search Project
        dataresultProject.filter((values: any) => {
            return e.target.value !== values.name.includes(e.target.value)
        })

        dataresultTask.filter((values: any) => {
            return e.target.value !== values.name.includes(e.target.value)
        })

        dataresultTeam.filter((values: any) => {
            return e.target.value !== values.name.includes(e.target.value)
        })

        dataresultUser.filter((values: any) => {
            return e.target.value !== values.name.includes(e.target.value)
        })
    }
    useEffect(() => {
        dataresultProject.map((item: any) => {
            setopentype(item?.resourcetype)
        })
    }, [dataresultProject])

    useEffect(() => {
        dataresultTask.map((item: any) => {
            setopentypetask(item?.resourcetype)
        })
    }, [dataresultTask])

    useEffect(() => {
        dataresultUser.map((item: any) => {
            setopentypepeople(item?.resourcetype)
        })
    }, [dataresultUser])

    useEffect(() => {
        dataresultTeam.map((item: any) => {
            setopentypeteam(item?.resourcetype)
        })
    }, [dataresultTeam])

    useClickAway(clickRef, (e) => {
        setdataresultProject([])
        setdatadataresultTask([])
        setdataresultTeam([])
        setdataresultUser([])
        setpageproject(1)
        setpagetask(1)
        setpageuser(1)
        setpageteam(1)
        setmodal(false)
    })

    const onClickShowMoreproject = () => {
        setExpandproject(true)
        setpageproject((prev) => prev + 1)
        fetchDropdownOptionsproject(searchInput, pageproject + 1, checkloadingProject)
    }

    const onClickShowLessproject = () => {
        setExpandproject(false)
        setpageproject(1)
    }
    const onClickShowMoretask = () => {
        setExpandtask(true)
        setpagetask((prev) => prev + 1)
        fetchDropdownOptionstask(searchInput, pagetask + 1, checkloadingtask)
    }

    const onClickShowLesstask = () => {
        setExpandtask(false)
        setpagetask(1)
    }
    const onClickShowMoreUser = () => {
        setExpandUser(true)
        setpageuser((prev) => prev + 1)
        fetchDropdownOptionsuser(searchInput, pageuser + 1, checkloadinguser)
    }

    const onClickShowLessUser = () => {
        setExpandUser(false)
        setpageuser(1)
    }
    const onClickShowMoreTeam = () => {
        setExpandTeam(true)
        setpageteam((prev) => prev + 1)
        fetchDropdownOptionsteam(searchInput, pageteam + 1, checkloadingteam)
    }
    const onClickShowLessTeam = () => {
        setExpandTeam(false)
        setpageteam(1)
    }

    const handleclickBaskTask = (item: any, e: any) => {
        show('task', item?.id)
        setmodal(false)
        setsearchInput('')
    }
    const handleclickBaskProject = (item: any) => {
        show('projects', item?.id)
        setmodal(false)
        setsearchInput('')
    }
    const handleclickBaskpeople = (item: any) => {
        if (item?.id) {
            setidUsermytask?.setidUsermytask(item)
            show('user', item?.id)
            setmodal(false)
            setsearchInput('')
        } else {
            setmodal(false)
            setsearchInput('')
        }
    }
    const handleclickBaskteam = (item: any) => {
        show('team', item?.id)
        setmodal(false)
        setsearchInput('')
    }

    function renderdataresultProjects() {
        if (dataresultProject.length > 0) {
            return dataresultProject.map((item: any, index: any) => {
                if (index > 4 && !expandproject) {
                    return
                }
                return (
                    <li className="search_itemTopbar_li" key={item.id} onClick={() => handleclickBaskProject(item)}>
                        <div className="search_itemTopbar_li_task_text">
                            {item.assignee ? (
                                <IoIosArrowDropdownCircle
                                    size={20}
                                    className={'row-item-icon completed search_icon_task'}
                                />
                            ) : (
                                <IoIosArrowDropdown size={20} className={'row-item-icon incomplete'} />
                            )}
                            <a
                                href={`/projects/show/${item.id}`}
                                target="_blank"
                                style={{ color: '#000', width: '100%', padding: '2px 0px 2px 0px' }}
                                onClick={(e) => e.preventDefault()}
                            >
                                <Highlighter
                                    highlightClassName="YourHighlightClass_search"
                                    searchWords={[searchInput]}
                                    autoEscape={true}
                                    textToHighlight={item.name}
                                ></Highlighter>
                            </a>
                        </div>
                    </li>
                )
            })
        }
    }
    function renderdataresultTask() {
        if (dataresultTask.length > 0) {
            return dataresultTask.map((item: any, index: any) => {
                if (index > 4 && !expandtask) {
                    return
                }
                return (
                    <li
                        className="search_itemTopbar_li search_itemTopbar_li_task"
                        key={item.id}
                        onClick={(e) => handleclickBaskTask(item, e)}
                    >
                        <div className="search_itemTopbar_li_task_text">
                            {item.completed ? (
                                <IoIosArrowDropdownCircle
                                    size={20}
                                    className={'row-item-icon completed search_icon_task'}
                                />
                            ) : (
                                <IoIosArrowDropdown size={20} className={'row-item-icon incomplete'} />
                            )}

                            <a
                                href={`/task/show/${item.id}`}
                                target="_blank"
                                style={{ color: '#000', width: '100%', padding: '2px 0px 2px 0px' }}
                                onClick={(e) => e.preventDefault()}
                            >
                                <Highlighter
                                    highlightClassName="YourHighlightClass_search"
                                    searchWords={[searchInput]}
                                    autoEscape={true}
                                    textToHighlight={item.name}
                                ></Highlighter>
                            </a>
                        </div>
                        {item.assignee === null ? '' : <Avatar userId={item?.assignee} />}
                    </li>
                )
            })
        }
    }

    function renderdataresultUser() {
        if (dataresultUser.length > 0) {
            return dataresultUser.map((item: any, index: any) => {
                if (index > 4 && !expanUser) {
                    return
                }
                return (
                    <li key={item.id} className="search_itemTopbar_li" onClick={() => handleclickBaskpeople(item)}>
                        <div className="search_itemTopbar_li_task_text">
                            <span className="avatar_search">
                                <Avatar userId={item?.id} />
                            </span>
                            <a
                                href={`/user/show/${item.id}`}
                                target="_blank"
                                style={{ color: '#000', width: '100%', padding: '2px 0px 2px 0px' }}
                                onClick={(e) => e.preventDefault()}
                            >
                                <Highlighter
                                    highlightClassName="YourHighlightClass_search"
                                    searchWords={[searchInput]}
                                    autoEscape={true}
                                    textToHighlight={item.name}
                                ></Highlighter>
                            </a>
                        </div>
                    </li>
                )
            })
        }
    }

    function renderdataresultTeam() {
        if (dataresultTeam.length > 0) {
            return dataresultTeam.map((item: any, index: any) => {
                if (index > 4 && !expandTeam) {
                    return
                }
                return (
                    <li className="search_itemTopbar_li" key={item.id} onClick={() => handleclickBaskteam(item)}>
                        <div className="search_itemTopbar_li_task_text">
                            {item.assignee ? (
                                <IoIosArrowDropdownCircle
                                    size={20}
                                    className={'row-item-icon completed search_icon_task'}
                                />
                            ) : (
                                <IoIosArrowDropdown size={20} className={'row-item-icon incomplete'} />
                            )}
                            <a
                                href={`/team/show/${item.id}`}
                                target="_blank"
                                style={{ color: '#000', width: '100%', padding: '2px 0px 2px 0px' }}
                                onClick={(e) => e.preventDefault()}
                            >
                                <Highlighter
                                    highlightClassName="YourHighlightClass_search"
                                    searchWords={[searchInput]}
                                    autoEscape={true}
                                    textToHighlight={item.name}
                                ></Highlighter>
                            </a>
                        </div>
                    </li>
                )
            })
        }
    }

    return (
        <div className="TopbarSearchTypeaheade" ref={clickRef}>
            <input
                className="TopbarSearchTypeaheade-searchInput SearchTextInput"
                placeholder="Search"
                value={searchInput}
                onChange={handleonChange}
                onClick={() => setmodal(true)}
            />
            {modal && (
                <div
                    className="search__ul"
                    style={searchInput.length === 0 ? { display: 'none' } : { display: 'block' }}
                >
                    <div className="container_search">
                        <div
                            className="search_itemTopbar"
                            style={{ display: 'flex', justifyContent: 'space-between', paddingRight: '50px' }}
                        >
                            <div>
                                <span>View all results with {searchInput} </span>
                            </div>
                            <div>{loaddingcallapi ? <Loadingsearch /> : null}</div>
                        </div>

                        {dataresultProject.length > 0 ? (
                            <ul className="search_itemTopbar_ul">
                                {opentype ? <span>Projects</span> : ''}
                                {checkloadingProject ? <Loading /> : renderdataresultProjects()}
                                {dataresultProject.length >= 4 ? (
                                    <p className="show_more_left" onClick={onClickShowMoreproject}>
                                        Show more{' '}
                                    </p>
                                ) : (
                                    <p className="show_more_left" onClick={onClickShowLessproject}>
                                        Show less
                                    </p>
                                )}
                            </ul>
                        ) : null}

                        {dataresultTask.length > 0 ? (
                            <ul className="search_itemTopbar_ul">
                                {opentypetask ? <span>Tasks</span> : ''}
                                {checkloadingtask ? <Loading /> : renderdataresultTask()}
                                {dataresultTask.length >= 4 ? (
                                    <p className="show_more_left" onClick={onClickShowMoretask}>
                                        Show more
                                    </p>
                                ) : (
                                    <p className="show_more_left" onClick={onClickShowLesstask}>
                                        Show less
                                    </p>
                                )}
                            </ul>
                        ) : (
                            ''
                        )}

                        {dataresultUser.length > 0 ? (
                            <ul className="search_itemTopbar_ul">
                                {' '}
                                {opentypepeople ? <span>People</span> : ''}
                                {checkloadinguser ? <Loading /> : renderdataresultUser()}
                                {dataresultUser.length >= 4 ? (
                                    <p className="show_more_left" onClick={onClickShowMoreUser}>
                                        Show more
                                    </p>
                                ) : (
                                    <p className="show_more_left" onClick={onClickShowLessUser}>
                                        Show less
                                    </p>
                                )}
                            </ul>
                        ) : (
                            ''
                        )}

                        {dataresultTeam.length > 0 ? (
                            <ul className="search_itemTopbar_ul_ul">
                                {' '}
                                {opentypeteam ? <span>Teams</span> : ''}
                                {checkloadingteam ? <Loading /> : renderdataresultTeam()}
                                {dataresultTeam.length >= 4 ? (
                                    <p className="show_more_left" onClick={onClickShowMoreTeam}>
                                        Show more
                                    </p>
                                ) : (
                                    <p className="show_more_left" onClick={onClickShowLessTeam}>
                                        Show less
                                    </p>
                                )}
                            </ul>
                        ) : (
                            ''
                        )}
                    </div>
                </div>
            )}
            <svg
                className="Icon TopbarSearchTypeahead_icon MagnifyerIcon"
                viewBox="0 0 32 32"
                aria-hidden="true"
                focusable="false"
            >
                <path d="M29.707,28.293l-8.256-8.256C23.042,18.13,24,15.677,24,13c0-6.075-4.925-11-11-11S2,6.925,2,13s4.925,11,11,11c2.677,0,5.13-0.958,7.037-2.549l8.256,8.256L29.707,28.293z M4,13c0-4.963,4.037-9,9-9c4.963,0,9,4.037,9,9s-4.037,9-9,9C8.037,22,4,17.963,4,13z"></path>
            </svg>
        </div>
    )
}
