import { BaseRecord, useUpdate } from "@pankod/refine-core";
import { useAppContext } from "App.context/App.context";
import { USER_ID } from "authProvider";
import { COMPLETED } from "components/Mytask/Constant";
import { useMyTask } from "components/Mytask/Context/MyTask.context";
import { useDetectProposePage } from "pages/ProposeTasks/hooks";
import React, { useEffect, useState } from "react";
import { AiOutlineCheck } from "react-icons/ai";
import { BsCheckCircle, BsFillCheckCircleFill } from "react-icons/bs";

interface MarkCompleteProps {
  resourceId?: number;
  type?: string;
  completedStatus?: boolean;
  isSubtask?: boolean;
}

const MarkComplete: React.FC<MarkCompleteProps> = ({
  resourceId,
  type,
  completedStatus,
  isSubtask,
}) => {
  const [complete, setComplete] = useState(completedStatus);
  const { mutate } = useUpdate();
  const values = useMyTask();
  const setComments = values?.setComments;
  const { isProposeTaskPage } = useDetectProposePage();

  useEffect(() => {
    setComplete(completedStatus);
  }, [completedStatus]);

  const handleToggleCompleteStatus = () => {
    if (resourceId) {
      setComplete(!complete);

      mutate(
        {
          resource: "resourcesdetails/upsert",
          id: resourceId,
          values: {
            completed: !complete,
            type: !complete ? "markcomplete" : "markuncomplete",
          },
          successNotification: {
            message: "Cập nhật trạng thái thành công!",
            type: "success",
          },
        },
        {
          onSuccess(data, variables, context) {

            // Nếu có biến isSubtask thì tức là sub task => sẽ không set biến cập nhật bên task list
            if (!isSubtask) {
              //Nếu không có biến isSubtask thì mới vào đây để kiếm tra tiếp
              !values?.subTaskDetailsMode &&
                values?.setResourceDetailsChange({ completed: !complete }); // Kiểm tra nếu không phải là sub task thì mới cập nhật bên task list
              !values?.subTaskDetailsMode &&
                values?.showMyTaskDetails &&
                values?.setDetailsHeaderData([{ completed: !complete }]);
            }


            
            const userId = localStorage.getItem(USER_ID);
            if (!complete) {
              const newComment = {
                resourcesid: Math.random(),
                resources: {
                  createdAt: new Date().toISOString(),
                  createdby: userId,
                  name: `{{${userId}}} completed this task.`,
                  resourcetype: "log",
                },
              };

              if (!isSubtask) {
                //Nếu không có biến isSubtask thì mới vào đây để kiếm tra tiếp
                !values?.subTaskDetailsMode &&
                  setComments &&
                  setComments((prev) => [...prev, newComment]);
              }
            } else if (complete) {
              const newComment = {
                resourcesid: Math.random(),
                resources: {
                  createdAt: new Date().toISOString(),
                  createdby: userId,
                  name: `{{${userId}}} uncomplete this task.`,
                  resourcetype: "log",
                },
              };

              if (!isSubtask) {
                //Nếu không có biến isSubtask thì mới vào đây để kiếm tra tiếp
                !values?.subTaskDetailsMode &&
                  setComments &&
                  setComments((prev) => [...prev, newComment]);
              }
            }
          },
          onError(error, variables, context) {
            setComplete(complete);
          },
        }
      );
    }
  };

  if (type) {
    return (
      <div className={"mark-complete-datails " + (complete ? "completed" : "")}>
        <AiOutlineCheck onClick={handleToggleCompleteStatus} />
        {
          <span onClick={handleToggleCompleteStatus}>
            {complete ? "Completed" : "Mark complete"}
          </span>
        }
      </div>
    );
  }

  return (
    <>
      {complete ? (
        <BsFillCheckCircleFill
          className="row-title-check-icon"
          style={{ color: "#58a182" }}
          onClick={handleToggleCompleteStatus}
        />
      ) : (
        <BsCheckCircle
          className="row-title-check-icon"
          onClick={handleToggleCompleteStatus}
        />
      )}
    </>
  );
};

export default MarkComplete;
