import "./ProjectItem.scss";
import { MdClear } from "react-icons/md";
import { BiChevronDown } from "react-icons/bi";
import React, { useCallback, useEffect, useState } from "react";
import { useAppContext } from "App.context/App.context";
import { BaseRecord, useDelete, useList, useNavigation, useUpdate } from "@pankod/refine-core";
import { Dropdown, Menu } from "@pankod/refine-antd";
import { ItemType } from "../../../../../../../node_modules/antd/lib/menu/hooks/useItems";
import { useMyTask } from "components/Mytask/Context/MyTask.context";
import axios from "axios";
import { ALLTASK } from "components/Mytask/Constant";
import { useGlobalDataState } from "pages/ProposeTasks/state/global";
import { keyBy } from "lodash";

interface ProjectItemProps {
  projectId?: number;
  sectionId?: number;
  type?: string;
}

const ProjectItem: React.FC<ProjectItemProps> = ({ projectId, sectionId, type }) => {
  const [projectName, setProjectName] = useState("");
  const [sectionName, setSectionName] = useState("");
  const [sections, setSections] = useState<ItemType[]>([]);
  const [sectionsData, setSectionsData] = useState<BaseRecord[]>([]);
  const [sectionKey, setSectionKey] = useState<number | undefined>(undefined);
  const [getReq, setGetReq] = useState(true)
  const { mutate } = useUpdate();
  const { show } = useNavigation();

  const {readableProjects} = useGlobalDataState()
  const projectData = keyBy(readableProjects, "id")
  const values = useMyTask();


  useEffect(() => {

    if (projectId && projectData) {
      const projectName = projectData[projectId]?.name;
      setProjectName(projectName);
    }
  }, [projectId, projectData]);

  useEffect(() => {   
    if (sectionId && sectionsData.length > 0) {
      const section = sectionsData.find((item) => {
        return item?.resourcesid === sectionId;
      });

      section && setSectionName(section.resources.name);
    }
  }, [sectionId]);


  const fetchSections = useCallback(async () => {
    const sectionsRes = await axios.get(`/resourcesrelation?filter=resourcesparentid||$eq||${projectId}&filter=resources.deletedAt||$isnull&filter=resources.id||$notnull&fields=resourcesid,order&join=resources||name&limit=1000&page=1&offset=0&sort=order,DESC`)
  
    
    if (sectionsRes?.data) {
      sectionsRes?.data?.data.forEach((section: any) => {
        if (section.resources?.id === sectionId) {
          setSectionName(section.resources.name);
        }
      });

      setSectionsData(sectionsRes.data);
      setSections(() => {
        const newSections = sectionsRes?.data?.data.map((section: any) => {
          return {
            label: section.resources?.name,
            key: section.resources?.id,
            onClick: handleClick,
          };
        });

        return newSections;
      });
    }
  
  
  },[])

  useEffect(() => {
    if(projectName) {
      fetchSections()
    }

  },[projectName, fetchSections])


  const handleClick = (data: any) => {
    const selectedSectionId = data.key;
    setSectionKey(selectedSectionId);
    //call api
    mutate(
      {
        resource: "resources/updatesectionfortask",
        id: selectedSectionId,
        values: {
          newSectionId: +selectedSectionId,
          taskId: values?.currentTaskDetailId,
          oldSectionId: sectionId,
        },
      },
      {
        onSuccess(data, variables, context) {

          values?.setProjectAndSection((prev) => {
            const oldData = [...prev];
            const newData = oldData.map((item) => {
              if (item.sectionId === sectionId) {
                return { ...item, sectionId: +selectedSectionId };
              } else {
                return item;
              }
            });

            return newData;
          });
        },
      }
    );
  };


  useEffect(() => {
    if (sectionKey) {
      if(sectionsData.length > 0){

        sectionsData?.map((item) => {
          if (item?.resourcesid == sectionKey) {
            setSectionName(item.resources.name);
          }
        });
      }
    }
  }, [sectionKey]);

  const handleRemove = () => {
    mutate(
      {
        resource: "resources/removefromproject",
        id: 1,
        values: {
          sectionId,
          taskId: values?.currentTaskDetailId,
        },
      },
      {
        onSuccess(data, variables, context) {
          setTimeout(() => {
            values?.setProjectAndSection((prev) => {
              const oldData = [...prev];
        
              const newData = oldData.filter(
                (item) => item.sectionId !== sectionId
              );
  
              return newData;
            });
  
            values?.setResourceTaskChange({sectionId:  sectionId, type:'remove'})
          },0)
        },
      }
    );
  };

  const navigateProject=(projectId:any)=>{
    // show(`projects`, projectId)
    // AppContext?.setTaskDisplayStatus(ALLTASK)
    // AppContext?.setIsMyTaskLoading(true)

  }

  const menu = <Menu items={sections} />;

  return (
    <div className="project-item-container" style={{display: projectName ? '' : "none"}}>
      <div className="project-name">
        <div className="project-tag"></div>
        <p onClick={()=>navigateProject(projectId)}>{projectName}</p>
      </div>
      {!type && (
        <>
          <Dropdown overlay={menu} trigger={["click"]}>
            <div className="section-name">
              <p>{sectionName}</p>
              <BiChevronDown />
            </div>
          </Dropdown>
          <div className="remove-btn" onClick={handleRemove}>
            <MdClear />
          </div>
        </>
      )}
    </div>
  );
};

export default ProjectItem;
