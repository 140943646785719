import { BaseRecord, useRouterContext } from "@pankod/refine-core";
import { useMyTask } from "components/Mytask/Context/MyTask.context";
import { useState, useEffect, useRef } from "react";
import { Comment } from "./Comment/Comment";
import "./Comments.scss";
import { HasMoreCmt } from "../DetailsContent";
import { useAppContext } from "App.context/App.context";
import { Avatar } from "components/avatar/avatar";
import { getDateTimeFormat, getTimeFromNow } from "../../myTaskDetailUtils";
import { Tooltip } from "@pankod/refine-antd";
import { Log } from "./Log/Log";
import { useDetectProposePage } from "pages/ProposeTasks/hooks";
import { extractCommentId } from "pages/ProposeTasks/services";
import { useGlobalDataState } from "pages/ProposeTasks/state/global";
interface CommentsProps {
  hasMoreCmt: HasMoreCmt;
  setHasmoreCmt: React.Dispatch<React.SetStateAction<HasMoreCmt>>;
  setgetRqAllComment: React.Dispatch<React.SetStateAction<boolean>>;
  resourceData: BaseRecord;
  isScrollCmt: boolean;
  setIsScrollCmt: React.Dispatch<React.SetStateAction<boolean>>;
  setFileList: React.Dispatch<React.SetStateAction<BaseRecord[]>>
}

export const Comments: React.FC<CommentsProps> = ({
  setgetRqAllComment,
  setIsScrollCmt,
  resourceData,
  hasMoreCmt,
  setHasmoreCmt,
  isScrollCmt,
  setFileList
}) => {
  const values = useMyTask();
  const AppContext = useAppContext();
  const comments = values?.comments;
  const setComments = values?.setComments;
  const taskCreatedAt = getTimeFromNow(resourceData?.createdAt);
  const taskCreatedAtWithFullTime = getDateTimeFormat(resourceData?.createdAt);
  const { useLocation } = useRouterContext();
  const location = useLocation();
  const [showAll, setShowAll] = useState<boolean>(true);
  const handleLoadMoreCmt = () => {
    setgetRqAllComment(true);
  };

  const handleLocalHideCmt = () => {
    setShowAll(false);
  };

  const handleLocalLoadMoreCmt = () => {
    setShowAll(true);
  };

  const {users} = useGlobalDataState()
  const {isProposeTaskPage} = useDetectProposePage()

  const user = !isProposeTaskPage ? AppContext?.usersData?.[resourceData?.createdby] : users?.[resourceData?.createdby]; 

  const lastCmtRef = useRef(null);
  useEffect(() => {
    let timerId: any;
    if (isScrollCmt && lastCmtRef?.current) {
      const { search } = location;
      let cmtId;

      if (!isProposeTaskPage && search.includes("&")) {
        const searchArr = search.split("&");
        const searchStr = searchArr.join("=");
        cmtId = searchStr.split("=")?.at(1);
      }

      if(isProposeTaskPage) {
        cmtId = extractCommentId(window.location.href)
      }

      const commentInDom = document.getElementById(cmtId);

      if (commentInDom) {
        commentInDom.style.backgroundColor = "#0000ff12";
        setTimeout(() => {
          commentInDom.scrollIntoView({
            behavior: "smooth",
            block: "end",
            inline: "end",
          });
        },500)

        setTimeout(() => {
          timerId = commentInDom.style.backgroundColor = "";
          setIsScrollCmt(false);
        }, 4000);
      }
    }

    return () => clearTimeout(timerId);
  }, [lastCmtRef?.current]);


  
  return (
    <div className="dt-comment-container">
   
      <div className="comment-header">
        {showAll && !hasMoreCmt.isHasMore && comments && comments?.length > 3 && (
          
          <div className="cmt-header-content">
            <span onClick={handleLocalHideCmt}>Hide Earlier Comments</span>
          </div>
        )}

        {!showAll && (
          <div className="cmt-header-content">
            <span onClick={handleLocalLoadMoreCmt}>Load more comments</span>
          </div>
        )}

        <div className="cmt-header-content">
          <Avatar userId={user?.id} />
          <div className="cmt-activity">
            {user?.name ? user?.name : "Family user name"} created this task.
            <Tooltip title={taskCreatedAtWithFullTime} placement="bottom">
              <p className="task-created-time">{taskCreatedAt}</p>
            </Tooltip>
          </div>
        </div>

        <div className="cmt-loadmore" onClick={handleLoadMoreCmt}>
          {hasMoreCmt.isHasMore && `${hasMoreCmt.unLoadCmt} more comments`}
        </div>
      </div>

      {comments?.map((comment, index) => {
  
        
        if (comment?.resources?.resourcetype === "log") {
          return (
            <Log
              resources={comment?.resources}
              key={index}
              lastCmtRef={
                comments.length === index + 1 ? lastCmtRef : undefined
              }
            />
          );
        } else {
          if (!showAll && index >= comments.length - 3) {
            return (
              <Comment
                key={index}
                content={comment.resources.name}
                gid={comment.resources.gid}
                file={comment.file}
                fileId={comment.fileId}
                cmtId={comment.resourcesid}
                userId={comment.resources.createdby}
                likes={comment.resources.resourcesLikes}
                cmtCreatedAt={comment?.resources?.createdAt}
                cmtUpdatedAt={comment?.resources?.updatedAt}
                setFileList={setFileList}

              />
            );
          }

          if (showAll) {
            return (
              <Comment
                setComments={setComments}
                key={index}
                content={comment.resources.name}
                gid={comment.resources.gid}
                file={comment.file}
                fileId={comment.fileId}
                cmtId={comment.resourcesid}
                userId={comment.resources.createdby}
                likes={comment.resources.resourcesLikes}
                cmtCreatedAt={comment?.resources?.createdAt}
                cmtUpdatedAt={comment?.resources?.updatedAt}
                lastCmtRef={
                  comments.length === index + 1 ? lastCmtRef : undefined
                }
                setFileList={setFileList}
              />
            );
          }
        }
      })}
    </div>
  );
};
