import { AiOutlineBars, AiOutlineStar } from "react-icons/ai";
import { BaseRecord, useNavigation, useList } from "@pankod/refine-core";
import DropdownUpdateProject from "./Dropdown";
import { TeamContext } from "context/contextapi";
import { useEffect } from "react";
import routerProvider from "@pankod/refine-react-router-v6";
import UpdateProjectContext from "./Dropdown";
import { RiGitRepositoryPrivateFill } from "react-icons/ri";

const { Link } = routerProvider;

interface IsProjectItem {
  projectItem: BaseRecord;
  key: BaseRecord;
}
const ProjectItem: React.FC<IsProjectItem> = ({ projectItem }) => {
  const setdataListproject = TeamContext();
  const setdatamemberuser = TeamContext();
  const { show } = useNavigation();
  const handleclickTeam = (projectItem: any) => {
    setdataListproject?.setdataListproject(projectItem);
    show(`projects`, projectItem?.id);
  };
  const userListmember = useList({
    resource: "resources",
    config: {
      filters: [
        {
          field: "id", // dieu kien where.
          operator: "eq",
          value: projectItem?.resourcesmember[0]?.memberId, // id trong where
        },
      ],
    },
    metaData: {
      config: {
        fields: ["resourcesid", "name"],
      },
    },
  }).data;

  useEffect(() => {
    userListmember?.data &&
      setdatamemberuser?.setdatamemberuser(userListmember.data);
  }, [userListmember]);
  return (
    <div className="wrapper-project">
      <div className="project-content">
        <div className="line"></div>
        <div className="wrapper-new-project">
          <Link
            to={"/projects/show/" + projectItem?.id}
            rel="noopener noreferrer"
            className="open-new-tab"
          >
            <div
              className="new-project"
              onClick={() => handleclickTeam(projectItem)}
            >
              <div className="wrap-icon">
                <AiOutlineBars />
              </div>
              <div className="name-project">
                <p className="name">
                  {projectItem?.name}
                  <span className="star">
                    <AiOutlineStar />
                  </span>
                </p>
                {projectItem?.resourcesDetails?.isPublic === false && (
                  <p className="status"> <RiGitRepositoryPrivateFill className="private-icon"/> Private</p>
                )}
              </div>
            </div>
          </Link>
          <UpdateProjectContext itemUpdate={projectItem} />
        </div>
      </div>
    </div>
  );
};
export default ProjectItem;
