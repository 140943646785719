import { useEffect, useState } from "react";

interface isPropShowMoreProject {
  showMoreProjectName?: any;
}
const ShowMoreProject: React.FC<isPropShowMoreProject> = ({
  showMoreProjectName,
}) => {
  return (
    <div className="show-more-wrap">
      {showMoreProjectName?.length > 0 &&
        showMoreProjectName?.map((item: any, i: any) =>
          i > 0 ? (
            <p key={item.id} className="show-more-project-item">
              {item?.projectname?.name}
            </p>
          ) : null
        )}
    </div>
  );
};
export default ShowMoreProject;
