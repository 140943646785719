import { useMyTask } from "components/Mytask/Context/MyTask.context";
import React, { useEffect, useRef } from "react";

import { useAppContext } from "App.context/App.context";
import { Avatar } from "components/avatar/avatar";
import "./DetailsFooter.scss";
import { FooterTextEditHOC } from "./FooterTextEdit/FooterTextEdit";
import { USER_ID } from "authProvider";
import { CollaboratorsFooter } from "./CollaboratorsFooter";

interface DetailsFooterProps {
  setDetailsFooterHeight: React.Dispatch<React.SetStateAction<number>>;
  type?: string;
  isLoading?: boolean;
}

export const DetailsFooter: React.FC<DetailsFooterProps> = ({
  setDetailsFooterHeight,
  type,
  isLoading,
}) => {
  const values = useMyTask();
  const footerRef = useRef<HTMLDivElement>(null);
  const AppContext = useAppContext();
  const showSidebar = AppContext?.showSideBar;
  const userId = localStorage.getItem(USER_ID);

  useEffect(() => {
    if (footerRef.current) {
      new ResizeObserver(() => {
        footerRef.current?.offsetHeight &&
          setDetailsFooterHeight(footerRef.current?.offsetHeight);
      }).observe(footerRef.current as HTMLElement);
    }
  }, []);

  const detailsStyles =
    type === "singledetails"
      ? { width: "50%", right: "25%", boxShadow: "none" }
      : { width: "50%", right: "0%" };

  return (
    <div
      className={
        "details-footer-container " + (values?.showMyTaskDetails && "show")
      }
      style={
        !type
          ? {
              minHeight:  "100px",
              width: showSidebar ? "55%" : "calc(55% - 200px)",
            }
          : detailsStyles
      }
      ref={footerRef}
    >
      {!isLoading && (
        <>
          <div className="footer-content">
            <div className="footer-ava">
              {/* <img src={avatar} alt="" /> */}
              <Avatar userId={userId} isTooltip={false} />
            </div>

            <div className="footer-text-editor">
              <FooterTextEditHOC />
            </div>
          </div>
          <div className="collaborators">
            {values?.showMyTaskDetails && (
              <CollaboratorsFooter resourceId={values?.currentTaskDetailId} />
            )}
          </div>
        </>
      )}
    </div>
  );
};
