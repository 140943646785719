import { useList } from "@pankod/refine-core";
import React, { createContext, useContext, useState, useEffect } from "react";

type PropsPin = {
  children: React.ReactNode;
};
type Context = {
    dataResultPin:any,

    setdataResultPin:any
};
const ContextPin = createContext<Context | null>(null);

function ContextPinProvider({ children }: PropsPin) {
    const [dataResultPin, setdataResultPin] = useState([]);
  
  return (
    <ContextPin.Provider
      value={{
        dataResultPin,setdataResultPin
      }}
    >
      {children}
    </ContextPin.Provider>
  );
}
const PinContext = () => useContext(ContextPin);
export { PinContext, ContextPinProvider };
