import { Dropdown, Image, Menu, message, Tooltip } from '@pankod/refine-antd'
import { BaseRecord, useDelete, useCreate, useRouterContext } from '@pankod/refine-core'
import { useAppContext } from 'App.context/App.context'
import { USER_ID } from 'authProvider'
import { Avatar } from 'components/avatar/avatar'
import { CommentFile } from 'components/Mytask/mtDetails/DetailsFooter/FooterTextEdit/CommentFile/CommentFile'
import { FooterTextEditHOC } from 'components/Mytask/mtDetails/DetailsFooter/FooterTextEdit/FooterTextEdit'
import { getDateTimeFormat, getTimeFromNow } from 'components/Mytask/mtDetails/myTaskDetailUtils'
import moment from 'moment'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { AiOutlineLike } from 'react-icons/ai'
import { BsFillPinAngleFill, BsTrash } from 'react-icons/bs'
import { ImLink } from 'react-icons/im'

import { FaEdit } from 'react-icons/fa'
import { AiFillFire } from 'react-icons/ai'
import { GoChevronDown } from 'react-icons/go'
import { Like } from '../Like/Like'
import './Comment.scss'
import { useMyTask } from 'components/Mytask/Context/MyTask.context'
import privateImg from '../../../../../../Assets/Image/privateImg.png'
import { TeamContext } from 'context/contextapi'
import axios from 'axios'
import { CommentContext } from 'context/ContextComment'
import { PinContext } from 'context/ContextPin'
import { size } from 'lodash'
import { useDetectProposePage } from 'pages/ProposeTasks/hooks'
import { useProposeState } from 'pages/ProposeTasks/state'
import { useGlobalDataState } from 'pages/ProposeTasks/state/global'

interface CommentProps {
    content: string
    gid?: number
    file?: BaseRecord[]
    fileId?: number
    cmtId: number
    setComments?: React.Dispatch<React.SetStateAction<BaseRecord[]>>
    userId: number
    likes: BaseRecord[]
    cmtCreatedAt?: string
    cmtUpdatedAt?: string
    lastCmtRef?: React.RefObject<HTMLDivElement>
    pinTop?: any
    setcmtIdPtt?: any
    setFileList?: React.Dispatch<React.SetStateAction<BaseRecord[]>>
}

export const Comment: React.FC<CommentProps> = ({
    content,
    gid,
    file,
    fileId,
    cmtId,
    setComments,
    userId,
    likes,
    cmtCreatedAt,
    cmtUpdatedAt,
    lastCmtRef,
    pinTop,
    setFileList
}) => {
    const { isProposeTaskPage } = useDetectProposePage()

    const [showCmtOption, setShowCmtOption] = useState(false)
    const [showCmtEditor, setShowCmtEditor] = useState(false)
    const [cmtContent, setCmtContent] = useState(content)
    const [pinTotop, setPintotop] = useState(false)
    const AppContext = useAppContext()
    const values = useMyTask()
    const comments = values?.comments
    const usersData = AppContext?.usersData

    const commentCreatedTime = cmtCreatedAt && getTimeFromNow(cmtCreatedAt)
    const commentCreatedAtWithFullTime = cmtCreatedAt && getDateTimeFormat(cmtCreatedAt)
    const commentUpdatedAtWithFullTime = cmtUpdatedAt ? getDateTimeFormat(cmtUpdatedAt) : null
    const currentUserId = Number(localStorage.getItem(USER_ID))

    const CurrentUserId = localStorage.getItem(USER_ID)

    const { useParams } = useRouterContext()
    const setdataResultcomment = CommentContext()
    const setdataPintotop = TeamContext()
    const setdataResultPin = PinContext()
    const dataResultPin = PinContext()
    const dataResultcomment = CommentContext()
    const params = useParams() as BaseRecord
    const dataPintotop = TeamContext()
    useEffect(() => {
        setCmtContent(content)
    }, [content])

    const handleShowCmtOption = () => {
        setShowCmtOption(!showCmtOption)
    }

    const handleShowCmtEditor = () => {
        setShowCmtEditor(true)
        setShowCmtOption(false)
    }

    const optionRef = useRef<HTMLDivElement>(null)

    const handleClick = async (event: MouseEvent) => {
        const isClickInside = optionRef.current?.contains(event.target as HTMLElement)

        if (!isClickInside) setShowCmtOption(false)
    }

    useEffect(() => {
        setdataResultcomment?.setdataResultcomment(comments)
        if (showCmtOption) {
            document.addEventListener('click', handleClick)
        }
        return () => document.removeEventListener('click', handleClick)
    }, [showCmtOption])

    //Delete---------------------
    const { mutate } = useDelete()

    const handleDelete = () => {
        if (cmtId) {
            mutate(
                {
                    resource: 'resources/deletecomment',
                    id: cmtId,
                    successNotification: {
                        message: 'Xóa thành công 1!',
                        type: 'success'
                    },
                    invalidates: ['all']
                },
                {
                    onSuccess(data, variables, context) {
                        setComments &&
                            setComments((prev) => {
                                const oldComments = [...prev]
                                const newComments = oldComments.filter((cmt) => cmt.resourcesid !== cmtId)

                                return newComments
                            })

                        if (file) {
                            const fileIds = file.map((_) => _.fileId)
                            setFileList &&
                                setFileList((prev) => {
                                    const oldFileList = [...prev]
                                    const newFileList = []

                                    for (const iterator of oldFileList) {
                                        if (!fileIds.includes(iterator.resources.id)) {
                                            newFileList.push(iterator)
                                        }
                                    }

                                    return newFileList
                                })
                        }
                    }
                }
            )
        }
    }

    const { users } = useGlobalDataState()

    const userName = useMemo(() => {
        return usersData?.[userId]?.name || users?.[userId]?.name || 'Family User Name'
    }, [isProposeTaskPage, usersData])

    const handleCopyCmtLink = () => {
        const url = !isProposeTaskPage
            ? `${window.location.origin}/task/show/${values?.currentTaskDetailId}?comment_id=${cmtId}&projectid=${values?.currentProjectId}&resource=${params.resource}`
            : `${window.location.href}&comment_id=${cmtId}`

        navigator.clipboard.writeText(url)
        message.success('Copy link !')
    }

    //Create Pin to top---------------------
    const Create = useCreate()
    const mutateCreate = Create.mutate
    const handlePintotop = (cmtId: any) => {
        const data = dataResultcomment?.dataResultcomment.find((item: any) => item?.resourcesid == cmtId)
        const Filedata = true
        const newDataResult = {
            name: data.resources?.name,
            gid: null,
            hasFile: Filedata,
            commentId: data.resources.id,
            createdby: data.resources?.createdby,
            createdAt: data.resources?.createdAt,
            likes: data.resources.resourcesLikes,
            updatedAt: ''
        }

        mutateCreate(
            {
                resource: 'pincomment',
                values: {
                    taskId: values?.currentTaskDetailId,
                    commentId: cmtId,
                    file
                },
                successNotification: {
                    message: 'Pin to top thành công !',
                    type: 'success'
                }
                // invalidates:['all'],
            },
            {
                onSuccess: (data, variables, context) => {
                    setdataResultPin && setdataResultPin?.setdataResultPin((prev: any) => [newDataResult, ...prev])
                    setPintotop(!pinTotop)
                }
            }
        )
    }
    //Delete pintotop--------------
    const Delete = useCreate()
    const mutateDelete = Delete.mutate
    const handleupintotop = () => {
        mutateDelete(
            {
                resource: 'pincomment/deletecomment',
                values: {
                    id: datapintotop?.id,
                    commentId: cmtId
                },
                successNotification: {
                    message: 'Unpin thành công !',
                    type: 'success'
                }
                //  invalidates:['all'],
            },
            {
                onSuccess(data, variables, context) {
                    setdataResultPin &&
                        setdataResultPin?.setdataResultPin((prev: any) => {
                            const CommentsPintotop = [...prev]
                            const newCommentsPintotop = CommentsPintotop.filter((cmt) => cmt.commentId !== cmtId)
                            return newCommentsPintotop
                        })
                }
            }
        )
    }
    const datapintotop = dataResultPin?.dataResultPin?.find((item: any) => item.commentId == cmtId)
    const menu = (
        <Menu
            items={[
                {
                    label: 'Edit comment',
                    key: '1',
                    onClick: handleShowCmtEditor,
                    icon: <FaEdit />,
                    disabled: currentUserId === Number(userId) ? false : true
                },
                {
                    label: 'Copy comment link',
                    key: '2',
                    onClick: handleCopyCmtLink,
                    icon: <ImLink />
                },
                {
                    label: cmtId == datapintotop?.commentId ? 'Unpin from top' : 'Pin to  top',
                    key: '3',
                    onClick: cmtId == datapintotop?.commentId ? handleupintotop : () => handlePintotop(cmtId),
                    icon: <BsFillPinAngleFill />
                },
                {
                    type: 'divider'
                },
                {
                    label: 'Delete comment',
                    key: '4',
                    danger: true,
                    onClick: handleDelete,
                    icon: <BsTrash />,
                    disabled: currentUserId === Number(userId) ? false : true
                }
            ]}
        />
    )

    const imgTypes = ['png', 'jpg', 'jpeg', 'pjpeg']

    const imageFields: any = []
    const otherFields: any = []

    file?.forEach((item) => {
        const fileType = item.fileName.split('.').pop()

        if (imgTypes.includes(fileType)) {
            imageFields.push(item)
        } else {
            otherFields.push(item)
        }
    })

    return (
        <div id={cmtId?.toString()} className="comment-container" ref={lastCmtRef}>
            <div className={cmtId === datapintotop?.commentId ? 'comment_boderleft' : ''}></div>
            <div className="comment-ava">
                <Avatar userId={userId} />
            </div>
            <div className="comment-details">
                <div className="comment-user-name">
                    <div className="cmt-name-time">
                        <span>{usersData?.[userId]?.name || users?.[userId]?.name || 'Family User Name'}</span>

                        <Tooltip title={commentCreatedAtWithFullTime} placement="bottom">
                            <p>{commentCreatedTime}</p>
                        </Tooltip>

                        <Tooltip placement="bottom" title={commentUpdatedAtWithFullTime}>
                            {cmtUpdatedAt && <p>(edited)</p>}
                        </Tooltip>
                    </div>

                    <div className="comment-icons">
                        <Like resourceId={cmtId} likes={likes} CurrentUserId={JSON.parse(CurrentUserId as string)} />
                        <div ref={optionRef}>
                            {!showCmtEditor && (
                                <Dropdown overlay={menu} trigger={['click']}>
                                    <GoChevronDown className="comment-icon" onClick={handleShowCmtOption} />
                                </Dropdown>
                            )}
                        </div>
                    </div>
                </div>

                {showCmtEditor && (
                    <div className="comment-textEdit">
                        <FooterTextEditHOC
                            type="commentEdit"
                            setShowCmtEditor={setShowCmtEditor}
                            cmtContent={cmtContent}
                            cmtId={cmtId}
                            setCmtContent={setCmtContent}
                        />
                    </div>
                )}

                {!showCmtEditor && (
                    <div
                        className={`comment-content${gid ? ' content-asana' : ''}`}
                        dangerouslySetInnerHTML={{ __html: cmtContent }}
                    ></div>
                )}

                {file && (
                    <div className="cmt-file-container">
                        <div style={{ width: '100%', display: 'flex' }}>
                            {size(imageFields) ? (
                                <Image.PreviewGroup>
                                    {imageFields.map((img: any) => (
                                        <Image
                                            src={`${
                                                process.env.REACT_APP_API_URL || 'http://localhost:7000/'
                                            }resources/getimagethumb/${img.fileId}`}
                                            alt=""
                                            onError={({ currentTarget }) => {
                                                currentTarget.onerror = null // prevents looping
                                                currentTarget.src = privateImg
                                            }}
                                            key={img.fileId}
                                            height={100}
                                            width={100}
                                        />
                                    ))}
                                </Image.PreviewGroup>
                            ) : (
                                <></>
                            )}
                        </div>

                        {size(otherFields) ? (
                            otherFields.map((file: any) => (
                                <CommentFile name={file.fileName} fileId={file.fileId} key={file.fileId} />
                            ))
                        ) : (
                            <></>
                        )}
                    </div>
                )}
            </div>
        </div>
    )
}
