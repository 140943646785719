import {
  BaseRecord,
  useNavigation,
  useRouterContext,
} from "@pankod/refine-core";
import { useAppContext } from "App.context/App.context";
import { TOKEN_KEY_LOGIN } from "authProvider";
import React, { useEffect, useState } from "react";
import { useMyTask } from "../Context/MyTask.context";
import { MyTaskFooterProvider } from "../Context/MyTask.footer.context";
import { DetailsFooter } from "./DetailsFooter/DetailsFooter";
import "./MyTaskDetails.scss";
import { DetailsContentHOC } from "./mtDetails-Content/DetailsContent";
import { DetailsHeader } from "./mtDetails-Header/DetailsHeader";
import { useDetectProposePage, useProjectId } from "pages/ProposeTasks/hooks";
import { ProposeDetailsHeader } from "pages/ProposeTasks/components/Details/DetailHeader/DetailsHeader";
import { useProposeState } from "pages/ProposeTasks/state";


interface MyTaskDetailsProps {
  type?: string;
  setShowMyTaskDetails?: React.Dispatch<React.SetStateAction<boolean | null>>;
  setCurrentTaskDetailId?: React.Dispatch<
    React.SetStateAction<string | number | null>
  >;
  showMyTaskDetails?: boolean | null;
  detailsRef?: React.RefObject<HTMLDivElement>;
  isDetailsLoading?: boolean;
  setCurrentProjectId?: React.Dispatch<
    React.SetStateAction<number | undefined>
  >;
}

interface MyTaskDetailsHOCProps {
  type?: string;
}

export const MyTaskDetailsHOC: React.FC<MyTaskDetailsHOCProps> = ({ type }) => {
  const values = useMyTask();
  const setShowMyTaskDetails = values?.setShowMyTaskDetails;
  const setCurrentTaskDetailId = values?.setCurrentTaskDetailId;
  const showMyTaskDetails = values?.showMyTaskDetails;
  const detailsRef = values?.detailsRef;
  const isDetailsLoading = values?.isDetailsLoading;
  const setCurrentProjectId = values?.setCurrentProjectId;

  return (
    <MyTaskDetails
      type={type}
      setShowMyTaskDetails={setShowMyTaskDetails}
      setCurrentTaskDetailId={setCurrentTaskDetailId}
      showMyTaskDetails={showMyTaskDetails}
      detailsRef={detailsRef}
      isDetailsLoading={isDetailsLoading}
      setCurrentProjectId={setCurrentProjectId}
    />
  );
};

export const MyTaskDetails: React.FC<MyTaskDetailsProps> = ({
  type,
  setShowMyTaskDetails,
  setCurrentTaskDetailId,
  showMyTaskDetails,
  detailsRef,
  isDetailsLoading,
  setCurrentProjectId,
}) => {
  const [detailsFooterHeight, setDetailsFooterHeight] = useState(100);
  const [isScrollCmt, setIsScrollCmt] = useState(false);

  const { useParams, useLocation } = useRouterContext();
  let params = useParams();
  let location = useLocation();

  const values = useMyTask();
  const currentTaskDetailId = values?.currentTaskDetailId


  const AppContext = useAppContext()
  const disPlayStatus = AppContext?.taskDisplayStatus;
  const showSidebar = AppContext?.showSideBar
  
  
  const customStyles = (type === 'singledetails') ? {width: '50%', right: '25%', bottom: detailsFooterHeight + 'px'} : {width: '50%', right: '0%', bottom: detailsFooterHeight + 'px'}
  const defaultStyles = {bottom: detailsFooterHeight + 'px', width: showSidebar ? "55%" : "calc(55% - 200px)"} 
  const { id } = params as BaseRecord
  const getProjectIdRegex = /projectid=(.*)&/
  

  const { isProposeTaskPage } = useDetectProposePage();
  const {projectId} = useProposeState()

  useEffect(() => {
    if(type === 'singledetails') {
      setShowMyTaskDetails &&setShowMyTaskDetails(true)
      id && setCurrentTaskDetailId && setCurrentTaskDetailId(+id)
      const {search} = location
      search.includes('comment_id') && setIsScrollCmt(true)

      const projectId = search && search?.match(getProjectIdRegex)?.at(1)
      setCurrentProjectId && setCurrentProjectId(projectId)
      
    }else if (isProposeTaskPage && projectId) {
      const { search } = location;
      search.includes("comment_id") && setIsScrollCmt(true);
      setCurrentProjectId && setCurrentProjectId(projectId);
    }

  },[id, location?.search, projectId, isProposeTaskPage])

  useEffect(() => {
    if (type !== "singledetails") {
      let { search } = location;
      const regex = /[?&]projectid=(\d+)/;
      const match = regex.exec(search);
      const projectId2 = match && match[1];

      // f5 nếu mở task details thì giữ nguyên trạng thái mở task detail của task đó
      if (
        id &&
        projectId2 &&
        !window.location.pathname.includes("mytask/show") &&
        !window.location.pathname.includes("projects/show")
      ) {
        setShowMyTaskDetails && setShowMyTaskDetails(true);

        // set giá trị bên task detail nếu click vào task khác
        if(currentTaskDetailId) {
          setCurrentTaskDetailId && setCurrentTaskDetailId(Number(currentTaskDetailId))
        }
        // lấy resourceId ở param để set giá trị lần đầu vào task detail
        else if (id) {
          setCurrentTaskDetailId && setCurrentTaskDetailId(+id);
        }
        
        setCurrentProjectId &&
          projectId2 &&
          setCurrentProjectId(Number.parseInt(projectId2, 10));
          
        values?.setSelectedTaskId(Number(currentTaskDetailId) ?? +id);

        const { search } = location;
        search.includes("comment_id") && setIsScrollCmt(true);
      }  
    }
  }, [id,location?.search, showMyTaskDetails,currentTaskDetailId]);

  return (
    <div
      className={"mt-details-container " + (showMyTaskDetails && "show")}
      ref={detailsRef}
      style={!type ? defaultStyles : customStyles}
    >
      {isProposeTaskPage ? <ProposeDetailsHeader type={type} /> : <DetailsHeader  type={type} disPlayStatus={disPlayStatus}/>}
      {showMyTaskDetails && <DetailsContentHOC setIsScrollCmt={setIsScrollCmt} isScrollCmt={isScrollCmt}  type={type} disPlayStatus={disPlayStatus}/>}
      <MyTaskFooterProvider>
          <DetailsFooter
            setDetailsFooterHeight={setDetailsFooterHeight}
            type={type}
            isLoading={isDetailsLoading}
          />
      </MyTaskFooterProvider>
    </div>
  );
};
