import axios from "axios"
import ApiConstants from "constanst"

export const assignTaskAsync = async (value: { memberId: number, resourceId: number }) => {
    await axios.post(`${ApiConstants.BASE_URL}/resources/assigntask`, value)
}

export const removeAssignTaskAsync = async (taskId: number) => {
    await axios.post(`${ApiConstants.BASE_URL}/resources/removeassign`, { resourceId: taskId })
}

export const upsertResourceDetailAsync = async (rsId: number, value: any) => {
    await axios.patch(`${ApiConstants.BASE_URL}/resourcesdetails/upsert/${rsId}`, value)
}

export const updateResource = async(rsId: number, value: any) => {
    await axios.patch(`${ApiConstants.BASE_URL}/resources/${rsId}`, value)
}

export const getProjectList = async() => {
    return await axios.get(`${ApiConstants.BASE_URL}/resourcesmembers/projectlist?filter=memberId||$eq||1&filter=isRead||$eq||true&filter=resources.resourcetype||$eq||project&fields=resourcesId&join=resources||name,resourcetype&limit=1500&page=1&offset=0`)
}


