import React, { memo, useEffect, useRef, useState } from "react";
import { AiFillCaretRight } from "react-icons/ai";
import { GoPlus } from "react-icons/go";
import { MdDragIndicator } from "react-icons/md";
import { BsThreeDots } from "react-icons/bs";
import { useMyTask } from "../Context/MyTask.context";
import { BaseRecord, useDelete, useUpdate } from "@pankod/refine-core";
import SectionRowWrapper, {
  SectionRowWrapperHOC,
} from "./SectionRowWrapper/SectionRowWrapper";
import { useCreate } from "@pankod/refine-core";
import "./myTaskSection.scss";
import {
  Task,
  MyTaskSectionType,
  MyTaskSectionHOCType,
} from "../Type/MyTaskType";
import { Dropdown, Menu } from "@pankod/refine-antd";
import { MdOutlineDriveFileRenameOutline } from "react-icons/md";
import { BsTrash } from "react-icons/bs";
import { useAppContext } from "App.context/App.context";
import useMyTaskStore from "pages/myTask/state/store";
import { Draggable } from "react-beautiful-dnd";
import { NewInsertTaskSocketTeamContext } from "socket/newinserttaskcontext";
import axios from "axios";
import { TOKEN_KEY_LOGIN } from "authProvider";
import { RECENTLYTASK } from "../Constant";

export const MyTaskSectionHOC: React.FC<MyTaskSectionHOCType> = memo(
  ({
    sectionId,
    resources,
    index,
    setSections,
    projectId,
    myTaskSetGetReq,
    displayStatus,
  }) => {
    const values = useMyTask();
    const AppContext = useAppContext();
    const newInsertTaskSocketTeamContext = NewInsertTaskSocketTeamContext();
    const setFirstSectionId = values?.setFirstSectionId;
    const setAddTaskByid = values?.setAddTaskByid;
    const firstSectionId = values?.firstSectionId;
    const addTaskByBtn = values?.addTaskByBtn;
    const setAddTaskByBtn = values?.setAddTaskByBtn;
    const addTaskByid = values?.addTaskByid;
    const addTaskRef = values?.addTaskRef;
    const setScrollVisible = values?.setScrollVisible;
    const mtContentRef = values?.mtContentRef;
    const detailsRef = values?.detailsRef;
    const setCurrentTaskDetailTitle = values?.setCurrentTaskDetailTitle;
    const setSelectedTaskId = values?.setSelectedTaskId;
    const setCurrentTaskDetailId = values?.setCurrentTaskDetailId;
    const currentTaskDetailTitle = values?.currentTaskDetailTitle;
    const setAddTaskPosition = values?.setAddTaskPosition;
    const addTaskPosition = values?.addTaskPosition;
    const setShowMyTaskDetails = values?.setShowMyTaskDetails;
    const showMyTaskDetails = values?.showMyTaskDetails;
    const setCurrentAddTaskDetailTitle = values?.setCurrentAddTaskDetailTitle;
    const currentAddTaskDetailTitle = values?.currentAddTaskDetailTitle;
    const taskRef = values?.taskRef;
    const setIsMyTaskLoading = AppContext?.setIsMyTaskLoading;

    return (
      <>
        <MyTaskSection
          sectionId={sectionId}
          resources={resources}
          index={index}
          setFirstSectionId={setFirstSectionId}
          setAddTaskByid={setAddTaskByid}
          firstSectionId={firstSectionId}
          setAddTaskByBtn={setAddTaskByBtn}
          addTaskByBtn={addTaskByBtn}
          addTaskByid={addTaskByid}
          addTaskRef={addTaskRef}
          setScrollVisible={setScrollVisible}
          mtContentRef={mtContentRef}
          detailsRef={detailsRef}
          setCurrentTaskDetailTitle={setCurrentTaskDetailTitle}
          setSelectedTaskId={setSelectedTaskId}
          setCurrentTaskDetailId={setCurrentTaskDetailId}
          setAddTaskPosition={setAddTaskPosition}
          currentTaskDetailTitle={currentTaskDetailTitle}
          addTaskPosition={addTaskPosition}
          showMyTaskDetails={showMyTaskDetails}
          setShowMyTaskDetails={setShowMyTaskDetails}
          setCurrentAddTaskDetailTitle={setCurrentAddTaskDetailTitle}
          taskRef={taskRef}
          currentAddTaskDetailTitle={currentAddTaskDetailTitle}
          setSections={setSections}
          projectId={projectId}
          myTaskSetGetReq={myTaskSetGetReq}
          setIsMyTaskLoading={setIsMyTaskLoading}
          setCurrentProjectId={
            newInsertTaskSocketTeamContext?.setCurrentProjectId
          }
          setPrevProjectId={newInsertTaskSocketTeamContext?.setPrevProjectId}
          socketNewTaskId={newInsertTaskSocketTeamContext?.socketNewTaskId}
          displayStatus={displayStatus}
        />
      </>
    );
  }
);

export const MyTaskSection: React.FC<MyTaskSectionType> = memo(
  ({
    sectionId,
    resources,
    index,
    setFirstSectionId,
    setAddTaskByid,
    firstSectionId,
    setAddTaskByBtn,
    addTaskByBtn,
    addTaskByid,
    setScrollVisible,
    mtContentRef,
    setCurrentTaskDetailTitle,
    setSelectedTaskId,
    setCurrentTaskDetailId,
    setAddTaskPosition,
    showMyTaskDetails,
    setShowMyTaskDetails,
    setCurrentAddTaskDetailTitle,
    taskRef,
    currentAddTaskDetailTitle,
    setSections,
    projectId,
    myTaskSetGetReq,
    setIsMyTaskLoading,
    setCurrentProjectId,
    setPrevProjectId,
    socketNewTaskId,
    displayStatus,
  }) => {
    const [extend, setExtend] = useState(() => {
      return index === 0 ? true : false;
    });
    const addTaskBtnDownRef = useRef<HTMLInputElement>(null);
    const [tasks, setTasks] = useState<BaseRecord[]>([]);
    const [getRequest, setGetRequest] = useState(true);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(false);
    // const [hasMore, setHasMore] = useState(true);
    const [newAddTask, setNewAddTask] = useState<BaseRecord | null>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const [isClickTask, setIsClickTask] = useState<boolean>(false);
    const [taskAfterInsert, setTaskAfterInsert] = useState<BaseRecord>({});

    //Update variable
    const [sectionName, setSectionName] = useState(resources.name);
    const [showUpdateSection, setShowUpdateSection] = useState(false);
    const [oldSectionName, setOldSectionName] = useState("");

    const sectionIpRef = useRef<HTMLInputElement>(null);

    // const [projectDetailId, setProjectDetailId] = useState(0);
    // const [prevProjectDetailId, setPrevProjectDetailId] = useState(0);

    const { mutate } = useCreate<Task>();
    const {
      addNewTaskDown,
      addNewTaskUp,
      removeAddTask,
      appendNewTasksDown,
      appendNewTasksUp,
      updateTaskIdAfterInsert,
      deleteSection,
    } = useMyTaskStore();

    // Kiểm tra xem có click vào task hay không
    const handleClick = (event: MouseEvent) => {
      const isClickInside =
        taskRef &&
        taskRef.current?.find((item) => {
          return item && item.contains(event.target as HTMLElement);
        });
      isClickInside ? setIsClickTask(true) : setIsClickTask(false);
    };

    useEffect(() => {
      if (addTaskByBtn?.show) {
        document.addEventListener("click", handleClick);
      }

      return () => {
        document.removeEventListener("click", handleClick);
      };
    }, [addTaskByBtn?.show]);

    useEffect(() => {
      // console.log('projectIdprojectId', projectId);
      // if(projectId){

      //   setProjectDetailId(prevState=>{
      //     setPrevProjectDetailId(prevState)
      //     return projectId
      //   })
      // }
      if (projectId && setCurrentProjectId && setPrevProjectId) {
        setCurrentProjectId((prevState) => {
          if (prevState !== projectId) {
            setPrevProjectId(prevState);
          }
          return projectId;
        });
      }
    }, [projectId]);

    // useEffect(()=>{
    //   console.log('pagepage', page);
    //   console.log('prevProjectDetailId2', prevProjectDetailId);

    // },[page])

    //-----------------------------------------------------------------------

    //--------------------------------------------------------------------------
    const addNewTask = (position: string) => {
      // const newTask = tasks.filter(item => {
      //   return !item.isNew
      // })
      removeAddTask(sectionId);

      if (position === "UP") {
        // setTasks([{isNew: true, key: 'key'}, ...newTask])
        //EDIT
        addNewTaskUp(sectionId);
      }

      if (position === "DOWN") {
        // setTasks([ ...newTask, {isNew: true, key: 'key'}])
        //EDIT
        addNewTaskDown(sectionId);
      }
    };

    const removeAddNewTask = () => {
      removeAddTask(sectionId);
    };

    const pushNewTask = async (newTask: BaseRecord) => {
      newTask.position === "UP" && appendNewTasksUp(newTask, sectionId);
      newTask.position === "DOWN" && appendNewTasksDown(newTask, sectionId);

      //Đặt dòng được chọn là dòng vừa được thêm vào, dùng để thêm biến css
      setSelectedTaskId &&
        !isClickTask &&
        setSelectedTaskId(newTask?.resources.id);

      //Sau khi thêm task mới thì đặt taskid sẽ hiển thị bên detail bằng id task vừa thêm
      //(Vì mỗi lần thay đổi thông tin bảng detail thì chỉ có 1 task tương ứng được thay đổi, nên phải có id để xác định)
      showMyTaskDetails &&
        setCurrentTaskDetailId &&
        !isClickTask &&
        setCurrentTaskDetailId(newTask?.resources.id);

      //Sau khi thêm task mới thì đặt tên sẽ hiển thị bên detail bằng tên task vừa thêm
      setCurrentTaskDetailTitle &&
        !isClickTask &&
        setCurrentTaskDetailTitle(newTask.resources.name);

      //Sau khi thêm task xong thì set về lại mãng rỗng
      newAddTask && setNewAddTask(null);

      //CallApi lưu task vào db
      const taskData = {
        name: newTask.resources.name,
        resourcetype: "task",
        mytaskId: projectId ? +projectId : undefined,
        position: newTask.position,
      };

      mutate(
        {
          resource: `resources/inserttask/${sectionId}`,
          values: taskData,
          successNotification: {
            message: "Tạo Task thành công !",
            type: "success",
          },
        },
        {
          onError: (error, variables, context) => {
            //Khi lỗi thì sẽ xóa task vừa thêm và xóa các id tương ứng
            removeAddNewTask();
            showMyTaskDetails &&
              setCurrentTaskDetailId &&
              setCurrentTaskDetailId(null);
            setSelectedTaskId && setSelectedTaskId(undefined);
            setCurrentTaskDetailTitle && setCurrentTaskDetailTitle(undefined);
            setShowMyTaskDetails && setShowMyTaskDetails(false);
          },
          onSuccess: (data, variables, context) => {
            const TaskAfterInsertId = data?.data?.resourcesid;

            const TaskAfterInsert = {
              id: newTask.id,
              resources: {
                id: TaskAfterInsertId,
                name: newTask.resources.name,
              },
              order: data?.data?.orderNumber,
            };

            setTaskAfterInsert(TaskAfterInsert);
          },
        }
      );
    };
    //--------------------------------------------------------------------

    //Sau khi goị api inserttask thì nhận về taskid, dùng task id đó để cập nhật lại mảng
    useEffect(() => {
      const updateTasks = () => {
        updateTaskIdAfterInsert(sectionId, taskAfterInsert);

        //Đặt dòng được chọn là dòng vừa được thêm vào, dùng để thêm biến css
        setSelectedTaskId &&
          !isClickTask &&
          setSelectedTaskId(taskAfterInsert.resources.id);

        //Sau khi thêm task mới thì đặt taskid sẽ hiển thị bên detail bằng id task vừa thêm
        //(Vì mỗi lần thay đổi thông tin bảng detail thì chỉ có 1 task tương ứng được thay đổi, nên phải có id để xác định)
        showMyTaskDetails &&
          setCurrentTaskDetailId &&
          !isClickTask &&
          setCurrentTaskDetailId(taskAfterInsert.resources.id);
        setTaskAfterInsert({});
      };

      taskAfterInsert.id && updateTasks();
    }, [taskAfterInsert]);

    useEffect(() => {
      //Xóa các dòng đang được thêm trước
      !addTaskByBtn?.show && removeAddNewTask();
      //Kiểm tra nếu addtask = false và Nếu có task mới thì thêm task
      !addTaskByBtn?.show && newAddTask && pushNewTask(newAddTask);

      //Thêm dòng add task
      if (addTaskByBtn?.show && sectionId === addTaskByid) {
        addTaskByBtn?.position === "UP" && addNewTaskUp(sectionId);
        addTaskByBtn?.position === "DOWN" && addNewTaskDown(sectionId);
      }
      // console.log('socketNewTaskId',socketNewTaskId);

      // }, [addTaskByBtn,socketNewTaskId]);
    }, [addTaskByBtn]);

    // Đóng detail và dòng đang được thêm khi click ra ngoài và input không có giá trị
    useEffect(() => {
      showMyTaskDetails &&
        !currentAddTaskDetailTitle &&
        !addTaskByBtn?.show &&
        setShowMyTaskDetails &&
        setShowMyTaskDetails(false);
    }, [addTaskByBtn]);

    //Mặc định khi click addbutton sẽ mở ô ở section đầu tiên
    useEffect(() => {
      if (index === 0) {
        setFirstSectionId && setFirstSectionId(sectionId);
        setAddTaskByid && setAddTaskByid(sectionId);
      }
    }, []);

    const hasScrollBar = () => {
      if (mtContentRef) {
        return (
          (mtContentRef.current?.offsetWidth as number) >
          (mtContentRef.current?.clientWidth as number)
        );
      }
    };

    //Khi đóng section lại thì mặc định nhấn addbutton sẽ ô ở section đầu tiên
    useEffect(() => {
      if (!extend) {
        firstSectionId && setAddTaskByid && setAddTaskByid(firstSectionId);
        setAddTaskByBtn && setAddTaskByBtn({ position: null, show: false });
      }
      setScrollVisible && setScrollVisible(hasScrollBar());
    }, [extend]);

    useEffect(() => {
      if (addTaskByBtn?.show && sectionId === addTaskByid) {
        setExtend(true);
      }
    }, [addTaskByBtn]);

    //Handle Func
    const handleExpend = () => {
      setExtend(!extend);
    };

    const handleAddTask = () => {
      setAddTaskByBtn && setAddTaskByBtn({ position: "DOWN", show: true });
      setAddTaskByid && setAddTaskByid(sectionId);
      setCurrentTaskDetailId && setCurrentTaskDetailId("ADDTASK");
      setAddTaskPosition && setAddTaskPosition("DOWN");
      setCurrentTaskDetailTitle && setCurrentTaskDetailTitle(undefined);
      setCurrentAddTaskDetailTitle && setCurrentAddTaskDetailTitle(undefined);
    };

    const idUserTaskList = JSON.parse(
      localStorage.getItem(TOKEN_KEY_LOGIN) || ""
    );
    const { appendSection } = useMyTaskStore();

    //Delete section
    const Delete = useDelete();
    const deleteFunc = Delete.mutate;
    const HandleDeleteSection = () => {
      if (sectionId) {
        deleteFunc(
          {
            resource: "resources",
            id: sectionId,
            successNotification: {
              message: "Xóa thành công !",
              type: "success",
            },
          },
          {
            onSuccess(data, variables, context) {
              deleteSection(sectionId);
              myTaskSetGetReq && myTaskSetGetReq(true);
              setIsMyTaskLoading && setIsMyTaskLoading(true);

              axios
                .get(
                  `resourcesrelation/${
                    projectId === idUserTaskList ? "mytask" : "project"
                  }?filter=resourcesparentid%7C%7C%24eq%7C%7C${projectId}&filter=resources.deletedAt%7C%7C%24isnull&filter=resources.id%7C%7C%24notnull&fields=resourcesid%2Corder&join=resources%7C%7Cname%2Cid%2CcreatedAt&limit=1500&page=1&offset=0&sort=order%2CDESC&sort=resources.createdAt%2CDESC`
                )
                .then((res) => {
                  const dataSection = res.data.data;

                  if (dataSection) {
                    setIsMyTaskLoading && setIsMyTaskLoading(false);
                    if (dataSection && dataSection.length > 0) {
                      setSections && setSections(dataSection);
                    }
                    appendSection(dataSection);
                  }
                });
            },
          }
        );
      }
    };

    //Update section
    const HandleShowUpdateSection = () => {
      setShowUpdateSection(!showUpdateSection);
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setSectionName(e.target.value);
    };

    const Update = useUpdate();
    const updateFunc = Update.mutate;

    const handleBlur = () => {
      if (sectionName === oldSectionName) {
        setShowUpdateSection(false);
        return;
      }

      const updateValue = {
        name: sectionName,
      };
      updateFunc(
        {
          resource: "resources",
          id: sectionId,
          values: updateValue,
          successNotification: {
            message: "Cập nhật thành công !",
            type: "success",
          },
        },
        {
          onSuccess(data, variables, context) {
            setShowUpdateSection(false);
            setOldSectionName("");
          },
        }
      );
    };

    const handleKeyDown = (e: React.KeyboardEvent) => {
      if (e.keyCode !== 13) {
        return;
      }
      handleBlur();
    };

    // Khi mở update thì sẽ tự động focus vào và thêm tên section cũ để so sánh với tên thay đổi, nếu khác thì mới gọi api
    useEffect(() => {
      if (showUpdateSection && sectionIpRef?.current) {
        setOldSectionName(sectionName);
        sectionIpRef.current.focus();
      }
    }, [showUpdateSection]);

    //Options
    const menu = (
      <Menu
        items={[
          {
            label: "Rename section",
            key: "1",
            icon: <MdOutlineDriveFileRenameOutline />,
            onClick: HandleShowUpdateSection,
          },

          {
            type: "divider",
          },
          {
            label: "Delete section",
            key: "2",
            danger: true,
            icon: <BsTrash />,
            onClick: HandleDeleteSection,
          },
        ]}
      />
    );

    return (
      <>
        {displayStatus !== RECENTLYTASK && (
          <Draggable draggableId={`${sectionId}`} index={index}>
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.dragHandleProps}
                {...provided.draggableProps}
              >
                <div className="mt-session-container">
                  {displayStatus !== RECENTLYTASK && (
                    <div
                      className={
                        "mt-session-title " + (extend ? "hasborder" : "")
                      }
                    >
                      <MdDragIndicator className="session-title-icon-drag hide" />
                      <AiFillCaretRight
                        className={
                          "session-title-icon " + (extend ? "extend" : "")
                        }
                        onClick={handleExpend}
                      />
                      {!showUpdateSection && <h3>{sectionName}</h3>}
                      {showUpdateSection && (
                        <input
                          type="text"
                          className="edit-section"
                          value={sectionName}
                          onChange={handleChange}
                          ref={sectionIpRef}
                          onBlur={handleBlur}
                          onKeyDown={handleKeyDown}
                        />
                      )}

                      <Dropdown overlay={menu} trigger={["click"]}>
                        <BsThreeDots className="session-title-icon hide" />
                      </Dropdown>
                    </div>
                  )}

                  {extend && (
                    <div className="mt-session-content">
                      <SectionRowWrapperHOC
                        inputRef={inputRef}
                        page={page}
                        setPage={setPage}
                        hasMore={hasMore}
                        setHasMore={setHasMore}
                        tasks={tasks}
                        setTasks={setTasks}
                        getRequest={getRequest}
                        setGetRequest={setGetRequest}
                        sectionId={sectionId}
                        setNewAddTask={setNewAddTask}
                        myTaskSetGetReq={myTaskSetGetReq}
                      />

                      <div
                        className="add-task-btn"
                        onClick={handleAddTask}
                        ref={addTaskBtnDownRef}
                      >
                        <div>Add task...</div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </Draggable>
        )}
      </>
    );
  }
);
