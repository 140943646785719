import { keyBy } from 'lodash'
import create from 'zustand'

export enum SortType  {
Completed = "Completed Tasks",
InCompleted = "Incompleted Tasks",
AllTasks = "All Tasks"
}


export interface Sort {
    mode: SortType
    updateSort: (arg: SortType) => void
}

export const useSortState = create<Sort>((set) => ({
    mode: SortType.InCompleted,
    updateSort: (arg: SortType) => set((state) => {
        state.mode = arg
        return { ...state }
    }),
}))