import { MyTaskContextType, useMyTask } from "components/Mytask/Context/MyTask.context";
import { Mention } from "components/Mytask/Mention/Mention";

import React,{ useCallback, useEffect, useState } from "react";
import { BiChevronRight } from "react-icons/bi";
import { BsPersonCircle, BsTrash } from "react-icons/bs";
import { MdDragIndicator } from "react-icons/md";
import { RiArrowUpDownFill } from "react-icons/ri";
import { useRecentlyState } from "../state";
import { deleteTaskRecentlyAsync, removeAssigneeRecentlyAsync, toggleCompleteStatusAsync, updateAssigneeRecentlyAsync, updateRecentlyTaskAsync } from "../service";
import { useRecentlyTaskOld } from "../state/recently-task-old";
import {  Dropdown, Menu, Tooltip } from "@pankod/refine-antd";
import { Avatar } from "components/avatar/avatar";
import { FaTimes } from "react-icons/fa";
import MarkCompletedRecently from "./MarkCompletedRecent";
import DueDateRecently from "./DueDateRecently";
import { useLocationParams } from "../hooks";
import ProjectRecently from "./ProjectRecently";
import { MentionData } from "@draft-js-plugins/mention";
import { AiOutlineCheckCircle, AiOutlineFullscreenExit } from "react-icons/ai";
import { USER_ID } from "authProvider";
import { upsertResourceDetailAsync } from "pages/ProposeTasks/api.ts";

interface TaskRecentlyIF {
    task:any,
    lastElement:any,
}

const TaskRecently:React.FC<TaskRecentlyIF> = ({task,lastElement}) => {
    const [isRowFoucs, setIsRowFocus] = useState<boolean>(false);
    const [showMention, setShowMention] = useState<boolean>(false);
    const [open, setOpen] = useState<boolean>(false);

    const {paramResource,paramId} = useLocationParams()
  
    const { tasks, updateValueTask, removeTasks } = useRecentlyState()
    const {task: oldTask,addRecentlyTaskOld}:any = useRecentlyTaskOld()

    const {
        selectedTaskId,
        setShowMyTaskDetails,
        showMyTaskDetails,
        setCurrentTaskDetailId,
        setSelectedTaskId,
        setComments
      } = useMyTask() as MyTaskContextType;

      const onTaskClick = useCallback(() => {
        setSelectedTaskId(task.taskId);
        addRecentlyTaskOld(task);
    
        if (showMyTaskDetails) {
          setCurrentTaskDetailId(task.taskId);

          window.history.replaceState(
            {},
            "mytask/show",
            `/task/show/${task?.id}?projectid=${paramId}&screen=recently`
          );
        }
      },[showMyTaskDetails, task]);


    const handleChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
          const value = { name: e.target.value };
            updateValueTask(task.taskId, value);
        },
        [task.taskId]
      );


    const handleFocus = useCallback(() => {
        setIsRowFocus(true);
    }, []);
    
    const handleBlur = useCallback(() => {
      setIsRowFocus(false);
      if(oldTask) {
          updateRecentlyTaskAsync(task.taskId, {name: task?.name}, oldTask , updateValueTask)
      }
    },[task,oldTask,isRowFoucs])

    const handleKeyDown = () => {}

    const handleShowDetails = useCallback(() => {
        setShowMyTaskDetails(true);
        setCurrentTaskDetailId(task.taskId);
        addRecentlyTaskOld(task);

        window.history.replaceState(
          {},
          "mytask/show",
          `/task/show/${task?.id}?projectid=${paramId}&screen=recently`
        );

      },[task,task.taskId]);

      const handleShowMention = useCallback(() => {
        if (task.assigneeId) return;
        setShowMention(true);
      }, [task.assigneeId]);
      
    const handleRemoveAssign = useCallback(() => {
      removeAssigneeRecentlyAsync(task.taskId, updateValueTask)
    }, [task.taskId]);

    const handleUpdateAssignee = useCallback((data:MentionData) => {
      updateAssigneeRecentlyAsync(data, task.taskId, updateValueTask)
    },[task])

    const handleShowProjectList = () => {}

    //click mouse right task
    const handleRightClick = useCallback((e:any) => {
      e.preventDefault()
      addRecentlyTaskOld(task);
    },[task,oldTask])

    const handleclickOpenNewTab = useCallback(() => {
      window.open(
        `/task/show/${task?.id}?projectid=${paramId}&screen=recently`,
        "_blank",
        "noopener,noreferrer"
      );
    },[task?.id,paramId]);

    const handleTaskDelete = useCallback(() => {
      deleteTaskRecentlyAsync(task.id,tasks,removeTasks)
    },[task])

    const handleToggleCompleteStatus = useCallback(async () => {
      toggleCompleteStatusAsync(task,upsertResourceDetailAsync,USER_ID,updateValueTask,setComments)
    },[task])

    const menu = (
      <Menu
        items={[
          {
            label: "Mark uncomplete",
            key: "1",
            icon: <AiOutlineCheckCircle />,
            onClick: handleToggleCompleteStatus
          },
          {
            label: "Open in new tab",
            key: "2",
            icon: <AiOutlineFullscreenExit />,
            onClick: handleclickOpenNewTab,
          },
          {
            type: "divider",
          },
          {
            label: "Delete task",
            key: "3",
            danger: true,
            onClick: handleTaskDelete,
            icon: <BsTrash />,
          },
        ]}
      />
    );


    return (
    <Dropdown overlay={menu} trigger={["contextMenu"]}>
      <div
        className="session-row-container"
        style={{ borderTop: "none" }}
        ref={lastElement ? lastElement : null}
        onClick={onTaskClick}
        onContextMenu={handleRightClick}
      >
        <div
          className={`session-row ${selectedTaskId == task.taskId ? 'selected-row' : ''}`}  
        >
          <div className={"session-row-title " + (isRowFoucs ? "focus" : "")}>
            <MdDragIndicator className="drag-icon hide" />
  
            <div className="row-title">
              <MarkCompletedRecently task={task} />
              <input
                value={task.name}
                onChange={handleChange}
                onFocus={handleFocus}
                spellCheck={false}
                onBlur={handleBlur}
                onKeyDown={handleKeyDown}
              />
            </div>
  
            <div
              className={"row-item " + (showMyTaskDetails ? "rowitem-hasDt" : "")}
            >
              <RiArrowUpDownFill className="row-item-icon " />
              {!showMyTaskDetails && (
                <div className="row-item-detail" onClick={handleShowDetails}>
                  <span>Details</span>
                  <BiChevronRight className="row-item-detail-icon" />
                </div>
              )}
            </div>
          </div>
  
          {paramResource == 'projects' && !showMyTaskDetails && (
            <div className="session-row-col date" onClick={handleShowMention}>
              <BsPersonCircle
                style={{
                  display: showMention || task.assigneeId ? "none" : "",
                }}
                className="row-col-icon"
              />

              {task.assigneeId && (
                <>
                  <Tooltip placement="bottom" title={task.assigneeName}>
                  <div className="assignee">
                      <Avatar userId={task.assigneeId} />
                      <p className="">{task.assigneeName}</p>
                      <FaTimes className="remove-assign" onClick={handleRemoveAssign} />
                  </div>
                  </Tooltip>
                </>
              )}

              {showMention && (
                <Mention
                  customFunc={setShowMention}
                  setOpen={setOpen}
                  open={open}
                  onSelectMention={handleUpdateAssignee}
                />
              )}
            </div>
          )}


          {!showMyTaskDetails && <div
            className="session-row-col date"
            onClick={handleShowProjectList}
          >
            <DueDateRecently task={task} />
          </div>}

          {paramResource == 'mytask' && !showMyTaskDetails &&
            <div className="session-row-col date">
             <ProjectRecently task={task} updateValueTask={updateValueTask}/>
            </div>
          }

        </div>
      </div>
    </Dropdown>
  )
}
export default TaskRecently 