import create from 'zustand'

interface RecentlyTaskOldIF {
    task: any
    addRecentlyTaskOld: (task: any) => void
    updateRecentlyTaskOld: (value: any) => void
}


export const useRecentlyTaskOld = create<RecentlyTaskOldIF>((set) => ({
    task:{},
    addRecentlyTaskOld: (oldTask:any) => set((state):any => {
        state.task = oldTask
        return {
            ...state,
          
        }
    }),
    updateRecentlyTaskOld: (value:any) => set((state):any => {
        return {
            ...state,
            task: {
                ...state.task,
                ...value
            }
        }
    })
}))