import { USER_ID } from "authProvider";
import React, {
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";

import { SocketTeamContext } from "./socketcontext";


type Props = {
  children: React.ReactNode;
};
type Context = {
  changeAssigneeSocket?: any;
  setChangeAssigneeSocket?: any;
};
const AssigneeSocketContext = createContext<Context | null>(null);

function AssigneeSocketProvider({ children }: Props) {
  const userId = localStorage.getItem(USER_ID);
  const socketTeamContext = SocketTeamContext();
  const [changeAssigneeSocket, setChangeAssigneeSocket] = useState({});

  useEffect(() => {
    socketTeamContext?.socket?.on('changeAssignee',(response:any)=>{
      if(userId === response?.id?.toString()){
        setChangeAssigneeSocket(response)
      }
        
    });
  }, [socketTeamContext?.socket,userId]);

  return (
    <AssigneeSocketContext.Provider
      value={{
        changeAssigneeSocket,
        setChangeAssigneeSocket
      }}
    >
      {children}
    </AssigneeSocketContext.Provider>
  );
}
const SocketAssigneeConText = () => useContext(AssigneeSocketContext);
export { SocketAssigneeConText, AssigneeSocketProvider };
