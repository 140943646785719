import {
  BaseRecord,
  useCreate,
  useDelete,
  useUpdate,
} from "@pankod/refine-core";
import { useMyTask } from "components/Mytask/Context/MyTask.context";
import React, { memo, useEffect, useMemo, useRef, useState } from "react";
import { AiOutlineCheckCircle, AiOutlineFullscreenExit } from "react-icons/ai";
import { BiChevronRight } from "react-icons/bi";
import { BsPersonCircle, BsTrash } from "react-icons/bs";
import { MdDragIndicator } from "react-icons/md";
import { RiArrowUpDownFill } from "react-icons/ri";
import { useNavigation, useRouterContext } from "@pankod/refine-core";
import { MentionData } from "@draft-js-plugins/mention";
import { Dropdown, Menu } from "@pankod/refine-antd";
import { useAppContext } from "App.context/App.context";
import { Mention } from "components/Mytask/Mention/Mention";
import { PopUpCarlendar } from "components/Mytask/PopUpCarlendar/PopUpCarlendar";
import { ProjectList } from "components/Mytask/ProjectList/ProjectList";
import {
  SectionRowHOCProps,
  SectionRowProps,
  Task,
} from "../../Type/MyTaskType";
import MarkComplete from "./MarkComplete/MarkComplete";
import "./sectionRow.scss";
import {
  handleDeleteTask,
  handleProjectsChange,
  handleResourceDetailsChange,
  mutateAddAsiggne,
  mutateRemoveAsiggne,
  updateTask,
} from "./SectionRowUtils";
import Assignee from "./Assignee/Assignee";
import useMyTaskStore from "pages/myTask/state/store";
import { Draggable } from "react-beautiful-dnd";
import { ActionType, NewInsertTaskSocketTeamContext } from "socket/newinserttaskcontext";
import { TOKEN_KEY_LOGIN, USER_ID } from "authProvider";
import { RECENTLYTASK } from "components/Mytask/Constant";

const SectionRowHOC: React.FC<SectionRowHOCProps> = ({
  ssId,
  resources,
  lastElement,
  taskRef,
  setTasks,
  resourceDetails,
  resourceTask,
  hide,
  index,
  ssIndex
}) => {
  const values = useMyTask();
  const AppConText = useAppContext();
  const newInsertTaskSocketTeamContext = NewInsertTaskSocketTeamContext();
  const setAddTaskByid = values?.setAddTaskByid;
  const setShowMyTaskDetails = values?.setShowMyTaskDetails;
  const showMyTaskDetails = values?.showMyTaskDetails;
  const setCurrentTaskDetailTitle = values?.setCurrentTaskDetailTitle;
  const currentTaskDetailTitle = values?.currentTaskDetailTitle;
  const setCurrentTaskDetailId = values?.setCurrentTaskDetailId;
  const currentTaskDetailId = values?.currentTaskDetailId;
  const setSelectedTaskId = values?.setSelectedTaskId;
  const selectedTaskId = values?.selectedTaskId;
  const setCurrentAddTaskDetailTitle = values?.setCurrentAddTaskDetailTitle;
  const isTaskUpdate = values?.isTaskUpdate;
  const setIsTaskUpdate = values?.setIsTaskUpdate;
  const detailInputFocus = values?.detailInputFocus;
  const setDetailInputFocus = values?.setDetailInputFocus;
  const pathName = values?.pathName;
  const resourceDetailsChange = values?.resourceDetailsChange;
  const setResourceDetailsChange = values?.setResourceDetailsChange;
  const setSubTaskDetailsMode = values?.setSubTaskDetailsMode;
  const setTaskDetailsQueue = values?.setTaskDetailsQueue;
  const setResourceTaskChange = values?.setResourceTaskChange;
  const resourceTaskChange = values?.resourceTaskChange;
  const disPlayStatus = AppConText?.taskDisplayStatus;
  const currentProjectId = values?.currentProjectId;
  const isBackMyTask = values?.isBackMyTask;
  // FUNC
  const hasScrollBar = () => {
    return (
      (values?.mtContentRef.current?.offsetWidth as number) >
      (values?.mtContentRef.current?.clientWidth as number)
    );
  };

  useEffect(() => {
    values?.setScrollVisible(hasScrollBar());
  }, []);

  // console.log('isBackMyTask',isBackMyTask);
  

  return (
    <>
      <SessionRow
        ssId={ssId}
        resources={resources}
        lastElement={lastElement ? lastElement : null}
        setAddTaskByid={setAddTaskByid}
        setShowMyTaskDetails={setShowMyTaskDetails}
        showMyTaskDetails={showMyTaskDetails}
        setCurrentTaskDetailTitle={setCurrentTaskDetailTitle}
        currentTaskDetailTitle={
          currentTaskDetailId === resources?.id
            ? currentTaskDetailTitle
            : undefined
        }
        setCurrentTaskDetailId={setCurrentTaskDetailId}
        currentTaskDetailId={
          currentTaskDetailId === resources?.id ? currentTaskDetailId : null
        }
        selectedTaskId={
          selectedTaskId === resources?.id ? selectedTaskId : undefined
        }
        setSelectedTaskId={setSelectedTaskId}
        setCurrentAddTaskDetailTitle={setCurrentAddTaskDetailTitle}
        taskRef={taskRef}
        isTaskUpdate={
          selectedTaskId === resources?.id ? isTaskUpdate : undefined
        }
        setIsTaskUpdate={setIsTaskUpdate}
        setDetailInputFocus={setDetailInputFocus}
        detailInputFocus={
          currentTaskDetailId === resources?.id ? detailInputFocus : undefined
        }
        setTasks={setTasks}
        pathName={pathName}
        resourceDetails={resourceDetails}
        resourceDetailsChange={
          selectedTaskId === resources?.id ? resourceDetailsChange : undefined
        }
        // resourceDetailsChange={resourceDetailsChange }

        setResourceDetailsChange={setResourceDetailsChange}
        setSubTaskDetailsMode={setSubTaskDetailsMode}
        setTaskDetailsQueue={setTaskDetailsQueue}
        resourceTask={resourceTask}
        resourceTaskChange={
          selectedTaskId === resources?.id ? resourceTaskChange : undefined
        }
        setResourceTaskChange={setResourceTaskChange}
        disPlayStatus={disPlayStatus}
        hide={hide}
        currentProjectId={currentProjectId}
        index={index}
        updateTaskId={newInsertTaskSocketTeamContext?.socketNewTaskId}
        updateTaskName={newInsertTaskSocketTeamContext?.nameTask}
        dispatch={newInsertTaskSocketTeamContext?.dispatch}
        nameAction={newInsertTaskSocketTeamContext?.nameAction}
        isBackMyTask={isBackMyTask}
        ssIndex={ssIndex}
      />
    </>
  );
};

export const SessionRow: React.FC<SectionRowProps> = memo(
  ({
    ssId,
    resources,
    setShowMyTaskDetails,
    showMyTaskDetails,
    setAddTaskByid,
    lastElement,
    currentTaskDetailTitle,
    setCurrentTaskDetailTitle,
    setCurrentTaskDetailId,
    selectedTaskId,
    setSelectedTaskId,
    taskRef,
    setIsTaskUpdate,
    isTaskUpdate,
    detailInputFocus,
    setDetailInputFocus,
    setTasks,
    pathName,
    resourceDetails,
    resourceDetailsChange,
    setResourceDetailsChange,
    setSubTaskDetailsMode,
    setTaskDetailsQueue,
    resourceTask,
    resourceTaskChange,
    setResourceTaskChange,
    disPlayStatus,
    hide,
    currentProjectId,
    index,
    updateTaskId,
    updateTaskName,
    dispatch,
    nameAction,
    isBackMyTask,
    currentTaskDetailId,
    ssIndex
  }) => {
    const [ipFoucs, setIpFocus] = useState(false);
    const [ipvalue, setIpValue] = useState<string>(resources?.name);
    const [oldValue, setOldValue] = useState<string | null>(null);
    const UpdateFunc = useUpdate<Task>();
    const deleteFunc = useDelete();
    const ipRef = useRef<HTMLInputElement>(null);
    const [openProjectSearch, setOpenProjectSearch] = useState(false);

    const { deleteTask, data, updateTaskState } = useMyTaskStore();
    const { push } = useNavigation();
    const { useParams } = useRouterContext();
    const params = useParams() as BaseRecord;
    const values = useMyTask();
    const { mutate } = useUpdate();
    const [isComplete, setIsComplete] = useState(
      resourceDetails?.completed
    );
    const idUserTaskList =JSON.parse(localStorage.getItem(TOKEN_KEY_LOGIN) || "") ;
    //Khi thay đổi tên task ở bảng detail thì đồng thời thay đổi luôn giá trị tên task ở task
    useEffect(() => {
      (currentTaskDetailTitle || currentTaskDetailTitle === "") &&
        setIpValue(currentTaskDetailTitle); 
    }, [currentTaskDetailTitle]);

    useEffect(()=>{
      if(resources?.id === updateTaskId && updateTaskName && nameAction === ActionType.Update){
      // if(updateTaskName){

        setIpValue(updateTaskName)
      }
    },[updateTaskName])

    
    //tetst thu

    useEffect(()=>{
      if(dispatch &&  resources?.id){

        dispatch({type: 'joinRoomTaskAction', payload: resources?.id?.toString()})
      }
    },[ssId])

    //Handle Func
    //Khi click vào mỗi task thì làm những việc sau
    const dispatchSessionId = () => {
      //Set biến addaTaskById bằng section id, để xác định khi nhấn nút add task sẽ biết là thêm task ở section nào
      setAddTaskByid && setAddTaskByid(ssId);

      //Set biến CurrentTaskDetailId để biết detail đang hiển thị thông tin của task nào, đồng thời dùng id này để xác định thông tin task nào sẽ thay đổi khi detail thay đổi thông tin
      setCurrentTaskDetailId &&
        resources?.id &&
        showMyTaskDetails &&
        setCurrentTaskDetailId(resources?.id);

      //Sẽ set tên task hiển thị bên bảng detail bằng tên task mình vừa click
      setCurrentTaskDetailTitle && setCurrentTaskDetailTitle(ipvalue);

      //set biến SelectedTaskId để biết task nào đang được chọn và thêm tuộc tính css cho nó
      setSelectedTaskId && setSelectedTaskId(resources?.id);

      //Khi click vào task sẽ đổi chế độ subtask qua false
      setSubTaskDetailsMode && setSubTaskDetailsMode(false);

      //set tasid vào taskQueue để hiện thị subtask và làm nút quay lại
      setTaskDetailsQueue &&
        showMyTaskDetails &&
        resources?.id &&
        setTaskDetailsQueue([{ taskId: resources?.id, name: ipvalue }]);

        
      if(currentProjectId == idUserTaskList){
        showMyTaskDetails &&
              window.history.replaceState(
                {},
                "mytask/show",
                `/task/show/${resources?.id}?projectid=${currentProjectId}`
              );
      }else{
      showMyTaskDetails &&
              window.history.replaceState(
                {},
                "mytask/show",
                `/task/show/${resources?.id}?projectid=${currentProjectId}&resource=projects`
              );
      }

      // showMyTaskDetails &&
      //   window.history.replaceState(
      //     {},
      //     "mytask/show",
      //     `/task/show/${resources?.id}?projectid=${currentProjectId}&resource=projects`
      //   );
        // window.history.replaceState(
        //   {},
        //   "mytask/show",
        //   `/task/show/${resources?.id}?projectid=${currentProjectId}`
        // );
    };

    //Có 2 biến để lưu tên task, 1 biến cục bộ dùng để hiển thị ở cả task và ở bảng detail, 1 biến chỉ dùng để hiện thị ở task khi không hiện bảng detail
    //Nếu show bảng detail thì xét giá trị thay đổi cho biến toàn cục (setCurrentTaskDetailTitle) và biến cục bộ, còn nếu không thì chỉ xét giá trị thay đổi cho biến cục bộ (ipvalue)
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (showMyTaskDetails) {
        setCurrentTaskDetailTitle && setCurrentTaskDetailTitle(e.target.value);
        setIpValue(e.target.value);
      } else {
        setIpValue(e.target.value);
      }
    };

    //Khi focus thì sẽ xét giá trị oldvalue bằng giá trị hiện tại và set biến focus bằng true để hiển thị css cho dòng đang focus
    const handleFocus = () => {
      setIpFocus(true);
      setOldValue(ipvalue);
    };

    //Khi blur ra ngoài thì sẽ mất thuộc tính css và kích hoạt kiểm tra update
    const handleBlur = () => {
      setIpFocus(false);
      setIsTaskUpdate && setIsTaskUpdate(!isTaskUpdate);
    };

    //Xử lý khi enter thì sẽ sửa task
    const handleKeyDown = (e: React.KeyboardEvent) => {
      if (e.keyCode !== 13) {
        return;
      }

      setIpFocus(false);
      ipRef.current?.blur();
      setIsTaskUpdate && setIsTaskUpdate(!isTaskUpdate);
    };

    //hàm show detail
    const handleShowDetails = () => {

      //Set biến để show detail
      setShowMyTaskDetails && setShowMyTaskDetails(true);

      //Sẽ set tên task hiển thị bên bảng detail bằng tên task mình vừa click
      setCurrentTaskDetailTitle && setCurrentTaskDetailTitle(ipvalue);

      //Set biến CurrentTaskDetailId để biết detail đang hiển thị thông tin của task nào, đồng thời dùng id này để xác định thông tin task nào sẽ thay đổi khi detail thay đổi thông tin
      setCurrentTaskDetailId &&
        resources?.id &&
        setCurrentTaskDetailId(resources?.id);

      //set tasid vào taskQueue để hiện thị subtask và làm nút quay lại
      setTaskDetailsQueue &&
        resources?.id &&
        setTaskDetailsQueue([{ taskId: resources?.id, name: ipvalue }]);

        
        
      window.history.replaceState(
        {},
        "mytask/show",
        `/task/show/${resources?.id}?projectid=${currentProjectId}`
      );
    };

    //Để nhận tín hiệu focus bên bảng detail, nếu focus bên đó, sẽ xét giá trị cho oldvalue
    useEffect(() => {
      if (detailInputFocus) {
        setOldValue(ipvalue);
        setDetailInputFocus && setDetailInputFocus(false);
      }
    }, [detailInputFocus]);



    // Call API update task
    useEffect(() => {
      if(disPlayStatus === RECENTLYTASK) {
        oldValue && ssIndex === 0 &&
          updateTask({
            oldValue,
            setOldValue,
            ipvalue,
            setIpValue,
            resources,
            UpdateFunc,
          });
      } else {
        oldValue && 
        updateTask({
          oldValue,
          setOldValue,
          ipvalue,
          setIpValue,
          resources,
          UpdateFunc,
        });
      }
        
    }, [isTaskUpdate]);

    // Call API delete task
    const handleTaskDelete = () => {
      handleDeleteTask({ resources, deleteFunc, deleteTask, ssId });
    };

    const handleclickOpenNewTab = () => {
      window.open(
        `/task/show/${resources?.id}?projectid=${currentProjectId}&resource=${params?.resource}`,
        "_blank",
        "noopener,noreferrer"
      );
    };

    const handleToggleCompleteStatus = () => {
      if(resources?.id) {
        setIsComplete(!isComplete)


          mutate(
              {
              resource: 'resourcesdetails/upsert',
              id: resources?.id,
              values: {
                  completed: !isComplete,
                  type: !isComplete ? 'markcomplete' : 'markuncomplete'
              },
              successNotification: {
                  message: "Cập nhật trạng thái thành công!",
                  type: "success" 
                }
          },{
              onSuccess(data, variables, context) {
              },
              onError(error, variables, context) {
                setIsComplete(isComplete)
              },
          }
          )
      }
  }

    const menu = (
      <Menu
        items={[
          {
            label: "Mark complete",
            key: "1",
            icon: <AiOutlineCheckCircle />,
            onClick: handleToggleCompleteStatus
          },
          {
            label: "Open in new tab",
            key: "2",
            icon: <AiOutlineFullscreenExit />,
            onClick: handleclickOpenNewTab,
          },
          {
            type: "divider",
          },
          {
            label: "Delete task",
            key: "3",
            danger: true,
            onClick: handleTaskDelete,
            icon: <BsTrash />,
          },
        ]}
      />
    );

    //Thêm và xóa assign----------------------------------------------------------------------------
    const [showMention, setShowMention] = useState(false);
    const [open, setOpen] = useState(false);
    const [assignee, setAssignee] = useState<MentionData | null>(null);
    const [assigneeData, setAssigneeData] = useState<MentionData | null>(null);

    const createFunc = useCreate();
    const appContext = useAppContext();

    const handleShowMention = () => {
      if (assignee) return;
      setShowMention(true);
    };

    useEffect(() => {
      const getAssignee = (resourceDetails: BaseRecord) => {
        if (
          resourceDetails?.assignee &&
          resourceDetails?.assignee !== "removed"
        ) {
          const user = appContext?.mentionsData.find(
            (user) => user.id === +resourceDetails?.assignee
          );
          if (!user) return;
          setAssignee(user);
        } else if (resourceDetails?.assignee === "removed") {
          setAssignee(null);
        }
      };
      appContext?.mentionsData &&
        resourceDetails &&
        getAssignee(resourceDetails);
    }, [appContext?.mentionsData, resourceDetails]);

    //Xóa assignee
    const handleRemoveAssign = () => {
      mutateRemoveAsiggne({ createFunc, resources, setAssignee });
    };

    //Thêm assignee
    useEffect(() => {
      mutateAddAsiggne({
        createFunc,
        assigneeData,
        setAssigneeData,
        resources,
        setAssignee,
      });
    }, [assigneeData]);

    //Cập nhật UI Assignee, complete và DueOn khi thay đổi thông tin assign bên bảng details
    useEffect(() => {
      handleResourceDetailsChange({
        resourceDetailsChange,
        setTasks,
        resources,
        setResourceDetailsChange,
        disPlayStatus,
        pathName,
        data,
        ssId,
        updateTaskState,
      });
    }, [resourceDetailsChange]);

    //Xử lý giao diện khi thêm và xóa task khỏi project
    useEffect(() => {
      handleProjectsChange({
        resourceTaskChange,
        setTasks,
        setResourceTaskChange,
        resources,
      });
    }, [resourceTaskChange]);

    const handleShowProjectList = () => {
      pathName?.includes("/mytask") && setOpenProjectSearch(true);
    };

    

    return (
      <Draggable draggableId={`${resources?.id}`} index={index} isDragDisabled={disPlayStatus === RECENTLYTASK}>
        {(provided, snapshot) => {
          return (
            <Dropdown overlay={menu} trigger={["contextMenu"]}>
              <div
                ref={provided.innerRef}
                // snapshot={snapshot}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
              >
                <div
                  className="session-row-container"
                  onClick={dispatchSessionId}
                  ref={lastElement ? lastElement : null}
                  style={{ display: hide ? "none" : "", borderTop: (disPlayStatus === RECENTLYTASK && index === 0) ? "1px solid #edeae9" : "none"  }}
                >
                  <div
                    ref={(e: HTMLDivElement) => {
                      taskRef?.current && taskRef.current.push(e);
                    }}
                    className={
                      "session-row " +
                      (selectedTaskId === resources?.id ? "selected-row" : "")
                    }
                  >
                    <div
                      className={
                        "session-row-title " + (ipFoucs ? "focus" : "")
                      }
                    >
                      <MdDragIndicator className="drag-icon hide" />

                      <div className="row-title">
                        <MarkComplete
                          resourceId={resources?.id}
                          completedStatus={isComplete}
                        />
                        <input
                          value={
                            currentTaskDetailTitle ||
                            currentTaskDetailTitle === ""
                              ? currentTaskDetailTitle
                              : ipvalue
                          }
                          onChange={handleChange}
                          onFocus={handleFocus}
                          spellCheck={false}
                          onBlur={handleBlur}
                          onKeyDown={handleKeyDown}
                          ref={ipRef}
                        />
                      </div>

                      <div
                        className={
                          "row-item " +
                          (showMyTaskDetails ? "rowitem-hasDt" : "")
                        }
                      >
                        <RiArrowUpDownFill className="row-item-icon " />
                        {!showMyTaskDetails && (
                          <div
                            className="row-item-detail"
                            onClick={handleShowDetails}
                          >
                            <span>Details</span>
                            <BiChevronRight className="row-item-detail-icon" />
                          </div>
                        )}
                      </div>
                    </div>

                    {!showMyTaskDetails && (
                      <>
                          <div
                            className="session-row-col date"
                            onClick={handleShowMention}
                          >
                            <PopUpCarlendar
                              resourceId={resources?.id}
                              dueOn={resources?.createdAt}
                            />
                          </div>
                        {/* Nếu là màn hình mytask thì hiện phần này */}
                        {pathName?.includes("/mytask") && (
                          <div
                            className="session-row-col date"
                            onClick={handleShowMention}
                          >
                            <PopUpCarlendar
                              resourceId={resources?.id}
                              dueOn={resourceDetails?.dueOn}
                            />
                          </div>
                        )}

                        {/* Màn hình projects thì hiện phần này */}
                        {!pathName?.includes("/mytask") && (
                          <div
                            className="session-row-col date"
                            onClick={handleShowMention}
                          >
                            <BsPersonCircle
                              style={{
                                display: showMention || assignee ? "none" : "",
                              }}
                              className="row-col-icon"
                            />
                            {showMention && (
                              <Mention
                                customFunc={setShowMention}
                                setOpen={setOpen}
                                getData={setAssigneeData}
                                open={open}
                              />
                            )}
                            {assignee && (
                              <Assignee
                                handleRemoveAssign={handleRemoveAssign}
                                assignee={assignee}
                              />
                            )}
                          </div>
                        )}

                        <div
                          className="session-row-col date"
                          onClick={handleShowProjectList}
                        >
                          {!pathName?.includes("/mytask") && (
                            <PopUpCarlendar
                              resourceId={resources?.id}
                              dueOn={resourceDetails?.dueOn}
                            />
                          )}
                          {pathName?.includes("/mytask") && (
                            <ProjectList
                              resourceTask={resourceTask}
                              taskId={resources?.id}
                              openProjectSearch={openProjectSearch}
                              setOpenProjectSearch={setOpenProjectSearch}
                            />
                          )}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </Dropdown>
          );
        }}
      </Draggable>
    );
  }
);

export default memo(SectionRowHOC);
