/* eslint-disable react-hooks/rules-of-hooks */
import { useCreate, useUpdate } from "@pankod/refine-core";
import { TeamContext } from "context/contextapi";
import { useGetValue } from "hooks/useGetValue";
import { useState } from "react";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
interface isPropFavoriteInbox {
  comment: any;
  type: any;
  getIdStarred: any;
}
const FavoriteScreenStarred: React.FC<isPropFavoriteInbox> = ({
  comment,
  type,
  getIdStarred,
}) => {
  const { mutate } = useCreate();
  const toggleStarred = (comment: any) => {
    mutate(
      {
        resource:"resourcesstarred/starred",
        values: {
          taskId:useGetValue(comment, "resourceId", 'Starred') ,
        },
        invalidates: ["resourceAll"],
      },
      {
        onSuccess: (data, variables, context) => {
          getIdStarred(comment?.taskId);
        },
      }
    );
  };

  return (
    <>
      <span>
        { useGetValue(comment, "isStarred", 'Starred') ? (
          <AiFillStar
            onClick={() => toggleStarred(comment)}
            className="aiFillStar-icon"
            title="Remove from star"
          />
        ) : (
          <AiOutlineStar
            className="star-icon-mention general-icon"
            title="Add to star"
            onClick={() => toggleStarred(comment)}
          ></AiOutlineStar>
        )}
      </span>
    </>
  );
};
export default FavoriteScreenStarred;
