import { BaseRecord, useNavigation } from '@pankod/refine-core';
import { Dropdown, Menu, Space } from "@pankod/refine-antd";
import { TeamContext } from 'context/contextapi';
import React, { memo } from 'react';
import { FiMoreHorizontal } from 'react-icons/fi';

interface IsUpdateItem {
  itemUpdate:BaseRecord
 }
interface PropUpdateProject {
  itemUpdate?:any

}
const UpdateProjectContext:React.FC<IsUpdateItem>=({itemUpdate})=>{
  return <DropdownUpdateProject
   itemUpdate={itemUpdate}
   />
}

const DropdownUpdateProject: React.FC<PropUpdateProject>=memo(({itemUpdate}) => {
  const { edit } = useNavigation();
 
  const handleUpdate=(e:any)=>{
    edit("projects",itemUpdate?.id)
  }
  const menu = (
    <Menu
      items={[
        {
          label: 'Edit Project Detail',
          onClick:handleUpdate,
          key: '0',
        },
        
      ]}
    />
  );
  return(
    <>
   <Dropdown overlay={menu} trigger={['click']}>
            <a onClick={e => e.preventDefault()}>
              <Space>        
                 <FiMoreHorizontal className='icon-edit'/>
              </Space>
            </a>
          </Dropdown>
    </>
  )
})

export default UpdateProjectContext;