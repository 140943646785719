import { TaskType } from ".";
import create from 'zustand'

export interface DetailTask {
    task: TaskType | null
    addDetailTask: (task: TaskType) => void
    updateDetailTask: (task: any) => void
}

export const useProposeTaskDetail = create<DetailTask>((set) => ({
    task: null,
    addDetailTask: (task: TaskType) => set((state) => {
        state.task = task
        return { ...state }
    }),
    updateDetailTask: (data: any) => set((state) => {        
        state.task = {...state.task, ...data}
        return { ...state }
    }),
    
}))