import { USER_ID } from "authProvider";
import React, {
  createContext,
  Reducer,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";

import { SocketTeamContext } from "./socketcontext";

type Props = {
  children: React.ReactNode;
};
type Context = {
  tokenKey?: string;
  setTokenKey?: React.Dispatch<React.SetStateAction<string>>;
  socketNewCmtId?: number;
  setSocketNewCmtId?: React.Dispatch<React.SetStateAction<number>>;
  currentTaskId?: number;
  setCurrentTaskId?: React.Dispatch<React.SetStateAction<number>>;
  userReceiveMessage?: number;
  setUserReceiveMessage?: React.Dispatch<React.SetStateAction<number>>;
  dispatch?: React.Dispatch<ActionNewComment>;
};
const NewCommentContextTeam = createContext<Context | null>(null);
const token = localStorage.getItem("refine-auth");

export type StateNewComment = {
  taskId: string;
};
export type ActionNewComment =
  | { type: "joinRoomTask"; payload: string }
  | { type: "leaveRoomTask"; payload: string }
  | { type: "checkRoomTask"; payload: string };

export const inittialnewCommentState: StateNewComment = { taskId: "" };

function NewCommentContextProvider({ children }: Props) {
  const userId = localStorage.getItem(USER_ID);
  const socketTeamContext = SocketTeamContext();
  const [socketNewCmtId, setSocketNewCmtId] = useState(0);
  const [currentTaskId, setCurrentTaskId] = useState(0);
  const [userReceiveMessage, setUserReceiveMessage] = useState(0);
  const initialState = { taskId: "" };
  const reducer: Reducer<StateNewComment, ActionNewComment> = (
    state,
    action
  ) => {
    switch (action.type) {
      case "joinRoomTask":
        socketTeamContext?.socket?.emit("joinRoomTask", action?.payload);
        return { taskId: "" };
      case "leaveRoomTask":
        socketTeamContext?.socket?.emit(
          "leaveRoomTask",
          action?.payload?.toString()
        );
        return { taskId: "" };
      case "checkRoomTask":
        socketTeamContext?.socket?.emit("checkRoomExists", action?.payload);
        return { taskId: "" };
      default:
        throw new Error();
    }
  };

  const [state, dispatch] = useReducer(reducer, inittialnewCommentState);

  useEffect(() => {
    socketTeamContext?.socket?.on("getNewCommentClient", (response: any) => {
      if (userId === response?.userId?.toString()) {
        setSocketNewCmtId && setSocketNewCmtId(response?.commentId);
        setUserReceiveMessage(response?.userId);
      }
    });
  }, [socketTeamContext?.socket]);

  return (
    <NewCommentContextTeam.Provider
      value={{
        socketNewCmtId,
        setSocketNewCmtId,
        currentTaskId,
        setCurrentTaskId,
        dispatch,
        userReceiveMessage,
        setUserReceiveMessage,
      }}
    >
      {children}
    </NewCommentContextTeam.Provider>
  );
}
const NewCommentSocketTeamContext = () => useContext(NewCommentContextTeam);
export { NewCommentSocketTeamContext, NewCommentContextProvider };
