import {
  AntdLayout,
} from "@pankod/refine-antd";
import { useRouterContext } from "@pankod/refine-core";
import { MyTask } from "components/Mytask/MyTask";
import { HeaderProject } from "pages/HeaderSidebar/HeaderProject";
import { HeaderSidebar } from "pages/HeaderSidebar/HeaderSidebar";
import React, { useState } from "react";
import './header.css';
export const Header: React.FC = () => {

  const {useLocation} = useRouterContext()
  const location = useLocation()
  
  return (
    <AntdLayout.Header
      style={{
        // padding: "0px 24px",
        height: "64px",
        backgroundColor: "#f0f2f5",
        lineHeight:"none",
        borderBottom: "1px solid #ccc",
        position: "fixed",
        left: 0,
        right: 0,
        zIndex: 99,
        display:"flex"
      }}
    >
      <div className="header_container_top">
        <HeaderProject path={location.pathname} search={location?.search}/>
        <HeaderSidebar path={location.pathname}/>
      </div>
    </AntdLayout.Header>
  );
};
