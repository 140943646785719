import React from 'react'
import './index.scss'
import styled from 'styled-components'

const Wrap  = styled.div`
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`
const TextLoader = () => {
  return (
    <Wrap>
        <span className="loaders"></span>
    </Wrap>
  )
}

export default TextLoader