import React from "react";
import { BiError } from "react-icons/bi";

import styled from "styled-components";

const Wrap = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 48px) ;
`;
const ErorIcon = styled(BiError)`
    font-size: 6rem;
`;
const Inner = styled.div`
        display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

const Error = () => {
  return (
    <Wrap>
      <Inner>
        <ErorIcon />
        <h2>An error occurred, please try again later !</h2>
      </Inner>
    </Wrap>
  );
};

export default Error;
