import { BaseRecord } from "@pankod/refine-core";
import axios from "axios";
import { Button, CheckBox, SelectBox } from "devextreme-react";
import Popup, { Position } from "devextreme-react/popup";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import "./cauhinhchitiet.css";

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  dataGrid: any;
  formId: number;
  setRandomNumber: any;
};
export const CauHinhPopupInsert = ({
  open,
  setOpen,
  dataGrid,
  formId,
  setRandomNumber
}: Props) => {
  const [projectList, setProjectList] = useState([]);
  const [keyCauHinh, setKeyCauHinh] = useState(0);
  const [stateRead, setStateRead] = useState(false);
  const [stateCreate, setStateCreate] = useState(false);
  const [stateUpdate, setStateUpdate] = useState(false);
  const [stateDelete, setStateDelete] = useState(false);
  const [projectId, setProjectId] = useState<number>(0);
  const [sectionList, setSectionList] = useState([]);
  const [sectionId, setSectionId] = useState<number>(0);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  const loaiCauHinhs = [
    { id: 1, name: "Add task vào Project" },
    { id: 2, name: "Copy quyền của Project vào task" },
    { id: 3, name: "Add quyền của người tạo vào task" },
  ];
  useEffect(() => {
    const fetchProject = async () => {
      const results = await axios.get(
        `resourcesmembers/projectlist?filter=memberId%7C%7C%24eq%7C%7C1&filter=isRead%7C%7C%24eq%7C%7Ctrue&filter=resources.resourcetype%7C%7C%24eq%7C%7Cproject&fields=resourcesId&join=resources%7C%7Cname%2Cresourcetype&limit=1500&page=1&offset=0`
      );
      const ProjectOption = results?.data?.data?.map((project: BaseRecord) => {
        return {
          name: project?.resources?.name,
          id: project?.resources?.id,
        };
      });

      

      setProjectList(ProjectOption);
    };
    fetchProject();
  }, []);
  useEffect(() => {
    const fetchProject = async () => {
      const results = await axios.get(
        `resourcesrelation?filter=resourcesparentid||$eq||${projectId}&filter=resources.deletedAt||$isnull&filter=resources.id||$notnull&fields=resourcesid,order&join=resources||name&limit=1000&page=1&offset=0&sort=order,DESC`
      );

      const sectionOption = results?.data?.data?.map((project: BaseRecord) => {
        return {
          name: project?.resources?.name,
          id: project?.resources?.id,
        };
      });

      setSectionList(sectionOption);
    };
    fetchProject();
  }, [projectId]);

  const hideInfo = () => {
    setOpen(false);
    setStateRead(false);
    setStateCreate(false);
    setStateUpdate(false);
    setStateDelete(false);
    setProjectId(0);
    setSectionId(0);
    setSectionList([])
    setErrorMessages([]);
    setRandomNumber(Math.random() * 9)
  };

  const titleRender = () => {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <h1 style={{ fontSize: "20px", marginLeft: "20px" }}>Thêm</h1>
        <button
          style={{
            marginLeft: "388px",
            fontWeight: "600",
            marginBottom: "10px",
            border: "1px solid #edeae9",
            cursor: "pointer",
            width: "50px",
            height: "40px",
          }}
          onClick={hideInfo}
          id="closePopupInsert"
        >
          X
        </button>
      </div>
    );
  };

  const loaiCauHinhChange = (e: any) => {
    setKeyCauHinh(e?.value);
  };

  const onChangeCreate = (e: any) => {
    setStateCreate(e?.value);
  };
  const onChangeRead = (e: any) => {
    setStateRead(e?.value);
  };
  const onChangeUpdate = (e: any) => {
    setStateUpdate(e?.value);
  };
  const onChangeDelete = (e: any) => {
    setStateDelete(e?.value);
  };

  const onProjectChange = (e: any) => {
    setSectionId(0);
    setProjectId(e?.value);
  };
  const onSectionChange = (e: any) => {
    setSectionId(e?.value);
  };

  const saveData = (data: any) => {
    dataGrid.current.instance.saveEditData();

    let inputdata: any = {
      id: uuidv4(),
      textStatus: 'create',
      type: { id: keyCauHinh },
    };

    if (keyCauHinh === 1) {
      inputdata.type.projectId = projectId;
      inputdata.type.sectionId = sectionId;
    }
    if (keyCauHinh === 2) {
      inputdata.type.projectId = projectId;
    }
    if (keyCauHinh === 3) {
      inputdata.type.permission = {
        isCreate: stateCreate,
        isRead: stateRead,
        isUpdate: stateUpdate,
        isDelete: stateDelete,
      };
    }

    const sendRequest = async () => {
      const results = await axios
        .patch(`/resources/update-form-detail/${formId}`, inputdata)
        .then((data) => {
          if (data) {
            hideInfo()
            toast.success("Lưu thành công!", {
              autoClose: 3000,
              style: {
                fontSize: "14px",
                position: "fixed",
                top: "0px",
                right: "0px",
              },
            });
          }
        })
        .catch((error) => {
          const message = { ...error };
          if (
            message &&
            message.response &&
            message.response.data &&
            message.response.data.message &&
            Array.isArray(message.response.data.message)
          ) {
            const lastErrorMessage = message.response.data.message.map(
              (item: any) => {
                return item.replace("type.", "");
              }
            );

            setErrorMessages(lastErrorMessage);

          } else if (
            message &&
            message.response &&
            message.response.data &&
            message.response.data.message &&
            !Array.isArray(message.response.data.message)
          ) {
            setErrorMessages([message?.response?.data?.message]);
          } else {
            setErrorMessages(["Có lỗi xảy ra vui lòng thử lại"]);
          }
        });
    };

    sendRequest();
  };

  return (
    <div id="container">
      <Popup
        visible={open}
        dragEnabled={false}
        hideOnOutsideClick={true}
        showCloseButton={false}
        showTitle={true}
        titleRender={titleRender}
        width={550}
        height={320}
      >
        <Position at="center" my="center" collision="fit" />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          {errorMessages.length > 0 ? (
            <div
              style={{
                textAlign: "center",
                backgroundColor: "#f68e9a",
                color: "#fff",
                marginBottom: "10px",
              }}
            >
              {errorMessages.join(", ")}
            </div>
          ) : null}
          <div>
            <div className="dx-field-label">Loại: </div>
            <div className="dx-field-value">
              <SelectBox
                dataSource={loaiCauHinhs}
                displayExpr={"name"}
                valueExpr={"id"}
                onValueChanged={loaiCauHinhChange}
                width={300}
                value={keyCauHinh}
              />
            </div>
          </div>
          <div id="projectList" style={{ marginTop: "10px" }}>
            <div
              className="dx-field-label"
              style={{
                display:
                  keyCauHinh === 1 || keyCauHinh === 2 ? "block" : "none",
              }}
            >
              Project list:{" "}
            </div>
            <div className="dx-field-value">
              <SelectBox
                dataSource={projectList}
                displayExpr={"name"}
                valueExpr={"id"}
                onValueChanged={onProjectChange}
                width={300}
                value={projectId}
                visible={keyCauHinh === 1 || keyCauHinh === 2 ? true : false}
                searchMode="contains"
                searchExpr="name"
                searchTimeout={200}
                showDataBeforeSearch={false}
                searchEnabled={true}
              />
            </div>
          </div>
          <div id="sectionList" style={{ marginTop: "10px" }}>
            <div
              className="dx-field-label"
              style={{ display: keyCauHinh === 1 ? "block" : "none" }}
            >
              Section:{" "}
            </div>
            <div className="dx-field-value">
              <SelectBox
                dataSource={sectionList}
                displayExpr={"name"}
                valueExpr={"id"}
                onValueChanged={onSectionChange}
                width={300}
                value={sectionId}
                visible={keyCauHinh === 1 ? true : false}
                searchMode="contains"
                searchExpr="name"
                searchTimeout={200}
                showDataBeforeSearch={false}
                searchEnabled={true}
              />
            </div>
          </div>
          <div
            id="permissionList"
            style={{  marginLeft: "80px", marginBottom: '10px' }}
          >
            <CheckBox
              value={stateCreate}
              text="Thêm"
              onValueChanged={onChangeCreate}
              className="action_detail_button"
              visible={keyCauHinh === 3 ? true : false}
            />
            <CheckBox
              value={stateRead}
              text="Đọc"
              onValueChanged={onChangeRead}
              className="action_detail_button"
              visible={keyCauHinh === 3 ? true : false}
            />
            <CheckBox
              value={stateUpdate}
              text="Sửa"
              onValueChanged={onChangeUpdate}
              className="action_detail_button"
              visible={keyCauHinh === 3 ? true : false}
            />
            <CheckBox
              value={stateDelete}
              text="Xóa"
              onValueChanged={onChangeDelete}
              visible={keyCauHinh === 3 ? true : false}
              className="action_detail_button"
            />
          </div>
        </div>
        <Button
          icon="save"
          type="default"
          text="Lưu"
          onClick={saveData}
          style={{
            marginLeft: "300px",
          }}
        />
        <Button
          icon="close"
          type="danger"
          text="Thoát"
          onClick={hideInfo}
          style={{
            marginLeft: "10px",
          }}
        />
      </Popup>
    </div>
  );
};
