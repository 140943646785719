import React from "react";
import { BiChevronDown } from "react-icons/bi";
import { BsPlusLg } from "react-icons/bs";

import "./button.scss";
import { useMyTask } from "../Context/MyTask.context";

export const Button: React.FC = () => {
  const values = useMyTask();

  const handleAdd = () => {
    values?.setAddTaskByBtn({position: "UP", show: true});
    values?.setCurrentTaskDetailTitle(undefined);
    values?.setAddTaskPosition('UP')
    values?.setCurrentAddTaskDetailTitle(undefined)
    values?.setCurrentTaskDetailId("ADDTASK")
  };

  return (
    <div className="mt-btn-container " >
      <div
        className="mt-add-task"
        onClick={handleAdd}
        ref={values?.addTaskBtnRef}
      >
        <BsPlusLg className="mt-btn-icon" />
        <div className="mt-btn-title">Add task</div>
      </div>

      <div className="mt-down">
        <BiChevronDown className="mt-btn-icon" />
      </div>
    </div>
  );
};
