import {createSlice} from '@reduxjs/toolkit'

interface MyTaskState {
    myTaskDetails: {
        isShow: boolean
    }
}

const initialState: MyTaskState = {
    myTaskDetails: {
        isShow: false
    }
}


export default createSlice({
    name: 'mytask',
    initialState,
    reducers: {
        showMyTaskDetail: (state) => {
            state.myTaskDetails.isShow = true
        },
        hideMyTaskDetail: (state) => {
            state.myTaskDetails.isShow = false
        }
    }
})