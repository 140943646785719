import { AppContextType, useAppContext } from "App.context/App.context";
import {
  MyTaskContextType,
  useMyTask,
} from "components/Mytask/Context/MyTask.context";
import styled from "styled-components";
import AddNewBtn from "./AddNewBtn";
import { MainHeader } from "./MainHeader";
import ProposeSort from './ProposeSort'
import Section from "./Section";
import { Section as SectionType, useProposeState } from "../state";
import { isObject, orderBy, size, sortBy } from "lodash";
import { useAutoShowDetails } from "../hooks";
import { FC, useEffect, useMemo, useRef } from "react";

const Flex = styled.div`
    display: flex;
    width: 100%;
`;

const Wrapper = styled.div`
display: flex;
background-color: white;
height: 100vh;
margin: -24px;
padding: 0 24px;
`;

const HeaderWrap = styled.div<{ showSidebar?: boolean }>`
position: fixed;
right: 0;
left: 0;
top: 64px;
transition: ease 0.2s;
left: ${({ showSidebar }) => (showSidebar ? "0px" : "200px")};
`;

const Header = styled(Flex)`
    padding: 0 24px;
    background-color: white;
    height: 56px;
    align-items: center;
    justify-content: space-between;

`;

const MainContent = styled.div<{ hasDetail?: boolean }>`
    transition: ease 0.2s;
    right: 0;
    padding: ${({ hasDetail }) => (hasDetail ? "0  0 0 24px" : "0 24px")};
    width: ${({ hasDetail }) => (hasDetail ? "45%" : "")};
    position: fixed;
    left: 0;
    bottom: 0;
    overflow-y: auto;
    overflow-x: hidden;
    top: calc(var( --session-row-height) + var(--page-header-height) + var(--my-task-header-height) );
`;
const SectionWrap = styled.div<{ hasDetail?: boolean; showSidebar?: boolean }>`
    transition: ease 0.2s;
    right: 0;
    padding: ${({ hasDetail }) => (hasDetail ? "0  0 0 24px" : "0 24px")};
    width: ${({ hasDetail }) => (hasDetail ? "45%" : "")};
    position: fixed;
    bottom: 0;
    overflow-y: auto;
    overflow-x: hidden;
    left: ${({ showSidebar }) => (showSidebar ? "0px" : "200px")};
    top: calc(var( --session-row-height) + var(--page-header-height) + var(--my-task-header-height) );
`;
const ProposeTask: FC<{isLoading: boolean}> = ({isLoading}) => {
  const { showSideBar } = useAppContext() as AppContextType;
  const { showMyTaskDetails } = useMyTask() as MyTaskContextType;
  const { sections, sectionsError } = useProposeState();
  useAutoShowDetails(isLoading)

  const MainContentRef = useRef<any>(null)

  return (
    <Wrapper >
      <HeaderWrap showSidebar={showSideBar}>
        <Header>
          <AddNewBtn  />
          <ProposeSort />
        </Header>
        <MainHeader />
      </HeaderWrap>

      <MainContent hasDetail={!!showMyTaskDetails}>
        <SectionWrap hasDetail={!!showMyTaskDetails}  ref={MainContentRef} showSidebar={showSideBar}>
          {!sectionsError &&
            isObject(sections) &&
            orderBy(Object.values(sections), "order", "desc")?.map((section: SectionType, i) => (
              <Section key={section.sectionId} section={section} index={i} wrapRef={MainContentRef} />
            ))}
        </SectionWrap>
      </MainContent>
    </Wrapper>
  );
};

export default ProposeTask;
