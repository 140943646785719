import React, { useCallback, useMemo } from "react";
import ProposeTask from "./components/ProposeTask";
import { MyTaskProvider } from "components/Mytask/Context/MyTask.context";
import {
  useProjectSectionsAsync,
  useProjectId,
  useGlobalDataAsync,
  useAutoShowDetails,
} from "./hooks";
import SectionLoader from "./components/SectionLoader";
import Permission from "./components/StatusComponents/Permission";
import { ErrorCode, useProposeState } from "./state";
import Error from "./components/StatusComponents/Error";
import { MyTaskDetailsHOC } from "components/Mytask/mtDetails/MyTaskDetails";

const ProposeTasksPage = () => {
  const { isLoading: isGlobalDataLoading } = useGlobalDataAsync();
  const { isLoading } = useProjectSectionsAsync();
  const { sectionsError } = useProposeState();

  const shouldShowLoading = useMemo(() => {
    return isLoading || isGlobalDataLoading
  },[isGlobalDataLoading, isLoading])



  useProjectId();

  const render = useCallback(() => {
    if (shouldShowLoading) {
      return <SectionLoader />;
    } else if (sectionsError && sectionsError === ErrorCode.AUTHORIZE) {
      return <Permission />;
    } else if (sectionsError && sectionsError === ErrorCode.SERVER_ERROR) {
      return <Error />;
    }

    return (
      <>
        <ProposeTask isLoading={shouldShowLoading} />
        <MyTaskDetailsHOC />
      </>
    );
  }, [shouldShowLoading, sectionsError]);

  return <MyTaskProvider>{render()}</MyTaskProvider>;
};

export default ProposeTasksPage;
