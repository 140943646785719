import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { AiFillCaretRight, AiFillCaretDown } from "react-icons/ai";
import styled from "styled-components";
import Task from "./Task";
import { Section as SectionType, TaskType, useProposeState } from "../state";
import { useTasksDataAsync } from "../hooks";
import { isEmpty, orderBy, size } from "lodash";
import { ueSectionFetchState } from "../state/loading";
import { SortType, useSortState } from "../state/sort";
import { useGlobalDataState } from "../state/global";

const Wrapper = styled.div``;

const Content = styled.div<{ hasborder?: boolean }>`
    display: flex;
    align-items: center;
    height: 50px;
    border-bottom: 1px solid transparent;
    gap: 10px;
    position: relative;
    border-bottom: ${({ hasborder }) =>
    hasborder ? "1px solid var(--border-color)" : ""}  ;
`;

const AiFillCaretRightCustom = styled(AiFillCaretRight)`
   color: var( --title-icon-color);
        font-size: 18px;
        cursor: pointer;
        width: 28px;
        height: 28px;
        padding: 6px;
        border-radius: 4px;

        &:hover{
            background-color: var(--text-hover-background-color);
            color: var(--title-hover-color);
        }
`;

const AiFillCaretDownCustom = styled(AiFillCaretDown)`
   color: var( --title-icon-color);
        font-size: 18px;
        cursor: pointer;
        width: 28px;
        height: 28px;
        padding: 6px;
        border-radius: 4px;

        &:hover{
            background-color: var(--text-hover-background-color);
            color: var(--title-hover-color);
        }
`;

const SectionName = styled.h3`
  margin: 0;
  font-weight: 600;
`;

const TasksWrap = styled.div``;

const Section: FC<{ section: SectionType; index: number, wrapRef: any }> = ({ section, wrapRef }) => {
  const { sectionsFetchState, updateSectionsFetchStateById } =
    ueSectionFetchState();
  const { mode } = useSortState();
  const {updateScrollVisible} = useGlobalDataState()


  const fetchState = useMemo(() => {
    return sectionsFetchState[section?.sectionId];
  }, [JSON.stringify(sectionsFetchState), JSON.stringify(section)]);

  const { isLoading } = useTasksDataAsync(
    section?.sectionId,
    fetchState?.extend,
    fetchState.page,
    updateSectionsFetchStateById
  );

  const tasksToDisPlay: TaskType[] = useMemo(() => {
    const allTasks = !isEmpty(section.tasks)
      ? orderBy(Object.values(section.tasks), "order", "desc")
      : [];
    if (mode === SortType.Completed) {
      return allTasks.filter((_) => _.completed);
    }

    if (mode === SortType.InCompleted) {
      return allTasks.filter((_) => !_.completed);
    }

    return allTasks;
  }, [JSON.stringify(section?.tasks), mode]);

  //Infiniti Load area
  const observe = useRef<IntersectionObserver | null>(null);

  const lastElement = useCallback(
    (node: HTMLElement) => {
      if (observe.current) observe.current?.disconnect();

      observe.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && fetchState?.hasMore) {
          updateSectionsFetchStateById(section.sectionId, {
            page: fetchState.page + 1,
          });
        }
      });

      if (node) {
        observe.current?.observe(node);
      }
    },

    [JSON.stringify(fetchState), section.tasks]
  );

  const handleExpend = () => {
    updateSectionsFetchStateById(section.sectionId, {
      extend: !fetchState.extend,
    });
  };

  const hasScrollBar = useCallback(() => {
    if (wrapRef.current) {
      return (
        (wrapRef.current?.offsetWidth as number) >
        (wrapRef.current?.clientWidth as number)
      );
    }
    return false
  },[wrapRef?.current ])

  useEffect(() => {
    updateScrollVisible(hasScrollBar());
  }, [tasksToDisPlay, fetchState.extend]);

  return (
    <Wrapper>
      <Content hasborder={fetchState?.extend}>
        {fetchState?.extend ? (
          <AiFillCaretDownCustom onClick={handleExpend} />
        ) : (
          <AiFillCaretRightCustom onClick={handleExpend} />
        )}
        <SectionName>{section?.name}</SectionName>
      </Content>

      <TasksWrap>
        {fetchState?.extend &&
          !isLoading &&
          !!size(tasksToDisPlay) &&
          tasksToDisPlay?.map((task, index) => (
            <Task
              key={task.taskId}
              task={task}
              lastElement={
                tasksToDisPlay.length === index + 1 ? lastElement : null
              }
            />
          ))}
      </TasksWrap>
    </Wrapper>
  );
};

export default Section;
