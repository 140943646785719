import { useState } from "react";
interface isPropComment {
  commentItem?: any;
}
const CommentItem: React.FC<isPropComment> = ({ commentItem }) => {
  const [readMore, setReadMore] = useState(false);

  return (
    <div className="content-comment-inbox">
      <div
        className={!readMore ? "comment-name" : "comment-name-read-more"}
        dangerouslySetInnerHTML={{ __html: commentItem }}
      />
      {commentItem &&
        commentItem?.length > 230 && (
          <button
            className="btn-readmore-inbox"
            onClick={() => setReadMore(!readMore)}
          >
            {readMore ? "Read less" : "  Read more"}
          </button>
        )}
    </div>
  );
};
export default CommentItem;
