import { MyTaskProvider } from 'components/Mytask/Context/MyTask.context'
import {MyTask, MyTaskHOC} from '../../components/Mytask/MyTask'
// import {MyTask, MyTaskHOC} from '../../components/Mytask/MyTask'
import {Provider} from 'react-redux'
import { store } from 'components/Mytask/Redux/store'
import {  MyTaskDetailsHOC } from 'components/Mytask/mtDetails/MyTaskDetails'
import { BaseRecord, useList, useRouterContext } from '@pankod/refine-core'
import { MentionData } from '@draft-js-plugins/mention'
import { useEffect } from 'react'
import { useAppContext } from 'App.context/App.context'
export const SingleTaskDetailsPage: React.FC = () => {

  const { useParams, useLocation } = useRouterContext();
  const location = useLocation();
  let { search } = location;  
  let isFullScreen =false
 

  const urlParams = new URLSearchParams(search);
  const params:any = {};


  

  for (const [key, value] of urlParams.entries()) {
    params[key] = value;
  }

  if(params['fullscreen']){
    isFullScreen=true;
  }

  if(!params['projectid'] && !params['fullscreen']){
    isFullScreen=true;
  }




  const AppContext = useAppContext()
  const usersRes  = useList({
    resource: "resources/allusers",
    config: {
      filters: [
        {
          field: "resourcetype",
          operator: "eq",
          value: 'user',
        },
      ],
      pagination: { current: 1, pageSize: 1500 },

    },
    metaData: {
      config: {
        fields: ["name", "id"],
        joins: [{field: 'resourceRelation2',select: ["resourcesid", "id"]}]
      },
    },
  }).data as MentionData | undefined

  const projectsRes  = useList({
    resource: "resourcesmembers/projectlist",
    queryOptions: { cacheTime: 0 },
    config: {
      filters: [
        {
          field: "memberId",
          operator: "eq",
          value: 1,
        },
        {
          field: "isRead",
          operator: "eq",
          value: true,
        },
        {
          field: "resources.resourcetype",
          operator: "eq",
          value: 'project',
        },
      ],
      pagination: { current: 1, pageSize: 1500 },

    },
    metaData: {
      config: {
        fields: ["resourcesId"],
        joins: [{ field: "resources", select: ["name", "resourcetype"] }],

      },
    },
  }).data as MentionData | undefined

  const hashMapData = (MentionDatas: BaseRecord[]) => {
    const dataObj = MentionDatas.reduce((total: BaseRecord, data: BaseRecord) => {
      if(data.id) {
        total[data?.resources?.id] = data.resources
      }
      return total
    },{})
    return dataObj
  }



  const hashMapUserData = (MentionDatas: BaseRecord[]) => {
    const usersObj = MentionDatas.reduce((total: BaseRecord, user: BaseRecord) => {
      if(user.id) {
        total[user.id] = user
      }
      return total
    },{})
    return usersObj
  }

   
  useEffect(() => {
    if(projectsRes) {
      const ProjectOption = projectsRes.data.map((project: BaseRecord) => {
        return {
          label: project?.resources?.name,
          value: project?.resources?.id
        }
      })
      AppContext?.setProjectList(ProjectOption)
      AppContext?.setProjectData(hashMapData(projectsRes.data))
    }
    
  },[projectsRes])

  useEffect(() => {
    usersRes && AppContext?.setMentionsData(usersRes.data)
    usersRes && AppContext?.setUsersData(hashMapUserData(usersRes.data))    
  },[usersRes])

  return (
    <MyTaskProvider>
      <Provider store={store}>
          {usersRes &&  isFullScreen ? <MyTaskDetailsHOC type="singledetails"/> : <MyTaskHOC/>}
      </Provider>
    </MyTaskProvider>
  )
}