import React, { useCallback, useState } from "react"

import { Avatar } from "components/avatar/avatar";
import { Mention } from "components/Mytask/Mention/Mention";
import { FaTimes } from "react-icons/fa";

import { DescTextEditor } from "components/Mytask/mtDetails/DescTextEditor/DescTextEditor";
import { removeAssigneeRecentlyAsync, updateAssigneeRecentlyAsync } from "pages/Recently-completed-task/service";
import { useRecentlyTaskOld } from "pages/Recently-completed-task/state/recently-task-old";
import { useTaskRecentSelect } from "pages/Recently-completed-task/hooks";
import { useRecentlyState } from "pages/Recently-completed-task/state";
import DueDateRecently from "../DueDateRecently";
import TaskProjectDetailRecently from "./TaskProject";
import { MentionData } from "@draft-js-plugins/mention";
import { useMyTask } from "components/Mytask/Context/MyTask.context";
import { SocketTeamContext } from "socket/socketcontext";
import { TOKEN_KEY_LOGIN, USER_ID } from "authProvider";
interface TaskInfoDetailRecentlyIF {
    description:any,
    resourcesid:any
}

const TaskInfoDetailRecently:React.FC<TaskInfoDetailRecentlyIF> = ({description,resourcesid}) => {
    const [open, setOpen] = useState(false);
    const [showMention, setShowMention] = useState(false);
    const [dataAssignee, setDataAssignee] = useState<{}>({})

    const socketTeamContext = SocketTeamContext();

    const values = useMyTask();
    const selectedTaskId:any = values?.selectedTaskId
    const currentProjectId = values?.currentProjectId;
    
    const idUserTaskList =JSON.parse(localStorage.getItem(TOKEN_KEY_LOGIN) || "") ;

    const {task: taskRecently}:any = useRecentlyTaskOld()
    const taskRecentSelect = useTaskRecentSelect(taskRecently?.taskId ?? selectedTaskId)
    const { updateValueTask } = useRecentlyState()

    const handleAssignClick = () => {
        setShowMention(prev => !prev);
    }

    const handleUpdateAssignee = useCallback(async (data:MentionData) => {
      const userId = localStorage.getItem(USER_ID);
      updateAssigneeRecentlyAsync(data,taskRecentSelect, updateValueTask,socketTeamContext,userId,currentProjectId,idUserTaskList)
      setDataAssignee(data)
    }, [taskRecentSelect,socketTeamContext]);

    const handleRemoveAssignee = useCallback(async () => {
      removeAssigneeRecentlyAsync(taskRecentSelect?.taskId, updateValueTask,dataAssignee,socketTeamContext,currentProjectId,idUserTaskList)
    }, [taskRecentSelect]);

    return (
        <div className="TaskInfo-container">
          <div className={"info-item " + (open && "focus")}>
            <div className="info-label">Assignee</div>
            <div className="info-detail" onClick={handleAssignClick}>
    
              {taskRecentSelect?.assigneeId && !showMention && <Avatar userId={Number(taskRecentSelect?.assigneeId)} />}
                <>
                  {showMention ? (
                    <Mention
                      customFunc={setShowMention}
                      open={open}
                      setOpen={setOpen}
                      onSelectMention={handleUpdateAssignee}
                    />
                  ) : !taskRecentSelect?.assigneeId ? <span>Add Assignee</span> : <></>
                  }
                </>
              {taskRecentSelect?.assigneeId && !showMention && <div className="asignee-name">{taskRecentSelect.assigneeName}</div>}
    
            </div>
    
            {!open && taskRecentSelect?.assigneeId && (
              <FaTimes
                className="del-assigne-icon"
                onClick={handleRemoveAssignee}
              />
            )}
    
          </div>
    
          <div className="info-item">
            <div className="info-label">Due date</div>
            <div className="info-detail">
              <DueDateRecently task={taskRecentSelect} />
            </div>
          </div>
    
          <TaskProjectDetailRecently task={taskRecentSelect} updateValueTask={updateValueTask}/>
    
          <div className="info-item">
            <div className="info-label">Description</div>
          </div>
          <DescTextEditor description={description} resourcesid={resourcesid} />
        </div>
      );
}
export default TaskInfoDetailRecently 