/* eslint-disable react-hooks/rules-of-hooks */
import { BaseRecord, useUpdate } from "@pankod/refine-core";
import {
  AiFillMessage,
  AiOutlineCheckCircle,
  AiOutlineLike,
} from "react-icons/ai";
import { IoIosArrowDropdown, IoIosArrowDropdownCircle } from "react-icons/io";
import parse from "html-react-parser";
import { HiArrowNarrowLeft } from "react-icons/hi";
import { useMyTask } from "components/Mytask/Context/MyTask.context";
import moment from "moment";
import { TeamContext } from "context/contextapi";
import { Like } from "components/Mytask/mtDetails/mtDetails-Content/Comments/Like/Like";
import { Avatar } from "components/avatar/avatar";
import { FaUser } from "react-icons/fa";
import { useEffect, useState } from "react";
import ProjectNameItem from "../mt_activity/assignee/ProjectNameItem";
import CommentItem from "../mt_activity/assignee/CommentItem";

import { useGetValue } from "hooks/useGetValue";
interface IspropArchive {
  archive: BaseRecord;
  getUserId: string | null;
  lastElement?: any;
  index: any;
  type: any;
  dataScreenActivity: any;
  setDataScreenActivity: any;
  dataScreenArchive: any;
  setDataScreenArchive: any;
  setPage: any;
}
const ArchiveItem: React.FC<IspropArchive> = ({
  archive,
  getUserId,
  lastElement,
  index,
  type,
  dataScreenActivity,
  setDataScreenActivity,
  dataScreenArchive,
  setDataScreenArchive,
  setPage,
}) => {
  const { mutate } = useUpdate();
  const values = useMyTask();
  const [isOpen, setIsopen] = useState(true);

  const handleArchire = (archive: any, index: any) => {
    mutate(
      {
        resource:
          type === "All"
            ? "resourcesmentions/archive"
            : "resourcesfilterlastesmention/archive",
        values: {
          isArchived: true,
        },
        id: archive?.resourcesmentions?.at(0)?.id
          ? archive?.resourcesmentions?.at(0)?.id
          : useGetValue(archive, "resourcesmentions", type),
        invalidates: ["all"],
      },
      {
        onSuccess: (data, variables, context) => {
          setDataScreenActivity((prev: any) => [archive, ...prev]);
          setDataScreenArchive &&
            setDataScreenArchive((prev: any) => {
              const olddata = [...prev];
              const newdata = olddata.filter((cmt) => cmt.id !== archive?.id);
              if (newdata?.length === 0) {
                setPage(1);
              }

              return newdata;
            });
        },
      }
    );
  };
  useEffect(() => {
    if (isOpen) {
      values?.setShowMyTaskDetails(true);
      if (index === 0) {
        values?.setCurrentTaskDetailId(useGetValue(archive, "parentId", type));
        values?.setCurrentTaskDetailTitle(
          useGetValue(archive, "resourcesParentName", type)
        );
      }
      values?.setHideArrowToRightScreenDetail("hide");
      setIsopen(false);
    }
  }, [values]);

  const handleDetailArchived = (archive: any) => {
    values?.setHideArrowToRightScreenDetail("hide");
    values?.setShowMyTaskDetails(true);
    values?.setCurrentTaskDetailId(useGetValue(archive, "parentId", type));
    values?.setCurrentTaskDetailTitle(
      useGetValue(archive, "resourcesParentName", type)
    );
  };

  return (
    <>
      <div
        className="asign-item"
        key={archive.id}
        ref={lastElement ? lastElement : null}
        onClick={() => handleDetailArchived(archive)}
      >
        <div>
          <div className="assign-project-name">
            <div className="project-name-wrap">
              <ProjectNameItem
                projectName={useGetValue(archive, "resourcestasks", type)}
              />
            </div>
            {useGetValue(archive, "complete", type) ? (
              <IoIosArrowDropdownCircle
                size={20}
                className={"row-item-icon completed"}
              />
            ) : (
              <IoIosArrowDropdown
                size={20}
                className={"row-item-icon incomplete"}
              />
            )}
            <span>{useGetValue(archive, "resourcesParentName", type)}</span>
          </div>

          <div className="asign-wrap">
            <div className="user-asign">
              <div className="avatar">
                <Avatar userId={useGetValue(archive, "resourcecmId", type)} />

                <div className="message">
                  <span className="icon-message">
                    <AiFillMessage />
                  </span>
                </div>
              </div>
              <div className="task-content">
                <p className="user-name">
                  {useGetValue(archive, "resourcecmName", type)}
                </p>

                <CommentItem commentItem={useGetValue(archive, "name", type)} />

                <div className="comment-date">
                  {useGetValue(archive, "createdAt", type)
                    ? moment
                        .utc(useGetValue(archive, "createdAt", type))
                        .local()
                        .startOf("seconds")
                        .fromNow()
                    : "--"}
                </div>
              </div>
            </div>
            {/* <span className="icon-like">
              <AiOutlineLike />
            </span> */}
            <div className="icon-like-wrap">
              <Like
                resourceId={useGetValue(archive, "id", type)}
                likes={useGetValue(archive, "resourcesLikes", type)}
                CurrentUserId={JSON.parse(getUserId as string)}
              />
            </div>
          </div>

          <div className="Archive-list">
            {/* <span title="Create follow-up task" className="icon-check general-icon">
              <AiOutlineCheckCircle />
            </span> */}

            <span
              title="Move to inbox"
              className="archive-icon general-icon"
              onClick={() => handleArchire(archive, index)}
            >
              {/* <BsArchive/> */}
              <HiArrowNarrowLeft />
            </span>
          </div>
        </div>
      </div>
    </>
  );
};
export default ArchiveItem;
