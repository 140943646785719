const ApiConstants = {
    BASE_URL: `${process.env.REACT_APP_API_URL || 'http://localhost:8000'}`,
    BASE_URL_CLIENT: `${process.env.REACT_APP_CLIENT_URL || 'http://localhost:3000'}`,
    SOCKET_URL: `${process.env.REACT_APP_CLIENT_URL || 'http://localhost:7000'}`,
    RESOURCES: 'resources',
    RESOURCESTASKS: 'resourcestasks',
    AVATAR: 'resources/avatar',
}

export   const MOBILE_URL = 'com.familyhospital.workingspace:/'
  
export default ApiConstants