import React from "react";
import { createRoot } from "react-dom/client";

import reportWebVitals from "./reportWebVitals";
import App from "./App";
import "./i18n";
import { XContextProvider } from "context/contextapi";
import { GlobalStyle } from "../src/components/GlobalStyle/GlobalStyle";
import { AppProvider } from "App.context/App.context";
import { GlobalData } from "components/GlobalStyleData/GlobalStyleData";
import { MyTaskProvider } from "components/Mytask/Context/MyTask.context";

import { ToastContainer } from "react-toastify";
import { ContextPinProvider } from "context/ContextPin";
import { ContextCommentProvider } from "context/ContextComment";

import { SocketContextProvider,  } from "socket/socketcontext";
import { NewInboxContextProvider } from "socket/newinboxcontext";
import { NewCommentContextProvider } from "socket/newcommentcontext";
import { NewInsertTaskContextProvider } from "socket/newinserttaskcontext";
import './index.css';
import { AssigneeSocketProvider } from "socket/socket-asssign-context";

const container = document.getElementById("root") as HTMLElement;
const root = createRoot(container);

root.render(
  <React.Suspense fallback="loading">
    <AppProvider>

      <XContextProvider>
        <MyTaskProvider>
          <ContextPinProvider>
            <ContextCommentProvider>
            <SocketContextProvider>
              <NewInboxContextProvider>
                <NewCommentContextProvider>
                  <NewInsertTaskContextProvider>
                    <AssigneeSocketProvider>
                      <GlobalStyle>
                      <App />
                      </GlobalStyle>
                    </AssigneeSocketProvider>
                  </NewInsertTaskContextProvider>
                </NewCommentContextProvider>
              </NewInboxContextProvider>
              </SocketContextProvider>
            </ContextCommentProvider>
          </ContextPinProvider>


        </MyTaskProvider>
      </XContextProvider>


      
    </AppProvider>
    <ToastContainer />
  </React.Suspense>
);

reportWebVitals();
