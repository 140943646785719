import { extractLinks } from "@draft-js-plugins/linkify";
import { MentionData } from "@draft-js-plugins/mention";
import { BaseRecord, GetListResponse, HttpError, UseCreateReturnType } from "@pankod/refine-core";
import { UseUpdateReturnType } from "@pankod/refine-core/dist/hooks/data/useUpdate";
import { USER_ID } from "authProvider";
import axios from "axios";
import ApiConstants from "constanst";
import { ContentState, EditorState, Entity, RichUtils, convertFromHTML, convertFromRaw, convertToRaw } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { toast } from "react-toastify";

interface handleCommentParam {
  taskId: number,
  editorState: EditorState,
  Files: File[],
  setComments?: React.Dispatch<React.SetStateAction<BaseRecord[]>>;
  createFunc: UseCreateReturnType<BaseRecord, HttpError, {}>
  setCommentAfterInsertId: React.Dispatch<React.SetStateAction<string | number | null>>,
  setEditorState: React.Dispatch<React.SetStateAction<EditorState>>,
  setUploadedPercent?: React.Dispatch<React.SetStateAction<number>>,
  setFiles: React.Dispatch<React.SetStateAction<File[]>>;
  detailsRef: React.RefObject<HTMLDivElement> | undefined
  mentionsDataHasmap: any
  setIsCmtLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setFileList?: React.Dispatch<React.SetStateAction<BaseRecord[]>>;
}

interface updateCommentParam {
  editorState: EditorState,
  cmtId?: number,
  setCmtContent?: React.Dispatch<React.SetStateAction<string>>
  setShowCmtEditor?: React.Dispatch<React.SetStateAction<boolean>>
  setComments?: React.Dispatch<React.SetStateAction<BaseRecord[]>>
  updateFunc: any
  mentionsDataHasmap: any

}

interface getUserNameParam {
  mentionsData: MentionData[],
  mentionIdsRes: GetListResponse<BaseRecord> | undefined
}


interface mentionInfos {
  id: number,
  name: string
}

interface styleProps {
  editorState: EditorState,
  setEditorState: React.Dispatch<React.SetStateAction<EditorState>>,
}

interface InlineStyleControlProps {
  active?: boolean;
  editorState: EditorState;
  onStyleBtnClick: (name: string) => void;
}


interface DescriptionIT {
  editorStateDescription: EditorState,
  oldTextValue: string,
  resourcesid: any,
  editorText: string,
  mutate:any,
  setValueDescription:any,
  editorTextToRaw:string
}

interface textEditor {
  description: string;
}


export const createMentionEntities = (tags: { id: number, name: string }[], cmtContent: string) => {
  const blocksFromHTML = convertFromHTML(cmtContent);
  const rawContent = convertToRaw(ContentState.createFromBlockArray(
    blocksFromHTML.contentBlocks,
    blocksFromHTML.entityMap
  ))


  // Kiểm tra nếu có mention mới thực hiện logic này, tags là data trả về từ api bảng mention
  if (tags.length > 0) {
    //Lặp qua tags để tạo entitymap cho mention
    const rawState: Entity[] = tags.map((tag: { id: number, name: string }) => ({
      type: 'mention',
      mutability: 'IMMUTABLE',
      data: {
        mention: {
          id: tag.id, name: tag?.name
        }
      }
    }));

    // Gán entitmap mới tạo cho content
    const a: Entity = Object.assign({}, rawState)
    rawContent.entityMap = { ...a };


    //Hàm lấy vị trí của mention (offset)
    const getIndicesOf = (searchStr: string, str: string, caseSensitive?: any) => {
      let tempStr = str;
      let tempSearchStr = searchStr;
      const searchStrLen = tempSearchStr.length;
      if (searchStrLen === 0) {
        return [];
      }
      let startIndex = 0;
      let index;
      const indices = [];
      if (!caseSensitive) {
        tempStr = tempStr.toLowerCase();
        tempSearchStr = tempSearchStr.toLowerCase();
      }

      while ((index = tempStr.indexOf(tempSearchStr, startIndex)) > -1) {
        indices.push(index);
        startIndex = index + searchStrLen;
      }

      return [indices[0] - 1];
    };

    // Hàm tạo entityRanges cho blocks
    const getEntityRanges = (text: string, mentionName: string, mentionKey: any) => {
      const indices = getIndicesOf(mentionName, text);

      if (indices.length > 0) {
        return indices.map(offset => ({
          key: mentionKey,
          length: mentionName.length + 1,
          offset
        }));
      }

      return null;
    };

    // Lặp qua các blocks gán entityRanges cho block để khởi tạo mention
    rawContent.blocks = rawContent.blocks.map(block => {
      const ranges: any = [];

      tags.forEach((tag, index) => {
        const entityRanges = getEntityRanges(block.text, tag.name, index);
        // Kiểm tra nếu có entityRanges và offset có tồn tại thì mới push nó vào mảng và gán cho blocks
        if (entityRanges && !isNaN(entityRanges[0]?.offset)) {
          ranges.push(...entityRanges);
        }
      });

      return { ...block, entityRanges: ranges };
    });


  }



  return convertFromRaw(rawContent);
};



export const sendComment = async ({ taskId, editorState, Files, setComments, createFunc, setCommentAfterInsertId, setEditorState, setUploadedPercent, setFiles, detailsRef, mentionsDataHasmap, setIsCmtLoading, setFileList }: handleCommentParam) => {
  if (!taskId) {
    alert("Vui lòng tạo Task trước khi comment !");
    return;
  }

  const { mutate } = createFunc


  const options = {
    entityStyleFn: (entity: any) => {
      const entityType = entity.get("type").toLowerCase();

      if (entityType === "link") {
        const data = entity.get("data");


        return {
          element: "a",
          attributes: {
            href: data.url,

          },
        };
      }

      if (entityType === "mention") {
        const { mention } = entity.get("data");
        const user = mentionsDataHasmap[mention?.id as string]

        return {
          element: "a",
          attributes: {
            href: `${ApiConstants.BASE_URL_CLIENT}/mytask/show/${user?.resourceRelation2?.resourcesid}`, //ĐỂ TẠM
            "user-id": mention?.id
          },
        };
      }



    },
  };


  const contentState = editorState.getCurrentContent();

  let editorHTML = stateToHTML(contentState, options);

  const currentEditorText = convertToRaw(contentState).blocks[0].text;
  const newEditTor = EditorState.createEmpty();

  editorHTML = addLinkToEntityMap(contentState, editorHTML, options)


  const newComment: BaseRecord = {
    id: Math.floor(Date.now()),
    resourcesid: Math.floor(Date.now()),
    resources: {
      id: Math.floor(Date.now()),
      name: editorHTML,
    },
  };

  const entityMap = convertToRaw(contentState).entityMap
  let mentions: number[] = []


  for (const key in entityMap) {
    if (Object.prototype.hasOwnProperty.call(entityMap, key)) {
      const element = entityMap[key];
      if (element.type === 'mention') {
        mentions.push(element.data.mention.id)
      }

    }
  }

  if (Files.length === 0) {
    if (currentEditorText) {
      setIsCmtLoading(true)

      setComments && setComments((prev) => [...prev, newComment]);
      setEditorState(newEditTor);

      setTimeout(() => {
        detailsRef?.current?.scrollTo({
          top: detailsRef?.current?.scrollHeight,
          left: 0,
          behavior: 'smooth'
        })
      }, 100);

      const commentData = {
        name: editorHTML,
        resourcetype: "comment",
        resourceId: taskId,
        mentions
      };

      mutate(
        {
          resource: `resources/insertcomment`,
          values: commentData,
          // invalidates:['all'],
          successNotification: {
            message: "Thành công !",
            type: "success"
          }
        },
        {
          onError: () => {
            setCommentAfterInsertId("error");
            setIsCmtLoading(false)

          },
          onSuccess: (data) => {
            newComment.resources.name = data.data.name
            setComments && setComments((prev) => [...prev]);
            // setEditorState(newEditTor);
            setCommentAfterInsertId(data.data.commentId);
            setIsCmtLoading(false)
            deleteTypingText(taskId)
          },
        }
      );

    }
  }

  else {
    const submitFile = async () => {
      setIsCmtLoading(true)

      const formData = new FormData();
      // formData.append("workspace_file", Files[0] as File);
      formData.append("name", editorHTML);
      formData.append("resourceId", taskId.toString());
      formData.append("hasFile", "true");



      for (var i = 0; i < Files.length; i++) {
        formData.append("workspace_file", Files[i]);
      }


      for (var i = 0; i < mentions.length; i++) {
        formData.append("mentions[]", mentions[i].toString());
      }


      const url = `resources/insertcomment${Files.length > 1 ? '?multiplefile=1' : ''}`

      const options = {
        withCredentials: false,
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
        onUploadProgress: (progressEvent: any) => {
          const { loaded, total } = progressEvent;
          let percent = Math.floor((loaded * 100) / total);
          setUploadedPercent && setUploadedPercent(percent)

        },

      }

      await axios.post<{ commentId: any; filesRes: any[]; url: string, name: any }>(url, formData, options).then(async res => {
        const { id } = await getUserIdentity()
        

        const newComment: BaseRecord = {
          id: res.data.commentId,
          resourcesid: res.data.commentId,
          resources: {
            id: res.data.commentId,
            name: editorHTML,
            createdby: id,
            createdAt: (new Date()).toISOString()
          },
          file: res.data.filesRes
        };

        // Cập nhật file list khi insert thành công
        setFileList && setFileList(prev => {
          const newFileList = res?.data?.filesRes?.map(_ => ({
              resourcesid: _.fileId, resources: {
                id: _.fileId, 
                name: _.fileName, 
                createdAt: new Date()
              }
            }))
          return [...prev, ...newFileList]
        })

        newComment.resources.name = res?.data?.name
        setComments && setComments((prev) => [...prev, newComment]);
        setFiles([])
        setEditorState(newEditTor);
        setUploadedPercent && setUploadedPercent(0)
        detailsRef?.current?.scrollTo(0, detailsRef?.current?.scrollHeight)
        setIsCmtLoading(false)

        deleteTypingText(taskId)



      }).catch(err => {
        setIsCmtLoading(false)
        toast.error(err?.response?.data?.message)
      })
    }
    submitFile()
  }
};






export const updateComment = async ({ editorState, cmtId, setCmtContent, setShowCmtEditor, updateFunc, setComments, mentionsDataHasmap }: updateCommentParam) => {
  const options = {
    entityStyleFn: (entity: any) => {
      const entityType = entity.get("type").toLowerCase();

      if (entityType === "mention") {
        const { mention } = entity.get("data");
        const user = mentionsDataHasmap[mention?.id as string]

        return {
          element: "a",
          attributes: {
            href: `${ApiConstants.BASE_URL_CLIENT}/mytask/show/${user?.resourceRelation2?.resourcesid}`, //ĐỂ TẠM
            "user-id": mention?.id
          },
        };
      }

      if (entityType === "link") {
        const data = entity.get("data");


        return {
          element: "a",
          attributes: {
            href: data.url,

          },
        };
      }
    },
  };

  const contentState = editorState.getCurrentContent();
  let editorHTML = stateToHTML(contentState, options);
  const entityMap = convertToRaw(contentState).entityMap

  editorHTML = addLinkToEntityMap(contentState, editorHTML, options)

  const mentions = []

  for (const key in entityMap) {
    if (Object.prototype.hasOwnProperty.call(entityMap, key)) {
      const element = entityMap[key];
      if (element.type === 'mention') {

        mentions.push(element.data.mention.id)
      }

    }
  }

  const updateValues = {
    name: editorHTML,
    resourceId: cmtId,
    mentions
  }

  if (cmtId) {
    try {
      const result = await updateFunc({
        resource: 'resources/updatecomment',
        id: cmtId,
        values: updateValues,
        invalidates: ['all'],
        successNotification: {
          message: "Cập nhật thành công!",
          type: "success"
        },
      })

      setComments && setComments(prev => {
        const oldComments = [...prev]
        const newComments = oldComments.find((item) => item?.resourcesid === cmtId)
        if (newComments) {
          newComments.resources.name = result?.data?.name
        }

        return prev
      })
    } catch (err) { }

  }

  setComments && setComments(prev => {
    const oldComments = [...prev]
    const newComments = oldComments.map(cmt => {
      if (cmtId !== cmt?.resourcesid) return cmt
      return { ...cmt, resources: { ...cmt.resources, updatedAt: (new Date()).toISOString() } }
    })
    return newComments
  })




  setCmtContent && setCmtContent(editorHTML)
  setShowCmtEditor && setShowCmtEditor(false)

}

export const updateDescription = async ({editorStateDescription,oldTextValue,resourcesid,editorText,mutate,setValueDescription,editorTextToRaw}:DescriptionIT) => {

  const options = {
    entityStyleFn: (entity: any) => {
      const entityType = entity.get("type").toLowerCase();
      if (entityType === "link") {
        const data = entity.get("data");
        return {
          element: "a",
          attributes: {
            href: `${data.url}`,
            target: "_blank"
          },

        };
      }
    },
  };

  const contentState = editorStateDescription.getCurrentContent();
  let editorHTML = stateToHTML(contentState, options);
  const entityMap = convertToRaw(contentState).entityMap

  editorHTML = addLinkToEntityMap(contentState, editorHTML, options)

  const upsertValues = { description: editorHTML };


  const updateEditorValue = () => {
    // console.log('up');
    
    if (resourcesid && editorHTML !== oldTextValue) {
      mutate({
        resource: "resourcesdetails/upsert",
        id: resourcesid,
        values: upsertValues,
      });

    }
  };

  const insertEditorValue = () => {
    // console.log('in');
    
    if(typeof resourcesid === 'string') {
      alert('Vui lòng tạo task trước khi thêm mô tả !')
      return
    }
    if (resourcesid && editorTextToRaw) {
      mutate({
        resource: "resourcesdetails/upsert",
        id: resourcesid,
        values: upsertValues,
      });
    }
  };

  editorText ? updateEditorValue() : insertEditorValue()
  setValueDescription && setValueDescription(editorHTML)
}

export const getUserName = ({ mentionsData, mentionIdsRes }: getUserNameParam) => {
  const mentionInfos: mentionInfos[] = []
  mentionsData?.forEach(user => {
    mentionIdsRes?.data.forEach(item => {
      if (user.id === item.memberId) {
        mentionInfos.push({ id: item.memberId as number, name: user.name })
      }

    })
  })

  return mentionInfos
}

//Hàm đưa link vào enity map để xuất ra html
const addLinkToEntityMap = (contentState: any, editorHTML: any, options: any) => {
  // Khởi tạo blocks từ dữ liệu được đưa vào
  const BLocks = convertToRaw(contentState).blocks

  //Lặp qua từng Blocks kiểm tra xem block đó có link hay không, nếu có thì trả về dữ liệu từ hàm Extraclink( vị trí của link trong block )
  const LinksMatchArr = BLocks.map(item => {
    return extractLinks(item.text)
  })
  const myContentState = convertToRaw(contentState)

  //Lặp qua mảng trả về từ extraclink kiểm tra nếu trong block có link thì sẽ insert vị trí của link vào entitymap
  LinksMatchArr.map((Match, index) => {
    if (Match) {
      let EntityRanges: any = []
      let CustomizeKeyEntityRanges
      let EntityMap
      let EntityMapToArr
      let NewEntityMap

      EntityRanges = myContentState.blocks[index].entityRanges
      Match?.forEach(item => {
        EntityRanges.push({ offset: item.index, length: item.lastIndex - item.index, key: 0 })
        // EntityRanges.push({ offset: item.index, length: item.lastIndex, key: 0 })

      })


      CustomizeKeyEntityRanges = EntityRanges.map((item: any, index: any) => {
        return { ...item, key: index }
      })

      EntityMap = myContentState.entityMap
      EntityMapToArr = Object.values(EntityMap)
      Match?.forEach(item => {
        EntityMapToArr.push({
          type: 'link',
          mutability: "MUTABLE",
          data: { url: item.url }
        })
      })

      NewEntityMap = Object.assign({}, EntityMapToArr)

      myContentState.blocks[index].entityRanges = [...CustomizeKeyEntityRanges]
      myContentState.entityMap = NewEntityMap as any


      editorHTML = stateToHTML(convertFromRaw(myContentState), options)
    }
  })

  return editorHTML
}

//Custom textEdit style button
export const onStyleIconMouseDown = (name: string, { editorState, setEditorState }: styleProps) => {
  switch (name) {
    case "BOLD":
      onBoldClick({ editorState, setEditorState });
      break;
    case "ITALIC":
      onItalicClick({ editorState, setEditorState });
      break;
    case "STRIKETHROUGH":
      onStrikeThroughClick({ editorState, setEditorState });
      break;
    case "UNDERLINE":
      onUnderlineClick({ editorState, setEditorState });
      break;
    case "unordered-list-item":
      onUnOrderedClick({ editorState, setEditorState });
      break;
    case "ordered-list-item":
      onorderedClick({ editorState, setEditorState });
      break;

    default:
      break;
  }
};

const onBoldClick = ({ editorState, setEditorState }: styleProps) => {
  setEditorState(RichUtils.toggleInlineStyle(editorState, "BOLD"));
};

const onItalicClick = ({ editorState, setEditorState }: styleProps) => {
  setEditorState(RichUtils.toggleInlineStyle(editorState, "ITALIC"));
};

const onUnderlineClick = ({ editorState, setEditorState }: styleProps) => {
  setEditorState(RichUtils.toggleInlineStyle(editorState, "UNDERLINE"));
};

const onStrikeThroughClick = ({ editorState, setEditorState }: styleProps) => {
  setEditorState(RichUtils.toggleInlineStyle(editorState, "STRIKETHROUGH"));
};

const onUnOrderedClick = ({ editorState, setEditorState }: styleProps) => {
  setEditorState(
    RichUtils.toggleBlockType(editorState, "unordered-list-item")
  );
};

const onorderedClick = ({ editorState, setEditorState }: styleProps) => {
  setEditorState(RichUtils.toggleBlockType(editorState, "ordered-list-item"));
};

export const getUserIdentity = async () => {
  const userId = localStorage.getItem(USER_ID);
  if (!userId) {
    return Promise.reject();
  }

  return Promise.resolve({
    id: userId,
  });
}

//Delete typing text
const deleteTypingText = async (taskId: number) => {
  await axios.delete(`resources/typingtext/${taskId}`)
}