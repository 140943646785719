
import React, { useState } from 'react'
import { AiOutlineArrowDown } from 'react-icons/ai'
import { FiChevronDown } from 'react-icons/fi'
import './index.scss'
import { useGlobalDataState } from 'pages/ProposeTasks/state/global'




export const MainHeader: React.FC = () => {

    const {isScrollVisible} = useGlobalDataState()
    return (
        <div  className="mytask-header-container" style={{paddingRight: isScrollVisible ? '8px' : '0px'}}>
            <div className="mytask-header-wrap">
                <div className="task-name-col header-col">
                    <span>Task name</span>
                </div>

   
                <div className="due-date-col header-col">
                    <div className="col-item">
                     <span>Assignee</span>
                        <AiOutlineArrowDown />
                    </div>

                    <FiChevronDown className="col-item-icon" />
                </div>
                
                <div className="projects-col header-col">
             <span>Due date</span>
                    <FiChevronDown className="col-item-icon" />
                </div>
               
            </div>
        </div>
    )
}