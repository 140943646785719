import { DatePicker } from "@pankod/refine-antd";
import { BaseRecord, useUpdate } from "@pankod/refine-core";
import dayjs from "dayjs";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { BsCalendar2Week } from "react-icons/bs";
import { USER_ID } from "authProvider";
import "./DueDate.scss";
import { MyTaskContextType, useMyTask } from "components/Mytask/Context/MyTask.context";
import { upsertResourceDetailAsync } from "pages/ProposeTasks/api.ts";
import { toast } from "react-toastify";
import { TaskType, useProposeState } from "pages/ProposeTasks/state";

interface DueDateProps {
  task: TaskType;
  placeholder?: string;
}

export const DueDate: React.FC<DueDateProps> = ({ task, placeholder }) => {
  const {setComments} = useMyTask() as MyTaskContextType;
  const { updateTask } = useProposeState();
  const { dueOn, taskId, sectionId } = task;

  const ref = useRef<any>(null);
  const userId = localStorage.getItem(USER_ID);

  const handleDateChange = useCallback((val: any) => {
    
    if (val) {
      ref.current?.blur();
      const getDate = (dateTime: Date) => {
        if (dueOn) {
          dateTime.setDate(dateTime.getDate() + 1);
        }
        const isoDate = dateTime.toISOString();
        return isoDate.split("T")[0];
      };

      const dateNoTime = getDate(val.$d);

      upsertResourceDetailAsync(taskId, {
        dueAt: dateNoTime,
        dueOn: dateNoTime,
        type: "addduedate",
      })
        .then((res) => {
          updateTask(sectionId, taskId, { dueOn: dateNoTime });
          toast.success("Cập nhật thành công");

          const newComment = {
            resourcesid: Math.random(),
            resources: {
              createdAt: new Date().toISOString(),
              createdby: userId,
              name: `{{${userId}}} changed the due date to ${dateNoTime}`,
              resourcetype: "log",
            },
          };

          setComments && setComments((prev) => [...prev, newComment]);
        })
        .catch((error) => {
          const message = error?.response?.data?.message;
          toast.error(message ?? "Có lỗi xảy ra !");
        });
    } else {

      upsertResourceDetailAsync(taskId, {
        dueAt: null,
        dueOn: null,
        type: "removeduedate",
      })
        .then((res) => {
          updateTask(sectionId, taskId, { dueOn: null });
          toast.success('"Xóa Thành công !"');

          const newComment = {
            resourcesid: Math.random(),
            resources: {
              createdAt: new Date().toISOString(),
              createdby: userId,
              name: `{{${userId}}} removed the due date.`,
              resourcetype: "log",
            },
          };

          setComments && setComments((prev) => [...prev, newComment]);
        })
        .catch((error) => {
          const message = error?.response?.data?.message;
          toast.error(message ?? "Có lỗi xảy ra !");
        });
    }
  },[taskId, sectionId,userId, upsertResourceDetailAsync, dueOn, ref ]);

  const dateFormat = "DD - MM - YYYY";
  
  return (
    <>
      <DatePicker
        placeholder={placeholder ? placeholder : ""}
        format={dateFormat}
        placement="bottomRight"
        className="datepicker"
        onChange={handleDateChange}
        value={dueOn ? dayjs(dueOn) : null}
        suffixIcon={<BsCalendar2Week />}
        ref={ref}
      />
    </>
  );
};
