import React, { useState, useRef } from "react";
import "./FileListItem.scss";
import {
  AttachmentFileIcon,
  ExcelFileIcon,
  PdfFileIcon,
  PowpointFileIcon,
  WordFileIcon,
} from "./Icons";
import { BsChevronDown, BsTrash } from "react-icons/bs";
import { HiDownload } from "react-icons/hi";
import { FaAngleDown } from "react-icons/fa";
import { BaseRecord, useDelete } from "@pankod/refine-core";
import { useMyTask } from "components/Mytask/Context/MyTask.context";
import { Dropdown, Menu, Tooltip } from "@pankod/refine-antd";
import moment from "moment";
import { getDateTimeFormat } from "components/Mytask/mtDetails/myTaskDetailUtils";

interface FileProps {
  name: string;
  type?: string;
  imgUrl?: string;
  fileId: number;
  setFileList?: React.Dispatch<React.SetStateAction<BaseRecord[]>>;
  createdAt?: Date;
}

export const FileListItem: React.FC<FileProps> = ({
  name,
  imgUrl,
  fileId,
  setFileList,
  createdAt,
}) => {
  const values = useMyTask();
  const setComments = values?.setComments;

  let type;
  let FileIcon = AttachmentFileIcon
  const fileType = name.slice(name.lastIndexOf('.') + 1)

  const { mutate } = useDelete();

  switch (fileType) {
    case "docx":
      type = "Word Document";
      FileIcon = WordFileIcon;
      break;

    case "pdf":
      type = "PDF";
      FileIcon = PdfFileIcon;

      break;

    case "xlsx":
      type = "Excel SpreadSheet";
      FileIcon = ExcelFileIcon;

      break;

    case "pptx":
      type = "Powpoint Slideshow";
      FileIcon = PowpointFileIcon;

      break;

    case "png":
      type = "Image";
      break;

    default:
      type = "Attachment";
      break;
  }

  const [showOption, setShowOption] = useState(false);

  const handleShowOption = () => {
    setShowOption(!showOption);
  };

  const handleDeleteFile = () => {
    if (fileId) {
      mutate(
        {
          resource: "resources",
          id: fileId,
          successNotification: {
            message: "Xóa thành công !",
            type: "success",
          },
        },
        {
          onSuccess(data, variables, context) {
            setFileList &&
              setFileList((prev) => {
                const oldFiles = [...prev];
                const newFiles = oldFiles.filter(
                  (file) => file.resourcesid !== fileId
                );

                return newFiles;
              });

            setComments &&
              setComments((prev) => {
                const oldComments = [...prev];

                const newComments = oldComments.map((cmt) => {
                  if (cmt.fileId?.at(0) !== fileId) {
                    return cmt;
                  } 
                  
                  else {
                    if (cmt.resources.name !== "<p><br></p>") {
                      const newCmt = {
                        id: cmt.id,
                        resourcesid: cmt.resourcesid,
                        resources: cmt.resources,
                      };
                      return newCmt;
                    } else if (cmt.resources.name === "<p><br></p>") {
                      return undefined;
                    }
                  }
                });


                const result = newComments.filter(
                  (item) => item !== undefined
                ) as BaseRecord[];

                return result;
              });
          },
        }
      );
    }
  };
  const fileContentRef = useRef<HTMLDivElement>(null);
  const fileIconRef = useRef<HTMLDivElement>(null);

  const handleDownloadFile = () => {
    window.open(
      `${
        process.env.REACT_APP_API_URL || "http://localhost:7000/"
      }resources/getfile/${fileId}`
    );
    setShowOption(false);
  };

  const handleDownloadFileWhenClickFile = (e: React.MouseEvent) => {
    const IsClickContent = () => {
      const isClickIcon = fileIconRef.current?.contains(
        e.target as HTMLElement
      );

      const isClickContent = fileContentRef.current?.contains(
        e.target as HTMLElement
      );
      const reusult = isClickIcon || isClickContent;
      return reusult;
    };

    if (!IsClickContent()) {
      return;
    }
    window.open(
      `${
        process.env.REACT_APP_API_URL || "http://localhost:7000/"
      }resources/getfile/${fileId}`
    );
    setShowOption(false);
  };

  const menu = (
    <Menu
      items={[
        {
          label: "Download attachment",
          key: "1",
          onClick: handleDownloadFile,
          icon: <HiDownload />,
        },
        // { type: "divider" },
        // {
        //   label: "Delete attachment",
        //   key: "2",
        //   danger: true,
        //   onClick: handleDeleteFile,
        //   icon: <BsTrash />,
        // },
      ]}
    />
  );

  const overlayStyle = {
    backgroundColor: "var(--tool-tip-bg-color)",
    whiteSpace: "pre-line",
    display: "flex",
    overflowX: "auto",
  };

  const infoStr = `${name}  \n ----------------------------------------- \n Created At: ${getDateTimeFormat(createdAt as any)}`;

  return (
    <>
      {!imgUrl && (
        <Tooltip
          placement="bottom"
          overlayInnerStyle={overlayStyle as any}
          title={infoStr}
        >
          <div
            className="fileList-item-container"
            onClick={handleDownloadFileWhenClickFile}
          >
            <div className="file-icon" ref={fileIconRef}>
              <FileIcon />
            </div>

            <div className="file-content" ref={fileContentRef}>
              <p>{name}</p>
              <span>{type} - Download</span>
            </div>

            <div>
              <Dropdown overlay={menu} trigger={["click"]}>
                <div className="file-option">
                  <BsChevronDown />
                </div>
              </Dropdown>
            </div>
          </div>
        </Tooltip>
      )}

      {imgUrl && (
        <Tooltip
          placement="bottom"
          overlayInnerStyle={overlayStyle as any}
          title={infoStr}
        >
          <div className="img-thumnail-container">
            <img src={imgUrl} alt="" />
            <Dropdown overlay={menu} trigger={["click"]}>
              <FaAngleDown
                className="image-option"
                onClick={handleShowOption}
              />
            </Dropdown>
          </div>
        </Tooltip>
      )}
    </>
  );
};
