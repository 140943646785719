import axios, { AxiosInstance } from "axios";
import {
    QueryFilter,
    QueryFilterArr,
    QuerySort,
    QuerySortArr,
    QuerySortOperator,
    RequestQueryBuilder,
    CondOperator,
    ComparisonOperator,
} from "@nestjsx/crud-request";
import {
    DataProvider,
    HttpError,
    CrudFilters as RefineCrudFilter,
    CrudOperators,
    CrudSorting,
} from "@pankod/refine-core";
import { stringify } from "query-string";
import {QueryJoin, QueryFields} from './@nestjsx/crud-request/lib/types/request-query.types'
import { Fields } from "@pankod/refine-core/dist/interfaces/metaData/fields";

type SortBy = QuerySort | QuerySortArr | Array<QuerySort | QuerySortArr>;
type CrudFilters =
    | QueryFilter
    | QueryFilterArr
    | Array<QueryFilter | QueryFilterArr>;

const axiosInstance = axios.create();

axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        const customError: HttpError = {
            ...error,
            message: error.response?.data?.message,
            statusCode: error.response?.status,
        };

        return Promise.reject(customError);
    },
);

axiosInstance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem("refine-auth");
 
        if (token && config && config.headers) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }

        return config;
    },

    (error) => {
        return Promise.reject(error);
    }
);

const mapOperator = (operator: CrudOperators): ComparisonOperator => {
    switch (operator) {
        case "ne":
            return CondOperator.NOT_EQUALS;
        case "lt":
            return CondOperator.LOWER_THAN;
        case "gt":
            return CondOperator.GREATER_THAN;
        case "lte":
            return CondOperator.LOWER_THAN_EQUALS;
        case "gte":
            return CondOperator.GREATER_THAN_EQUALS;
        case "in":
            return CondOperator.IN;
        case "nin":
            return CondOperator.NOT_IN;
        case "contains":
            return CondOperator.CONTAINS_LOW;
        case "ncontains":
            return CondOperator.EXCLUDES_LOW;
        case "containss":
            return CondOperator.CONTAINS;
        case "ncontainss":
            return CondOperator.EXCLUDES;
        case "null":
            return CondOperator.IS_NULL;
        case "nnull":
            return CondOperator.NOT_NULL;
    }

    return CondOperator.EQUALS;
};

const generateSort = (sort?: CrudSorting): SortBy | undefined => {
    if (sort && sort.length > 0) {
        const multipleSort: SortBy = [];
        sort.map(({ field, order }) => {
            if (field && order) {
                multipleSort.push({
                    field: field,
                    order: order.toUpperCase() as QuerySortOperator,
                });
            }
        });
        return multipleSort;
    }

    return;
};

const generateFilter = (
    filters?: RefineCrudFilter,
): { crudFilters: CrudFilters; orFilters: CrudFilters } => {
    const crudFilters: CrudFilters = [];
    const orFilters: CrudFilters = [];

    if (filters) {
        filters.map((filter) => {
            
            if (filter.operator !== "or") {
                crudFilters.push({
                    field: filter.field,
                    operator: mapOperator(filter.operator),
                    value: filter.value,
                });
            } else {
                filter.value.map((orFilter) => {
                    orFilters.push({
                        field: orFilter.field,
                        operator: mapOperator(orFilter.operator),
                        value: orFilter.value,
                    });
                });
            }
        });
    }

    return { crudFilters, orFilters };
};


const checkNullFields = (data: any) => {
    if(!data || data.length === 0){
        return true
    }
    return false
}


const NestsxCrud = (
    apiUrl: string,
    httpClient: AxiosInstance = axiosInstance,
): DataProvider => ({

    //Custom
    getList: async ({
        resource,
        hasPagination = true,
        pagination = { current: 1, pageSize: 10 },
        filters,
        sort,
        metaData
    }) => {
        const condition = metaData?.condition ?? true
        
        if(!condition) {
              return{
                data: null,
                total: null,
            };
        }

        let url = `${apiUrl}/${resource}`;
        
        const joins: QueryJoin[] | undefined  = metaData?.config?.joins
        const fields: QueryFields | undefined = metaData?.config?.fields
        const { current = 1, pageSize = 10 } = pagination ?? {};
        const { crudFilters, orFilters } = generateFilter(filters);

        const requestQueryBuilder = RequestQueryBuilder.create()
            .setFilter(crudFilters)
            .setOr(orFilters);

            if(checkNullFields(fields)){ 
                throw new Error('Trường fields trong config bắt buộc phải có')
            }
            
            fields && requestQueryBuilder.select(fields)
    
    
            if(joins) {
                joins.forEach((join) => {
                   if(checkNullFields(join.select)){ 
                       throw new Error('Trường select trong joins bắt buộc phải có')
                   }
                   requestQueryBuilder.setJoin(join)
                }) 
              }

        if (hasPagination) {
            requestQueryBuilder
                .setLimit(pageSize)
                .setPage(current)
                .setOffset((current - 1) * pageSize);
        }

        const sortBy = generateSort(sort);
        if (sortBy) {
            requestQueryBuilder.sortBy(sortBy);
        }

        
        const { data } = await httpClient.get(`${url}?${requestQueryBuilder.query()}`);

        return {
            data: data.data,
            total: data.total,
        };
        
        
    },

    getMany: async ({ resource, ids }) => {
        const url = `${apiUrl}/${resource}`;

        const query = RequestQueryBuilder.create()
            .setFilter({
                field: "id",
                operator: CondOperator.IN,
                value: ids,
            })
            .query();

        const { data } = await httpClient.get(`${url}?${query}`);

        return {
            data,
        };
    },

    create: async ({ resource, variables }) => {
        const url = `${apiUrl}/${resource}`;

        const { data } = await httpClient.post(url, variables);

        return {
            data,
        };
    },

    update: async ({ resource, id, variables }) => {
        const url = `${apiUrl}/${resource}/${id}`;

        const { data } = await httpClient.patch(url, variables);

        return {
            data,
        };
    },

    updateMany: async ({ resource, ids, variables }) => {
        const response = await Promise.all(
            ids.map(async (id) => {
                const { data } = await httpClient.patch(
                    `${apiUrl}/${resource}/${id}`,
                    variables,
                );
                return data;
            }),
        );

        return { data: response };
    },

    createMany: async ({ resource, variables }) => {
        const url = `${apiUrl}/${resource}/bulk`;

        const { data } = await httpClient.post(url, { bulk: variables });

        return {
            data,
        };
    },

    //Custom
    getOne: async ({ resource, id, metaData }) => {
        let url = `${apiUrl}/${resource}/${id}`;

        const joins: QueryJoin[] | undefined  = metaData?.config?.joins
        const fields: QueryFields | undefined = metaData?.config?.fields
        let requestQueryBuilder = RequestQueryBuilder.create()

        if(checkNullFields(fields)){ 
            throw new Error('Trường fields trong config bắt buộc phải có')
        }
        
        fields && requestQueryBuilder.select(fields)


        if(joins) {
            joins.forEach((join) => {
               if(checkNullFields(join.select)){ 
                   throw new Error('Trường select trong joins bắt buộc phải có')
               }
               requestQueryBuilder.setJoin(join)
            }) 
          }
        const { data } = await httpClient.get(`${url}?${requestQueryBuilder.query()}`);
        return {
            data,
        };
    },

    deleteOne: async ({ resource, id }) => {
        const url = `${apiUrl}/${resource}/${id}`;

        const { data } = await httpClient.delete(url);

        return {
            data,
        };
    },

    deleteMany: async ({ resource, ids }) => {
        const response = await Promise.all(
            ids.map(async (id) => {
                const { data } = await httpClient.delete(
                    `${apiUrl}/${resource}/${id}`,
                );
                return data;
            }),
        );
        return { data: response };
    },

    getApiUrl: () => {
        return apiUrl;
    },


    //custom
    custom: async ({ url, method, filters, sort, payload, query, headers, metaData }) => {
        const { crudFilters } = generateFilter(filters);
        const requestQueryBuilder =
        RequestQueryBuilder.create().setFilter(crudFilters);

        const joins: QueryJoin[] | undefined  = metaData?.config?.joins
        const fields:QueryFields | undefined  = metaData?.config?.fields

     
        if(checkNullFields(fields)){ 
            throw new Error('Trường fields trong config bắt buộc phải có')
        }
        
        fields && requestQueryBuilder.select(fields)

       if(joins) {
         joins.forEach((join) => {
            if(checkNullFields(join.select)){ 
                throw new Error('Trường select trong joins bắt buộc phải có')
            }
            requestQueryBuilder.setJoin(join)
         }) 
       }

        const sortBy = generateSort(sort);
        if (sortBy) {
            requestQueryBuilder.sortBy(sortBy);
        }

        let requestUrl = `${url}?${requestQueryBuilder.query()}`;

        if (query) {
            requestUrl = `${requestUrl}&${stringify(query)}`;
        }

        if (headers) {
            httpClient.defaults.headers = {
                ...httpClient.defaults.headers,
                ...headers,
            };
        }

        let axiosResponse;
        switch (method) {
            case "put":
            case "post":
            case "patch":
                axiosResponse = await httpClient[method](url, payload);
                break;
            case "delete":
                axiosResponse = await httpClient.delete(url, {
                    data: payload,
                });
                break;
            default:
                axiosResponse = await httpClient.get(requestUrl);
                break;
        }

        const { data } = axiosResponse;

        return Promise.resolve({ data });
    },
});

export default NestsxCrud;
