import { BaseRecord, useList } from "@pankod/refine-core";
import moment from "moment";
import { useEffect, useState } from "react";
import "./LikeListContent.scss";



interface ContentProps {
  cmtId: number;
}
export const Content = ({ cmtId }: ContentProps) => {
  const [likedList, setLikedList] = useState<BaseRecord>([]);

  const likedListRes = useList({
    resource: `resourceslikes`,
    config: {
      filters: [
        {
          field: "resourcesId",
          operator: "eq",
          value: cmtId,
        },
      ],
      pagination: { current: 1, pageSize: 1500 },
    },
    metaData: {
      config: {
        fields: ["name", "createdAt"],
        joins: [{ field: "user", select: ["name"] }],
      },
    },
  }).data;
  
  
  useEffect(() => {
    likedListRes && setLikedList(likedListRes.data);
  }, [likedListRes,likedList]);

  return (
    <div className="like-list-wrapper">
      {likedList.map((item: any) => {
   
        const time = item?.createdAt ? moment.utc(item?.createdAt).local().startOf("seconds").fromNow(): "--"
        return (
          <div className="list-container" key={item.id} >
            <span className="name">{item?.user?.name || 'loading...'}</span>
            <span>-</span>
            <span className="time">{time || 'loading...'}</span>
          </div>
        );
      })}
    </div>
  );
};
