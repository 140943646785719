import { Select, Tag, Tooltip } from "@pankod/refine-antd"
import { useCallback, useEffect, useState } from "react"
import type { CustomTagProps } from 'rc-select/lib/BaseSelect';

import { useProjectRecentlyState } from "../state/project-list";
import { addProjectRecentlyAsync } from "../service";
import { useRecentlyState } from "../state";

interface ProjectRecentlyIF {
  task?:any,
  updateValueTask?:any
}

const ProjectRecently:React.FC<ProjectRecentlyIF> = ({task,updateValueTask}) => {
    const [isDropDownVisible, setIsDropDownVisible] = useState<boolean>(false)
    const [projectId,setProjectId] = useState<any>([])

    const {projects}:any = useProjectRecentlyState()

    useEffect(() => {
      if(projects && projects.length > 0) {
        const id = task?.resourcesTask.map((item:any) => item.projectId)
        setProjectId(id.filter((projectId:any) => projectId !== null));
      }
    },[task?.resourcesTask,projects])
    

    const handleSelect = (projectId:number) => {
      addProjectRecentlyAsync(task?.taskId,projectId,task,updateValueTask)
    }

    const getListProjectName = () => {
      const filterProject = projects && projects.length>0 && projects.filter((item:any) => projectId.includes(item.value))
   
      const nameArrayProjects = filterProject.map((item:any) => item.label)
      return nameArrayProjects?.join(' \n ')
      
    }


    const tagRender = (props: CustomTagProps) => {
        const { label} = props;
        const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
          event.preventDefault();
          event.stopPropagation();
        };
        return (
          <Tag
            color='green'
            onMouseDown={onPreventMouseDown}
            style={{ marginRight: 3 }}
          >
            {label}
          </Tag>
        );
      };

    const overlayStyle = {
      backgroundColor: "var(--tool-tip-bg-color)",
      whiteSpace: "pre-line",
      display: "flex",
      maxHeight: 200,
      overflowY: "auto",
    };


    return (
      <Tooltip overlayInnerStyle={overlayStyle as any} 
      title={!isDropDownVisible && (projectId.length > 0 ? `This task is also part of: \n ${getListProjectName()} ` : '')} placement="bottom" 
      >

        <div className="ProjectList-container">
        <Select
          onDropdownVisibleChange={e => setIsDropDownVisible(e)}
          mode="multiple"
          showArrow={false}
          tagRender={tagRender}
          defaultValue={[]}
          style={{ width: '100%' }}
          options={projects}
          className='ProjectList-seclect'
          onSelect={handleSelect}
          value={projectId}
          filterOption={(input:any, option:any) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
        />
        </div>
      </Tooltip> 
   
    )
}
export default ProjectRecently

