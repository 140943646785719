import axios from "axios";
import { Button, TextBox } from "devextreme-react";
import Popup, { Position } from "devextreme-react/popup";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  dataGrid: any;
  setRandomNumber: any;
};
export const CauHinhPopupInsert = ({
  open,
  setOpen,
  dataGrid,
  setRandomNumber,
}: Props) => {
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [nameForm, setNameForm] = useState("");

  useEffect(() => {}, []);

  const hideInfo = () => {
    setOpen(false);
    setErrorMessages([]);
    setRandomNumber(Math.random() * 9);
    setNameForm("");
  };

  const titleRender = () => {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <h1 style={{ fontSize: "20px", marginLeft: "20px" }}>Thêm</h1>
        <button
          style={{
            marginLeft: "388px",
            fontWeight: "600",
            marginBottom: "10px",
            border: "1px solid #edeae9",
            cursor: "pointer",
            width: "50px",
            height: "40px",
          }}
          onClick={hideInfo}
          id="closePopupInsert"
        >
          X
        </button>
      </div>
    );
  };

  const saveData = (data: any) => {
    let inputdata: any = {
      name: nameForm,
    };
    const senRequest = async () => {
      const results = axios
        .post(`resources/insert-form`, inputdata)
        .then((data) => {
          if (data) {
            hideInfo();
            toast.success("Lưu thành công!", {
              autoClose: 3000,
              style: {
                fontSize: "14px",
                position: "fixed",
                top: "0px",
                right: "0px",
              },
            });
          }
        })
        .catch((error) => {
          const message = { ...error };

          if (
            message &&
            message.response &&
            message.response.data &&
            message.response.data.message &&
            Array.isArray(message.response.data.message)
          ) {
            setErrorMessages(message?.response?.data?.message);
          } else if (
            message &&
            message.response &&
            message.response.data &&
            message.response.data.message &&
            !Array.isArray(message.response.data.message)
          ) {
            setErrorMessages([message?.response?.data?.message]);
          } else {
            return Promise.reject("Có lỗi xảy ra vui lòng thử lại");
          }
        });
      return results;
    };

    senRequest();
  };

  const nameChanged = (e: any) => {
    setNameForm(e?.value);
  };

  return (
    <div id="container">
      <Popup
        visible={open}
        dragEnabled={false}
        hideOnOutsideClick={true}
        showCloseButton={false}
        showTitle={true}
        titleRender={titleRender}
        width={550}
        height={220}
      >
        <Position at="center" my="center" collision="fit" />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          {errorMessages.length > 0 ? (
            <div
              style={{
                textAlign: "center",
                backgroundColor: "#f68e9a",
                color: "#fff",
                marginBottom: "10px",
              }}
            >
              {errorMessages.join(", ")}
            </div>
          ) : null}
          <div>
            <div className="dx-field-label">Tên Form: </div>
            <div className="dx-field-value">
              <TextBox defaultValue={""} onValueChanged={nameChanged} />
            </div>
          </div>
        </div>
        <Button
          icon="save"
          type="default"
          text="Lưu"
          onClick={saveData}
          style={{
            marginLeft: "300px",
            marginTop: "10px",
          }}
        />
        <Button
          icon="close"
          type="danger"
          text="Thoát"
          onClick={hideInfo}
          style={{
            marginLeft: "10px",
            marginTop: "10px",
          }}
        />
      </Popup>
    </div>
  );
};
