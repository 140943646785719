import { Tooltip } from "@pankod/refine-antd";
import { BaseRecord } from "@pankod/refine-core";
import { useAppContext } from "App.context/App.context";
import {
  ADDASSIGN,
  ADDDUEDATE,
  MARKCOMPLETE,
  MARKUNCOMPLETE,
  REMOVEASSIGN,
  REMOVEDUEDATE,
} from "components/Mytask/Constant";
import {
  getDateTimeFormat,
  getTimeFromNow,
} from "components/Mytask/mtDetails/myTaskDetailUtils";
import React, { useEffect, useState } from "react";
import { BsCheckCircle, BsFillCheckCircleFill } from "react-icons/bs";
import "./Log.scss";
import { useGlobalDataState } from "pages/ProposeTasks/state/global";
import { isEmpty } from "lodash";

interface LogProps {
  resources: BaseRecord;
  lastCmtRef?: React.RefObject<HTMLDivElement>
}

export const overlayStyle = {
  backgroundColor: "var(--tool-tip-bg-color)",
  whiteSpace: "pre-line",
  display: "flex",
  maxHeight: 200,
  overflowY: "auto",
};

export const Log: React.FC<LogProps> = ({ resources,lastCmtRef }) => {
  const [LogType, setLogType] = useState<string | null>(null);
  const [LogContent, setLogContent] = useState<string | null>(null);
  const AppContext = useAppContext();
  const {users} = useGlobalDataState()
  const userData = !isEmpty(AppContext?.usersData) ? AppContext?.usersData : users;
  const logCreatedTime = getTimeFromNow(resources.createdAt);
  const LogWithFullTime = getDateTimeFormat(resources.createdAt);


  

  const getIdReg = /[^{}]+(?=})/g;
  const getIdWithCurlyBraceReg = /{{(.*?)}}/g

  const getLogContent = (rawContent: string) => {
    const userIds =  rawContent.match(getIdReg)
    let userNames

    if(userData) {
       userNames = userIds?.map(id => {
        return userData[id]?.name
      })
    }
    const content = rawContent.replace(getIdWithCurlyBraceReg, userNames?.at(0))
    return content
  };

  const getUserName = (rawContent: string, index: number) => {
    const userIds =  rawContent.match(getIdReg)
    if(userData) {
      const id = userIds?.at(index)
       return userData[id as string]?.name
   }
  };

  const getContent = (rawContent: string) => {
    return rawContent.replace(getIdWithCurlyBraceReg, '')
  }

  useEffect(() => {
    if (resources?.name.includes(MARKCOMPLETE)) {
      setLogType(MARKCOMPLETE);
      setLogContent(getLogContent(resources?.name));
    }

    if (resources?.name.includes(MARKUNCOMPLETE)) {
      setLogType(MARKUNCOMPLETE);
      setLogContent(getLogContent(resources?.name));
    }

    if (resources?.name.includes(ADDASSIGN)) {
      setLogType(ADDASSIGN);
      setLogContent(resources?.name);
    }

    if (resources?.name.includes(REMOVEASSIGN)) {
      setLogType(REMOVEASSIGN);
      setLogContent(resources?.name);
    }

    if (resources?.name.includes(ADDDUEDATE)) {
      setLogType(ADDDUEDATE);
      setLogContent(resources?.name);
    }

    if (resources?.name.includes(REMOVEDUEDATE)) {
      setLogType(REMOVEDUEDATE);
      setLogContent(resources?.name);
    }
  }, [resources]);



  return (
    <div className="Log-container" ref={lastCmtRef}>
      <div className="complete-log">
        {LogType === MARKCOMPLETE && (
          <>
            <div className="log-content">
              <BsFillCheckCircleFill className="complete-icon" />
              <span>{LogContent}</span>
            </div>

            <Tooltip placement="bottom" overlayInnerStyle={overlayStyle as any}  title={LogWithFullTime}>
              <span className="created-time">{logCreatedTime}</span>
            </Tooltip>
          </>
        )}

        {LogType === MARKUNCOMPLETE && (
          <>
            <div className="log-content">
              <BsCheckCircle className="uncomplete-icon" />
              <span className="uncomplete-para">{LogContent}</span>
            </div>
            <Tooltip placement="bottom" overlayInnerStyle={overlayStyle as any}  title={LogWithFullTime}>
              <span className="created-time">{logCreatedTime}</span>
            </Tooltip>
          </>
        )}

        <>
          {LogType === ADDASSIGN && (
            <div className="common-log">
              <div className="">
                <span className="log-username">
                  {getUserName(LogContent as string, 0)}
                </span>
                <span>{getContent(LogContent as string)}</span>
                <span className="log-username">
                  {getUserName(LogContent as string, 1)}
                </span>
              </div>
              <Tooltip placement="bottom" overlayInnerStyle={overlayStyle as any}  title={LogWithFullTime}>
                <span className="common-created-time">{logCreatedTime}</span>
              </Tooltip>
            </div>
          )}
        </>

        {LogType === REMOVEASSIGN && (
          <div className="common-log">
            <div>
              <span className="log-username">
                {getUserName(LogContent as string, 0)}
              </span>
              <span>{getContent(LogContent as string)}</span>

              <span className="log-username">
                {getUserName(LogContent as string, 1)}
              </span>
            </div>
            <Tooltip placement="bottom" overlayInnerStyle={overlayStyle as any}  title={LogWithFullTime}>
              <span className="common-created-time">{logCreatedTime}</span>
            </Tooltip>
          </div>
        )}

        {LogType === ADDDUEDATE && (
          <div className="common-log">
            <div>
              <span className="log-username">
                {getUserName(LogContent as string, 0)}
              </span>
              <span>{getContent(LogContent as string)}</span>

            </div>
            <Tooltip placement="bottom" overlayInnerStyle={overlayStyle as any}  title={LogWithFullTime}>
              <span className="common-created-time">{logCreatedTime}</span>
            </Tooltip>
          </div>
        )}

        {LogType === REMOVEDUEDATE && (
          <div className="common-log">
            <div>
              <span className="log-username">
                {getUserName(LogContent as string, 0)}
              </span>
              <span>{getContent(LogContent as string)}</span>
            </div>
            <Tooltip placement="bottom" overlayInnerStyle={overlayStyle as any}  title={LogWithFullTime}>
              <span className="common-created-time">{logCreatedTime}</span>
            </Tooltip>
          </div>
        )}
      </div>
    </div>
  );
};
