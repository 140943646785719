// import "./Inputmension.css";
import { MentionData } from "@draft-js-plugins/mention";
import { BaseRecord, useCreate } from "@pankod/refine-core";
import axios from 'axios';
import { CheckBox } from 'devextreme-react/check-box';
import {
  Column,
  DataGrid,
  Editing,
  FilterRow,
  Scrolling,
  Selection
} from 'devextreme-react/data-grid';
import { EditorState } from "draft-js";
import React, { useEffect, useReducer, useRef, useState } from "react";
import './Inputmension.css';
import { useDetectProposePage } from "pages/ProposeTasks/hooks";
import styled from "styled-components";
import {FaUserCog} from 'react-icons/fa'
import MemberButton from "pages/ProposeTasks/components/MemberButton";
interface propsIdMention{
  id:any
}

interface propsAction{
  type:string
  payload:any
}

function reducer(state:any, action: propsAction) {

  const hashMap = new Map();
  switch (action.type) {
    case 'MULTIPLE':
      // console.log('MULTIPLE',action.payload);
      
      [state,action.payload]?.forEach(arr=>{
        arr.forEach((obj:any) => {
          if(hashMap.has(obj?.key)){
            Object.assign(hashMap.get(obj?.key), obj)
          }else{
            hashMap.set(obj?.key,obj)
          }
        });
      });
      return Array.from(hashMap.values())
    case 'DISCARD':
      return []
    default:
      return state;
  }
}



const Mentionprojectshow: React.FC<propsIdMention> = ({id}) => {
  const [mentionData, setMentionData] = useState<MentionData | null>(null)

  const { mutate } = useCreate();
  // const { mutate } = useUpdate();
  const [dataProjectTeam,setdataProjectTeam]=useState<BaseRecord[]>([])
  const editor = EditorState.createEmpty()
  const [ischeck,setischeck]=useState(false);
  const [ischeckmember,setischeckmember]=useState(false);
  const [stateRead,setStateRead]=useState(false);
  const [stateCreate,setStateCreate]=useState(false);
  const [stateUpdate,setStateUpdate]=useState(false);
  const [stateDelete,setStateDelete]=useState(false);
  const [rowIndex, setSelectedRowIndex] = useState(null);
  const [focusedRowKey, setFocusedrowkey] = useState(null);
  const [multipleMember, setMultipleMember] = useState<number[]>([]);
  const [saveTrigger, setSaveTrigger] = useState(true);
  const [saveStateData, setSaveStateData] = useState<any[]>([])
  const dataGrid = useRef<any|undefined>();
  const editingRef = useRef<any|undefined>();
  const [stateReducer, dispatch] = useReducer(reducer, []);
 
  

  useEffect(() => {
    const fetchData = async ()=>{
      const response = await axios.get(`resourcesmembers/getDataMemberPhongBan/${id}`)

      // console.log('response.data',response.data);
      
      response.data && setdataProjectTeam(response.data)
    }
    if(ischeck){

      fetchData()
    }
  },[id,ischeck])

  useEffect(()=>{

    if(!ischeck){
      setdataProjectTeam([])
    }
  },[ischeck])

  // console.log('dataProjectTeam', dataProjectTeam);
  
  
  const handleclickpopup=(ischeck:any)=>{
    setischeck(!ischeck)
    setischeckmember(false)
}

const handleclickclose=(ischeckmember:any)=>{
    setischeckmember(!ischeckmember)
    setischeck(false)
}
const onFocusedRowChanged = (e:any) => {
  setFocusedrowkey(e.component.option('focusedRowKey'));
  setSelectedRowIndex(e.row.rowIndex);
};
const onChangeCreate=(e:any)=>{
  
  setStateCreate(!stateCreate)
  onSelectChange('isCreate',e.value)
}
const onChangeRead=(e:any)=>{
  setStateRead(!stateRead)
  onSelectChange('isRead',e.value)
}
const onChangeUpdate=(e:any)=>{
  setStateUpdate(!stateUpdate)
  onSelectChange('isUpdate',e.value)
}

const onChangeDelete=(e:any)=>{
  setStateDelete(!stateDelete)
  onSelectChange('isDelete',e.value)
}
const editRow = (e:any) => {
  dataGrid.current.instance.editRow(rowIndex);
};
const onRowDblClick = (e:any) => {
  dataGrid.current.instance.editRow(e.rowIndex);
  dataGrid.current.instance.deselectAll();
};

// const changesText = React.useMemo(() => JSON.stringify(saveStateData.map((change) => ({
//   type: 'update',
//   key: change.id ,
//   data: {isCreate: change.isCreate, isRead: change.isRead,isUpdate: change.isUpdate,isDelete: change.isDelete},
// })), null, ' '), [saveStateData]);

const onSelectChange =async ( keyType: string, value:boolean)=>{

const selectedData = dataGrid.current.instance.getSelectedRowsData()

for(const item of selectedData){
  item.key = item?.id
  item.type=  "update";
  item.data=  {...item.data,[keyType]: value};
  const databykey =await  dataGrid.current.instance.getDataByKeys([item?.id]);
  Object.assign(databykey[0], {keyType  :value });
  // dataGrid.current.instance.editCell(dataGrid.current.instance.getRowIndexByKey(item?.id) ,keyType);
  // dataGrid.current.instance.cellValue(++i,keyType,value);
  dataGrid.current.instance.cellValue(dataGrid.current.instance.getRowIndexByKey(item?.id),keyType,value);
}
dispatch({type: 'MULTIPLE', payload: selectedData})

}


const {isProposeTaskPage} = useDetectProposePage() // Khánh thêm (ko chạy luật tự động cập nhật quyền cho trang đề xuất)


const fetchData = async (dataUser: any, e:any)=>{
 
  const url = isProposeTaskPage ? 'resourcesmembers/update-member-project-permission' : 'resourcesmembers/managePermissionMember'
  
  mutate({
    resource: url,
    // id: cmtId,
    values: dataUser,
    successNotification: {
      message: "Cập nhật thành công !",
      type: "success" 
    },
  },
  {
    onError: (error, variables, context) =>{
    // e?.component?.cancelEditData()
    // e?.component?.refresh()
    
  },
  onSuccess: (data, variables, context) =>{
      
    // e?.component?.cancelEditData()
    // e?.component?.refresh()
  },
}
)

}

const saving =async (e:any)=>{
  dispatch({type: 'DISCARD', payload: []})
  if(e?.changes?.length >0){
    
    let dataUser = {
      resourcesId: id,
      data: e?.changes
    }
    
    
    fetchData(dataUser, e)
  }
  
  
}

const onChangesChange = React.useCallback((changes:any) => {
  dispatch({type: 'MULTIPLE', payload: changes})
  
}, []);

const handleInitialized=(e: any)=>{
}

const onEditCanceling=(e: any)=>{
// console.log('eeeeeeeeeeeeeeeee', e);
dispatch({type: 'DISCARD', payload: []})
}



const onEditRowKeyChange=(e:any)=>{
}

const defaultSorting =  [{selector: 'isCreate'}]


  return (
    <div>
    {isProposeTaskPage &&  <MemberButton hideIcon name="Member" handler={()=>handleclickpopup(ischeck)}/>}
    {!isProposeTaskPage && <div className="box">
    <a className="button" style={{ fontSize: "13px",padding:"10px",background:"#f1bd6c",borderRadius:"4px",color:"#1e1f21" }}  onClick={()=>handleclickpopup(ischeck)}>Member</a>
    </div>}
    <div>
    <div  className={"overlay" + (ischeck ?  " targetmember" + (ischeckmember ? " display_none" : "") : "")}>
        <div className="popup popup__overplay">
        <div className='action_manage_member'>

            <h2 className='action_item_title'>Thành Viên...</h2>
            <div className='action_item_button'>
              <CheckBox
                value={stateCreate}
                text="Thêm"
                onValueChanged={onChangeCreate}
                className='action_detail_button'
              />
              <CheckBox
                value={stateRead}
                text="Đọc"
                onValueChanged={onChangeRead}
                className='action_detail_button'
              />
              <CheckBox
                value={stateUpdate}
                text="Sửa"
                onValueChanged={onChangeUpdate}
                className='action_detail_button'
              />
              <CheckBox
                value={stateDelete}
                text="Xóa"
                onValueChanged={onChangeDelete}
                className='action_detail_button'
              />
            </div>
        </div>
        
        <div className="close" onClick={()=>handleclickclose(ischeckmember)}>×</div>
        <React.Fragment>
        <DataGrid
          // dataSource={dataSource}
          dataSource={dataProjectTeam}
          showBorders={true}
          hoverStateEnabled={true}
          allowColumnReordering={true}
          rowAlternationEnabled={true}
          repaintChangesOnly={true}
          height={400}
          
          ref={dataGrid}
          focusedRowKey={focusedRowKey}
          focusedRowEnabled={true}
          remoteOperations={true}
          onFocusedRowChanged={(e:any)=>onFocusedRowChanged(e)}
          keyExpr="id"        
          onSaving={saving}
          onEditCanceling={onEditCanceling}
        >
          <Editing
            mode="batch"
            allowUpdating={true}
            ref={editingRef}
            changes={stateReducer}
            onChangesChange={onChangesChange}
          />
          <Scrolling mode="virtual" useNative={true}/>
      
          <Selection
            mode="multiple"
            selectAllMode='allPages'
            showCheckBoxesMode='onClick'
            
          />
          <Column
            dataField="name"
            caption="Tên nhân viên"
            dataType="string"
            width={200}
            allowEditing={false}
            />
          <Column
            dataField="dmPhongBan.dmPhongBan.tenPhongBan"
            caption="Tên phòng ban"
            dataType="string"
            width={200}
            allowEditing={false}
          />
          <Column
            dataField="isCreate"
            caption="Thêm"
            dataType="boolean"
            sortOrder={"desc"}
          />
          <Column
           dataField="isRead" 
           caption="Đọc" 
           dataType="boolean"
           sortOrder={"desc"}
          />
          <Column
           dataField="isUpdate" 
           caption="Sửa" 
           dataType="boolean"
           sortOrder={"desc"}
          />
          <Column
           dataField="isDelete" 
           caption="Xóa" 
           dataType="boolean"
           sortOrder={"desc"}
          />
          <FilterRow visible={true} />
        </DataGrid>
      </React.Fragment>
        </div>
    </div>
    </div>
</div>
  )
}


export default React.memo(Mentionprojectshow);
