import { toast } from "react-toastify";
import { assignTaskAsync, removeAssignTaskAsync, updateResource } from "../api.ts";
import { TaskType } from "../state/index.js";
import { MentionData } from "@draft-js-plugins/mention";

export const updateTaskAsync = async (taskId: number, value: any, task: TaskType, updateTask: any, updateDetailTask?: any) => {

    if (value?.name === task.name || !taskId || !task) return

    try {
        await updateResource(taskId, value);
        toast.success("Cập nhật thành công");
        if(updateDetailTask){
          updateDetailTask(value)
        }
    } catch (error: any) {
        updateTask(task.sectionId, task.taskId, { name: task.name })
        const message = error?.response?.data?.message;
        toast.error(message ?? "Có lỗi xảy ra !");
    }

}

export const updateAssignee = async (data: MentionData, task: TaskType, updateTask: any, updateLog?: any) => {
    const userId = data.id as number;
    if (!data || !task) return

    try {
        await assignTaskAsync({ memberId: userId, resourceId: task.taskId });
        updateTask(task.sectionId, task.taskId, { assignee: data });
        toast.success("Cập nhật thành công");


        if(updateLog) {
            const newComment = {
                resourcesid: Math.random(),
                resources: {
                  createdAt: new Date().toISOString(),
                  createdby: userId,
                  name: `{{${userId}}} assigned to {{${data.id}}}`,
                  resourcetype: "log",
                },
              };
              updateLog && updateLog((prev: any) => [...prev, newComment]);
        }
    } catch (error: any) {
        const message = error?.response?.data?.message;
        toast.error(message ?? "Có lỗi xảy ra !");
    }
}

export const removeAssign = async (task: TaskType, updateTask: any,userId: string | null, assigneeId: any , updateLog?: any) => {
    try {
      await removeAssignTaskAsync(task.taskId);
      updateTask(task.sectionId, task.taskId, { assignee: null });
      toast.success("Cập nhật thành công");

      if(updateLog) {
        const newComment = {
            resourcesid: Math.random(),
            resources: {
              createdAt: new Date().toISOString(),
              createdby: userId,
              name: `{{${userId}}} unassigned from  {{${assigneeId}}}`,
              resourcetype: "log",
            },
          };
          updateLog && updateLog((prev: any) => [...prev, newComment]);
    }

    } catch (error: any) {
      const message = error?.response?.data?.message;
      toast.error(message ?? "Có lỗi xảy ra !");
    }
  }


  export const extractValuesFromURL = (url: string) => {
    const regex = /\/task\/show\/(\d+)\?projectid=(\d+)/;
    const match = url.match(regex);

    if (match) {
        const taskId = match[1];
        const projectId = match[2];
        return { taskId, projectId };
    } else {
        return null; // If the URL doesn't match the expected pattern
    }
}


export function extractParametersWithRegex(url: string) {
  const regex = /\/(\d+)\?.*projectid=(\d+).*sectionId=(\d+)/;
  const match = url.match(regex);

  if (match) {
      const taskId = match[1];
      const projectId = match[2];
      const sectionId = match[3];

      return {
          taskId: taskId,
          projectId: projectId,
          sectionId: sectionId
      };
  } else {
      return null; // Return null if the regex doesn't match
  }
}


export function extractCommentId(url: string) {
  const regex = /comment_id=(\d+)/;
  const match = url.match(regex);

  if (match) {
      const commentId = match[1];
      return commentId;
  } else {
      return null; // Return null if the regex doesn't match
  }
}


