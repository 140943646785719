import { BaseRecord, useCreate } from "@pankod/refine-core";
import {
  MyTaskContextType,
  useMyTask,
} from "components/Mytask/Context/MyTask.context";
import React, { FC, useCallback, useRef, useState } from "react";
import { MdDragIndicator } from "react-icons/md";
import "./index.scss";
import { RiArrowUpDownFill } from "react-icons/ri";
import { BiChevronRight } from "react-icons/bi";
import { PopUpCarlendar } from "components/Mytask/PopUpCarlendar/PopUpCarlendar";
import { BsPersonCircle } from "react-icons/bs";
import { MentionData } from "@draft-js-plugins/mention";
import { TaskType, useProposeState } from "pages/ProposeTasks/state";
import Assignee from "components/Mytask/mtSession/SectionRow/Assignee/Assignee";
import { Mention } from "components/Mytask/Mention/Mention";
import MarkComplete from "../MarkComplete";
import { mutateRemoveAsiggne } from "components/Mytask/mtSession/SectionRow/SectionRowUtils";
import { toast } from "react-toastify";
import {
  assignTaskAsync,
  removeAssignTaskAsync,
} from "pages/ProposeTasks/api.ts";
import { AxiosError } from "axios";
import { DueDate } from "../DueDate/DueDate";
import { useProposeTaskDetail } from "pages/ProposeTasks/state/details";
import { updateAssignee, updateTaskAsync } from "pages/ProposeTasks/services";

interface Props {
  resourceDetails?: any;
  task: TaskType;
  lastElement?: any;
}

const Task: FC<Props> = ({ task, lastElement }) => {
  const [isRowFoucs, setIsRowFocus] = useState(false);
  const [showMention, setShowMention] = useState<any>(false);

  const {
    selectedTaskId,
    setShowMyTaskDetails,
    showMyTaskDetails,
    setCurrentTaskDetailId,
    setSelectedTaskId,
  } = useMyTask() as MyTaskContextType;
  const { assignee, taskId, sectionId } = task;

  const { updateTask , projectId} = useProposeState();
  const { addDetailTask, task: wrapperTask, updateDetailTask } = useProposeTaskDetail();  // wrapper current task to compare with new changes to update

  const handleShowDetails = useCallback(() => {
    setShowMyTaskDetails(true);
    setCurrentTaskDetailId(taskId);
    addDetailTask(task);


    window.history.replaceState(
      {},
      "propose-task",
      `/propose-task/show/${projectId}?taskId=${taskId}&sectionId=${sectionId}`
    );

  },[taskId, task]);

  const onTaskClick = useCallback(() => {
    setSelectedTaskId(taskId);
    addDetailTask(task);

    if (showMyTaskDetails) {
      setCurrentTaskDetailId(taskId);

      window.history.replaceState(
        {},
        "propose-task",
        `/propose-task/show/${projectId}?taskId=${taskId}&sectionId=${sectionId}`
      );
    }
  },[taskId,showMyTaskDetails,sectionId,projectId, task]);


  const handleShowMention = useCallback(() => {
    if (assignee) return;
    setShowMention(true);
  }, [assignee]);

  const handleKeyDown = () => {};
  const handleShowProjectList = () => {};

  const handleUpdateAssignee = (data: MentionData) => {
    updateAssignee(data, task, updateTask)
  }

  const handleRemoveAssign = useCallback(async () => {
    try {
      await removeAssignTaskAsync(taskId);
      updateTask(sectionId, taskId, { assignee: null });
      toast.success("Cập nhật thành công");
    } catch (error: any) {
      const message = error?.response?.data?.message;
      toast.error(message ?? "Có lỗi xảy ra !");
    }
  }, [taskId]);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = { name: e.target.value };

      if (task) {
        updateTask(task.sectionId, task.taskId, value);
      }
    },
    [task]
  );

  const handleFocus = useCallback(() => {
    setIsRowFocus(true);
  }, []);

  const handleBlur = useCallback(() => {
    setIsRowFocus(false);
    if(wrapperTask) {
      updateTaskAsync(taskId, {name: task?.name}, wrapperTask , updateTask, updateDetailTask)
    }
  }, [JSON.stringify(wrapperTask), taskId, task, updateTask]);

  const [open, setOpen] = useState(false);

  return (
    <div
      className="session-row-container"
      style={{ borderTop: "none" }}
      ref={lastElement ? lastElement : null}
      onClick={onTaskClick}
      id={`${sectionId}-${taskId}`}
    >
      <div
        className={
          "session-row " +
          (selectedTaskId === task?.taskId ? "selected-row" : "")
        }
      >
        <div className={"session-row-title " + (isRowFoucs ? "focus" : "")}>
          <MdDragIndicator className="drag-icon hide" />

          <div className="row-title">
            <MarkComplete task={task} />
            <input
              value={task.name}
              onChange={handleChange}
              onFocus={handleFocus}
              spellCheck={false}
              onBlur={handleBlur}
              onKeyDown={handleKeyDown}
            />
          </div>

          <div
            className={"row-item " + (showMyTaskDetails ? "rowitem-hasDt" : "")}
          >
            <RiArrowUpDownFill className="row-item-icon " />
            {!showMyTaskDetails && (
              <div className="row-item-detail" onClick={handleShowDetails}>
                <span>Details</span>
                <BiChevronRight className="row-item-detail-icon" />
              </div>
            )}
          </div>
        </div>

        {!showMyTaskDetails && (
          <>
            <div className="session-row-col date" onClick={handleShowMention}>
              <BsPersonCircle
                style={{
                  display: showMention || assignee ? "none" : "",
                }}
                className="row-col-icon"
              />

              {assignee && (
                <Assignee
                  assignee={assignee}
                  handleRemoveAssign={handleRemoveAssign}
                />
              )}

              {showMention && (
                <Mention
                  customFunc={setShowMention}
                  setOpen={setOpen}
                  open={open}
                  onSelectMention={handleUpdateAssignee}
                />
              )}
            </div>

            <div
              className="session-row-col date"
              onClick={handleShowProjectList}
            >
              <DueDate task={task} />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Task;
