import { MentionData } from "@draft-js-plugins/mention";
import { BaseRecord, useCreate } from "@pankod/refine-core";
import { useAppContext } from "App.context/App.context";
import { USER_ID } from "authProvider";
import { Avatar } from "components/avatar/avatar";
import { MyTaskContextType, useMyTask } from "components/Mytask/Context/MyTask.context";
import { Mention } from "components/Mytask/Mention/Mention";
import { PopUpCarlendar } from "components/Mytask/PopUpCarlendar/PopUpCarlendar";
import { useEffect, useMemo, useState } from "react";
import { FaTimes } from "react-icons/fa";
import AddToProject from "./AddToProject/AddToProject";
import "./TaskInfo.scss";
import { SocketTeamContext } from "socket/socketcontext";
import { useDetectProposePage, useSelectTask } from "pages/ProposeTasks/hooks";
import { removeAssign, updateAssignee } from "pages/ProposeTasks/services";
import { useProposeState } from "pages/ProposeTasks/state";
import { useProposeTaskDetail } from "pages/ProposeTasks/state/details";
import { DescTextEditor } from "components/Mytask/mtDetails/DescTextEditor/DescTextEditor";
import { DueDate } from "../../DueDate/DueDate";
interface TaskInfoProps {
  description?: string;
  resourcesid?: number;
}

export const ProposeTaskInfo: React.FC<TaskInfoProps> = ({
  description,
  resourcesid,
}) => {

  const [open, setOpen] = useState(false);
  const [showMention, setShowMention] = useState(false);

  const values = useMyTask();

  const {setComments} = values as MyTaskContextType
  const userId = localStorage.getItem(USER_ID);


  const {updateTask} = useProposeState()
  const {task: wrapperTask } = useProposeTaskDetail()

  const [sectionId, taskId] = useMemo(() => {
    if(wrapperTask?.sectionId && wrapperTask.taskId) {
      return [wrapperTask?.sectionId, wrapperTask?.taskId]
    }

    return [null, null]
  },[wrapperTask])

  const activateTask = useSelectTask(sectionId as number, taskId as number);

  const {assignee} = useMemo(() => {
    return {
      assignee: activateTask?.assignee ? activateTask.assignee : null
    }
  },[JSON.stringify(activateTask)])

  const handleAssignClick = () => {
    setShowMention(prev => !prev);
  };
  
  const handleUpdateAssignee = (data: MentionData) => {
    if(wrapperTask) {   
        updateAssignee(data, wrapperTask, updateTask, setComments)
    }
  }

  const handleRemoveAssignee = () => {
    if(!userId || !assignee ||  !activateTask) return
    removeAssign(activateTask, updateTask, userId, assignee?.id, setComments)
  };

  return (
    <div className="TaskInfo-container">
      <div className={"info-item " + (open && "focus")}>
        <div className="info-label">Assignee</div>
        <div className="info-detail" onClick={handleAssignClick}>

          {assignee && !showMention && <Avatar userId={Number(assignee.id)} />}
            <>
              {showMention ? (
                <Mention
                  customFunc={setShowMention}
                  open={open}
                  setOpen={setOpen}
                  onSelectMention={handleUpdateAssignee}
                />
              ) : !assignee ? <span>Add Assignee</span> : <></>
              }
            </>
          {assignee && !showMention && <div className="asignee-name">{assignee.name}</div>}

        </div>

        {!open && assignee && (
          <FaTimes
            className="del-assigne-icon"
            onClick={handleRemoveAssignee}
          />
        )}

      </div>

      <div className="info-item">
        <div className="info-label">Due date</div>
        <div className="info-detail">
        {activateTask &&  <DueDate task={activateTask} />}
        </div>
      </div>

      <AddToProject />

      <div className="info-item">
        <div className="info-label">Description</div>
      </div>
      <DescTextEditor description={description} resourcesid={resourcesid} />
    </div>
  );
};
