import { useList } from "@pankod/refine-core";
import { useAppContext } from "App.context/App.context";
import axios from "axios";
import { TeamContext } from "context/contextapi";
import { flatten } from "hooks/useGetValue";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import DropdownFilter from "../mt_activity/DropdownFilter";
import StarredItem from "./StarredItem";

interface isPropStared {
  dataStarred?: any;
  setDataStarred?: any;
  dataScreenActivity: any;
  setDataScreenActivity: any;
  dataScreenArchive: any;
  setDataScreenArchive: any;
}
interface isPropStaredHOC {
  dataStarred?: any;
  setDataStarred?: any;
  showSideBarInbox: any;
  keyFilter: any;
  setKeyFilter: any;
  type: any;
  setType: any;
  setCheckStarred: any;
  dataScreenActivity: any;
  setDataScreenActivity: any;
  resultStar: any;
  dataScreenArchive: any;
  setDataScreenArchive: any;
}

const getConfig = (page: number, getUserId: string | null) => {
  return {
    Starred: `resourcesstarred/dataScreenStarred?join=taskname&filter=memberId||$eq||1&join=taskname.complete&join=taskname.resourcestasks&join=taskname.resourcestasks.projectname&page=${page}&limit=10`,
  };
};

const getUrl = (getUserId: string | null) => {
  return {
    Starred: "resourcesstarred/dataScreenStarred?join=taskname&filter=memberId||$eq||1&join=taskname.complete&join=taskname.resourcestasks&join=taskname.resourcestasks.projectname&page=1&limit=10",
    
  };
};
const StarredHOC: React.FC<isPropStared> = ({
  dataStarred,
  setDataStarred,
  dataScreenActivity,
  setDataScreenActivity,
  dataScreenArchive,
  setDataScreenArchive,
}) => {
  const values = TeamContext();
  const collapse = useAppContext();
  return (
    <StarredWrapper
      dataStarred={dataStarred}
      setDataStarred={setDataStarred}
      keyFilter={values?.keyFilter}
      setKeyFilter={values?.setKeyFilter}
      type={values?.type}
      setType={values?.setType}
      showSideBarInbox={collapse?.showSideBarInbox}
      setCheckStarred={values?.setCheckStarred}
      resultStar={values?.resultStar}
      dataScreenActivity={dataScreenActivity}
      setDataScreenActivity={setDataScreenActivity}
      dataScreenArchive={dataScreenArchive}
      setDataScreenArchive={setDataScreenArchive}
    />
  );
};
const StarredWrapper: React.FC<isPropStaredHOC> = memo(
  ({
    dataStarred,
    setDataStarred,
    keyFilter,
    setKeyFilter,
    type,
    setType,
    showSideBarInbox,
    setCheckStarred,
    dataScreenActivity,
    setDataScreenActivity,
    resultStar,
    dataScreenArchive,
    setDataScreenArchive,
  }) => {
    const getUserId = localStorage.getItem("userId");
    const [page, setPage] = useState(1);
    const [selected, setSelected] = useState("");
    const [noMore, setNoMore] = useState(false);
    const config: any = getConfig(page, getUserId);
    const getUrlType: any = getUrl(getUserId);

    useEffect(() => {
      setPage(1);
      setNoMore(false);
      const getComment = async () => {
        setDataStarred([]);
        const res = await axios.get(getUrlType['Starred'])
        const data = await res.data.data;   
        if (data?.length < 10) {
          setNoMore(true);
        }
        setDataStarred(data);
      };
      getComment();
    
  }, []);

  useEffect(() => {
    fetchData();
  }, [page]);

  useEffect(()=>{
    const getComment = async () => {
      const res = await axios.get(getUrlType['Starred'])
      const data = await res.data.data;   
      setDataStarred(data);
     
    };
    getComment();

  },[])

  

  const fetchComment = async () => {
    const res = await axios.get(config['Starred']);
    const data = await res.data.data;
    return data;
  };

  const fetchData = async () => {
    const commentFormSever = await fetchComment();
    setDataStarred([
      ...dataStarred,
      ...commentFormSever,
    ]);
    if (commentFormSever?.length < 10) {
      setNoMore(true);
    }
  };

  const observe = useRef<IntersectionObserver | null>(null);

  const lastElement = useCallback(
    (node: HTMLElement) => {
      if (observe.current) observe.current?.disconnect();
      observe.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && !noMore) {
          setPage(page + 1);
        }
      });

      if (node) {
        observe.current?.observe(node);
      }
    },
    [dataStarred]
  );


    const getIdStarred = (taskId: any) => {
      setDataStarred((prev: any) => {
        const olddata = [...prev];
        const newdata = olddata.filter((cmt) => cmt.taskId !== taskId);
        return newdata;
      });
    };

    return (
      <div
        className="asign-list-archive"
        style={{ width: `${showSideBarInbox ? "52%" : "45%"}` }}
      >
        {dataStarred &&
          dataStarred?.map((item: any, index: any) => {
            if (dataStarred?.length === index + 1){

              return (
                <div
                  className="active-item-ibox"
                  style={{ background: `${item?.id === selected ? "#eee" : ""}` }}
                  onClick={() => {
                    setSelected(item?.id);
                  }}
                >
                  <StarredItem
                    starredItem={flatten(item)}
                    getUserId={getUserId}
                    index={index}
                    type={type}
                    dataStarred={dataStarred}
                    setDataStarred={setDataStarred}
                    setPage={setPage}
                    getIdStarred={getIdStarred}
                    dataScreenActivity={dataScreenActivity}
                    setDataScreenActivity={setDataScreenActivity}
                    dataScreenArchive={dataScreenArchive}
                    setDataScreenArchive={setDataScreenArchive}
                    lastElement={lastElement}

                  />
                </div>
              );
            }else{
              return (
                <div
                  className="active-item-ibox"
                  style={{ background: `${item?.id === selected ? "#eee" : ""}` }}
                  onClick={() => {
                    setSelected(item?.id);
                  }}
                >
                  <StarredItem
                    starredItem={flatten(item)}
                    getUserId={getUserId}
                    index={index}
                    type={type}
                    dataStarred={dataStarred}
                    setDataStarred={setDataStarred}
                    setPage={setPage}
                    getIdStarred={getIdStarred}
                    dataScreenActivity={dataScreenActivity}
                    setDataScreenActivity={setDataScreenActivity}
                    dataScreenArchive={dataScreenArchive}
                    setDataScreenArchive={setDataScreenArchive}
                  />
                </div>
              );

            }
          })}
      </div>
    );
  }
);
export default StarredHOC;
