import React, { useCallback } from "react";
import styled from "styled-components";
import { Dropdown, Menu } from "@pankod/refine-antd";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { FaChevronDown, FaCheckCircle, FaRegCheckCircle } from "react-icons/fa";
import { MdOutlineAllInclusive } from "react-icons/md";

import { SortType, useSortState } from "../../state/sort";

const Wrap = styled.div`
  display: flex;
`;
const Content = styled.div`
    background-image: linear-gradient(to right, #606c88 0%, #3f4c6b  51%, #606c88  100%);
    padding: 5px 10px;
    text-align: center;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;            
    box-shadow: 0 0 20px #eee;
    border-radius: 5px;
    gap: 8px;
    display: flex;
    cursor: pointer;
    align-items: center;
    min-width: 180px;
    position: relative;
    width: max-content;
    justify-content: center;

    &:hover {
      transform: scale(1.05);
      background-position: right center;
      color: #fff;
      text-decoration: none;
  }
`;

const Text = styled.div`
  line-height: 1;
  margin: 0;
  font-weight: 600;
  color: white;
  margin-right: 10px;
`;
const RightIcon = styled.div`
  position: absolute;
  right: 10px;
`;

const ProposeSort = () => {
  const { mode, updateSort } = useSortState();

  const menu = (
    <Menu
      selectable
      items={[
        {
          label: "Incomplete tasks",
          key: "1",
          onClick: () => updateSort(SortType.InCompleted),
          icon: <FaRegCheckCircle fontSize="14px" color="#faad14" />,
        },

        {
          label: "Completed tasks",
          key: "2",
          icon: <FaCheckCircle fontSize="14px" color="#37da37" />,
          onClick: () => updateSort(SortType.Completed),
        },

        {
          label: "All tasks",
          key: "3",
          icon: <MdOutlineAllInclusive fontSize="14px" color="#ff4b1f" />,
          onClick: () => updateSort(SortType.AllTasks),
        },
      ]}
    />
  );

  const getIcon = useCallback(() => {
    if (mode === SortType.AllTasks) {
      return <MdOutlineAllInclusive fontSize="14px" color="#ff4b1f" />;
    }
    if (mode === SortType.Completed) {
      return  <FaCheckCircle fontSize="12px" color="#37da37" />
    }
    if (mode === SortType.InCompleted) {
      return  <FaRegCheckCircle fontSize="12px" color="#faad14" />
    }
  }, [mode]);

  return (
    <Wrap>
      <Dropdown overlay={menu} trigger={["click"]} >
        <Content>
          {getIcon()}
          <Text>{mode}</Text>
          <RightIcon>
            <FaChevronDown fontSize="10px" />
          </RightIcon>
        </Content>
      </Dropdown>
    </Wrap>
  );
};

export default ProposeSort;
