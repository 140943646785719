/* eslint-disable react-hooks/rules-of-hooks */
import { BaseRecord, useCreate, useRouterContext, useUpdate } from "@pankod/refine-core";
import { Avatar } from "components/avatar/avatar";
import { useMyTask } from "components/Mytask/Context/MyTask.context";
import { Like } from "components/Mytask/mtDetails/mtDetails-Content/Comments/Like/Like";
import { TeamContext } from "context/contextapi";
import { useGetValue } from "hooks/useGetValue";
import moment from "moment";
import { memo, useEffect, useState } from "react";
import {
  AiFillMessage,
  AiFillStar,
  AiOutlineStar,
} from "react-icons/ai";
import { BsArchive } from "react-icons/bs";
import { IoIosArrowDropdown, IoIosArrowDropdownCircle } from "react-icons/io";
import CommentItem from "./CommentItem";
import ProjectNameItem from "./ProjectNameItem";


import axios from "axios";

import { SocketTeamContext } from "socket/socketcontext";
import { TOKEN_KEY_LOGIN, USER_ID } from "authProvider";
import { NewInboxSocketTeamContext } from "socket/newinboxcontext";
interface IsComment {
  comment?: BaseRecord;
  getUserId?: string | null;
  lastElement?: any;
  index?: any;
  type?: any;
  dataScreenActivity: any;
  setDataScreenActivity: any;
  dataScreenArchive: any;
  setDataScreenArchive: any;
  setPage: any;
  getIdStarred: any;
  commentId: any;
  checkClickItem?: boolean
  // isStar: any;
}

interface IspropContext {
  comment?: any;
  setInput?: any;
  input?: any;
  getUserId?: any;
  lastElement?: any;
  setDataArchiveAll?: any;
  index?: any;
  type?: any;
  dataScreenActivity: any;
  setDataScreenActivity: any;
  dataScreenArchive: any;
  setDataScreenArchive: any;
  setPage: any;
  getIdStarred?: any;
  checkStarred: any;
  isNewInboxSocket: any;
  setIsNewInboxSocket: any;
  socket: any;
  commentId: any;
  currentProjectId: any;
  checkClickItem?: boolean
  // isStar: any;
}

const AsignItemContext: React.FC<IsComment> = ({
  comment,
  getUserId,
  lastElement,
  index,
  type,
  dataScreenActivity,
  setDataScreenActivity,
  dataScreenArchive,
  setDataScreenArchive,
  setPage,
  getIdStarred,
  commentId,
  checkClickItem
  // isStar,
}) => {
  const value = TeamContext();

  const values = useMyTask();

  const valueSocketNewInbox = NewInboxSocketTeamContext();
  const valueSocket = SocketTeamContext();
  return (
    <AsignItem
      comment={comment}
      getUserId={getUserId}
      lastElement={lastElement}
      index={index}
      type={type}
      dataScreenActivity={dataScreenActivity}
      setDataScreenActivity={setDataScreenActivity}
      dataScreenArchive={dataScreenArchive}
      setDataScreenArchive={setDataScreenArchive}
      setPage={setPage}
      getIdStarred={getIdStarred}
      checkStarred={value?.checkStarred}
      isNewInboxSocket={valueSocketNewInbox?.isNewInbox}
      setIsNewInboxSocket={valueSocketNewInbox?.setIsNewInbox}
      // isNewInboxSocket={valueSocket?.isNewInbox}
      // setIsNewInboxSocket={valueSocket?.setIsNewInbox}
      socket={valueSocket?.socket}
      commentId={commentId}
      currentProjectId={values?.currentProjectId}
      checkClickItem={checkClickItem}
      // isStar={isStar}
    />
  );
};
const AsignItem: React.FC<IspropContext> = memo(
  ({
    comment,
    setDataScreenArchive,
    getUserId,
    lastElement,
    index,
    type,
    dataScreenArchive,
    dataScreenActivity,
    setDataScreenActivity,
    setPage,
    getIdStarred,
    checkStarred,
    isNewInboxSocket,
    setIsNewInboxSocket,
    socket,
    commentId,
    currentProjectId,
    checkClickItem
    // isStar,
  }) => {
    const { mutate } = useUpdate();
    const mutate2 = useCreate()
    const createOrUpdateStar = mutate2.mutate
    const values = useMyTask();
    const [isOpen, setIsopen] = useState(true);
    const [isNewInbox, setIsNewInbox] = useState(false);
    const userId = localStorage.getItem(USER_ID);
    const idUserTaskList =JSON.parse(localStorage.getItem(TOKEN_KEY_LOGIN) || "") ;
    const {useLocation} = useRouterContext()
  const location = useLocation()
  const { pathname }: any = useLocation();
    // const [isStarLocal, setIsStarLocal] = useState(isStar);

    const [isStar, setIsStar] =useState(()=>{
      return useGetValue(comment, "isStarred", type)?.some((star:any) => Number(star?.memberId) === Number(getUserId))
  } )

  useEffect(() => {
    setIsStar(()=>{
     return useGetValue(comment, "isStarred", type)?.some((star:any) => Number(star?.memberId) === Number(getUserId))
    })
  }, [useGetValue(comment, "isStarred", type)]);

  const toggleStarred = (comment: any,taskId:any) => {
    setIsStar((prev:any) =>!prev);
    // setParentIsStar((prev:any) =>!prev);
    createOrUpdateStar(
      {
        resource: "resourcesstarred/starred",
        values: {
          taskId: taskId,
        },
        invalidates: ["all"],
      },
      {
        onSuccess: (data, variables, context) => {},
      }
    );
  };


    useEffect(() => {
      let dataUser = {
        userId,
        commentId: commentId ?? comment.id
      }
      
      const fetchData = async ()=>{
        const response = await axios.post('resourcesfilterlastesmention/getNewInboxComment', dataUser)

        if(response){
          setIsNewInbox(response?.data)
        }
        
      }

      fetchData()
      
    },[commentId])

    const handleArchire = (comment: any, index: any) => {
      mutate(
        {
          resource:
            type === "All"
              ? "resourcesmentions/archive"
              : "resourcesfilterlastesmention/archive",
          values: {
            isArchived: false,
            resourceId: comment?.resourceRelation?.resourcesParent?.id,
          },
          id: comment?.resourcesmentions?.at(0)?.id
            ? comment?.resourcesmentions?.at(0)?.id
            : useGetValue(comment, "resourcesmentions", type),
          invalidates: ["all"],
        },
        {
          onSuccess: (data, variables, context) => {
            setDataScreenArchive((prev: any) => [comment, ...prev]);
            setDataScreenActivity &&
              setDataScreenActivity((prev: any) => {
                const olddata = [...prev];
                const newdata = olddata.filter((cmt) => cmt.id !== comment?.id);
                if (newdata?.length === 0) {
                  setPage(1);
                }
                return newdata;
              });
          },
        }
      );
    };
    useEffect(() => {
      if(checkClickItem) {
        return
      }
      if (isOpen && !values?.isBackInbox) {
        values?.setShowMyTaskDetails(true);
        if (index === 0 && pathname === '/Inbox') {
          
          values?.setCurrentTaskDetailId(
            useGetValue(comment, "parentId", type)
          );
          values?.setCurrentTaskDetailTitle(
            useGetValue(comment, "resourcesParentName", type)
          );
        }
        values?.setHideArrowToRightScreenDetail("hide");
        values?.setCurrentProjectId(idUserTaskList)

        setIsopen(false);
      }
      
      
    }, [values,checkClickItem]);

    const handleDetailInbox = (comment: any) => {
      values?.setHideArrowToRightScreenDetail("hide");
      values?.setShowMyTaskDetails(true);
      values?.setCurrentTaskDetailId(useGetValue(comment, "parentId", type));
      values?.setCurrentTaskDetailTitle(
        useGetValue(comment, "resourcesParentName", type)
      );
      setIsNewInbox(false);

      

      window.history.replaceState(
        {},
        "Inbox",
        `/Inbox/show/${useGetValue(
          comment,
          "parentId",
          type
        )}?projectid=${idUserTaskList}`
      );

      values?.setCurrentProjectId(idUserTaskList)
    };
    return (
      <>
        {useGetValue(comment, "parent", type) && (
          <div
            className="asign-item"
            key={comment.id}
            ref={lastElement ? lastElement : null}
            onClick={() => handleDetailInbox(comment)}
          >
            <div className="assign-project-name">
              <ProjectNameItem
                projectName={useGetValue(comment, "resourcestasks", type)}
              />
              <div className="task-wrapper">
                <div className="task-content-item">
                  {useGetValue(comment, "complete", type) ? (
                    <IoIosArrowDropdownCircle
                      size={20}
                      className={"row-item-icon completed"}
                    />
                  ) : (
                    <IoIosArrowDropdown
                      size={20}
                      className={"row-item-icon incomplete"}
                    />
                  )}

                  <span className="task-name">
                    {useGetValue(comment, "resourcesParentName", type)}
                  </span>
                </div>
                <div className="starred-inbox">
                  {isStar && <AiFillStar className="icon-starred-inbox" />}
                </div>
              </div>
            </div>
            <div className="asign-wrap">
              <div className="user-asign">
                <div className="avatar">
                  <Avatar userId={useGetValue(comment, "resourcecmId", type)} />
                  <div className="message">
                    <span className="icon-message">
                      <AiFillMessage />
                    </span>
                  </div>
                </div>
                <div className="task-content">
                  <p className="user-name">
                    {useGetValue(comment, "resourcecmName", type)}
                  </p>
                  <div className="wrap-content">
                    <CommentItem
                      commentItem={useGetValue(comment, "name", type)}
                    />
                  </div>
                  <div className="comment-date">
                    {useGetValue(comment, "createdAt", type)
                      ? moment
                          .utc(useGetValue(comment, "createdAt", type))
                          .local()
                          .startOf("seconds")
                          .fromNow()
                      : "--"}
                  </div>
                </div>
              </div>
              <div className="icon-like-and-new-inbox">
                <div className="icon-like-wrap">
                  <Like
                    resourceId={useGetValue(comment, "id", type)}
                    likes={useGetValue(comment, "resourcesLikes", type)}
                    CurrentUserId={JSON.parse(getUserId as string)}
                  />
                </div>
                {isNewInbox ? <div style={{ marginRight: '10px',backgroundColor: '#4573d2',  borderRadius:'50%', width:'8px', height:'8px'}}></div>: null}
              </div>
            </div>

            <div className="Archive-list">
            <span className="general-icon">
                {isStar ? (
                  <AiFillStar
                    onClick={() => toggleStarred(useGetValue(comment, "isStarred", type),useGetValue(comment, "resourceId", type))}
                    className="aiFillStar-icon"
                    title={"Remove from star"}
                  />
                ) : (
                  <AiOutlineStar
                    className="star-icon-mention"
                    title={"Add to star"}
                    onClick={() => toggleStarred(useGetValue(comment, "isStarred", type),useGetValue(comment, "resourceId", type))}
                  ></AiOutlineStar>
                )}
              </span>
              {/* <FavoriteInbox
                type={type}
                comment={useGetValue(comment, "isStarred", type)}
                taskId = {useGetValue(comment, "resourceId", type)}
                getIdStarred={getIdStarred}
                // isStar={isStar}
                // setParentIsStar = {setIsStarLocal}
                // setItem={setItem}
              /> */}
              {/* <span title="Create follow-up task" className="icon-check general-icon">
                <AiOutlineCheckCircle />
              </span>
              <span title="Mark as unread" className="unread-icon general-icon">
                <BiSquareRounded />
              </span> */}
              <span
                title="Archive notifications"
                className="archive-icon general-icon"
                onClick={() => handleArchire(comment, index)}
              >
                <BsArchive />
              </span>
            </div>
          </div>
        )}
      </>
    );
  }
);

export default AsignItemContext;
