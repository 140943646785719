import { Dropdown, Menu } from "@pankod/refine-antd";
import { useNavigation } from "@pankod/refine-core";
import { useAppContext } from "App.context/App.context";
import { ALLTASK } from "components/Mytask/Constant";
import { useMyTask } from "components/Mytask/Context/MyTask.context";
import { useSectionTaskDetailRecentAsync } from "pages/Recently-completed-task/hooks";
import { removeProjectTaskDetailRecentlyAsync, updateProjectTaskDetailRecentlyAsync } from "pages/Recently-completed-task/service";
import { useRecentlyState } from "pages/Recently-completed-task/state";


import React, { useCallback, useEffect, useState } from "react";
import { BiChevronDown } from "react-icons/bi";
import { MdClear } from "react-icons/md";

interface ProjectItemPropsIF {
    type?: string;
    item?:any
    task?:any
  }
  

const ProjectItemRecently:React.FC<ProjectItemPropsIF> = ({item,task}) => {
    const [sectionName, setSectionName] = useState('')
    const [sections, setSections] = useState<any[]>([]);

    const { show } = useNavigation();

    const AppContext = useAppContext();
    const values = useMyTask();

    const {data} = useSectionTaskDetailRecentAsync(item)
    const { updateValueTask } = useRecentlyState()

    const navigateProject = (projectId:any) => {
      show(`projects`, projectId)
      AppContext?.setTaskDisplayStatus(ALLTASK)
      AppContext?.setIsMyTaskLoading(true)
    }

    const handleRemove = useCallback(() => { 
      removeProjectTaskDetailRecentlyAsync({sectionId:item.sectionId, taskId: values?.currentTaskDetailId},updateValueTask,task)
      
    },[task])

    const handleClick = (data:any) => {
      updateProjectTaskDetailRecentlyAsync({newSectionId:Number(data.key),taskId:values?.currentTaskDetailId,oldSectionId:item.sectionId},updateValueTask,task)
    }
  
  

    useEffect(() => {
      if(data && data.length>0) {
        data.forEach((_:any) => {
          if(_?.resources?.id == item.sectionId) {
            setSectionName(_.resources.name);
          }
        })
      }
    },[data,item,task])

    useEffect(() => {
      if(data && data.length>0) {
        const newSections = data.map((section: any) => {
          return {
            label: section.resources?.name,
            key: section.resources?.id,
            onClick: handleClick,
          };
        });
        setSections(newSections)
      }
    },[item,data])

  
    const menu = <Menu items={sections} />;
    return (
        <div className="project-item-container" style={{display: item.label ? '' : "none"}}>
          <div className="project-name">
            <div className="project-tag"></div>
            <p onClick={()=>navigateProject(item?.projectId)}>{item.label}</p>
          </div>
            <>
              <Dropdown overlay={menu} trigger={["click"]}>
                <div className="section-name">
                  <p>{sectionName}</p>
                  <BiChevronDown />
                </div>
              </Dropdown>
              <div className="remove-btn" onClick={handleRemove}>
                <MdClear />
              </div>
            </>
      
        </div>
      );
}
export default ProjectItemRecently