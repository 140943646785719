import { useUpdate } from "@pankod/refine-core";
import { USER_ID } from "authProvider";
import { MyTaskContextType, useMyTask } from "components/Mytask/Context/MyTask.context";
import React from "react";
import { AiOutlineCheck } from "react-icons/ai";
import { BsCheckCircle, BsFillCheckCircleFill } from "react-icons/bs";
import { TaskType, useProposeState } from "../state";
import { upsertResourceDetailAsync } from "../api.ts";
import { toast } from "react-toastify";

interface MarkCompleteProps {
  type?: string;
  task: TaskType;
}

const MarkComplete: React.FC<MarkCompleteProps> = ({ type, task }) => {
  const values = useMyTask();
  const {setComments} = values as MyTaskContextType

  const { updateTask } = useProposeState();

  const handleToggleCompleteStatus = async () => {

    if (task?.taskId) {
      try {
        await upsertResourceDetailAsync(task?.taskId, {
          completed: !task?.completed,
          type: !task?.completed ? "markcomplete" : "markuncomplete",
        });

        toast.success("Cập nhật thành công");

        const userId = localStorage.getItem(USER_ID);
        updateTask(task?.sectionId, task?.taskId, { completed: !task?.completed });

        if (!task?.completed) {
          const newComment = {
            resourcesid: Math.random(),
            resources: {
              createdAt: new Date().toISOString(),
              createdby: userId,
              name: `{{${userId}}} completed this task.`,
              resourcetype: "log",
            },
          };

          if (setComments) {
            setComments((prev) => [...prev, newComment]);
          }
        } else if (task?.completed) {
          const newComment = {
            resourcesid: Math.random(),
            resources: {
              createdAt: new Date().toISOString(),
              createdby: userId,
              name: `{{${userId}}} uncomplete this task.`,
              resourcetype: "log",
            },
          };

          if (setComments) {
            setComments((prev) => [...prev, newComment]);
          }
        }
      } catch (error: any) {
        const message = error?.response?.data?.message;
        toast.error(message ?? "Có lỗi xảy ra !");
      }
    }
  };

  if (type) {
    return (
      <div
        className={"mark-complete-datails " + (task?.completed ? "completed" : "")}
      >
        <AiOutlineCheck onClick={handleToggleCompleteStatus} />
        {
          <span onClick={handleToggleCompleteStatus}>
            {task?.completed ? "Completed" : "Mark complete"}
          </span>
        }
      </div>
    );
  }

  return (
    <>
      {task?.completed ? (
        <BsFillCheckCircleFill
          className="row-title-check-icon"
          style={{ color: "#58a182" }}
          onClick={handleToggleCompleteStatus}
        />
      ) : (
        <BsCheckCircle
          className="row-title-check-icon"
          onClick={handleToggleCompleteStatus}
        />
      )}
    </>
  );
};

export default MarkComplete;
