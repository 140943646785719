import {  updateResource } from "pages/ProposeTasks/api.ts";
import { toast } from "react-toastify";
import { addProject, deleteTaskRecently, removeAssignRecentlyAsync, removeSectionTaskDetailRecently, updateAssignRecently, updateSectionTaskDetailRecently } from "../api";
import { MentionData } from "@draft-js-plugins/mention";

export const updateRecentlyTaskAsync = async (taskId: number, value: any, oldTask: any, updateValueTask: any,updateRecentlyTaskOld?:any) => {
    if (value?.name === oldTask.name || !taskId || !oldTask) return
    try {
        await updateResource(taskId, value);
        toast.success("Cập nhật thành công");
        if(updateRecentlyTaskOld) {
            updateRecentlyTaskOld(value)
        }
        
    } catch (error: any) {
        updateValueTask(oldTask.taskId, { name: oldTask.name })
        const message = error?.response?.data?.message;
        toast.error(message ?? "Có lỗi xảy ra !");
    }
}

export const removeAssigneeRecentlyAsync = async (taskId:number,updateValueTask:any,dataAssignee?:any ,socketTeamContext?:any,currentProjectId?:any,idUserTaskList?:any) => {
    try {
      currentProjectId == idUserTaskList && await socketTeamContext?.socket?.emit("joinRoomAssignee", {type:'remove',assignee:dataAssignee, resourceId: taskId});
      await removeAssignRecentlyAsync(taskId);
      updateValueTask(taskId, { assigneeId: null,assigneeName:null,hide:true });
      toast.success("Cập nhật thành công");
    } catch (error: any) {
      const message = error?.response?.data?.message;
      toast.error(message ?? "Có lỗi xảy ra !");
    }
}

export const updateAssigneeRecentlyAsync = async (data:MentionData,taskRecentSelect:any,updateValueTask:any,socketTeamContext?:any,userId?:any,currentProjectId?:any,idUserTaskList?:any) => {
    const userAssignee = data.id as number;
    try {
        await updateAssignRecently({
            memberId: userAssignee,
            resourceId:taskRecentSelect?.taskId
        });
        // check màn hình mytask
        if(currentProjectId == idUserTaskList) {
          socketTeamContext && await socketTeamContext?.socket?.emit("joinRoomAssignee", {type:'add',assigneeData:data,resourceId: taskRecentSelect?.taskId});
          if(data.id == Number(userId)) {
            // update state name,assignee,duedate,projects
            await updateValueTask(taskRecentSelect?.taskId, {...taskRecentSelect,assigneeId: data.id,assigneeName:data.name,hide:false});
            
          }else {
            await updateValueTask(taskRecentSelect?.taskId, {...taskRecentSelect,assigneeId: data.id,assigneeName:data.name,hide:true});
          }
        }
        // else {
        //   await updateValueTask(taskRecentSelect?.taskId, {...taskRecentSelect,assigneeId: data.id,assigneeName:data.name});
        // }
        toast.success("Cập nhật thành công");
      } catch (error: any) {
        const message = error?.response?.data?.message;
        toast.error(message ?? "Có lỗi xảy ra !");
      }
}

export const addProjectRecentlyAsync = async (taskId:number,projectId:number,task:any,updateValueTask:any) => {
    try {
        const data = await addProject(taskId,projectId)
        updateValueTask(taskId, { resourcesTask:[...task.resourcesTask,...[{projectId,sectionId: data?.data?.sectionId}]] });
        toast.success("Cập nhật thành công");
    }catch (err:any) {
        const message = err?.response?.data?.message;
        toast.error(message ?? "Có lỗi xảy ra !");
    }
}

export const updateProjectTaskDetailRecentlyAsync = async (values:any,updateValueTask:any,task:any) => {
    try {
        await updateSectionTaskDetailRecently(values)
        const data = task?.resourcesTask?.map((item:any) => {
            return {
                ...item,
                sectionId:values?.newSectionId
            }
        })
        updateValueTask(values?.taskId, {resourcesTask: data});
        toast.success("Cập nhật thành công");
    }catch (err:any) {
        const message = err?.response?.data?.message;
        toast.error(message ?? "Có lỗi xảy ra !");
    }
}

export const removeProjectTaskDetailRecentlyAsync = async (values:any,updateValueTask:any,task:any) => {
    try {
        await removeSectionTaskDetailRecently(values)
        const newData = task?.resourcesTask?.filter((item:any) => item?.sectionId !== values.sectionId)
        updateValueTask(values?.taskId, {resourcesTask: newData});
        toast.success("Cập nhật thành công");
    }catch (err:any) {
        const message = err?.response?.data?.message;
        toast.error(message ?? "Có lỗi xảy ra !");
    }
}

export const deleteTaskRecentlyAsync = async (taskId:number,tasks:any,removeTasks:any) => {
    try {
        await deleteTaskRecently(taskId)
        const dataNotTaskSelected = tasks?.filter((item:any) => item.taskId !== taskId)
        removeTasks(dataNotTaskSelected)
        toast.success("Xoá thành công");
    }catch (err:any) {
        const message = err?.response?.data?.message;
        toast.error(message ?? "Có lỗi xảy ra !");
    }
}

export const toggleCompleteStatusAsync = async (task:any,upsertResourceDetailAsync:any,USER_ID:any,updateValueTask:any,setComments:any) => {
    if (task?.taskId) {
        try {
          await upsertResourceDetailAsync(task?.taskId, {
            completed: !task?.completed,
            type: !task?.completed ? "markcomplete" : "markuncomplete",
          });
  
          toast.success("Cập nhật thành công");
  
          const userId = localStorage.getItem(USER_ID);
          updateValueTask(task.taskId, { completed: !task?.completed });
          
  
          if (!task?.completed) {
            const newComment = {
              resourcesid: Math.random(),
              resources: {
                createdAt: new Date().toISOString(),
                createdby: userId,
                name: `{{${userId}}} completed this task.`,
                resourcetype: "log",
              },
            };
  
            if (setComments) {
              setComments((prev:any) => [...prev, newComment]);
            }
          } else if (task?.completed) {
            const newComment = {
              resourcesid: Math.random(),
              resources: {
                createdAt: new Date().toISOString(),
                createdby: userId,
                name: `{{${userId}}} uncomplete this task.`,
                resourcetype: "log",
              },
            };
  
            if (setComments) {
              setComments((prev:any) => [...prev, newComment]);
            }
          }
        } catch (error: any) {
          const message = error?.response?.data?.message;
          toast.error(message ?? "Có lỗi xảy ra !");
        }
      }
}