import { useCallback, useEffect, useMemo } from "react";
import useSWRImmutable from "swr/immutable";
import { getDataRecently, getProjectListRecently, getSectionTaskDetailRecently } from "../api";
import { useRecentlyState } from "../state";

import useSWR from "swr";
import { useProjectRecentlyState } from "../state/project-list";
import {  extractValuesFromURL } from "pages/ProposeTasks/services";

export const getValueFromUrl = (url:string) => {
    const urlRegex = /^https?:\/\/[^\/]+\/([^\/]+)/;
    
    const match = url.match(urlRegex);
    
    return match ? match[1] : null;
}

export const getProjectIdByUrl = (url:string, key:string) => {
    const regex = new RegExp(`${key}/(\\d+)`);
    const match = url.match(regex);
  
    return match ? match[1] : null;
  }

export const useLocationParams = () => {
    let paramId:any

    const url = window.location.href;

    if(getValueFromUrl(url) == 'mytask') {
        paramId = getProjectIdByUrl(url, 'mytask/show');
    }else if (getValueFromUrl(url) == 'task') {
        const {projectId}:any = extractValuesFromURL(url) ?? {}
        paramId = projectId;
    }else if (getValueFromUrl(url) == 'projects') {
        paramId = getProjectIdByUrl(url, 'projects/show');
    }
    
    return {
        paramId,
        paramResource: getValueFromUrl(url),
    }
}

export const useRecentlyDataAsync = (page:number,setHasmore:any,setPage:any) => {
    const { appendTasks, resetTasks }:any = useRecentlyState()
    const { paramId:projectId } = useLocationParams();
    const fetcher = useCallback(async ([projectId,page]: any) => {
        try {
            const res = await getDataRecently(projectId,'all', page)
            appendTasks(res.data) 
            if (page == res.data[0].pageCount) {
                setHasmore(false)
            }
            return res.data;
        } catch (error) {
            throw error;
          }
    }, []);

    const { isLoading, error } = useSWR([projectId,page], fetcher,{revalidateOnMount:true,revalidateOnFocus:false});

    useEffect(() => {
        setPage(1)
        setHasmore(true)
        return () => resetTasks()
    },[projectId])

    return { isLoading, error }
}

export const useProjectListAsync = () => {
    const {addProject}:any = useProjectRecentlyState()
    const { data, isLoading, error }:any = useSWRImmutable(`list-projects-member-id`, getProjectListRecently);
    useEffect(() => {
        if(data) {
            const projectsOption = Object.values(data?.data?.data).map((item:any) => {
                return {
                    value: item?.resources?.id as number,
                    label: item?.resources?.name as string}
            })
            addProject(projectsOption)
        }
    },[data])

    return { isLoading, error}
}

export const useTaskRecentSelect = (taskId:number) => {
    const {tasks} = useRecentlyState()
    
    const task = useMemo(() => {
        const taskSelect = tasks?.filter((item:any) => item.taskId == taskId)
        return taskSelect[0]
      }, [tasks,taskId])
    return task
}

export const useSectionTaskDetailRecentAsync = (item:any) => {
    const fetcher = useCallback(async ([projectId]: any) => {
        try {
            const res = projectId && await getSectionTaskDetailRecently(projectId)
            return res?.data?.data;
        } catch (error) {
            throw error;
          }
    }, []);

    const { data,isLoading, error } = useSWRImmutable([item.projectId], fetcher)
    return { data, isLoading, error }
}

export const useDataNewestRecently = (changeAssigneeSocket:any) => {
    const {  appendTaskAssignee,updateValueTask } = useRecentlyState()
    const fetcher = useCallback(async ([changeAssigneeSocket]: any) => {
        try {
            if(changeAssigneeSocket.type == 'add') {
                const res = changeAssigneeSocket && await getDataRecently(changeAssigneeSocket?.resourceRelation2?.resourcesid,'newest')
                return res.data
            }
            
        } catch (error) {
            throw error;
          }
    },[changeAssigneeSocket]);

    const { data,isLoading, error } = useSWRImmutable([changeAssigneeSocket], fetcher)

    useEffect(() => {
        if(changeAssigneeSocket.type == 'add') {
            if(data && data.length> 0) {
                appendTaskAssignee(data[0])            
                updateValueTask(data[0]?.taskId, {...data[0],hide:false })
            }
            
        }else {       
            updateValueTask(changeAssigneeSocket?.resourceId, { assigneeId: null,assigneeName:null,hide:true });
        }
    
    },[changeAssigneeSocket,data])

    return { data, isLoading, error }
}
