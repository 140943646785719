import { BaseRecord, CreateResponse, GetListResponse, HttpError, UseCreateReturnType } from "@pankod/refine-core";
import axios from "axios";
import moment from "moment";
import React from "react";
import { HasMoreCmt } from "../mtDetails-Content/DetailsContent";

export const getFileWithCommentId = (commentAllRes: GetListResponse<BaseRecord>, data: BaseRecord) => {
  const commentWithFiles = commentAllRes?.data.map((cmt) => {
    let commentWithFile: BaseRecord = { ...cmt, file: [], fileId: [] };

    data.data.forEach((file: BaseRecord) => {
      if (cmt.resourcesid === file.resourcesparentid) {
        commentWithFile.file = [...commentWithFile.file, { fileName: file.resources?.name, fileId: file.resourcesid }];
        commentWithFile.fileId = [...commentWithFile.fileId, file.resourcesid]
      }
    });

    return commentWithFile;
  });

  return commentWithFiles
}

interface getCommentWithFileParam {
  commentDataRes: GetListResponse<BaseRecord>,
  createFunc: UseCreateReturnType<BaseRecord, HttpError, {}>,
  setComments: React.Dispatch<React.SetStateAction<BaseRecord[]>>
}

export const getCommentWithFile = ({ commentDataRes, createFunc, setComments }: getCommentWithFileParam) => {
  const CommentIds = commentDataRes?.data.map((cmt) => cmt.resourcesid);
  createFunc.mutate(
    {
      resource: "resourcesrelation/getcommentfile",
      values: {
        commentIds: CommentIds,
      },
      successNotification: false,
      errorNotification: false,
    },
    {
      onSuccess(data) {
        // Lặp qua các cmt trả về so sánh với file nếu trùng id thì thêm file đó vào cmt để hiển thị
        const commentWithFiles = commentDataRes && getFileWithCommentId(commentDataRes, data)
        commentWithFiles && setComments(commentWithFiles.reverse())
      },
    }
  );
}

interface setResetDataWhenChangeTaskDeTailsParams {
  setPage: React.Dispatch<React.SetStateAction<number>>
  setgetRqAllComment: React.Dispatch<React.SetStateAction<boolean>>
  setIsDetailsLoading?: React.Dispatch<React.SetStateAction<boolean>>
  setHasmore: React.Dispatch<React.SetStateAction<boolean>>
  setGetRequest: React.Dispatch<React.SetStateAction<boolean>>
  currentTaskDetailId?: string | number | null | undefined
  setHasmoreCmt: React.Dispatch<React.SetStateAction<HasMoreCmt>>
}

export const ResetDataWhenChangeTaskDeTails = ({ setPage, setgetRqAllComment, setIsDetailsLoading, setHasmore, setHasmoreCmt }: setResetDataWhenChangeTaskDeTailsParams) => {
  setPage(1);
  setHasmore(false);
  setHasmoreCmt({ isHasMore: false, unLoadCmt: null });
  setIsDetailsLoading && setIsDetailsLoading(true)
  setgetRqAllComment(false)
}

interface ResetDataWhenOpenAddNewTaskParams {
  setSubTasks: React.Dispatch<React.SetStateAction<BaseRecord[]>>
  setDescription: React.Dispatch<React.SetStateAction<undefined>>
  setComments?: React.Dispatch<React.SetStateAction<BaseRecord[]>>
  setFileList: React.Dispatch<React.SetStateAction<BaseRecord[]>>
  setIsDetailsLoading?: React.Dispatch<React.SetStateAction<boolean>>
  setProjectAndSection?: React.Dispatch<React.SetStateAction<BaseRecord[]>>
  setResourceDetails: React.Dispatch<React.SetStateAction<BaseRecord[]>>
}

export const ResetDataWhenOpenAddNewTask = (
  {
    setSubTasks,
    setDescription,
    setComments,
    setFileList,
    setIsDetailsLoading,
    setProjectAndSection,
    setResourceDetails,
  }: ResetDataWhenOpenAddNewTaskParams) => {
  setSubTasks([]);
  setDescription(undefined);
  setComments && setComments([]);
  setFileList([])
  setIsDetailsLoading && setIsDetailsLoading(false)
  setProjectAndSection && setProjectAndSection([])
  setResourceDetails([])
}

export const getTimeFromNow = (ISOTime: string) => {
  return ISOTime ? moment.utc(ISOTime).local().startOf("seconds").fromNow() : "--"
}

export const getDateTimeFormat = (ISOTime: string) => {
  return moment(ISOTime).format("dddd, MMMM Do YYYY, h:mm:ss a")
}

interface checkPermissionProps {
  id: number
  setGetRequest: React.Dispatch<React.SetStateAction<boolean>>
  setIsLock: React.Dispatch<React.SetStateAction<boolean>>
  setErr: React.Dispatch<React.SetStateAction<any>>
}
export const checkPermission = async ({ id, setGetRequest, setIsLock, setErr }: checkPermissionProps) => {
  await axios.post('resourcesmembers/checkreadpermission', { resourceId: id })
    .then(() => {
      setGetRequest(true)
    })
    .catch((err) => {

      if (err?.response?.data.statusCode === 403) {
        setIsLock(true)
      } 

      if(err?.response?.data.statusCode === 404) {
        setErr(err?.response?.data)
      }

    })
}