import { useEffect } from "react";

export const useTextboxAutoResize = (textAreaRef: React.RefObject<HTMLTextAreaElement>, taskName?: string) => {
    const resizeTextArea = () => {
        textAreaRef?.current && (textAreaRef.current.style.height = "auto");
        textAreaRef?.current &&
            (textAreaRef.current.style.height =
                textAreaRef.current?.scrollHeight + "px");
    };
    useEffect(resizeTextArea, [taskName]);
}

export const useDetectScroll = (detailsRef: any, textAreaRef: React.RefObject<HTMLTextAreaElement>,setChangeTitle: any) => {
    const handleScroll = (e: Event) => {
        const scToTopHeight = detailsRef && detailsRef.current?.scrollTop;
        const textAreaPosition =
          textAreaRef?.current &&
          textAreaRef.current?.offsetTop + textAreaRef.current?.scrollHeight;
    
        if (scToTopHeight && textAreaPosition) {
          scToTopHeight + 56 >= textAreaPosition
            ? setChangeTitle && setChangeTitle(true)
            : setChangeTitle && setChangeTitle(false);
        }
      };

      useEffect(() => {
        detailsRef && detailsRef.current?.addEventListener("scroll", handleScroll);
        return () =>
          detailsRef &&
          detailsRef.current?.removeEventListener("scroll", handleScroll);
      }, []);
}