/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-self-compare */

import {
  BellOutlined,
  CheckCircleOutlined,
  StarOutlined,
  TeamOutlined,

} from "@ant-design/icons";
import { BiSquareRounded } from "react-icons/bi";
import { TeamContext } from "context/contextapi";
import { DEFAULT_WORKSPACE, TOKEN_KEY_LOGIN, USER_ID } from "authProvider";
import { Menu, MenuProps, } from "@pankod/refine-antd";
import {
  BaseRecord,
  useList,
  useNavigation,
} from "@pankod/refine-core";
import React, { memo, useEffect, useState, } from "react";
import "react-toastify/dist/ReactToastify.css";
import "./slidebar.scss";
import { Tooltip } from "@pankod/refine-antd";


import { SocketTeamContext } from "socket/socketcontext";
import { Socket } from "socket.io-client";

import { NewInboxSocketTeamContext } from "socket/newinboxcontext";
import { useAppContext } from "App.context/App.context";
import { ALLTASK, INCOMPLETE } from "components/Mytask/Constant";
import {BsClipboardData} from 'react-icons/bs'


interface Isidebarcontex {
  setdataTeam?: any;
  favorites?: any;
  setfavorites?: any;
  setstart?: any;
  start?: any;
  setIsUserTaskList?: any;
  isUserTaskList?: any;

  type?: any;

  socket?:Socket;
  isNewInbox:boolean;
  setIsNewInbox:any;
  socketCmtId:any;
  displayStatus?:any

}
function SidebarContext() {
  const values = TeamContext();
  const valuessetstart = TeamContext();
  const valuesstart = TeamContext();

  const value = NewInboxSocketTeamContext();
  const valueSocket = SocketTeamContext();
  const valuesAppConText = useAppContext()


  return (
    <Sidebarteam
      setdataTeam={values?.setdataTeam}
      setstart={valuessetstart?.setstart}
      start={valuesstart?.start}
      setIsUserTaskList={valuesstart?.setIsUserTaskList}
      isUserTaskList={valuesstart?.isUserTaskList}
      socket={valueSocket?.socket}
      isNewInbox={value?.isNewInbox}
      setIsNewInbox={value?.setIsNewInbox}
      socketCmtId={value?.socketCmtId}
      displayStatus={valuesAppConText?.taskDisplayStatus}

    />
  );
}
const Sidebarteam: React.FC<Isidebarcontex> = memo(

  ({ setdataTeam, setstart, setIsUserTaskList, socket , isNewInbox, setIsNewInbox, socketCmtId,displayStatus}) => {

    const { show, push } = useNavigation();
    // phan drag-drop
    const [collapsed, setCollapsed] = useState<boolean>(false);
    const [fruitItems, setFruitItems] = useState<BaseRecord[]>([]);
    const [favorites, setfavorites] = useState<BaseRecord[]>([]);
    const dragItem = React.useRef<any>(null);
    const dragOverItem = React.useRef<any>(null);
    const idUserTaskList = localStorage.getItem(TOKEN_KEY_LOGIN);
    const Idfavorites = localStorage.getItem(USER_ID);
    const [expand, setExpand] = useState(false);
    const [expandProject, setexpandProject] = useState(false);
    const [expandFavorites, setexpandFavorites] = useState(false);
    const [ListProjectShowmore, setListProjectShowmore] = useState([])
    const [isNewInboxSideBar, setIsNewInboxSideBar] = useState<boolean>(false);
    const AppContext = useAppContext()
    const handleSort = () => {
      //duplicate items
      let _fruitItems = [...fruitItems];
      //remove and save the dragged item content
      const draggedItemContent = _fruitItems.splice(dragItem.current, 1)[0];
      //switch the position
      _fruitItems.splice(dragOverItem.current, 0, draggedItemContent);
      //reset the position ref
      dragItem.current = null;
      dragOverItem.current = null;
      //update the actual array
      setFruitItems(_fruitItems);
    };
    

    useEffect(()=>{
      setIsNewInboxSideBar(isNewInbox)

    },[socketCmtId])


    const default_workspace = localStorage.getItem(DEFAULT_WORKSPACE);

    const dataTeam = useList({
      resource: `resources/checkpermissionteam/${default_workspace}`,
      // resource: "resourcesrelation",
      config: {
        filters: [
          {
            field: "resources.resourcetype", // dieu kien where.
            operator: "eq",
            value: "team", // id trong where
          },
        ],
      },
      metaData: {
        config: {
          fields: ["resourcesid", "resourcesparentid"],
          joins: [
            {
              field: "resources",
              select: [
                "name",
                // "isCheck"
              ],
            },
          ],
        },
      },
    }).data;

    useEffect(() => {
      dataTeam?.data && setFruitItems(dataTeam.data);
    }, [dataTeam]);

    const handleclickicon = (item: any) => {
      setdataTeam(item);
      if (item.id === item.id) {
        show("team", item.team_id);
      }
      localStorage.setItem("parent_id", JSON.stringify(item?.team_id))
    };
    const handleclickiconproject = (item: any) => {
      show("projects", item.project_id);
      AppContext?.setTaskDisplayStatus(ALLTASK)

    };

    const dataFavorties = useList({
      resource: "favorties",
      config: {
        filters: [
          {
            field: "userid", // dieu kien where.
            operator: "eq",
            value: Idfavorites, // id trong where
          },
        ],
        pagination: { current: 1, pageSize: 1500 },
      },
      metaData: {
        config: {
          fields: ["resourcesid", "suDung"],
          joins: [
            {
              field: "resources",
              select: ["name", "resourcetype"],
            },
          ],
        },
      },
    }).data;


    useEffect(() => {
      dataFavorties?.data && setfavorites(dataFavorties.data);
    }, [dataFavorties]);

    useEffect(() => {
      dataFavorties?.data && setstart(dataFavorties.data);
    }, [dataFavorties]);

    useEffect(() => {
      setIsUserTaskList(idUserTaskList);
    }, [idUserTaskList]);

    const handleclickfavorties = (item: any) => {
      if (item.resources.resourcetype === "team") {
      localStorage.setItem("parent_id", JSON.stringify(item.resources?.id))
        show("team", item.resources?.id);
      } else if (item.resources.resourcetype === "project") {
        show("projects", item.resources?.id);
      }
    };
    const onClickShowMore = () => {
      setExpand(true);
    };

    const onClickShowLess = () => {
      setExpand(false);
    };

    const onClickProject = (teamId: number) => {

      setFruitItems(pre => {
        for (const iterator of pre) {
          if (iterator.team_id === teamId) {
            iterator.expand = !iterator.expand;
          }
        }
        return [...pre]
      })

    };
    const onClickShowMoreFavorites = () => {
      setexpandFavorites(true);

    };

    const onClickShowLessFavorites = () => {
      setexpandFavorites(false);

    };
    const sorted = favorites.sort((a: any, b: any) => {
      return a?.resources?.name.localeCompare(b?.resources?.name)
     })

    function childrenrender(itemTeam: any, Projectchildren: any) {
      if (itemTeam.projects.length > 2) {
        return [...Projectchildren,
        {
          className: "showMore-Less_Menu",
          label: (itemTeam.expand ? ('Show less') : "Show more"),
          key: `${itemTeam.team_id}project`,
          onClick: () => {
            onClickProject(itemTeam.team_id)
          },
        }
        ]
      }
      return [...Projectchildren]
    }

    function renderdataFavorites() {
      return sorted.map((item: any, index: any) => {
        if (index > 4 && !expandFavorites) {
          return;
        }
        return {
          label: item?.resources?.name,
          key: `${item?.resources?.id}-favorites`,
          icon: <StarOutlined style={{ marginRight: "5px", fontSize: "12px" }} />,
          onClick: () => {
            handleclickfavorties(item)
          },
        }
      })
    }

    function renderInbox() {
      return (
        <div className="inbox">
        <BellOutlined style={{ marginRight: "4px" }} />
        Inbox
        {isNewInboxSideBar ? <div style={{marginLeft: "10px", backgroundColor: '#ffff00',  borderRadius:'50%', width:'8px', height:'8px'}}></div> : null}
        </div>
      )
    }
    const handleclickMytaskShow = () => {
      show("mytask", `${idUserTaskList}`);
      AppContext?.setTaskDisplayStatus(INCOMPLETE)
    }

    const handleclickPropose = () => {
      show("propose-task", '730919');
     
    }
    const handleclickInbox = () => {
      push("Inbox")
    }
    function renderdataMenu() {
      const dataMenus = fruitItems.map((itemTeam: any, index: any) => {
        const Projectchildren = itemTeam.projects.map((itemProject: any, index: any) => {
          if (index > 1 && !itemTeam.expand) {
            return;
          }
          return {
            label: (
              <Tooltip title={itemProject.project_name} placement="right">
                {itemProject.project_name}
              </Tooltip>
            ),
            key: `${itemProject.project_id}-project`,
            icon: <BiSquareRounded style={{ marginRight: "5px", fontSize: "10px" }} />,
            onClick: () => {
              handleclickiconproject(itemProject)
            },
          }
        })
        // if (index > 8 && !expand) {
        //   return;
        // }
        return {
          label: (<div
            draggable
            onDragStart={(e) => (dragItem.current = index)}
            onDragEnter={(e) => (dragOverItem.current = index)}
            onDragEnd={handleSort}
          >{itemTeam.team_name}</div>),
          key: itemTeam.team_id,
          onTitleClick: () => {
            handleclickicon(itemTeam)
          },
          icon: <TeamOutlined style={{ marginRight: "5px" }} />,
          children: childrenrender(itemTeam, Projectchildren)
        }
      })
      return [
        {
          label: "My Tasks",
          key: "1",
          icon: <CheckCircleOutlined style={{ marginRight: "5px" }} />,
          onClick: () => {
            handleclickMytaskShow()
          }
        },
        {
          // label: "Inbox",
          key: "2",
          icon: renderInbox(),
          // icon: <BellOutlined style={{ marginRight: "5px" }} />,
          onClick: () => {
            handleclickInbox()
          }
        },
        {
          label: "Quản lý đề xuất",
          key: "3",
          icon: <BsClipboardData style={{ marginRight: "5px" }}/>,
          onClick: () => {
            handleclickPropose()
          }
        },
        {
          label: "Favorites",
          key: "sub1",
          className: "menuItemFavorites",
          icon: <StarOutlined style={{ marginRight: "5px" }} />,
          children: [...renderdataFavorites(), {
            className: "showMore-Less_Menu",
            label: sorted.length > 5 ? (!expandFavorites ? ("Show more") : "Show less") : "",
            key: "sub-3",
            onClick: expandFavorites == false ? onClickShowMoreFavorites : onClickShowLessFavorites,

          }],
        },
        {
          label: "Teams",
          key: "sub2",
          icon: <TeamOutlined style={{ marginRight: "5px" }} />,
          className: "menuItemSidebar",
          children: [...dataMenus,
          // {
          //   className: "showMore-Less_Menu",
          //   label: fruitItems.length > 4 ? (!expand ? ("Show more") : "Show less") : "",
          //   key: "sub-2",
          //   onClick: expand == false ? onClickShowMore : onClickShowLess,
          // }
          ]
        },
      ];
    }
    const [current, setCurrent] = useState('1');
    const onClick: MenuProps['onClick'] = (e: any) => {
      setCurrent(e.key);
    };
    return (
      <div>
        <Menu
          onClick={onClick}
          style={{ width: 200 }}
          defaultOpenKeys={['sub1']}
          selectedKeys={[current]}
          mode="inline"
          items={renderdataMenu()}
        >
        </Menu>
      </div>
    );
  }
);

export default SidebarContext;