import { MentionData } from "@draft-js-plugins/mention"
import { BaseRecord, HttpError, UseCreateReturnType } from "@pankod/refine-core"
import { UseDeleteReturnType } from "@pankod/refine-core/dist/hooks/data/useDelete";
import { UseUpdateReturnType } from "@pankod/refine-core/dist/hooks/data/useUpdate"
import { USER_ID } from "authProvider";
import { ALLTASK, COMPLETED, INCOMPLETE, RECENTLYTASK } from "components/Mytask/Constant";
import { getUserIdentity } from "components/Mytask/mtDetails/DetailsFooter/FooterTextEdit/untils";
import useMyTaskStore from "pages/myTask/state/store";
import {  Task } from '../../../Type/MyTaskType';

interface handleProjectsChangePrams {
    resourceTaskChange?: BaseRecord
    setTasks?: React.Dispatch<React.SetStateAction<BaseRecord[]>> 
    setResourceTaskChange?:React.Dispatch<React.SetStateAction<BaseRecord>>
    resources: {
        id?: number | undefined;
        name: string;
    }
}

export const handleProjectsChange = ({resourceTaskChange, setTasks,setResourceTaskChange, resources}: handleProjectsChangePrams) => {
    
    if(resourceTaskChange?.type === 'add' ) {

        setTasks && setTasks(prev => {
          const oldTask = [...prev]
          const newTasksWithNewData = oldTask.map(task => {
            if(task.resources.id !== resources?.id) {
              return task
            }

            let newTask 
            if(task?.resourceTask) {
              newTask = {...task, resourceTask: [ ...task?.resourceTask,{id: null, resourcesId: resourceTaskChange?.projectId, sectionId: resourceTaskChange?.sectionId}] }
            } else {
              newTask = {...task, resourceTask: [ {id: null, resourcesId: resourceTaskChange?.projectId, sectionId: resourceTaskChange?.sectionId}] }
            }
                
            return newTask
          })
          
          setResourceTaskChange && setResourceTaskChange({})
          return newTasksWithNewData
          
        })

      } else if(resourceTaskChange?.type === 'remove') {
        setTasks && setTasks(prev => {
          const oldTask = [...prev]
          const newTasksWithNewData = oldTask.map(task => {
            if(task.resources.id !== resources?.id) {
              return task
            }

            let oldResourceTask = task?.resourceTask
            let newResourceTask = oldResourceTask?.filter((item: any) => item.sectionId !== resourceTaskChange?.sectionId)
            const newTask = {...task, resourceTask: newResourceTask}
                
            return newTask
          })
          
          setResourceTaskChange && setResourceTaskChange({})
          return newTasksWithNewData
          
        })
        
      }
}




interface handleProjectsChangePrams {
    resourceDetailsChange?: BaseRecord
    setTasks?: React.Dispatch<React.SetStateAction<BaseRecord[]>> 
    setResourceDetailsChange?:React.Dispatch<React.SetStateAction<BaseRecord>>
    resources: {
        id?: number | undefined;
        name: string;
    }
    disPlayStatus?: string
    pathName?: string
    data?: BaseRecord,
    ssId?: number
    updateTaskState?: (sectionId: number, newTasks: BaseRecord[]) => void
}

export const handleResourceDetailsChange = ({resourceDetailsChange, setTasks, resources, setResourceDetailsChange, disPlayStatus,pathName, data, ssId, updateTaskState}: handleProjectsChangePrams) => {
    if(resourceDetailsChange?.assignee 
        || resourceDetailsChange?.dueOn
        || resourceDetailsChange?.dueOn === null 
        || typeof resourceDetailsChange?.completed == 'boolean') {

          
          const oldTasks = data && data[ssId as number]?.tasks
          const newTasksWithNewData = oldTasks.map((task: BaseRecord) => {
            if(task.resources?.id !== resources?.id) {
              return task
            }

            let newTask 
            if(resourceDetailsChange?.assignee) {

              if(!pathName?.includes('/mytask')) {
                newTask = {...task, resourceDetails: {...task.resourceDetails, assignee: resourceDetailsChange.assignee}}
                
              } else {
                const userId = localStorage.getItem(USER_ID);

                if(userId == resourceDetailsChange?.assignee) {
                  newTask = {...task, resourceDetails: {...task.resourceDetails, assignee: resourceDetailsChange.assignee}}
                } else {
                  newTask = {...task, resourceDetails: {...task.resourceDetails, assignee: resourceDetailsChange.assignee}}
                }
                

              }
              
            } else if(resourceDetailsChange?.dueOn || resourceDetailsChange?.dueOn === null ) {
              newTask = {...task, resourceDetails: {...task.resourceDetails, dueOn: resourceDetailsChange.dueOn}}
            }
            else {


              switch (disPlayStatus) {
                case ALLTASK:
                  //Với trường hợp lọc là alltask thì mỗi lần thay đổi trạng thái cmplete chỉ cần set lại biến complete
                  newTask = {...task, resourceDetails: {...task.resourceDetails, completed: resourceDetailsChange?.completed}}
                  break;
                case INCOMPLETE:
                  // Với trường hợp lọc là incomplete thì mỗi lần thay đổi sẽ kiểm tra nếu trạng thái thay đổi là hoàn thành sẽ ẩn task đó đi => hide = true
                  //Nếu trạng thái thay đổi là chưa hoàn thành thì hiển thị lại => hide = false (Trường hợp hiển thị lại này chỉ có khi mở bảng task detail)
                  if(!resourceDetailsChange?.completed) {
                    newTask = {...task, resourceDetails: {...task.resourceDetails, completed: resourceDetailsChange?.completed}, hide: false}
                  } else {
                    newTask = {...task, resourceDetails: {...task.resourceDetails, completed: resourceDetailsChange?.completed}, hide: true}
                  }
              
                  break;
                case COMPLETED:
                  // Với trường hợp lọc là COMPLETED thì mỗi lần thay đổi sẽ kiểm tra nếu trạng thái thay đổi là chưa hoàn thành sẽ ẩn task đó đi => hide = true
                  //Nếu trạng thái thay đổi là  hoàn thành thì hiển thị lại => hide = false (Trường hợp hiển thị lại này chỉ có khi mở bảng task detail)
                  if(!resourceDetailsChange?.completed) {
                    newTask = {...task, resourceDetails: {...task.resourceDetails, completed: resourceDetailsChange?.completed}, hide: true}
                  } else {
                    newTask = {...task, resourceDetails: {...task.resourceDetails, completed: resourceDetailsChange?.completed},  hide: false}
                  }

                  break;

                  case RECENTLYTASK:
                    // Với trường hợp lọc là COMPLETED thì mỗi lần thay đổi sẽ kiểm tra nếu trạng thái thay đổi là chưa hoàn thành sẽ ẩn task đó đi => hide = true
                    //Nếu trạng thái thay đổi là  hoàn thành thì hiển thị lại => hide = false (Trường hợp hiển thị lại này chỉ có khi mở bảng task detail)
                    if(!resourceDetailsChange?.completed) {
                      newTask = {...task, resourceDetails: {...task.resourceDetails, completed: resourceDetailsChange?.completed}, hide: true}
                    } else {
                      newTask = {...task, resourceDetails: {...task.resourceDetails, completed: resourceDetailsChange?.completed},  hide: false}
                    }
  
                    break;
              
                default:
                  newTask = task

                  break;
              }
            }
         
            return newTask
          })
          

          
          setResourceDetailsChange && setResourceDetailsChange({})
          updateTaskState && ssId && updateTaskState(ssId, newTasksWithNewData)
           
      }
}


interface mutateAddAsiggneParams {
    createFunc:  UseCreateReturnType<BaseRecord, HttpError, {}>
    resources: {
        id?: number | undefined;
        name: string;
    }
     setAssignee: React.Dispatch<React.SetStateAction<MentionData | null>>
     assigneeData: MentionData | null
     setAssigneeData:React.Dispatch<React.SetStateAction<MentionData | null>>
}

export const mutateAddAsiggne = ({createFunc,assigneeData,setAssigneeData, resources, setAssignee}:mutateAddAsiggneParams) => {
       if(assigneeData) {
        const assigneeValues = {
            memberId: assigneeData.id,
            resourceId: resources.id
        }
        createFunc.mutate(
            {
              resource: `resources/assigntask`,
              values: assigneeValues,
              // invalidates:["all"]
            },
            
      
            {
              onSuccess: (data, variables, context) => {
                setAssignee(assigneeData)
                setAssigneeData(null)
              },
              onError: (error, variables, context) => {
              },
            }
          );
        

       }
}

interface mutateRemoveAsiggneParams {
    createFunc:  UseCreateReturnType<BaseRecord, HttpError, {}>
    resources: {
        id?: number | undefined;
        name?: string;

    }
     setAssignee?: React.Dispatch<React.SetStateAction<MentionData | null>>
     handler?: () => void
}
export const mutateRemoveAsiggne = ({createFunc, resources, setAssignee, handler}:mutateRemoveAsiggneParams) => {
    createFunc.mutate(
        {
            resource: `resources/removeassign`,
            values: {resourceId:  resources.id},
            successNotification: {
              message: "Thành công !",
              type: "success" 
            }
          },
    
          {
            onSuccess: (data, variables, context) => {
              setAssignee && setAssignee(null)
              handler && handler()
            },
            onError: (error, variables, context) => {
            },
          }
      );
}

interface updateTaskParams {
    oldValue: string | null
    setOldValue: React.Dispatch<React.SetStateAction<string | null>>
     ipvalue: string
    setIpValue: React.Dispatch<React.SetStateAction<string>>
    resources: {
        id?: number | undefined;
        name: string;
    } 
     UpdateFunc: UseUpdateReturnType<Task, HttpError, {}>
}

export const updateTask =async ({oldValue, setOldValue, ipvalue,setIpValue, resources, UpdateFunc}: updateTaskParams) => {
        
    //So sánh giá trị cũ và mới nếu khác nhau thì mới update còn không thì return
    if(oldValue === ipvalue) return

    const updateValues:Task = {
      name: ipvalue
    }
    const taskId = resources.id as number
    
    UpdateFunc.mutate({
      resource: 'resources',
      id: taskId,
      values: updateValues,
      successNotification: {
        message: "Cập nhật Task thành công !",
        type: "success" 
      }
    },
    {
      onError: (error, variables, context) =>{
        oldValue && setIpValue(oldValue)
      },
      onSuccess: (data, variables, context) =>{
      },
    }
    )
    setOldValue(null)
  }


  interface handleTaskDeleteParams {
    deleteTask: any
    ssId: number
    resources: {
        id?: number | undefined;
        name: string;
    }
    deleteFunc: UseDeleteReturnType<BaseRecord, HttpError, {}>
}
  export  const handleDeleteTask = ({resources, deleteFunc, deleteTask, ssId}: handleTaskDeleteParams) => {
    if(resources?.id) {        
      deleteFunc.mutate({
        resource: 'resources',
        id: resources?.id ,
        successNotification: {
          message: "Xóa thành công !",
          type: "success" 
        }
      }, {
        onSuccess(data, variables, context) {
          deleteTask(resources?.id, ssId)
        },
      })
    }
  }