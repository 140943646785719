import { MentionData } from "@draft-js-plugins/mention";
import { BaseRecord, useCreate, useRouterContext } from "@pankod/refine-core";
import axios from 'axios';
import { TeamContext } from "context/contextapi";
import { CheckBox } from 'devextreme-react/check-box';
import {
  Column,
  DataGrid,
  Editing,
  FilterRow,
  Scrolling,
  Selection
} from 'devextreme-react/data-grid';
import { EditorState } from "draft-js";
import React, { useEffect, useReducer, useRef, useState } from "react";
import { useClickAway } from "react-use";
import './Inputmension.css';
interface PropsIDtask{
  taskId:any
}
interface propsAction{
  type:string
  payload:any
}

function reducer(state:any, action: propsAction) {

  const hashMap = new Map();
  switch (action.type) {
    case 'MULTIPLE':
      
      [state,action.payload]?.forEach(arr=>{
        arr.forEach((obj:any) => {
          if(hashMap.has(obj?.key)){
            Object.assign(hashMap.get(obj?.key), obj)
          }else{
            hashMap.set(obj?.key,obj)
          }
        });
      });
      return Array.from(hashMap.values())
   case 'DISCARD':
    return []
    default:
      return state;
  }
}

const Inputmension: React.FC = () => {
  const [mentionData, setMentionData] = useState<MentionData | null>(null)
  const datadeletecheckbox=TeamContext()
  const dataTeams=TeamContext()
  const { mutate } = useCreate();
  const [modal, setmodal] = useState(false);
  const editor = EditorState.createEmpty()
  const getParam = useRouterContext()
  const getParamId:any = getParam.useParams()
  const clickRef: any = useRef("");
  const [ischeck,setischeck]=useState(false);
  const [ischeckmember,setischeckmember]=useState(false);
  const [stateRead,setStateRead]=useState(false);
  const [stateCreate,setStateCreate]=useState(false);
  const [stateUpdate,setStateUpdate]=useState(false);
  const [stateDelete,setStateDelete]=useState(false);
  const [dataProjectTeam,setdataProjectTeam]=useState<BaseRecord[]>([])
  const dataGrid = useRef<any|undefined>();
  const editingRef = useRef<any|undefined>();
  const [stateReducer, dispatch] = useReducer(reducer, []);
  const [focusedRowKey, setFocusedrowkey] = useState(null);
  const [rowIndex, setSelectedRowIndex] = useState(null);
  const onFocusedRowChanged = (e:any) => {
    setFocusedrowkey(e.component.option('focusedRowKey'));
    setSelectedRowIndex(e.row.rowIndex);
  };

  const onSelectChange =async ( keyType: string, value:boolean)=>{

    const selectedData = dataGrid.current.instance.getSelectedRowsData()
    
    for(const item of selectedData){
      item.key = item?.id
      item.type=  "update";
      item.data=  {...item.data,[keyType]: value};
      const databykey =await  dataGrid.current.instance.getDataByKeys([item?.id]);
      dataGrid.current.instance.cellValue(dataGrid.current.instance.getRowIndexByKey(item?.id),keyType,value);
    }
    dispatch({type: 'MULTIPLE', payload: selectedData})
  }
  let {id} = getParamId
    useEffect(()=>{
        if(mentionData){
            mutate({
                    resource: "resourcesmembers",
                    values: {
                        resourcesId:id,
                        memberId:mentionData?.id,
                    },
                  });
            }
                    EditorState.set(editor,{
                        allowUndo:false,
                    })
    },[mentionData])

    useEffect(() => {
      const fetchData = async ()=>{
        const response = await axios.get(`resourcesmembers/getDataMemberPhongBan/${id}`)
  
        
        response.data && setdataProjectTeam(response.data)
      }
      if(ischeck){
  
        fetchData()
      }
    },[id,ischeck])
  
    useEffect(()=>{
  
      if(!ischeck){
        setdataProjectTeam([])
      }
    },[ischeck])
  
    
    useClickAway(clickRef, () => {
      setmodal(false);
    });
  
    const handleclickpopup=(ischeck:any)=>{
      setischeck(!ischeck)
      setischeckmember(false)
  }

  const handleclickclose=(ischeckmember:any)=>{
      setischeckmember(!ischeckmember)
      setischeck(false)
  }

  const onChangeCreate=(e:any)=>{
  
    setStateCreate(!stateCreate)
    onSelectChange('isCreate',e.value)
  }
  const onChangeRead=(e:any)=>{
    setStateRead(!stateRead)
    onSelectChange('isRead',e.value)
  }
  const onChangeUpdate=(e:any)=>{
    setStateUpdate(!stateUpdate)
    onSelectChange('isUpdate',e.value)
  }
  
  const onChangeDelete=(e:any)=>{
    setStateDelete(!stateDelete)
    onSelectChange('isDelete',e.value)
  }

  const onChangesChange = React.useCallback((changes:any) => {
    dispatch({type: 'MULTIPLE', payload: changes})
    
  }, []);

  const onEditCanceling=(e: any)=>{
    dispatch({type: 'DISCARD', payload: []})
  }

  const saving =async (e:any)=>{
    dispatch({type: 'DISCARD', payload: []})
    if(e?.changes?.length >0){
      
      let dataUser = {
        resourcesId: id,
        data: e?.changes
      }
      
      
      fetchData(dataUser, e)
    }
    
    
  }

  const fetchData = async (dataUser: any, e:any)=>{
  
// console.log('dataUser', dataUser);

            
    mutate({
      resource: 'resourcesmembers/managePermissionMember',
      values: dataUser,
      successNotification: {
        message: "Cập nhật thành công !",
        type: "success" 
      },
    },
    {
      onError: (error, variables, context) =>{
        
      },
      onSuccess: (data, variables, context) =>{
          
      },
    }
  )
  
  }
  
    
  return(
    <div  >
            <div className="box">
                <a className="button" onClick={()=>handleclickpopup(ischeck)}>Manage members</a>

            </div>
            <div>
            <div  className={"overlay" + (ischeck ?  " targetmember" + (ischeckmember ? " display_none" : "") : "")}>
            <div className={"popup popup__overplay"}>
                    <div className='action_manage_member'>

                    <h2 className='action_item_title'>Thành Viên...</h2>
                    <div className='action_item_button'>
                    <CheckBox
                        value={stateCreate}
                        text="Thêm"
                        onValueChanged={onChangeCreate}
                        className='action_detail_button'
                    />
                    <CheckBox
                        value={stateRead}
                        text="Đọc"
                        onValueChanged={onChangeRead}
                        className='action_detail_button'
                    />
                    <CheckBox
                        value={stateUpdate}
                        text="Sửa"
                        onValueChanged={onChangeUpdate}
                        className='action_detail_button'
                    />
                    <CheckBox
                        value={stateDelete}
                        text="Xóa"
                        onValueChanged={onChangeDelete}
                        className='action_detail_button'
                    />
                    </div>
                    </div>

                    <div className="close" onClick={()=>handleclickclose(ischeckmember)}>×</div>
                    <React.Fragment>
                    <DataGrid
                    dataSource={dataProjectTeam}
                    showBorders={true}
                    hoverStateEnabled={true}
                    allowColumnReordering={true}
                    rowAlternationEnabled={true}
                    repaintChangesOnly={true}
                    height={400}
                    ref={dataGrid}
                    focusedRowKey={focusedRowKey}
                    focusedRowEnabled={true}
                    remoteOperations={true}
                    onFocusedRowChanged={(e:any)=>onFocusedRowChanged(e)}
                    keyExpr="id"        
                    onSaving={saving}
                    onEditCanceling={onEditCanceling}
                    >
                    <Editing
                    mode="batch"
                    allowUpdating={true}
                    ref={editingRef}
                    changes={stateReducer}
                    onChangesChange={onChangesChange}
                    />
                    <Scrolling mode="virtual" useNative={true}/>

                    <Selection
                    mode="multiple"
                    selectAllMode='allPages'
                    showCheckBoxesMode='onClick'

                    />
                    <Column
                    dataField="name"
                    caption="Tên nhân viên"
                    dataType="string"
                    width={200}
                    allowEditing={false}
                    />
                    <Column
                    dataField="dmPhongBan.dmPhongBan.tenPhongBan"
                    caption="Tên phòng ban"
                    dataType="string"
                    width={200}
                    allowEditing={false}
                    />
                    <Column
                    dataField="isCreate"
                    caption="Thêm"
                    dataType="boolean"
                    sortOrder={"desc"}
                    />
                    <Column
                    dataField="isRead" 
                    caption="Đọc" 
                    dataType="boolean"
                    sortOrder={"desc"}
                    />
                    <Column
                    dataField="isUpdate" 
                    caption="Sửa" 
                    dataType="boolean"
                    sortOrder={"desc"}
                    />
                    <Column
                    dataField="isDelete" 
                    caption="Xóa" 
                    dataType="boolean"
                    sortOrder={"desc"}
                    />
                    <FilterRow visible={true} />
                    </DataGrid>
                    </React.Fragment>
                </div>
                </div>  
            </div>       
    </div>
  )
}


export default  React.memo(Inputmension);
