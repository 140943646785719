import { BaseRecord } from "@pankod/refine-core"

export function hashMapFunc(MentionDatas: BaseRecord[], key: string){
  
  const dataObj = MentionDatas.reduce((total: BaseRecord, data: BaseRecord) => {
    
      if(data.id) {
        if(key === 'task-id') total[data?.resourceRelation2?.resourcesid] = data
        if(key === 'user-id') total[data.id] = data
      }
      return total
  },{})
  
  return dataObj
}
