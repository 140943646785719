import axios from "axios";
import { Button, Popup } from "devextreme-react";
import { useState } from "react";
import { toast } from "react-toastify";
type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  dataRow: any;
  dataGrid: any;
  formId: any;
  setRandomNumber: any;
};
export const CauHinhPopupDelete = ({
  open,
  setOpen,
  dataRow,
  dataGrid,
  formId,
  setRandomNumber
}: Props) => {
  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  const hideInfo = () => {
    setOpen(false);
    setRandomNumber(Math.random() * 9)
  };


  const deleteRow=()=>{
    
    const sendRequest = async () => {
      const results = await axios
        .delete(`/resources/delete-form-detail/${formId}/${dataRow?.id}`)
        .then((data) => {
          if (data) {
            hideInfo()
            toast.success("Xoá thành công!", {
              autoClose: 3000,
              style: {
                fontSize: "14px",
                position: "fixed",
                top: "0px",
                right: "0px",
              },
            });
          }
        })
        .catch((error) => {
          const message = { ...error };
          if (
            message &&
            message.response &&
            message.response.data &&
            message.response.data.message &&
            Array.isArray(message.response.data.message)
          ) {
            const lastErrorMessage = message.response.data.message.map(
              (item: any) => {
                return item.replace("type.", "");
              }
            );

            setErrorMessages(lastErrorMessage);

          } else if (
            message &&
            message.response &&
            message.response.data &&
            message.response.data.message &&
            !Array.isArray(message.response.data.message)
          ) {
            setErrorMessages([message?.response?.data?.message]);
          } else {
            setErrorMessages(["Có lỗi xảy ra vui lòng thử lại"]);
          }
        });
    };

    sendRequest();
  }
  const titleRender = () => {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <h1 style={{ fontSize: "20px", marginLeft: "20px" }}>
          Xóa cấu hình chi tiết
        </h1>
        <button
          style={{
            marginLeft: "150px",
            fontWeight: "600",
            marginBottom: "10px",
            border: "1px solid #edeae9",
            cursor: "pointer",
            width: "50px",
            height: "40px",
          }}
          onClick={hideInfo}
          id="closePopupInsert"
        >
          X
        </button>
      </div>
    );
  };

  return (
    <div>
      <Popup
        visible={open}
        dragEnabled={false}
        hideOnOutsideClick={true}
        showCloseButton={false}
        showTitle={true}
        titleRender={titleRender}
        width={450}
        height={200}
      >
        <div style={{ fontWeight: "bold", fontSize: "14px", marginBottom: '10px' }}>
          Bạn có chắc chắn muốn xóa không?
        </div>
        <Button
          icon="save"
          type="default"
          text="YES"
          onClick={deleteRow}
          style={{
            marginLeft: "200px",
          }}
        />
        <Button
          icon="close"
          type="default"
          text="NO  "
          onClick={hideInfo}
          style={{
            marginLeft: "10px",
          }}
        />
      </Popup>
    </div>
  );
};
