import { BaseRecord } from "@pankod/refine-core";
import { Tabs } from "@pankod/refine-antd";
import React, { useState } from "react";
import ArchiveScreen from "../mt-archive/ArchiveScreen";
import InboxScreen from "../mt_inbox/InboxScreen";
import StarredScreen from "../mt_starred/StarredScreen";
import "./tab.css";
import useTabState from "../state/tab";
const TabScreenInbox: React.FC = () => {
  const [dataScreenActivity, setDataScreenActivity] = useState([])
  const [dataScreenArchive, setDataScreenArchive] = useState([])
  const [dataStarred, setDataStarred] = useState<BaseRecord[]>([])
  const [tabActive, setTabActive] = useState("1")

  const { updateTabActive} = useTabState()

  return (
     <Tabs defaultActiveKey="1" onChange={(activeKey) => {
      setTabActive(activeKey)
      updateTabActive(activeKey)
     }}>
    <Tabs.TabPane tab="Activity" key="1">
     {tabActive==="1" && <InboxScreen dataScreenActivity={dataScreenActivity}
                   setDataScreenActivity={setDataScreenActivity}
                   dataScreenArchive={dataScreenArchive}
                   setDataScreenArchive={setDataScreenArchive}
                   dataStarred={dataStarred}
                   setDataStarred = {setDataStarred}
     />}
    </Tabs.TabPane>

    <Tabs.TabPane tab="Star" key="2">
    {tabActive==="2" && <StarredScreen dataStarred = {dataStarred} 
                     setDataStarred = {setDataStarred}
                     dataScreenActivity={dataScreenActivity}
                     setDataScreenActivity={setDataScreenActivity}
                     dataScreenArchive={dataScreenArchive}
                     setDataScreenArchive={setDataScreenArchive}

      />}
    </Tabs.TabPane>
    
    <Tabs.TabPane tab="Archive" key="3">
      {tabActive==="3" && <ArchiveScreen dataScreenActivity={dataScreenActivity}
                     setDataScreenActivity={setDataScreenActivity}
                     dataScreenArchive={dataScreenArchive}
                     setDataScreenArchive={setDataScreenArchive}
                     tabActive={tabActive}
      />}
    </Tabs.TabPane>
  </Tabs>
  )

}



export default TabScreenInbox;
