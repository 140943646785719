/* eslint-disable react-hooks/rules-of-hooks */
import { BaseRecord, useUpdate } from "@pankod/refine-core";
import { Avatar } from "components/avatar/avatar";
import { useMyTask } from "components/Mytask/Context/MyTask.context";
import { Like } from "components/Mytask/mtDetails/mtDetails-Content/Comments/Like/Like";
import { useGetValue } from "hooks/useGetValue";
import moment from "moment";
import { useEffect, useState } from "react";
import {
  AiFillMessage,
  AiFillStar,
  AiOutlineCheckCircle,
} from "react-icons/ai";
import { BiSquareRounded } from "react-icons/bi";
import { BsArchive } from "react-icons/bs";
import { HiArrowNarrowLeft } from "react-icons/hi";
import { IoIosArrowDropdown, IoIosArrowDropdownCircle } from "react-icons/io";
import CommentItem from "../mt_activity/assignee/CommentItem";
import FavoriteInbox from "../mt_activity/assignee/FavoriteInbox";
import ProjectNameItem from "../mt_activity/assignee/ProjectNameItem";
import FavoriteScreenStarred from "./FavoriteScreenStarred";

interface IspropStarredItem {
  starredItem: BaseRecord;
  getUserId: string | null;
  lastElement?: any;
  index: any;
  type: any;
  dataStarred: any;
  setDataStarred: any;
  setPage: any;
  getIdStarred: any;
  dataScreenActivity:any
  setDataScreenActivity:any
  dataScreenArchive:any
  setDataScreenArchive:any
}
const StarredItem: React.FC<IspropStarredItem> = ({
  starredItem,
  getUserId,
  lastElement,
  index,
  type,
  dataStarred,
  setDataStarred,
  setPage,
  getIdStarred,
  dataScreenActivity,
  setDataScreenActivity,
  dataScreenArchive,
  setDataScreenArchive
}) => {
  const values = useMyTask();
  const [isOpen, setIsopen] = useState(true);
  const { mutate } = useUpdate();  
  const handleArchire = (starredItem: any, index: any) => {
    mutate(
      {
        resource:
          type === "All"
            ? "resourcesmentions/archive"
            : "resourcesfilterlastesmention/archive",
        values: {
          isArchived: false,
          resourceId: starredItem?.resourceRelation?.resourcesParent?.id,
        },
        id: starredItem?.resourcesmentions?.at(0)?.id
          ? starredItem?.resourcesmentions?.at(0)?.id
          : useGetValue(starredItem, "resourcesmentions", type),
        invalidates: ["all"],
      },
      {
        onSuccess: (data, variables, context) => {
          setDataScreenArchive((prev: any) => [starredItem, ...prev]);
          setDataScreenActivity &&
            setDataScreenActivity((prev: any) => {
              const olddata = [...prev];
              const newdata = olddata.filter((cmt) => cmt.id !== starredItem?.id);
              if (newdata?.length === 0) {
                setPage(1);
              }
              return newdata;
            });
            setDataStarred &&
            setDataStarred((prev: any) => {
              const olddata = [...prev];
              const newdata = olddata.filter((cmt) => cmt.id !== starredItem?.id);
              if (newdata?.length === 0) {
                setPage(1);
              }
              return newdata;
            });

        },
      }
    );
  };
  useEffect(() => {
    if (isOpen) {
      values?.setShowMyTaskDetails(true);
      if (index === 0) {
        values?.setCurrentTaskDetailId(
          useGetValue(starredItem, "parentId", 'Starred')
        );
        values?.setCurrentTaskDetailTitle(
          useGetValue(starredItem, "resourcesParentName", 'Starred')
        );
      }
      values?.setHideArrowToRightScreenDetail("hide");
      setIsopen(false);
    }
  }, [values]);

  const handleDetailStarred = (starredItem: any) => {
    values?.setHideArrowToRightScreenDetail("hide");
    values?.setShowMyTaskDetails(true);
    values?.setCurrentTaskDetailId(useGetValue(starredItem, "parentId", 'Starred'));
    values?.setCurrentTaskDetailTitle(
      useGetValue(starredItem, "resourcesParentName", 'Starred')
    );
  };

  return (
    <div
      className="asign-item"
      key={starredItem.id}
      ref={lastElement ? lastElement : null}
      onClick={() => handleDetailStarred(starredItem)}
    >
      <div>
        <div className="assign-project-name">
          <div className="project-name-wrap">
            <ProjectNameItem
              projectName={useGetValue(starredItem, "resourcestasks", 'Starred')}
            />
          </div>
          <div className="task-wrapper">
            <div className="task-content-item">
              {useGetValue(starredItem, "complete", 'Starred') ? (
                <IoIosArrowDropdownCircle
                  size={20}
                  className={"row-item-icon completed"}
                />
              ) : (
                <IoIosArrowDropdown
                  size={20}
                  className={"row-item-icon incomplete"}
                />
              )}

              <span className="task-name">
                {useGetValue(starredItem, "resourcesParentName", 'Starred')}
              </span>
            </div>
            <div className="starred-inbox">
              {(
                useGetValue(starredItem, "isStarred", 'Starred')) && (
                <AiFillStar className="icon-starred-inbox" />
              )}
            </div>
          </div>
        </div>

        <div className="asign-wrap">
          <div className="user-asign">
            <div className="avatar">
              <Avatar userId={useGetValue(starredItem, "resourcecmId", 'Starred')} />

              <div className="message">
                <span className="icon-message">
                  <AiFillMessage />
                </span>
              </div>
            </div>
            <div className="task-content">
              <p className="user-name">
                {useGetValue(starredItem, "resourcecmName", 'Starred')}
              </p>

              <CommentItem
                commentItem={useGetValue(starredItem, "name", 'Starred')}
              />

              <div className="comment-date">
                {useGetValue(starredItem, "createdAt", 'Starred')
                  ? moment
                      .utc(useGetValue(starredItem, "createdAt", 'Starred'))
                      .local()
                      .startOf("seconds")
                      .fromNow()
                  : "--"}
              </div>
            </div>
          </div>
          {/* <span className="icon-like">
        <AiOutlineLike />
      </span> */}
          <div className="icon-like-wrap">
            <Like
              resourceId={useGetValue(starredItem, "id", 'Starred')}
              likes={useGetValue(starredItem, "resourcesLikes", 'Starred')}
              CurrentUserId={JSON.parse(getUserId as string)}
            />
          </div>
        </div>

        <div className="Archive-list">
          <FavoriteScreenStarred
            type={type}
            comment={starredItem}
            getIdStarred={getIdStarred}
          />
          {/* <span title="Create follow-up task" className="icon-check general-icon">
            <AiOutlineCheckCircle />
          </span>
          <span title="Mark as unread" className="unread-icon general-icon">
            <BiSquareRounded />
          </span>
          <span
            title="Archive notifications"
            className="archive-icon general-icon"
            onClick={() => handleArchire(starredItem,index)}
          >
            <BsArchive />
          </span> */}
        </div>
      </div>
    </div>
  );
};
export default StarredItem;
