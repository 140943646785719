import { MentionData } from '@draft-js-plugins/mention'
import { keyBy } from 'lodash'
import create from 'zustand'

export type Section = {
    name: string
    order: number
    sectionId: number
    createdAt: string
    tasks: {
        [field: string]: TaskType
    },
    extend: boolean
}

export type TaskType = {
    name: string
    order?: number
    taskId: number
    sectionId: number
    assignee: MentionData
    completed: boolean
    dueOn: string | null
    createdby: number

}

export enum ErrorCode {
    AUTHORIZE = 1,
    SERVER_ERROR = 2
}
export interface ProposeTaskType {
    projectId: number | null
    addProjectId: (id: number) => void
    sections: {
        [field: string]: Section
    },
    addSections: (sections: Section[]) => void
    sectionsError: ErrorCode | null
    addSectionsError: (err: ErrorCode) => void

    appendTasksToSection: (sectionId: number, tasks: any) => void
    updateTask: (sectionId: number, taskId: number, value: any) => void
    updateSection: (sectionId: number, value: any) => void
}

export const useProposeState = create<ProposeTaskType>((set) => ({
    projectId: null,
    addProjectId: (id: number) => set((state) => {
        state.projectId = id
        return { ...state }
    }),
    sections: {},
    addSections: (sections: Section[]) => set((state) => {
        state.sections = keyBy(sections, "sectionId")
        return { ...state }
    }),
    sectionsError: null,
    addSectionsError: (err: ErrorCode) => set((state) => {
        state.sectionsError = err
        return { ...state }
    }),

    appendTasksToSection: (sectionId: number, tasks: any) => set((state) => {
        const section = state.sections[sectionId]

        if (section) {
            const currentTasks = section?.tasks ? Object.values(section?.tasks) : []
            const updatedTasks = keyBy([...currentTasks, ...tasks], "taskId")

            section.tasks = updatedTasks
        }

        state.sections[sectionId] = section

        return { ...state }
    }),

    updateSection: (sectionId: number, value: any) => set((state) => {

        if (state.sections?.[sectionId]) {
            const sectionToUpdate = state.sections[sectionId]
            const updatedSection = { ...sectionToUpdate, ...value }

            state.sections[sectionId] = updatedSection

        }
        return { ...state }
    }),

    updateTask: (sectionId: number, taskId: number, value: any) => set((state) => {

        if (state.sections[sectionId]?.tasks[taskId]) {


            const taskToUpdate = state.sections[sectionId]?.tasks[taskId]
            const updatedTasks = { ...taskToUpdate, ...value }



            state.sections[sectionId].tasks[taskId] = updatedTasks

        }
        return { ...state }
    }),
}))





