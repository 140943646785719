import React from 'react'
import './index.scss'
import styled from 'styled-components'

const Wrap = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 48px) ;
`
const SectionLoader = () => {
  return (
    <Wrap>
        <div className='loader'/>
    </Wrap>
  )
}

export default SectionLoader