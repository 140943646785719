import { DataGrid } from "devextreme-react";
import axios from "axios";
import { Button, Toolbar } from "devextreme-react";
import {
  Column,
  FilterRow,
  HeaderFilter,
  Item as ItemToolbar,
  SearchPanel,
} from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { CauHinhPopupDelete } from "./cauhinhPopupDelete";
import { CauHinhPopupInsert } from "./cauhinhPopupInsert";
import { CauHinhPopupUpdate } from "./cauhinhPopupUpdate";
import "./cauhinhchitiet.css";
import { Search } from "pages/HeaderSidebar/Search";

interface Props {
  formId: number;
}

export const CauHinhChiTietHOC = React.memo(({ formId }: Props) => {
  const dataGrid = useRef<any | undefined>();
  const edittingKey = useRef(null);
  const cauHinhKey = useRef(null);
  const [openPopUp, setOpenPopup] = useState(false);
  const [openPopUpUpdate, setOpenPopupUpdate] = useState(false);
  const [openPopUpDelete, setOpenPopupDelete] = useState(false);
  const [dataRow, setDataRow] = useState({});
  const [listCauHinh, setListCauHinh] = useState([]);
  const [randomNumber, setRandomNumber] = useState(0);

  useEffect(() => {
    const sendRequest = async () => {
      if (formId) {
        const results = await axios.get(
          `/resources/get-all-detail-form/${formId}`
        );

        setListCauHinh(results.data);
      }
    };
    sendRequest();
  }, [formId, randomNumber]);

  const onRowClick = () => {};

  const onRowDblClick = () => {
    const currentFocus = dataGrid.current.instance.option("focusedRowKey");
    const data = dataGrid.current.instance.getDataByKeys([currentFocus])
      .resolveArgs[0][0];
    const rowIndex = dataGrid.current.instance.getRowIndexByKey(
      edittingKey?.current
    );

    if (rowIndex !== -1) {
      setDataRow(data);
      setOpenPopupUpdate(true);
    }
  };

  const onRowRemoving = () => {};

  const onInitNewRow = () => {
    dataGrid.current.instance.option("editing.popup.title", "Thêm bản ghi");
  };
  const onEditingStart = (e: any) => {
    dataGrid.current.instance.option(
      "editing.popup.title",
      "Chỉnh sửa bản ghi"
    );
  };

  const onFocusedRowChanged = (e: any) => {
    const clickedRowKey = e?.row?.data?.id;

    edittingKey.current = clickedRowKey;
    cauHinhKey.current = e?.row?.data?.loaicauhinh;
  };

  const linkRender = (e: any) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          color: "blue",
          backgroundColor: "transparent",
        }}
      >
        <button
          onClick={deleteRow}
          style={{
            backgroundColor: "inherit",
            border: "none",
            textDecoration: "underline",
            color: "blue",
          }}
        >
          Xoá
        </button>
      </div>
    );
  };

  const deleteRow = (e: any) => {
    const currentFocus = dataGrid.current.instance.option("focusedRowKey");
    const data = dataGrid.current.instance.getDataByKeys([currentFocus])
      .resolveArgs[0][0];
    setDataRow(data);
    setOpenPopupDelete(true);
  };

  const addRow = () => {
    setOpenPopup(true);
  };

  const editRow = (e: any) => {
    const currentFocus = dataGrid.current.instance.option("focusedRowKey");
    const data = dataGrid.current.instance.getDataByKeys([currentFocus])
      .resolveArgs[0][0];
    const rowIndex = dataGrid.current.instance.getRowIndexByKey(
      edittingKey?.current
    );

    if (rowIndex !== -1) {
      setDataRow(data);
      setOpenPopupUpdate(true);
    }
  };

  const renderColumn = (data: any) => {
    return <MoTaComponent id={data?.data?.id} type={data?.data?.type} />;
  };

  const MoTaComponent = ({ id, type }: any) => {
    return (
      <div>
        {type?.id === 1 ? (
          <div>
            {type?.projectName ? `${type?.projectName}` : ""}{" "}
            {type?.sectionName ? ` / ${type?.sectionName}` : ""}
          </div>
        ) : null}
        {type?.id === 2 ? <div>{type?.projectName}</div> : null}
        {type?.id === 3 ? (
          <div>
            {type?.permission?.isCreate ? "Thêm" : ""}{" "}
            {type?.permission?.isRead ? " / Đọc " : ""}{" "}
            {type?.permission?.isUpdate ? " / Sửa" : ""}{" "}
            {type?.permission?.isDelete ? " / Xóa" : ""}{" "}
          </div>
        ) : null}
      </div>
    );
  };


  return (
    <div style={{}} id="cauhinhchitietform">
      <h1 style={{ marginLeft: "10px" }}>CẤU HÌNH CHI TIẾT</h1>
      <div style={{ height: "100%" }}>
        <React.Fragment>
          <DataGrid
            dataSource={listCauHinh}
            showBorders={true}
            hoverStateEnabled={true}
            allowColumnReordering={true}
            rowAlternationEnabled={true}
            repaintChangesOnly={true}
            height="90%"
            width="100%"
            ref={dataGrid}
            remoteOperations={true}
            focusedRowEnabled={true}
            keyExpr="id"
            style={{ fontSize: "14px" }}
            allowColumnResizing={true}
            onRowClick={onRowClick}
            onRowDblClick={onRowDblClick}
            onRowRemoving={onRowRemoving}
            onInitNewRow={onInitNewRow}
            onEditingStart={onEditingStart}
            onFocusedRowChanged={onFocusedRowChanged}
          >
            <FilterRow visible={true} />
            <HeaderFilter visible={true} />
            <Column
              dataField="type.text"
              caption="Loại Cấu Hình"
              dataType="string"
              width={170}
            ></Column>
            <Column
              dataField="type.mota"
              caption="Mô Tả"
              dataType="string"
              width={270}
              cellRender={renderColumn}
              allowFiltering={true}
            >
            </Column>
            <Column
              dataField="link"
              caption="Xóa"
              dataType="string"
              width={70}
              cellRender={linkRender}
              allowResizing={false}
            />
          </DataGrid>
          <Toolbar>
            <ItemToolbar location={"before"}>
              <Button
                style={{ marginLeft: "5px" }}
                icon="add"
                type="default"
                onClick={addRow}
                text="Thêm Mới"
              />
            </ItemToolbar>
            <ItemToolbar location={"before"}>
              <Button
                style={{ marginLeft: "5px" }}
                icon="edit"
                type="default"
                onClick={editRow}
                text="Sửa"
              />
            </ItemToolbar>
          </Toolbar>
        </React.Fragment>
        <CauHinhPopupInsert
          open={openPopUp}
          setOpen={setOpenPopup}
          dataGrid={dataGrid}
          formId={formId}
          setRandomNumber={setRandomNumber}
        />
        <CauHinhPopupUpdate
          open={openPopUpUpdate}
          setOpen={setOpenPopupUpdate}
          dataRow={dataRow}
          dataGrid={dataGrid}
          formId={formId}
          setRandomNumber={setRandomNumber}
        />
        <CauHinhPopupDelete
          open={openPopUpDelete}
          setOpen={setOpenPopupDelete}
          dataRow={dataRow}
          dataGrid={dataGrid}
          formId={formId}
          setRandomNumber={setRandomNumber}
        />
      </div>
    </div>
  );
});
