import { AntdLayout, Dropdown, Menu } from "@pankod/refine-antd";
import { BaseRecord, useLogout, useRouterContext } from "@pankod/refine-core";
import { TOKEN_KEY_LOGIN, USER_ID } from "authProvider";
import { Avatar } from "components/avatar/avatar";
import { TeamContext } from "context/contextapi";
import { Search } from "pages/HeaderSidebar/Search";
import "./HeaderSidebar.css";

import Mentionprojectshow from "pages/Teams/Inputmension/Mentionprojectshow";
import { useDetectProposePage } from "pages/ProposeTasks/hooks";

interface IsUserspath {
  path?: any;
  dataListproject?: any;
}
export const HeaderSidebar: React.FC<IsUserspath> = ({ path }) => {
  const userId = localStorage.getItem(USER_ID);
  const { mutate: mutateLogout } = useLogout();
  const dataListproject = TeamContext();
  const getParam = useRouterContext();
  const getParamId: any = getParam.useParams();
  let { id } = getParamId;
  const { useParams,useLocation } = useRouterContext();
  const params = useParams() as BaseRecord;
  const locations = useLocation() as BaseRecord;

  let {search} = locations
  const regex = /[?&]projectid=(\d+)/;
  const match = regex.exec(search);
  const projectId2 = match && match[1];
  const url = window.location.href;
  const checkMember  = url.includes('fullscreen=true')


  const {isProposeTaskPage} = useDetectProposePage()


  

  const idUserTaskList =JSON.parse(localStorage.getItem(TOKEN_KEY_LOGIN) || "") ;
  // console.log('idUserTaskList', idUserTaskList);
  // console.log('projectId2', projectId2);
  // console.log('checkMember', checkMember);
  
  const menuUser = (
    <Menu>
      <Menu.Item key="1" onClick={() => mutateLogout()}>
        Log Out
      </Menu.Item>
    </Menu>
  );


  return (
    <AntdLayout.Header
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "0px 24px",
        height: "0px",
        backgroundColor: "#f0f2f5",
        lineHeight: "none",
      }}
    >
      {path === "/Mytask" ? (
        <div className="header_home_right">
          <Search />
          <Dropdown overlay={menuUser} trigger={["click"]}>
            <span className="header_home_user">
              <a>Us</a>
            </span>
          </Dropdown>
        </div>
      ) 
      : path.includes('projects') || (projectId2 && projectId2 != idUserTaskList && !checkMember) || isProposeTaskPage  ? (
        <div className="header_home_right">
          <div className="header_home_Team_user ">
            <Mentionprojectshow id={id}/>
     
          </div>
          <Search />
          <div className="phuflex">
            <Dropdown overlay={menuUser} trigger={["click"]}>
              <span className="header_home_user">
                <Avatar userId={userId} isTooltip={false} />
              </span>
            </Dropdown>
          </div>
        </div>
      ) 
      // : path === `/projects/show/${id}` ? (
      //   <div className="header_home_right">
      //     <div className="header_home_Team_user ">
      //       <Mentionprojectshow id={id}/>
     
      //     </div>
      //     <Search />
      //     <div className="phuflex">
      //       <Dropdown overlay={menuUser} trigger={["click"]}>
      //         <span className="header_home_user">
      //           <Avatar userId={userId} isTooltip={false} />
      //         </span>
      //       </Dropdown>
      //     </div>
      //   </div>
      // ) 
      : (
        <div className="header_home_right">
          <Search />
          <Dropdown overlay={menuUser} trigger={["click"]}>
            <span className="header_home_user">
              <Avatar userId={userId} isTooltip={false} />
            </span>
          </Dropdown>
        </div>
      )}
    </AntdLayout.Header>
  );
};
