import create from 'zustand'

interface TabState {
    tabActive: string
    updateTabActive: (arg: string) => void
 
}
const useTabState = create<TabState>((set) => ({
    tabActive: "1",
    updateTabActive: (arg: string) => set((state) => {
        state.tabActive = arg
        return {...state}
    }),

    
}))

export default useTabState